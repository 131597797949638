import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";

import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";

import { addDriversData } from "../../CustomComponents/dataStore/actions";
import CustomizedSnackbars from "../Alert/Alert";
import Switch from '@mui/material/Switch';

import LinearProgress from '@mui/material/LinearProgress';
import { useLocation, useHistory, Link, useRouteMatch } from "react-router-dom";
import { useAuth } from "CustomComponents/Authcontext";

function Alldrivers() {
  const { currentUser } = useAuth();
  //model handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dd = useSelector((state) => state.All_DriversStore);
  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState(dd.nextKey || "");
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
  let { path, url } = useRouteMatch();
  let history = useHistory();
  //snack bar
  const [snakopen, setsnakopen] = useState(false)
  const [snackmessage, setsnackmessage] = useState(null)
  //set target 
  const [setdroid, setsetdroid] = useState({ id: '', email: '' })
  const [setprice, setsetprice] = useState(false)
  const [price_Target, setprice_Target] = useState(0)
  const [setrides, setsetrides] = useState(false)
  const [rides_Target, setrides_Target] = useState(0)
  const [snackbarcolor, setsnackbarcolor] = useState('error')
  const handleSnackbar = (messages) => {
    setsnakopen(true)
    setsnackmessage(messages)
  };
  useEffect(() => {
    if (dd.arr.length === 0) {
      calldataall_driver('');
    }
  }, [0]);

  function callTargteset() {
    if (setrides || setprice) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + currentUser.accessToken
      );
      myHeaders.append("Content-Type", "application/json");
      var data = {
        "type": "target",
      }
      if (setprice) {
        data.price_flag = setprice;
        data.price_target = price_Target

      }
      if (setrides) {
        data.rides_flag = setrides;
        data.rides_target = rides_Target

      }
      var raw = JSON.stringify(
        data
      );
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log(raw, setdroid);
      console.log();
      fetch(process.env.REACT_APP_BASE_URL+`/all_mappable?id=${setdroid.id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if(result.status){
            setsnackbarcolor('success')
            handleSnackbar('Target Set Successful')
          }else{
            setsnackbarcolor('error')
            handleSnackbar('Target Set Successful')
          }
          })
        .catch(error => console.log('error', error));
    }
  }
  async function calldataall_driver(nexttokenparam, limit = 10) {
    setnexttoggle(false);
    setspinnershow(true);
    if (nexttokenparam === undefined) {
      nexttokenparam = dd.nextKey || nextkey;
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + currentUser.accessToken
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return await fetch(
      process.env.REACT_APP_BASE_URL+"/all_mappable",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          setnextkey(result.start);
          //
          result.data.map((dat) => {
            dat.action = (
              <div >
                {/* use this button to add a edit kind of action */}
                <SuiButton
                  id={dat.id}
                  onClick={() => {
                    setsetdroid({ id: dat.id, email: dat.email })
                    handleOpen()
                  }}
                  color="warning"
                >
                  edit
                </SuiButton>{" "}

              </div>
            );
            dat.full_name = dat.first_name + " " + dat.last_name;
            if (dat.joindate) {
              dat.joindate = new Date(dat.joindate * 1000).toLocaleString("en-US", {
                day: "numeric",
                month: "numeric",
                year: "numeric",

              });
            }
            if (dat.dob) {
              dat.dob = new Date(dat.dob * 1000).toLocaleString("en-US", {
                day: "numeric",
                month: "numeric",
                year: "numeric",

              });
            }

          });
          dispatch(addDriversData(result.data, result.start, result.count || result.data.length))
          setnexttoggle(true);
          setspinnershow(false);
          return result.start;
        } else {
          alert(result.message)
          setnexttoggle(false);
          setspinnershow(false);
          return null;
          ;
          setmessagttouser("Unable to get data....ERROR : " + result.message);
          console.log("error", result.message);
        }
      })
      .catch((error) => {
        setnexttoggle(false);
        setspinnershow(false);

        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
        return null;

      });

  }
  if (dd.arr.length === 0) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox pt={2} pb={3}>
          <Card>
            <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
          </Card>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={2} pb={3}>
        <Card >
          {sipnnershow && (
            <SuiBox sx={{ width: '100%', m: 0, p: 0 }}>
              <LinearProgress color="success" sx={{ width: '100%' }} />
            </SuiBox>
          )}
          <DataTable table={dd.arr}
            hidecolumnsdata={[]}
            functioncallcount={() => { }}
            datacolumns={[
              {
                Header: "countries",
                accessor: "countries",
              },
              {
                Header: "email",
                accessor: "email",
              },
              {
                Header: "name",
                accessor: "name",
              },
              {
                Header: "role",
                accessor: "role",
              },
              {
                Header: "parent",
                accessor: "parent[0]",
              },

              {
                Header: "Edit Driver",
                accessor: "action",
              },
            ]}

            canSearch />
        </Card>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition

        >
          <Box sx={
            {
              border: 0,
              borderRadius: '10px',
              boxShadow: '0 0 15px rgb(0 0 0 / 15%), 0 0 1px 1px rgb(0 0 0 / 10%)',
              position: 'absolute',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              flexDirection: 'column',
              width: '60vw',
              pointerEvents: 'auto',
              backgroundColor: '#fff',
              backgroundClip: 'padding-box',
              outline: 0,
              padding: '20px 50px'
            }
          } >
            <Typography sx={{ textAlign: 'center' }}>
              Set Target for user {setdroid.email}
            </Typography> 
            <hr />
            <div style={{ margin: '20px' }}>
              <SuiTypography sx={{ fontSize: '1rem' }}  >  Set Price Target  <Switch sx={{ mx: 2 }} checked={setprice}
                onChange={(e) => {
                  setsetprice(e.target.checked)
                }} /></SuiTypography >


              {setprice ? <>
                <SuiInput required
                  type="number"
                  value={price_Target}
                  onChange={(e) => {
                    setprice_Target(e.target.value)
                  }
                  }

                ></SuiInput>
              </> : <></>

              }

            </div>
            <div style={{ margin: '20px' }}>
              <SuiTypography sx={{ fontSize: '1rem' }}  >  Set Rides Target  <Switch sx={{ mx: 2 }} checked={setrides}
                onChange={(e) => {
                  setsetrides(e.target.checked)
                }} /></SuiTypography >


              {setrides ? <>
                <SuiInput required
                  type="number"
                  value={rides_Target}
                  onChange={(e) => {
                    setrides_Target(e.target.value)

                  }
                  }

                ></SuiInput>
              </> : <></>

              }

            </div>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} xl={6}>
                <SuiButton
                  variant="gradient"
                  className=""
                  // disabled={isDisabled}
                  onClick={() => {
                    callTargteset()
                  }
                  }
                >
                  Submit
                </SuiButton>
              </Grid>
              <Grid item xs={6} sm={6} md={6} xl={6}>
                <SuiButton
                  sx={{ float: 'right' }} onClick={() => {
                    handleClose()
                  }}
                >
                  Close
                </SuiButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <CustomizedSnackbars color={snackbarcolor} message={snackmessage} open={snakopen} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Alldrivers;
