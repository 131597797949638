/*

*/
export const setloding = () => {
  return {
    type: "SETLOADING",
  };
};
/*.....Target store....*/

export const setTarget = (DATA) => {
  return {
    type: "SET_TARGET",
    payload: DATA,
  };
};
/*.....permissions room....*/

export const setpermissions = (DATA) => {
  return {
    type: "permissions",
    payload: DATA,
  };
};
/*.....MAPABLE room....*/

export const setMAPABLEDATA = (DATA) => {
  return {
    type: "ADD_MAPABLE_DETAILS",
    payload: DATA,
  };
};
/*.....ALL driver location ....*/

export const setALLDriverlocation = (DATA) => {
  return {
    type: "ADD_DRIVERLOC",
    payload: DATA,
  };
};
/*.....Localization room....*/

export const setLocalization = (DATA) => {
  return {
    type: "ADD_Location_DATA",
    payload: DATA,
  };
};
export const Logoutreset = () => {
  return {
    type: "USER_LOGGED_OUT",
  };
};
/*.....war room....*/

export const addUSERinfo = (data) => {
  return {
    type: "ADD_USER_INFO",
    payload: data,
  };
};
/*.....war room....*/

export const addwarroomData = (data) => {
  return {
    type: "ADD_WAR_ROOM_DATA",
    payload: data,
  };
};
export const addwarroomCity = (data) => {
  return {
    type: "ADD_WAR_ROOM_CITY",
    payload: data,
  };
};
/*.....All Riders....*/

export const addall_ridersData = (data, key, count) => {
  return {
    type: "ADD_RIDERS",
    payload: data,
    keynext: key,
    counts: count ? count : "0",
  };
};

export const addDatalocation = (data, lenth) => {
  return {
    type: "ADD_LOC",
    payload: data,
    length: lenth,
  };
};

/*all vendores*/
export const addVendoresData = (data) => {
  return {
    type: "ADD_VENDORS",
    payload: data,
  };
};

/*all drivers*/
export const addDriversData = (data, key) => {
  return {
    type: "ADD_DRIVERS",
    payload: data,
    nextKey: key,
  };
};
/*all vehicals*/
export const addvehicalsData = (data, key) => {
  return {
    type: "ADD_VEHICLES",
    payload: data,
    nextKey: key,
  };
};
/*all ratecard*/
export const addratecardData = (data, ddr) => {
  return {
    type: "ADD_RATE_CARD",
    payload: data,
    cities: ddr,
  };
};

/*all COMPLETED*/
export const addCompletedRidesData = (data, key, count) => {
  return {
    type: "ADD_COMPLETED_DATA",
    payload: data,
    keynext: key,
  };
};
/*all search COMPLETED*/
export const addSEARCHCompletedRidesData = (data, key, count) => {
  return {
    type: "ADD_SECOND_COMPLETED_DATA",
    payload: data,
    keynext: key,
  };
};
/*all clean COMPLETED*/
export const CLEANSEARCHCompletedRidesData = (data, key, count) => {
  return {
    type: "CLEAN_SECOND_COMPLETED_DATA",

  };
};
/*all CANCELLED*/
export const addCanceledRidesData = (data, key, count) => {
  return {
    type: "ADD_CANCELLED_DATA",
    payload: data,
    keynext: key,
  };
};
/*all search CANCELLED*/
export const addSEARCHCanceledRidesData = (data, key, count) => {
  return {
    type: "ADD_SECOND_CANCELLED_DATA",
    payload: data,
    keynext: key,
  };
};
/*all clean CANCELLED*/
export const CLEANSEARCHCanceledRidesData = (data, key, count) => {
  return {
    type: "CLEAN_SECOND_CANCELLED_DATA",

  };
};

/*all PENDING*/
export const addPendingRidesData = (data, key, count) => {
  return {
    type: "ADD_PENDING_DATA",
    payload: data,
    keynext: key,
  };
};
/*all search PENDING*/
export const addSEARCHPendingRidesData = (data, key, count) => {
  return {
    type: "ADD_SECOND_PENDING_DATA",
    payload: data,
    keynext: key,
  };
};
/*all clean PENDING*/
export const CLEANSEARCHPendingRidesData = (data, key, count) => {
  return {
    type: "CLEAN_SECOND_PENDING_DATA",

  };
};
/*all promo code*/
export const addPromocode = (data, key) => {
  return {
    type: "ADD_PROMOCODEDATA",
    payload: data,
    keynext: key,
  };
};
/* promo edit data*/
export const addEditPromocode = (data) => {
  return {
    type: "ADD_edit_PROMOCODE_Data",
    payload: data,
  };
}; /*remove edit data*/
export const removeEditPromocode = () => {
  return {
    type: "CLEAN_STATE_PROMOCODE",
  };
};
/*add user permition, used in create user permitions*/
export const adduserpermition = (data) => {
  return {
    type: "ADD_USER_PERMISSIONS",
    payload: data,
  };
};
export const removeuserpermition = (data) => {
  return {
    type: "DELETE_USER_PERMISSIONS",
    payload: data,
  };
};
export const Clearuserpermition = () => {
  return {
    type: "CLEAN_STATE",
  };
};
///permitiomn
export const addpermition = (data) => {
  return {
    type: "ADD_user_DATA",
    payload: data,
  };
};
//apiuserp///permitiomn
export const addapipermition = (data) => {
  return {
    type: "USER_PERMISSIONS",
    payload: data,
  };
};
//ongoingrides///permitiomn
export const addOngoingRidesData = (data) => {
  return {
    type: "ON_GOING_RIDES",
    payload: data,
  };
};
export const SETadminlodingtrue = () => {
  return {
    type: "SETadminloding",
  };
};
/*all driver analytics data*/
export const adddriver_statsData = (data, key, count) => {
  if (count.total !== undefined) {
    return {
      type: "ADD_DRIVER_ANALYTICS_DATA_FIRST",
      payload: data,
      keynext: key,
      totalcount: count,
    };
  } else {
    return {
      type: "ADD_DRIVER_ANALYTICS_DATA",
      payload: data,
      keynext: key,
    };
  }
};
/*all unlinked vehicles*/
export const adddunlinkedvehicleData = (data, key) => {
  return {
    type: "ADD_UNLINKED_VEHICLE",
    payload: data,
    keynext: key,
  };
};
/*all unlinked dro vehicles*/
export const adddunlinkedvehicledroData = (data, key) => {
  return {
    type: "ADD_UNLINKED_DRO_VEHICLE",
    payload: data,
    keynext: key,
  };
};
/*all linked dro vehicles*/
export const adddlinkedvehicledroData = (data) => {
  return {
    type: "ADD_DRO_Linked_SEARCH",
    payload: data,
    
  };
};
/*all CASH Collection*/
export const addCASHCollectionData = (data, key) => {
  return {
    type: "ADD_CASH_COLLECTION_DATA",
    payload: data,
    keynext: key,
  };
};
export const removeCASHCollectionData = (data) => {
  return {
    type: "REMOVE_CASH_COLLECTION_DATA",
    payload: data,
  };
};
///test
// export const addurl =  (data)=> {
//     return {
//         type: 'ADD_urlDATA',
//         payload: data,
//     }
// }
export const searchdata = (data) => {
  return {
    type: "ADD_SEARCH",
    payload: data,
  };
};
//dro search data
export const searchDROdata = (data) => {
  return {
    type: "ADD_DRO_SEARCH",
    payload: data,
  };
};
//Drounlinkedvehicleds
export const Drounlinkeddata = (data) => {
  return {
    type: "ON_DRO_VEHICLES",
    payload: data,
  };
};
//sim data
export const AddSimdata = (data, key) => {
  return {
    type: "ADD_SIM_DATA",
    payload: data,
    nextKey: key
  };
};
//mobile data
export const AddMobileddata = (data, key) => {
  return {
    type: "ADD_Mobile_DATA",
    payload: data,
    nextKey: key
  };
};
/*all Referral*/
export const addReferalData = (data, key) => {
  return {
    type: "ADD_REFERRAL",
    payload: data,
    nextKey: key,
  };
};
/// Accounts -----  Collection --------
export const addCollectionDrivers = (data, key) => {
  return {
    type: "ADD_COLLECTION_DRIVER_DATA",
    payload: data,
    keynext: key,
  };
};

/*all search COMPLETED*/
export const addSEARCHCollectionsDriversData = (data, key, count) => {
  return {
    type: "ADD_SECOND_COLLECTION_DRIVER_DATA",
    payload: data,
    keynext: key,
  };
};
/*all clean COMPLETED*/
export const CLEANSEARCHCollectionsDriversData = (data, key, count) => {
  return {
    type: "CLEAN_SECOND_COLLECTION_DRIVER_DATA",

  };
};
export const addCollectionRides = (data, key) => {
  return {
    type: "ADD_COLLECTION_RIDES_DATA",
    payload: data,
    nextKey: key,
  };
};
export const addCollectionRidesSearch = (data, key) => {
  return {
    type: "ADD_SECOND_COLLECTION_RIDES_DATA",
    payload: data,
    nextKey: key,
  };
};
export const CLEANCollectionRidesSearch = () => {
  return {
    type: "CLEAN_SECOND_COLLECTION_RIDES_DATA",

  };
};
export const addCollectionVehicle = (data, key) => {
  return {
    type: "ADD_COLLECTION_VEHICLE_DATA",
    payload: data,
    nextKey: key,
  };
};
/// Accounts -----  Revenue --------
export const addRevenueDrivers = (data, key) => {
  return {
    type: "ADD_REVENUE_DRIVER_DATA",
    payload: data,
    nextKey: key,
  };
};
export const addRevenueRides = (data, key, count) => {
  return {
    type: "ADD_REVENUE_RIDES_DATA",
    payload: data,
    nextKey: key,
    counts: count ? count : "0",
  };
};
export const addRevenueRidesSearch = (data, key, count) => {
  return {
    type: "ADD_REVENUE_RIDES_SEARCH_DATA",
    payload: data,
    nextKey: key,
    counts: count ? count : "0",
  };
};
export const addRevenueVehicle = (data, key) => {
  return {
    type: "ADD_REVENUE_VEHICLE_DATA",
    payload: data,
    nextKey: key,
  };
};
export const addRevenueSearch = (data, key) => {
  return {
    type: "ADD_REVENUE_MORE_DATA",
    payload: data,
    nextKey: key,

  };
};
export const addRevenueSearchClean = () => {
  return {
    type: "CLEAN",


  };
};
export const addRevenuedriverdetails = (data, key) => {
  return {
    type: "ADD_REVENUE_DRIVER_DETAIL_DATA",
    payload: data,
    nextKey: key,

  };
};
export const addRevenuedriverdetailsSearch = (data,) => {
  return {
    type: "ADD_REVENUE_DRIVER_DETAI_SEARCH_DATA",
    payload: data,

  };
};
export const addRevenuedriverdetailsClean = () => {
  return {
    type: "CLEAN",


  };
};
//VEHICLE DETAIL
export const addRevenueVehicleDetails = (data, key) => {
  return {
    type: "ADD_REVENUE_VEHICLE_DETAIL_DATA",
    payload: data,
    nextKey: key,

  };
};
export const addRevenueVehicleDetailsSearch = (data,) => {
  return {
    type: "ADD_REVENUE_VEHICLE_DETAI_SEARCH_DATA",
    payload: data,

  };
};
export const addRevenuedVehicleDetailsClean = () => {
  return {
    type: "CLEAN",


  };
};
export const addOutstandingDetails = (data, key) => {
  return {
    type: "ADD_OUTSTANDING_DATA",
    payload: data,
    nextKey: key,

  };
};
export const addHierarchyDetails = (data, key) => {
  return {
    type: "ADD_HIREARCHY_DETAILS",
    payload: data,
    nextKey: key,

  };
};

export const addHierarchyDetailsClean = () => {
  return {
    type: "ADD_HIREARCHY_DETAILS_Clean",

  };
};
//asset nlink data
export const addassetunlink = (data, key) => {
  return {
    type: "ADD_ASSET_SEARCHdata",
    payload: data,
    keynext: key,
  };
};

export const cleanAssetunlink = () => {
  return {
    type: "CLEAN_STATE_assets",
  };
};
//Loges ///
export const addLogsData = (data, key) => {
  return {
    type: "ADD_LOGS",
    payload: data,
    key, key

  };
};
export const addtempLogsData = (data, key) => {
  return {
    type: "ADD_LOGS_search",
    payload: data,
    key, key

  };
};
export const clearTempLogsData = () => {
  return {
    type: "CLEAR_LOGS_search_temp",
  };
};
export const clearLogsData = () => {
  return {
    type: "CLEAN_STATE_LOG",
  };
};
//permision copy ///
export const addpermisionData = (data) => {
  return {
    type: "PermisionSet",
    payload: data,
  };
};
/*add Blacklisted user*/
export const addBlacklisteduser =(data, key, count) => {
    return {
      type: "ADD_BLACKLISTED_USERS",
      payload: data,
      keynext: key,
    };
};