import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import { useHistory } from "react-router-dom";

import { adduserpermition } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import { RTdatabase } from "../../CustomComponents/firebase";
import {  ref as retS, set,update } from "firebase/database";
function AddCityRateCard(props) {
  let datarr = props.location.customCRCData;
  let history = useHistory();
  const [nexttoggle, setnexttoggle] = useState(true);
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [isDisabled, setisDisabled] = useState(false);
  const [sipnnershow, setsipnnershow] = useState(false);
  const [network_charges, setnetwork_charges] = useState(0)
  const [share_network_charges, setshare_network_charges] = useState(0)

  //city
  const [cityName, setcityName] = useState("");
  const [typename, settypename] = useState("");
  //share
  const [baseFare, setbaseFare] = useState(0);
  const [convenience_multiplier, setconvenience_multiplier] = useState(0);
  const [lasg_fee, setlasg_fee] = useState(0);
  const [min_fare, setmin_fare] = useState(0);
  const [peak_multiplier, setpeak_multiplier] = useState(0);
  const [taxes, settaxes] = useState(0);
  const [time_multiplier, settime_multiplier] = useState(0);
  const [toll_charges, settoll_charges] = useState(0);
  const [percentage_2, setpercentage_2] = useState(0);

  //sdistance peak_multiplier
  const [Value0_4, setValue0_4] = useState(0);
  const [Value4_8, setValue4_8] = useState(0);
  const [Value8_12, setValue8_12] = useState(0);
  const [Value12_16, setValue12_16] = useState(0);
  const [Value16, setValue16] = useState(0);
  //single timegrid
  const [singletg1, setsingletg1] = useState(0);
  const [singletg2, setsingletg2] = useState(0);
  const [singletg3, setsingletg3] = useState(0);
  useEffect(() => {
    if (datarr === undefined) {
      setnexttoggle(true);
      history.goBack();
    } else {
      setcityName(datarr.city_name);
      settypename(datarr.type);
      setbaseFare(datarr.base_fare);
      setconvenience_multiplier(datarr.convenience_multiplier);
      setlasg_fee(datarr.lasg_fee);
      setmin_fare(datarr.min_fare);
      setpeak_multiplier(datarr.peak_multiplier);
      settaxes(datarr.taxes);
      settime_multiplier(datarr.time_multiplier);
      settoll_charges(datarr.toll_charges);
      setpercentage_2(datarr.share_percentage_2 || 0);
      setValue0_4(datarr["0-4"]);
      setValue4_8(datarr["4-8"]);
      setValue8_12(datarr["8-12"]);
      setValue12_16(datarr["12-16"]);
      setValue16(datarr["16>"]);
      if (datarr.time_grid !== undefined) {
        setsingletg1(datarr.time_grid.tg1);
        setsingletg2(datarr.time_grid.tg2);
        setsingletg3(datarr.time_grid.tg3);
      }
      setnexttoggle(false);
    }
  }, [datarr]);

  const handleSubmit1 = (e) => {
    e.preventDefault();
    setsipnnershow(true)
    setisDisabled(true);
    var cname = cityName.toLowerCase();
    var data = {
      [typename]: {
        base_fare: Number(baseFare),
        convenience_multiplier: Number(convenience_multiplier),
        distance_multiplier: {
          "0-4": Number(Value0_4),
          "12-16": Number(Value12_16),
          "16>": Number(Value16),
          "4-8": Number(Value4_8),
          "8-12": Number(Value8_12),
        },
        time_grid: {
          tg1: Number(singletg1),
          tg2: Number(singletg1),
          tg3: Number(singletg1),
        },
        lasg_fee: Number(lasg_fee),
        min_fare: Number(min_fare),
        peak_multiplier: Number(peak_multiplier),
        taxes: Number(taxes),
        time_multiplier: Number(time_multiplier),
        toll_charges: Number(toll_charges),
        network_charges:share_network_charges

      },
    };
    if (typename === "share") {
      data[typename].share_percentage_2 = Number(percentage_2);
    }
    console.log(data);
    // var dbSelect = database.ref("price_calculation/" + cname);
    update(retS(RTdatabase,"price_calculation/" + cname), data).then(() => {
        alert("successfully");
        setisDisabled(true);
        setsipnnershow(false)
        history.goBack();
      })
      .catch((e) => {alert(e);
        setsipnnershow(false)
        console.log(e);});
  };

  if (datarr === undefined || (datarr === null && nexttoggle)) {

    return (
      <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={2} pb={3}>
          <Card>
              <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
          </Card>
      </SuiBox>
      <Footer />
  </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={2} pb={3}>
        <Card sx={{ m: 2, p: 2,pt:0 }} >
        {sipnnershow && (
                        <Box sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{ width: '100%' }} />
                        </Box>
                    )}
          <form onSubmit={handleSubmit1} >
            <Grid container spacing={2} >
              <Grid item sm={12}>
               <Divider   />
                <h5 className="m-2">City Name</h5>
              </Grid>
              <Grid item md={4} className="ml-2">
                <FormGroup>
                  <SuiInput
                    required
                    placeholder="Enter city name"
                    disabled
                    type="text"
                    value={cityName}
                    onChange={(e) => {
                      setcityName(e.target.value);
                    }}
                  />
                </FormGroup>{" "}
              </Grid>
              <Grid item sm={12}>
               <Divider   />
                <h4 className="m-2" style={{ textTransform: "capitalize" }}>
                  {typename} Ride{" "}
                </h4>
               <Divider   />
              </Grid>
              <Grid item md={4} >
                <FormGroup>
                  <SuiTypography sx={{ fontSize: '0.9rem', }}  >Base Fare</SuiTypography >
                  <SuiInput
                    required
                    placeholder="Enter Base Fare"
                    type="number"
                    value={baseFare}
                    onChange={(e) => {
                      setbaseFare(e.target.value);
                    }}
                  />
                </FormGroup>{" "}
              </Grid>
              <Grid item md={4} >
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Network Charges</SuiTypography>
                                <SuiInput
                                    //required
                                    placeholder="Enter Base Fare"
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                    value={share_network_charges}
                                    onChange={(e) => {
                                        setshare_network_charges(e.target.value);
                                    }}
                                />
                            </FormGroup>{" "}
                        </Grid>
              <Grid item md={4} >
                <FormGroup>
                  <SuiTypography sx={{ fontSize: '0.9rem', }}  >convenience multiplier</SuiTypography >

                  <SuiInput
                    required
                    placeholder="Enter convenience multiplier"
                    type="number"
                    value={convenience_multiplier}
                    onChange={(e) => {
                      setconvenience_multiplier(e.target.value);
                    }}
                  />
                </FormGroup>{" "}
              </Grid>
              <Grid item md={12} className=" px-5">
               <Divider  />
                <h5 className="m-2">Distance Multiplier</h5>
               <Divider   />
                <Grid container spacing={2}>
                  <Grid item md={4} >
                    <FormGroup>
                      <SuiTypography sx={{ fontSize: '0.9rem', }}  >0 - 4</SuiTypography >

                      <SuiInput
                        required
                        value={Value0_4}
                        onChange={(e) => {
                          setValue0_4(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number"
                      />
                    </FormGroup>{" "}
                  </Grid>
                  <Grid item md={4} >
                    <FormGroup>
                      <SuiTypography sx={{ fontSize: '0.9rem', }}  >4 - 8</SuiTypography >

                      <SuiInput
                        required
                        value={Value4_8}
                        onChange={(e) => {
                          setValue4_8(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number"
                      />
                    </FormGroup>{" "}
                  </Grid>
                  <Grid item md={4} >
                    <FormGroup>
                      <SuiTypography sx={{ fontSize: '0.9rem', }}  >8 - 12</SuiTypography >

                      <SuiInput
                        required
                        value={Value8_12}
                        onChange={(e) => {
                          setValue8_12(e.target.value);
                        }}
                        placeholder="Enter  number"
                        type="number"
                      />
                    </FormGroup>{" "}
                  </Grid>
                  <Grid item md={4} >
                    <FormGroup>
                      <SuiTypography sx={{ fontSize: '0.9rem', }}  >12 - 16</SuiTypography >

                      <SuiInput
                        required
                        value={Value12_16}
                        onChange={(e) => {
                          setValue12_16(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number"
                      />
                    </FormGroup>{" "}
                  </Grid>
                  <Grid item md={4} >
                    <FormGroup>
                      <SuiTypography sx={{ fontSize: '0.9rem', }}  > 16 +</SuiTypography >

                      <SuiInput
                        required
                        value={Value16}
                        onChange={(e) => {
                          setValue16(e.target.value);
                        }}
                        placeholder="Enter  number"
                        type="number"
                      />
                    </FormGroup>{" "}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} className="mt-2 px-5">
               <Divider   />
                <h5 className="m-2">Time Grid Multiplier</h5>
               <Divider  />
                <Grid container spacing={2}>
                  <Grid item md={4} className="mt-4">
                    <FormGroup>
                      <SuiTypography sx={{ fontSize: '0.9rem', }}  >Less than 20 minutes</SuiTypography >

                      <SuiInput
                        required
                        value={singletg1}
                        onChange={(e) => {
                          setsingletg1(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number"
                      />
                    </FormGroup>{" "}
                  </Grid>
                  <Grid item md={4} className="mt-1">
                    <FormGroup>
                      <SuiTypography sx={{ fontSize: '0.9rem', }}  >
                        Greater than 20 minutes,Less than 40 minutes
                      </SuiTypography >

                      <SuiInput
                        required
                        value={singletg2}
                        onChange={(e) => {
                          setsingletg2(e.target.value);
                        }}
                        placeholder="Enter number"
                        type="number"
                      />
                    </FormGroup>{" "}
                  </Grid>
                  <Grid item md={4} className="mt-4">
                    <FormGroup>
                      <SuiTypography sx={{ fontSize: '0.9rem', }}  >Greater than 40 minutes</SuiTypography >

                      <SuiInput
                        required
                        value={singletg3}
                        onChange={(e) => {
                          setsingletg3(e.target.value);
                        }}
                        placeholder="Enter  number"
                        type="number"
                      />
                    </FormGroup>{" "}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} >
                <FormGroup>
                  <SuiTypography sx={{ fontSize: '0.9rem', }}  >LASG Fee</SuiTypography >

                  <SuiInput
                    required
                    value={lasg_fee}
                    onChange={(e) => {
                      setlasg_fee(e.target.value);
                    }}
                    type="number"
                  />
                </FormGroup>{" "}
              </Grid>
              <Grid item md={4} >
                <FormGroup>
                  <SuiTypography sx={{ fontSize: '0.9rem', }}  >Min Fare</SuiTypography >

                  <SuiInput
                    required
                    value={min_fare}
                    onChange={(e) => {
                      setmin_fare(e.target.value);
                    }}
                    placeholder="Enter  number"
                    type="number"
                  />
                </FormGroup>{" "}
              </Grid>
              <Grid item sm={4}>
                {" "}
                <FormGroup>
                  <SuiTypography sx={{ fontSize: '0.9rem', }}  >Peak Multiplier</SuiTypography >

                  <SuiInput
                    required
                    value={peak_multiplier}
                    onChange={(e) => {
                      setpeak_multiplier(e.target.value);
                    }}
                    placeholder="Enter Peak Multiplier"
                    type="number"
                  />
                </FormGroup>{" "}
              </Grid>
              <Grid item sm={4}>
                {" "}
                <FormGroup>
                  <SuiTypography sx={{ fontSize: '0.9rem', }}  >Taxes</SuiTypography >

                  <SuiInput
                    required
                    value={taxes}
                    onChange={(e) => {
                      settaxes(e.target.value);
                    }}
                    placeholder="Enter taxes"
                    type="number"
                  />
                </FormGroup>{" "}
              </Grid>
              {typename === "share" ? (
                <Grid item sm={4}>
                  {" "}
                  <FormGroup>
                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >
                      Percentage Increase for 2 Seats %
                    </SuiTypography >

                    <SuiInput
                      required
                      value={percentage_2}
                      onChange={(e) => {
                        setpercentage_2(e.target.value);
                      }}
                      placeholder="Enter Percentage Increase "
                      type="number"
                    />
                  </FormGroup>{" "}
                </Grid>
              ) : null}
              <Grid item sm={4}>
                {" "}
                <FormGroup>
                  <SuiTypography sx={{ fontSize: '0.9rem', }}  >Time Multiplier</SuiTypography >

                  <SuiInput
                    required
                    value={time_multiplier}
                    onChange={(e) => {
                      settime_multiplier(e.target.value);
                    }}
                    placeholder=""
                    type="number"
                  />
                </FormGroup>{" "}
              </Grid>{" "}
              <Grid item sm={4}>
                {" "}
                <FormGroup>
                  <SuiTypography sx={{ fontSize: '0.9rem', }}  >toll_charges</SuiTypography >

                  <SuiInput
                    required
                    value={toll_charges}
                    onChange={(e) => {
                      settoll_charges(e.target.value);
                    }}
                    type="number"
                  />
                </FormGroup>{" "}
              </Grid>{" "}
              <Grid item md={12} />
              <Grid item md={6}>
                {" "}
                <FormGroup>
                  <SuiTypography sx={{ fontSize: '0.9rem', }}  > </SuiTypography >
                  <SuiButton id="submit" disabled={isDisabled} type="submit">
                    Submit
                  </SuiButton>
                </FormGroup>{" "}
              </Grid>
              <Grid item md={6}>
                {" "}
                <FormGroup>
                  <SuiTypography sx={{ fontSize: '0.9rem', }}  > </SuiTypography >
                  <SuiButton
                    className="cancelButton"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </SuiButton>
                </FormGroup>{" "}
              </Grid>
            </Grid>
          </form>
        </Card>

      </SuiBox>
      <Footer />
    </DashboardLayout >
  );
}

export default AddCityRateCard;
