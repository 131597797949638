import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { styled } from '@mui/material/styles';
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// Data
import { useSelector } from "react-redux";
import Mapapp from "./MAP";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import CardContent from '@mui/material/CardContent';
import WarroomCards from "../Components/WarroomCountercards/WarroomCards";
///
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box } from "@material-ui/core";
import { LoadingManager } from "three";
///
const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    textAlign: 'center',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
        textAlign: 'center',

    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    textTransform: 'capitalize',
    padding: theme.spacing(2),
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    textAlign: 'center',

    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

function WarRoom() {
    const warroom = useSelector((state) => state.WarRoom.war_room);
    const citiesstore = useSelector((state) => state.Warroomcity.war_room);
    const ongoingrides = useSelector((state) => state.Ongoingridesdata);

    const userinfo = useSelector((state) => state.Userinfo);
    const Localization = useSelector((state) => state.Localization);
    const [isdisabledsetadmin, setisdisabledsetadmin] = useState(false)
    var cities = []
    if (citiesstore) {
        cities = Object.keys(citiesstore);
    }
    const [istargte, setistargte] = useState(null)
    const [selectCity, setselectCity] = useState(null);
    const [selectCountry, setselectCountry] = useState(userinfo.countries[0] || null);
    const [SelectType, setSelectType] = useState('All');
    const [SelectTypeCityCountry, setSelectTypeCityCountry] = useState({ country: userinfo.countries[0], city: null });

    const isloading = useSelector((state) => state.Isload);
    const [sharedridetilldate, setsharedridetilldate] = useState(false);
    const [singleridetilldate, setsingleridetilldate] = useState(false);
    const [availabledrivercount, setavailabledrivercount] = useState(0)
    const [sharedride, setsharedride] = useState(false);
    const [singleride, setsingleride] = useState(false);
    const [ongoing, setongoingCount] = useState(0);
    const [acceptedRides, setacceptedRides] = useState(0)
    const [cordinates, setcordinates] = useState({ lat: 6.5244, long: 3.3792 })
    const [iscountry, setiscountry] = useState(true)
    const avalabledrivers = useSelector((state) => state.Driverlocation.arr);

    const [warroomcarddata, setwarroomcarddata] = useState({
        SingleRides: 0,
        SharedRides: 0,
        TotalBooking: 0
    })
    //Accordion data
    const [singleridesdata, setsingleridesdata] = useState({
        rides_accepted: 0,
        rides_cancelled_by_drivers: 0,
        rides_cancelled_by_riders: 0,
        rides_completed: 0,
        rides_pending: 0,
        rides_requested: 0
    })
    const [shareridesdata, setshareridesdata] = useState({
        rides_accepted: 0,
        rides_cancelled_by_drivers: 0,
        rides_cancelled_by_riders: 0,
        rides_completed: 0,
        rides_pending: 0,
        rides_requested: 0
    })
    const [tillDatesingleridesdata, settillDatesingleridesdata] = useState({
        rides_accepted: 0,
        rides_cancelled_by_drivers: 0,
        rides_cancelled_by_riders: 0,
        rides_completed: 0,
        rides_pending: 0,
        rides_requested: 0
    })
    const [tillDateshareridesdata, settillDateshareridesdata] = useState({
        rides_accepted: 0,
        rides_cancelled_by_drivers: 0,
        rides_cancelled_by_riders: 0,
        rides_completed: 0,
        rides_pending: 0,
        rides_requested: 0
    })
    function tilldate(data) {
        if (data !== 0 && data !== undefined) {
            if (data[1] !== null || data[1] !== 0) {
                let previous_date = new Date(data[1] * 1000);
                let present_date = new Date();
                if (
                    previous_date.getFullYear() === present_date.getFullYear() &&
                    previous_date.getMonth() === present_date.getMonth() &&
                    previous_date.getDate() === present_date.getDate()
                ) {
                    return data[2] - data[0];
                } else return 0;
            } else {
                return 0;
            }
        } else { return 0 }
    }

    function cityDriverCounrCheck(city) {
        var count = 0
        if (userinfo.countries.length <= 1) {
            avalabledrivers.map((data) => {
                if (selectCountry !== null && userinfo.allcities.includes(data.city)) {
                    if (city === data.city) {
                        count = count + 1;
                    }
                }
            })
        } else {
            avalabledrivers.map((data) => {
                if (selectCountry !== null && userinfo.allcities[selectCountry].includes(data.city)) {
                    if (city === data.city) {
                        count = count + 1;
                    }
                }
            })
        }
        setavailabledrivercount(count)

    }
    useEffect(() => {

        var count = 0

        if (userinfo.countries.length <= 1) {
            avalabledrivers.map((data) => {
                if (selectCountry !== null && userinfo.allcities.includes(data.city)) {
                    count = count + 1;
                }
            })
        } else {
            avalabledrivers.map((data) => {
                if (selectCountry !== null && userinfo.allcities[selectCountry].includes(data.city)) {
                    count = count + 1;
                }
            })
        }
        setavailabledrivercount(count)
        var count1 = 0
        var count2 = 0

        if (selectCountry !== null) {
            ongoingrides.map((data) => {
                if (selectCountry !== null && data.country !== null && data.country !== undefined && data.country === selectCountry) {
                    count1 = count1 + 1;
                }
                if (selectCity !== null && data.city !== null && data.city !== undefined && data.city === selectCity && data.isAccepted === true && data.isVerified === true) {
                    count2 = count2 + 1;
                }
            })
        }
        setongoingCount(count1)
        setacceptedRides(count2)
    }, [selectCountry])
    useEffect(() => {

        var count = 0;
        var count2 = 0;
        if (selectCity !== null) {
            ongoingrides.map((data) => {
                if (selectCity !== null && data.city !== null && data.city !== undefined && data.city === selectCity && data.isAccepted === true && data.isVerified === false) {
                    count = count + 1;
                }
                if (selectCity !== null && data.city !== null && data.city !== undefined && data.city === selectCity && data.isAccepted === true && data.isVerified === true) {
                    count2 = count2 + 1;
                }
            })
        }
        setongoingCount(count)
        setacceptedRides(count2)

    }, [selectCity])

    useEffect(() => {
        if (userinfo.countries.length <= 1) {
            setselectCountry(userinfo.countries[0])
            setcordinates({ lat: Localization[userinfo.countries[0]].latitude || 6.5244, long: Localization[userinfo.countries[0]].longitude || 3.3792 })
            setisdisabledsetadmin(true)
        }
        var count = 0
        if (userinfo.countries[0] !== null) {
            ongoingrides.map((data) => {
                if (selectCountry !== null && data.country !== null && data.country !== undefined && data.country === userinfo.countries[0]) {
                    count = count + 1;
                }
            })

        }
        setongoingCount(count)
    }, []);
    useEffect(() => {

        if (userinfo.countries.length >= 0) {
            var cities = []
            if (userinfo.countries.length !== 1) {
                cities = userinfo.allcities[selectCountry || userinfo.countries[0]]
            }
            else {
                cities = userinfo.allcities
            }
            setcordinates({ lat: Localization[selectCountry].latitude || 6.5244, long: Localization[selectCountry].longitude || 3.3792 })
            if (cities.length !== 0 && warroom[cities[0].toLowerCase()] !== undefined && warroom[cities[0].toLowerCase()] !== null) {
                var ddd = {
                    rides_accepted: 0,
                    rides_cancelled_by_drivers: 0,
                    rides_cancelled_by_riders: 0,
                    rides_completed: 0,
                    rides_pending: 0,
                    rides_requested: 0
                }
                var singletoday = ddd;
                var sharetoday = ddd;
                var tilsingle = ddd;
                var tilshare = ddd;
                cities.map((item) => {
                    if (warroom[item.toLowerCase()] !== undefined) {
                        if ((warroom[item.toLowerCase()].single) !== undefined) {
                            singletoday = {
                                rides_accepted: singletoday.rides_accepted + tilldate(warroom[item.toLowerCase()].single.rides_accepted),
                                rides_cancelled_by_drivers: singletoday.rides_cancelled_by_drivers + tilldate(warroom[item.toLowerCase()].single.rides_cancelled_by_drivers),
                                rides_cancelled_by_riders: singletoday.rides_cancelled_by_riders + tilldate(warroom[item.toLowerCase()].single.rides_cancelled_by_riders),
                                rides_completed: singletoday.rides_completed + tilldate(warroom[item.toLowerCase()].single.rides_completed),
                                rides_pending: singletoday.rides_pending + tilldate(warroom[item.toLowerCase()].single.rides_pending),
                                rides_requested: singletoday.rides_requested + tilldate(warroom[item.toLowerCase()].single.rides_requested),
                            };
                        }
                        if ((warroom[item.toLowerCase()].share) !== undefined) {
                            sharetoday = {
                                rides_accepted: sharetoday.rides_accepted + tilldate(warroom[item.toLowerCase()].share.rides_accepted),
                                rides_cancelled_by_drivers: sharetoday.rides_cancelled_by_drivers + tilldate(warroom[item.toLowerCase()].share.rides_cancelled_by_drivers),
                                rides_cancelled_by_riders: sharetoday.rides_cancelled_by_riders + tilldate(warroom[item.toLowerCase()].share.rides_cancelled_by_riders),
                                rides_completed: sharetoday.rides_completed + tilldate(warroom[item.toLowerCase()].share.rides_completed),
                                rides_pending: sharetoday.rides_pending + tilldate(warroom[item.toLowerCase()].share.rides_pending),
                                rides_requested: sharetoday.rides_requested + tilldate(warroom[item.toLowerCase()].share.rides_requested),
                            };
                        }
                        if ((warroom[item.toLowerCase()].single) !== undefined) {
                            tilsingle = {
                                rides_accepted: warroom[item.toLowerCase()].single.rides_accepted !== undefined ? tilsingle.rides_accepted + warroom[item.toLowerCase()].single.rides_accepted[2] : tilsingle.rides_accepted,
                                rides_cancelled_by_drivers: warroom[item.toLowerCase()].single.rides_cancelled_by_drivers !== undefined ? tilsingle.rides_cancelled_by_drivers + warroom[item.toLowerCase()].single.rides_cancelled_by_drivers[2] : tilsingle.rides_cancelled_by_drivers,
                                rides_cancelled_by_riders: warroom[item.toLowerCase()].single.rides_cancelled_by_riders !== undefined ? tilsingle.rides_cancelled_by_riders + warroom[item.toLowerCase()].single.rides_cancelled_by_riders[2] : tilsingle.rides_cancelled_by_riders,
                                rides_completed: warroom[item.toLowerCase()].single.rides_completed !== undefined ? tilsingle.rides_completed + warroom[item.toLowerCase()].single.rides_completed[2] : tilsingle.rides_completed,
                                rides_pending: warroom[item.toLowerCase()].single.rides_pending !== undefined ? tilsingle.rides_pending + warroom[item.toLowerCase()].single.rides_pending[2] : tilsingle.rides_pending,
                                rides_requested: warroom[item.toLowerCase()].single.rides_requested !== undefined ? tilsingle.rides_requested + warroom[item.toLowerCase()].single.rides_requested[2] : tilsingle.rides_requested,
                            }
                        }

                        if ((warroom[item.toLowerCase()].share) !== undefined) {
                            tilshare = {
                                rides_accepted: warroom[item.toLowerCase()].share.rides_accepted !== undefined ? tilshare.rides_accepted + warroom[item.toLowerCase()].share.rides_accepted[2] : tilshare.rides_accepted,
                                rides_cancelled_by_drivers: warroom[item.toLowerCase()].share.rides_cancelled_by_drivers !== undefined ? tilshare.rides_cancelled_by_drivers + warroom[item.toLowerCase()].share.rides_cancelled_by_drivers[2] : tilshare.rides_cancelled_by_drivers,
                                rides_cancelled_by_riders: warroom[item.toLowerCase()].share.rides_cancelled_by_riders !== undefined ? tilshare.rides_cancelled_by_riders + warroom[item.toLowerCase()].share.rides_cancelled_by_riders[2] : tilshare.rides_cancelled_by_riders,
                                rides_completed: warroom[item.toLowerCase()].share.rides_completed !== undefined ? tilshare.rides_completed + warroom[item.toLowerCase()].share.rides_completed[2] : tilshare.rides_completed,
                                rides_pending: warroom[item.toLowerCase()].share.rides_pending !== undefined ? tilshare.rides_pending + warroom[item.toLowerCase()].share.rides_pending[2] : tilshare.rides_pending,
                                rides_requested: warroom[item.toLowerCase()].share.rides_requested !== undefined ? tilshare.rides_requested + warroom[item.toLowerCase()].share.rides_requested[2] : tilshare.rides_requested,
                            }
                        }
                    }
                })
                setsingleridesdata(singletoday)
                setshareridesdata(sharetoday)
                settillDatesingleridesdata(tilsingle)
                settillDateshareridesdata(tilshare)

            } else {
                setsingleridesdata(null)
                setshareridesdata(null)
                settillDatesingleridesdata(null)
                settillDateshareridesdata(null)
                alert('No data found for this option')
            }
        }
    }, [selectCountry])

    function changeridedatachangecity(item) {
        if (userinfo.countries.length >= 0) {
            var cities = []
            if (userinfo.countries.length !== 1) {
                cities = userinfo.allcities[selectCountry || userinfo.countries[0]]
            }
            else {
                cities = userinfo.allcities
            }
            if (cities.length !== 0 && warroom[cities[0].toLowerCase()] !== undefined) {
                var ddd = {
                    rides_accepted: 0,
                    rides_cancelled_by_drivers: 0,
                    rides_cancelled_by_riders: 0,
                    rides_completed: 0,
                    rides_pending: 0,
                    rides_requested: 0
                }
                var singletoday = ddd;
                var sharetoday = ddd;
                var tilsingle = ddd;
                var tilshare = ddd;
                if ((warroom[item.toLowerCase()].single) !== undefined) {

                    singletoday = {
                        rides_accepted: warroom[item.toLowerCase()].single.rides_accepted !== undefined ? tilshare.rides_accepted + warroom[item.toLowerCase()].single.rides_accepted[2] : tilshare.rides_accepted,
                        rides_accepted: tilldate(warroom[item.toLowerCase()].single.rides_accepted !== undefined ? warroom[item.toLowerCase()].single.rides_accepted : 0),
                        rides_cancelled_by_drivers: tilldate(warroom[item.toLowerCase()].single.rides_cancelled_by_drivers !== undefined ? warroom[item.toLowerCase()].single.rides_cancelled_by_drivers : 0),
                        rides_cancelled_by_riders: tilldate(warroom[item.toLowerCase()].single.rides_cancelled_by_riders !== undefined ? warroom[item.toLowerCase()].single.rides_cancelled_by_riders : 0),
                        rides_completed: tilldate(warroom[item.toLowerCase()].single.rides_completed !== undefined ? warroom[item.toLowerCase()].single.rides_completed : 0),
                        rides_pending: tilldate(warroom[item.toLowerCase()].single.rides_pending !== undefined ? warroom[item.toLowerCase()].single.rides_pending : 0),
                        rides_requested: tilldate(warroom[item.toLowerCase()].single.rides_requested !== undefined ? warroom[item.toLowerCase()].single.rides_requested : 0),
                    };
                } else singletoday = null;
                if ((warroom[item.toLowerCase()].share) !== undefined) {
                    sharetoday = {
                        rides_accepted: tilldate(warroom[item.toLowerCase()].share.rides_accepted !== undefined ? warroom[item.toLowerCase()].share.rides_accepted : 0),
                        rides_cancelled_by_drivers: tilldate(warroom[item.toLowerCase()].share.rides_cancelled_by_drivers !== undefined ? warroom[item.toLowerCase()].share.rides_cancelled_by_drivers : 0),
                        rides_cancelled_by_riders: tilldate(warroom[item.toLowerCase()].share.rides_cancelled_by_riders !== undefined ? warroom[item.toLowerCase()].share.rides_cancelled_by_riders : 0),
                        rides_completed: tilldate(warroom[item.toLowerCase()].share.rides_completed !== undefined ? warroom[item.toLowerCase()].share.rides_completed : 0),
                        rides_pending: tilldate(warroom[item.toLowerCase()].share.rides_pending !== undefined ? warroom[item.toLowerCase()].share.rides_pending : 0),
                        rides_requested: tilldate(warroom[item.toLowerCase()].share.rides_requested !== undefined ? warroom[item.toLowerCase()].share.rides_requested : 0),
                    };
                } else sharetoday = null;
                if (warroom[item.toLowerCase()].single !== undefined) {
                    tilsingle = {
                        rides_accepted: warroom[item.toLowerCase()].single.rides_accepted !== undefined ? warroom[item.toLowerCase()].single.rides_accepted[2] : tilsingle.rides_accepted,
                        rides_cancelled_by_drivers: warroom[item.toLowerCase()].single.rides_cancelled_by_drivers !== undefined ? warroom[item.toLowerCase()].single.rides_cancelled_by_drivers[2] : tilsingle.rides_cancelled_by_drivers,
                        rides_cancelled_by_riders: warroom[item.toLowerCase()].single.rides_cancelled_by_riders !== undefined ? warroom[item.toLowerCase()].single.rides_cancelled_by_riders[2] : tilsingle.rides_cancelled_by_riders,
                        rides_completed: warroom[item.toLowerCase()].single.rides_completed !== undefined ? warroom[item.toLowerCase()].single.rides_completed[2] : tilsingle.rides_completed,
                        rides_pending: warroom[item.toLowerCase()].single.rides_pending !== undefined ? warroom[item.toLowerCase()].single.rides_pending[2] : tilsingle.rides_pending,
                        rides_requested: warroom[item.toLowerCase()].single.rides_requested !== undefined ? warroom[item.toLowerCase()].single.rides_requested[2] : tilsingle.rides_requested,
                    }
                } else tilsingle = null;

                if (warroom[item.toLowerCase()].share !== undefined) {
                    tilshare = {
                        rides_accepted: warroom[item.toLowerCase()].share.rides_accepted !== undefined ? warroom[item.toLowerCase()].share.rides_accepted[2] : tilshare.rides_accepted,
                        rides_cancelled_by_drivers: warroom[item.toLowerCase()].share.rides_cancelled_by_drivers !== undefined ? warroom[item.toLowerCase()].share.rides_cancelled_by_drivers[2] : tilshare.rides_cancelled_by_drivers,
                        rides_cancelled_by_riders: warroom[item.toLowerCase()].share.rides_cancelled_by_riders !== undefined ? warroom[item.toLowerCase()].share.rides_cancelled_by_riders[2] : tilshare.rides_cancelled_by_riders,
                        rides_completed: warroom[item.toLowerCase()].share.rides_completed !== undefined ? warroom[item.toLowerCase()].share.rides_completed[2] : tilshare.rides_completed,
                        rides_pending: warroom[item.toLowerCase()].share.rides_pending !== undefined ? warroom[item.toLowerCase()].share.rides_pending[2] : tilshare.rides_pending,
                        rides_requested: warroom[item.toLowerCase()].share.rides_requested !== undefined ? warroom[item.toLowerCase()].share.rides_requested[2] : tilshare.rides_requested,
                    }
                } else tilshare = null;

                if (singletoday === null && sharetoday !== null) {
                    setwarroomcarddata({
                        SingleRides: 0,
                        SharedRides: sharetoday.rides_completed !== undefined ? sharetoday.rides_completed : 0,
                        TotalBooking: (sharetoday.rides_completed !== undefined ? sharetoday.rides_completed : 0 + 0)

                    })
                } if (sharetoday === null && singletoday !== null) {
                    setwarroomcarddata({
                        SingleRides: singletoday.rides_completed !== undefined ? singletoday.rides_completed : 0,
                        SharedRides: 0,
                        TotalBooking: (0 + singletoday.rides_completed !== undefined ? singletoday.rides_completed : 0) || 0
                    })
                }
                if (sharetoday !== null && singletoday !== null) {
                    setwarroomcarddata({
                        SingleRides: singletoday.rides_completed !== undefined ? singletoday.rides_completed : 0,
                        SharedRides: sharetoday.rides_completed !== undefined ? sharetoday.rides_completed : 0,
                        TotalBooking: (sharetoday.rides_completed !== undefined ? sharetoday.rides_completed : 0 + singletoday.rides_completed !== undefined ? singletoday.rides_completed : 0) || 0
                    })
                }
                setsingleridesdata(singletoday)
                setshareridesdata(sharetoday)
                settillDatesingleridesdata(tilsingle)
                settillDateshareridesdata(tilshare)
            } else {
                setwarroomcarddata({
                    SingleRides: 0,
                    SharedRides: 0,
                    TotalBooking: 0
                })
                setsingleridesdata(null)
                setshareridesdata(null)
                settillDatesingleridesdata(null)
                settillDateshareridesdata(null)
                alert('No data found for this option')
            }
        }

    }
    if (isloading && warroom.war_room === null) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{'Loading...'}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pb={3}>
                <div >
                    <Grid container spacing={2}>
                        <Grid item md={3}  >
                            <SuiTypography sx={{ fontSize: '0.9rem', }}  >

                                Select Country</SuiTypography>
                            {userinfo.countries.length !== 0 ? (
                                <SuiSelect
                                    required
                                    placeholder={'Query by country'}
                                    isDisabled={isdisabledsetadmin}
                                    value={selectCountry !== null ? { value: selectCountry, label: selectCountry } : null}
                                    options={userinfo.countries.map((data2) => ({ value: data2, label: data2 }))}
                                    onChange={(e) => {
                                        setiscountry(!iscountry)
                                        setselectCountry(e.value);
                                        setSelectTypeCityCountry({ country: e.value, city: null })
                                        setselectCity(null)
                                    }}
                                />
                            ) : null}
                        </Grid>

                        {selectCountry !== null ? userinfo.isadmin ? (
                            < >
                                <Grid item md={3}  >
                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select City</SuiTypography>
                                    <SuiSelect
                                        required
                                        placeholder={'Query by city'}
                                        value={selectCity !== null ? { value: selectCity, label: selectCity } : null}
                                        options={userinfo.allcities[selectCountry].map((data2, i) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            console.log(e.value.toLowerCase());
                                            if (warroom[(e.value.toLowerCase())] !== undefined) {
                                                setiscountry(!iscountry);
                                                setselectCity(e.value);
                                                changeridedatachangecity((e.value.toLowerCase()))
                                                setSelectTypeCityCountry({ ...SelectTypeCityCountry, city: e.value })
                                                setcordinates(warroom[(e.value.toLowerCase())].coordinates);
                                                cityDriverCounrCheck(e.value)
                                            }

                                            else {
                                                alert('This city is not found in database')
                                            }
                                        }}
                                    />
                                </Grid>
                            </>
                        ) : (
                            < >
                                <Grid item md={3}  >

                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select City</SuiTypography>
                                    <SuiSelect
                                        required
                                        widths={'16vw'}
                                        placeholder={'Query by city'}
                                        value={selectCity !== null ? { value: selectCity, label: selectCity } : null}
                                        options={userinfo.allcities.map((data2, i) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            if (warroom[(e.value.toLowerCase())] !== undefined) {
                                                setiscountry(!iscountry);
                                                setselectCity(e.value);
                                                changeridedatachangecity(e.value.toLowerCase())
                                                setSelectTypeCityCountry({ ...SelectTypeCityCountry, city: e.value })
                                                setcordinates(warroom[(e.value.toLowerCase())].coordinates);
                                                cityDriverCounrCheck(e.value)

                                            }
                                            else {
                                                alert('This city is not found in database')
                                            }
                                        }}
                                    />
                                </Grid>
                            </>
                        ) : null}

                        <Grid item lg={12} md={12} xs={12} sm={12}>
                            <Card sx={{ mb: 2 }}>
                                <Mapapp cord={cordinates} isCountry={iscountry} sortdata={SelectTypeCityCountry} typeSelect={SelectType} />
                            </Card>
                        </Grid>
                    </Grid>
                    {true ? (<Grid container spacing={2} >
                        {warroomcarddata !== null ? <Grid item lg={6} md={12} sm={12} >
                            < >
                                <Grid container spacing={2}>
                                    <Grid item lg={6} md={6} sm={12}>
                                        <WarroomCards
                                            color="success"
                                            count={availabledrivercount}
                                            title="Total Drivers"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12}>
                                        <WarroomCards
                                            color="success"
                                            count={ongoing}
                                            title="Processing Trips"
                                        />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4}>
                                        <WarroomCards
                                            color="success"
                                            count={warroomcarddata.SingleRides}
                                            title="Single Rides"
                                        />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4}>
                                        <WarroomCards
                                            color="success"
                                            count={acceptedRides}

                                            title="In progress Rides"
                                        />
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={4}>
                                        <WarroomCards
                                            color="success"
                                            count={warroomcarddata.TotalBooking}
                                            title="Total Booking"
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        </Grid> : null}
                        <Grid item xl={6} lg={6} md={12} sm={12} >
                            <Grid container spacing={2} >
                                {istargte ? <Grid item lg={12} md={12} sm={12} xl={12}>
                                    <Card  >
                                        <CardContent>
                                            <Grid container>
                                                <Grid item md={12} xs={12}>
                                                    <SuiTypography variant="caption" > Targeted Rides For The day : </SuiTypography >
                                                </Grid>
                                                <Grid item md={12} xs={12}>
                                                    <SuiTypography variant="caption" >
                                                        Number of Rides Yet To Achieve :
                                                    </SuiTypography >
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid> : null}
                                <Grid item md={4} sm={4} >
                                    <SuiButton sx={{
                                        width: '100%',
                                        height: '100%',
                                        background: "white",
                                        color: "blue",
                                        border: '2px solid blue',
                                        '&:hover': {
                                            border: '2px solid #fff',
                                            color: "#fff",
                                            background: "#41a7f5",
                                        },
                                    }} onClick={() => { setSelectType('All') }}>All Drivers</SuiButton>
                                </Grid>
                                <Grid item md={4} sm={4} >
                                    <SuiButton
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            background: "white",
                                            color: "green",
                                            border: '2px solid green',
                                            '&:hover': {
                                                border: '2px solid #fff',
                                                color: "#fff",
                                                background: "green",
                                            },
                                        }}

                                        color='success' onClick={() => { setSelectType('Waiting') }}>Available Drivers</SuiButton>
                                </Grid>
                                <Grid item md={4} sm={4} >
                                    <SuiButton
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            background: "white",
                                            color: "red",
                                            border: '2px solid red',
                                            '&:hover': {
                                                border: '2px solid #fff',
                                                color: "#fff",
                                                background: "red",
                                            },
                                        }}
                                        onClick={() => { setSelectType('OnGoing') }}>OnGoing Trip</SuiButton>

                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid item md={12} sx={{ mt: 2 }} >
                            <Grid container spacing={2}>
                                {singleridesdata !== null ? (
                                    <Grid item lg={6} md={6} sm={12}>
                                        <Card>
                                            <Accordion expanded={singleride} onChange={() => setsingleride(!singleride)}>
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                    <Typography sx={{
                                                        textAlign: 'center',
                                                    }}>Single Rides</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <CardContent style={{ textAlign: "left" }}>
                                                        <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>Ongoing Rides : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {ongoing}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>rides accepted : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {singleridesdata.rides_accepted}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>
                                                                    Rides cancelled by drivers :{" "}
                                                                </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {singleridesdata.rides_cancelled_by_drivers}

                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>
                                                                    Rides cancelled by riders :{" "}
                                                                </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {singleridesdata.rides_cancelled_by_riders}

                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>Rides completed : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {singleridesdata.rides_completed}
                                                                </span>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>Rides pending : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {singleridesdata.rides_pending}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>Rides requested : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {singleridesdata.rides_requested}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </AccordionDetails>
                                            </Accordion>

                                        </Card>
                                    </Grid>
                                ) : null}

                                {tillDatesingleridesdata !== null ? (
                                    <Grid item lg={6} md={6} sm={12}>
                                        <Card>
                                            <Accordion expanded={singleridetilldate} onChange={() => setsingleridetilldate(!singleridetilldate)}>
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                    <Typography sx={{
                                                        textAlign: 'center',
                                                    }}>Single Rides Till Date</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <CardContent style={{ textAlign: "left" }}>
                                                        {tillDatesingleridesdata.rides_accepted ? <Grid container spacing={2} sx={{ mt: 2 }}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>rides accepted : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {tillDatesingleridesdata.rides_accepted}
                                                                </span>
                                                            </Grid>
                                                        </Grid> : null}
                                                        {
                                                            tillDatesingleridesdata.rides_cancelled_by_drivers ? <Grid container spacing={2}>
                                                                <Grid item sm={6}>
                                                                    <h6 sx={{ ml: 3 }}>
                                                                        rides cancelled by drivers :{" "}
                                                                    </h6>
                                                                </Grid>
                                                                <Grid item sm={6}>
                                                                    <span>
                                                                        {
                                                                            tillDatesingleridesdata.rides_cancelled_by_drivers
                                                                        }
                                                                    </span>
                                                                </Grid>
                                                            </Grid> : null}
                                                        {
                                                            tillDatesingleridesdata.rides_cancelled_by_riders ? <Grid container spacing={2}>
                                                                <Grid item sm={6}>
                                                                    <h6 sx={{ ml: 3 }}>
                                                                        rides cancelled by riders :{" "}
                                                                    </h6>
                                                                </Grid>
                                                                <Grid item sm={6}>
                                                                    <span>
                                                                        {
                                                                            tillDatesingleridesdata.rides_cancelled_by_riders
                                                                        }
                                                                    </span>
                                                                </Grid>
                                                            </Grid> : null}
                                                        {tillDatesingleridesdata.rides_completed ? <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>rides completed : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {tillDatesingleridesdata.rides_completed}
                                                                </span>
                                                            </Grid>
                                                        </Grid> : null}
                                                        {tillDatesingleridesdata.rides_pending ? <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>rides pending : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {tillDatesingleridesdata.rides_pending}
                                                                </span>
                                                            </Grid>
                                                        </Grid> : null}
                                                        {tillDatesingleridesdata.rides_requested ? <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>rides requested : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {tillDatesingleridesdata.rides_requested}
                                                                </span>
                                                            </Grid>
                                                        </Grid> : null}
                                                    </CardContent>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Card>

                                    </Grid>
                                ) : null}
                                <Grid item lg={12} md={12} sm={12} />

                                {shareridesdata !== null ? (
                                    <Grid item lg={6} md={6} sm={12}>
                                        <Card>
                                            <Accordion expanded={sharedride} onChange={() => setsharedride(!sharedride)}>
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                    <Typography sx={{
                                                        textAlign: 'center',
                                                    }}>Shared Rides</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <CardContent style={{ textAlign: "left" }}>
                                                        <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>Rides accepted : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span >
                                                                    {shareridesdata.rides_accepted}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>
                                                                    Rides cancelled by drivers :{" "}
                                                                </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span >
                                                                    {shareridesdata.rides_accepted}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>
                                                                    Rides cancelled by riders :{" "}
                                                                </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span >
                                                                    {shareridesdata.rides_accepted}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>Rides completed : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {shareridesdata.rides_accepted}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>Rides pending : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {shareridesdata.rides_accepted}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>Rides requested : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {shareridesdata.rides_accepted}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Card>

                                    </Grid>
                                ) : null}
                                {tillDateshareridesdata !== null ? (
                                    <Grid item lg={6} md={6} sm={12}>
                                        <Card>
                                            <Accordion expanded={sharedridetilldate} onChange={() => setsharedridetilldate(!sharedridetilldate)}>
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                    <Typography sx={{
                                                        textAlign: 'center',
                                                    }}>Share Rides Till Date</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <CardContent style={{ textAlign: "left" }}>
                                                        {tillDateshareridesdata.rides_accepted ? <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>rides accepted : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span >
                                                                    {tillDateshareridesdata.rides_accepted}
                                                                </span>
                                                            </Grid>
                                                        </Grid> : null}
                                                        {
                                                            tillDateshareridesdata.rides_cancelled_by_drivers ? <Grid container spacing={2}>
                                                                <Grid item sm={6}>
                                                                    <h6 sx={{ ml: 3 }}>
                                                                        rides cancelled by drivers :{" "}
                                                                    </h6>
                                                                </Grid>
                                                                <Grid item sm={6}>
                                                                    <span >
                                                                        {
                                                                            tillDateshareridesdata.rides_cancelled_by_drivers
                                                                        }
                                                                    </span>
                                                                </Grid>
                                                            </Grid> : null}
                                                        {tillDateshareridesdata.rides_cancelled_by_riders ? <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>
                                                                    rides cancelled by riders :{" "}
                                                                </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span >
                                                                    {
                                                                        tillDateshareridesdata.rides_cancelled_by_riders
                                                                    }
                                                                </span>
                                                            </Grid>
                                                        </Grid> : null}
                                                        {tillDateshareridesdata.rides_completed ? <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>rides completed : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {tillDateshareridesdata.rides_completed}
                                                                </span>
                                                            </Grid>
                                                        </Grid> : null}
                                                        {tillDateshareridesdata.rides_pending ? <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>rides pending : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {tillDateshareridesdata.rides_pending}
                                                                </span>
                                                            </Grid>
                                                        </Grid> : null}
                                                        {tillDateshareridesdata.rides_requested ? <Grid container spacing={2}>
                                                            <Grid item sm={6}>
                                                                <h6 sx={{ ml: 3 }}>rides requested : </h6>
                                                            </Grid>
                                                            <Grid item sm={6}>
                                                                <span>
                                                                    {tillDateshareridesdata.rides_requested}
                                                                </span>
                                                            </Grid>
                                                        </Grid> : null}
                                                    </CardContent>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Card>

                                    </Grid>
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>) : null}
                </div>

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default WarRoom;
