import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import { ref as sRef, child, get, onValue } from "firebase/database";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import DownloadIcon from '@mui/icons-material/Download';
import Papa from "papaparse";
import { storage } from "../../CustomComponents/firebase";

import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Divider from "@mui/material/Divider";
import { useAuth } from "CustomComponents/Authcontext";

import { addReferalData } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import LinearProgress from '@mui/material/LinearProgress';

import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {

    useHistory,
} from "react-router-dom";
import { database } from "../../CustomComponents/firebase";
import { ref as retS, set } from "firebase/database";

function EditDriver(props) {
    let datarr = props.location.customCRCData;
    const isloading = useSelector((state) => state.Isload);
    let cities = useSelector((state) => state.Ratecarddata);
    let Userinfo = useSelector((state) => state.Userinfo);
    // isadmin
    var initialState = "";
    let history = useHistory();
    ///display proress
    const [uplode2, setuplode2] = useState({ label: "", value: 0 });
    const [uplode3, setuplode3] = useState({ label: "", value: 0 });
    const [uplode4, setuplode4] = useState({ label: "", value: 0 });
    const [uplode1, setuplode1] = useState({ label: "", value: 0 });
    //map api call
    const [apiresponce, setapiresponce] = useState(null);
    const [formatedaddress, setformatedaddress] = useState(null);
    const [address, setaddress] = useState(null);
    const [addresssetPrev, setaddresssetPrev] = useState(initialState)
    const [fname, setfname] = useState(initialState);
    const [lname, setlname] = useState(initialState);
    const [commission, setcommission] = useState(0);
    const [dob, setdob] = useState(initialState);
    const [gender, setgender] = useState(initialState);
    const [driverprofilepic, setdriverprofilepic] = useState(initialState);
    const [contactno, setcontactno] = useState(initialState);
    const [emailid, setemailid] = useState(initialState);
    const [city, setcity] = useState(initialState);
    const [joindate, setjoindate] = useState(initialState);
    const [licensevalidity, setlicensevalidity] = useState(initialState);
    const [license_back_pic, setlicense_back_pic] = useState(initialState);
    const [license_front_pic, setlicense_front_pic] = useState(initialState);
    const [licenseno, setlicenseno] = useState(initialState);
    const [lasdricard, setlasdricard] = useState(initialState);
    const [lasdrivalidity, setlasdrivalidity] = useState(null);
    const [police_verification, setpolice_verification] = useState(initialState);
    const [document_status, setdocument_status] = useState(initialState);
    const [police_verificationdoc, setpolice_verificationdoc] = useState(initialState);
    const [MaxDueAmount, setMaxDueAmount] = useState(20000);
    const [driver_id, setdriver_id] = useState(null);
    //changed data will be stored in this state
    const [changeddata, setchangeddata] = useState({})
    const { currentUser } = useAuth();
    const [selectCountry, setselectCountry] = useState(null);
    const [selectCity, setselectCity] = useState(null);
    const [editemail, seteditemail] = useState(false)
    const [newemail, setnewemail] = useState(null);
    const [password, setpassword] = useState('africar123')
    useEffect(() => {
        if (datarr === undefined) {
            //   setnexttoggle(true);
            history.goBack();
        } else {
            setapiresponce(null)
            setdriver_id(datarr.id)
            setfname(datarr.fname)
            setlname(datarr.lname)
            setformatedaddress(datarr.id)
            setcommission(datarr.commission)
            setdob(datarr.dob)
            setgender(datarr.gender)
            setdriverprofilepic(datarr.driver_profile_pic)
            setcontactno(datarr.contactno)
            setemailid(datarr.emailid)
            setcity(datarr.city)
            setjoindate(datarr.joindate)
            setlicensevalidity(new Date(datarr.licensevalidity * 1000).toLocaleDateString('en-CA'))
            setlicense_back_pic(datarr.license_back_pic)
            setlicense_front_pic(datarr.license_front_pic)
            setlicenseno(datarr.licenseno)
            setlasdricard(datarr.lasdricard)
            setpolice_verification(datarr.police_verification)
            setdocument_status(datarr.document_status)
            setpolice_verificationdoc(datarr.pvd)
            setMaxDueAmount(datarr.max_due)
            setselectCountry(datarr.country || null)
            setselectCity(datarr.city || null)
            getaddress(datarr.home_address)


            if (datarr.lasdrivalidity !== null || datarr.lasdrivalidity !== undefined) {
                setlasdrivalidity(new Date(datarr.lasdrivalidity * 1000).toLocaleDateString('en-CA'))
            }
            if (datarr.home_address !== null || datarr.home_address !== undefined) {
                setformatedaddress(datarr.home_address)
            }
        }
    }, [datarr]);
    function getaddress(home_address) {
        if (home_address === undefined) {
            const dbRef1 = sRef(database);
            get(child(dbRef1, `drivers/take_me_home/${datarr.id}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const datarate = snapshot.val();
                    console.log(datarate);
                    setaddresssetPrev(datarate.location_str);

                } else {
                    setaddresssetPrev('Home Address is not set for the driver')
                }
            }).catch((error) => {
                setaddresssetPrev('Home Address is not set for the driver')
                console.error(error);
            });
        } else {
            setaddresssetPrev('Home Address is not set for the driver')
        }
    }
    async function driverhome(id) {
        await set(retS(database, 'drivers/take_me_home/' + id), {
            location: [formatedaddress.geometry.location.lat, formatedaddress.geometry.location.lng],
            location_str: formatedaddress.formatted_address,
            status: false
        }).then((d) => {
            alert("Driver added Successfully");

        }).catch((f) => console.log(f))
    }

    const datesecond = (e) => {
        return new Date(e).getTime() / 1000;
    };

    function changeemailCall(params) {
        if (newemail !== null && emailid !== null) {
            var myHeaders = new Headers();
            myHeaders.append(
              "Authorization",
              "Bearer " + currentUser.accessToken
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "change_email_from": emailid,
                "change_email_to": newemail,
                "password": password //optional
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch( process.env.REACT_APP_BASE_URL+"/change_email", requestOptions)
                .then(response => response.json())
                .then(result => {
                    alert('Email update sucessfull')
                    console.log(result)
                })
                .catch(error => console.log('error', error));
        } else {

        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        // console.log(changeddata);
        if (currentUser.accessToken !== undefined && driver_id !== null) {
            // document.getElementById("submit").setAttribute("disabled", "disabled");
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(changeddata);
            var requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };
            fetch(
                process.env.REACT_APP_BASE_URL + "/all_drivers?id=" + driver_id,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === true) {
                        console.log(formatedaddress);
                        if (formatedaddress !== null && formatedaddress !== undefined) {
                            driverhome(driver_id);
                        }
                        alert("User data updated Successfull")
                        document.getElementById("submit").removeAttribute("disabled");
                        history.goBack()
                    } else {
                        alert("Error", result.message);
                        document.getElementById("submit").removeAttribute("disabled");
                    }
                })
                .catch((error) => console.log("error", error));
        } else {
            alert("accessToken error");
        }
    }
    function callapigeo(params) {
        setapiresponce(null)
        fetch(
            "https://maps.googleapis.com/maps/api/geocode/json?address=" + encodeURIComponent(address) + '&key=AIzaSyCUnt-bSmUWM_78k5Ggohf5sKvahSSFjuo')
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 'OK') {
                    setapiresponce(result.results)
                } else {
                    alert('search error ')
                }
            })
            .catch((error) => console.log("error", error));

    }
    function handeladdresschange(params) {
        setformatedaddress(apiresponce[params])
    }
    async function fileuplode(e, setdata, id, upoldevalue, setkey) {
        e.preventDefault();
        if (e.target.files[0] !== undefined) {
            const file = e.target.files[0];

            var fileurl = URL.createObjectURL(file)

            var timese = Math.floor(new Date().getTime() / 1000);

            const mountainsRef = ref(storage, "driver_info/" + timese + id + file.name);

            // const mountainsRef = ref(storage, "TESTUPLOAD_Dinesh/" + timese + id + file.name);
            const uploadTask = uploadBytesResumable(mountainsRef, file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    upoldevalue({ label: fileurl, value: prog });
                },
                (error) => console.log(error),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setdata(downloadURL)
                        changeddata[setkey] = downloadURL
                        setchangeddata(changeddata)
                    });
                }
            );
        }
    }

    if (isloading) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{'Loading Data...'}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        )
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={4} pb={3}>
                <Card sx={{ m: 'auto', p: 3 }}>

                    <form onSubmit={handleSubmit} className="w-100">
                        <Grid container spacing={3} >
                            <Grid item sm={12}>
                                <Divider />
                                <SuiTypography varient='h5'  >Driver Personal Details</SuiTypography  >
                                <Divider />
                            </Grid >
                            <Grid item md={4} sm={12} sx={12} >

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >First Name</SuiTypography >
                                <SuiInput

                                    placeholder="Enter first name"
                                    type="text"
                                    value={fname}
                                    onChange={(e) => {
                                        setfname(e.target.value);
                                        changeddata.fname = e.target.value
                                        setchangeddata(changeddata)
                                    }}
                                />

                            </Grid >

                            <Grid item md={4} sm={12} sx={12} >

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Last Name</SuiTypography >

                                <SuiInput

                                    placeholder="Enter last name"
                                    type="text"
                                    value={lname}
                                    onChange={(e) => {
                                        setlname(e.target.value);
                                        changeddata.lname = e.target.value
                                        setchangeddata(changeddata)
                                    }}
                                ></SuiInput>

                            </Grid >
                            <Grid item md={4} sm={12} sx={12} >

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Date of Birth</SuiTypography >

                                <SuiInput

                                    value={dob}
                                    onChange={(e) => {
                                        setdob(e.target.value);
                                        changeddata.dob = datesecond(e.target.value)
                                        setchangeddata(changeddata)
                                    }}
                                    type="date"
                                />

                            </Grid >
                            <Grid item md={4} sm={12} sx={12} >

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Gender</SuiTypography >
                                <SuiSelect
                                    sx={{ width: '100%' }}

                                    value={{ value: gender, label: gender }}
                                    options={["Male", "Female",
                                        "Do not wish to specify"].map((data2, i) => ({ value: data2, label: data2 }))}
                                    onChange={(e) => {
                                        setgender(e.value);
                                        changeddata.gender = e.value
                                        setchangeddata(changeddata)
                                    }}
                                />

                            </Grid >
                            <Grid item md={4} sm={12} sx={12} >

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Contact Number</SuiTypography >

                                <SuiInput

                                    value={contactno}
                                    onChange={(e) => {
                                        setcontactno(e.target.value);
                                        changeddata.contactno = e.target.value
                                        setchangeddata(changeddata)
                                    }}
                                    placeholder="Enter Contact number"
                                    type="text"
                                />

                            </Grid >

                            <Grid item md={4} sm={12} sx={12} >

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >city</SuiTypography >
                                {cities.asasa.length !== 0 ? (
                                    <SuiSelect
                                        sx={{ width: '100%' }}

                                        value={{ value: city, label: city }}
                                        options={cities.asasa.map((data2, i) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            setcity(e.value);
                                            changeddata.city = e.value
                                            setchangeddata(changeddata)
                                        }}
                                    />
                                ) : null}


                            </Grid >
                            <Grid item md={12} sm={12} lg={12} xl={12} >
                                <SuiTypography varient='h5'>Driver Home Address</SuiTypography  >
                                <SuiTypography varient='h6' sx={{ m: 2, p: 1 }}>{addresssetPrev}</SuiTypography  >
                                <SuiInput
                                    value={address}
                                    onChange={(e) => {
                                        setaddress(e.target.value);

                                    }}
                                    placeholder="Enter address"
                                    type="text"
                                />
                                <SuiButton sx={{ m: 1 }} onClick={() => callapigeo()}>Set Home Address</SuiButton>
                                {apiresponce !== null ? <SuiBox sx={{ ml: 4, p: 1, pl: 2 }}>
                                    <SuiTypography sx={{ fontSize: '1rem', }}  >Select Home Address </SuiTypography >
                                    <Divider></Divider><FormControl >
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="radio-buttons-group"
                                            // value={value}
                                            onChange={e => { handeladdresschange(e.target.value) }}
                                        >{apiresponce.map((d, key) => {
                                            return <FormControlLabel sx={{ disaply: 'inline' }} value={key.toString()} control={<Radio />} label={d.formatted_address} />
                                        })}</RadioGroup>
                                    </FormControl></SuiBox> : null}
                            </Grid >
                            <Grid item sm={12} md={12} sx={12}>
                                <Divider />
                                <SuiTypography varient='h5'  > Driver Profile Details</SuiTypography  >
                                <Divider />
                            </Grid >

                            <Grid item md={6} sm={12} sx={12}>
                                <Grid Container>
                                    <Grid item md={12} sm={12} sx={12}>
                                        {" "}
                                        {Userinfo.isadmin || Userinfo.role === 'DRO' ? <FormControlLabel
                                            value="end"
                                            control={<Switch checked={editemail} onChange={(e) => { seteditemail(e.target.checked) }} color="primary" />}
                                            label="Change email"
                                            labelPlacement="end"
                                        /> : ''}
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Email</SuiTypography >

                                        <SuiInput
                                            
                                            value={emailid}
                                            onChange={(e) => {
                                                setemailid(e.target.value);
                                                changeddata.lname = e.target.value
                                                setchangeddata(changeddata)
                                            }}
                                            placeholder="Enter email id"
                                            type="email"
                                        ></SuiInput>

                                    </Grid >
                                    {editemail ? <Grid item md={12} sm={12} sx={12}>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >New Email</SuiTypography >
                                        <SuiInput
                                            value={newemail}
                                            onChange={(e) => {
                                                setnewemail(e.target.value);
                                            }}
                                            type="text"
                                        /></Grid> : ''}
                                    {editemail ? <Grid item md={12} sm={12} sx={12}>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >New Password (optiomal)</SuiTypography >
                                        <SuiInput
                                            value={password}
                                            onChange={(e) => {
                                                setpassword(e.target.value);
                                            }}
                                            type="text"
                                        />
                                    </Grid > : ''}
                                    {editemail ? <Grid item md={12} sm={12} sx={{ mx: 'auto', my: 3 }}>
                                        <SuiButton color="info" id="submiddt" onClick={() => {
                                            changeemailCall()
                                        }}>
                                            Submit
                                        </SuiButton >
                                    </Grid > : ''}
                                    {!editemail ? <> <Grid item md={12} sm={12} sx={12}>

                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Join date</SuiTypography >

                                        <SuiInput
                                            disabled
                                            value={joindate}
                                            onChange={(e) => {
                                                setjoindate(e.target.value);
                                            }}
                                            type="text"
                                        />

                                    </Grid >
                                        <Grid item md={12} sm={12} sx={12}>

                                            <SuiTypography sx={{ fontSize: '0.9rem', }}  >
                                                Maximun Due Amount (Naira &#8358;)
                                            </SuiTypography >

                                            <SuiInput

                                                value={MaxDueAmount}
                                                onChange={(e) => {
                                                    setMaxDueAmount(e.target.value);
                                                    changeddata.max_due = e.target.value
                                                    setchangeddata(changeddata)
                                                }}
                                                type="number"
                                            />
                                        </Grid >
                                        <Grid item md={12} sm={12} sx={12}>

                                            <SuiTypography sx={{ fontSize: '0.9rem', }}  >
                                                Commission (%)
                                            </SuiTypography >

                                            <SuiInput

                                                value={commission}
                                                onChange={(e) => {
                                                    setcommission(e.target.value);
                                                    changeddata.commission = e.target.value
                                                    setchangeddata(changeddata)
                                                }}
                                                type="number"
                                            />
                                        </Grid ></> : ''}
                                </Grid>
                            </Grid >

                            <Grid item md={6} sm={12} sx={12}>
                                <center>
                                    {" "}
                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Driver profile picture</SuiTypography >

                                    <SuiInput

                                        className="w-50"
                                        onChange={(e) => {
                                            fileuplode(
                                                e,
                                                setdriverprofilepic,
                                                "profile",
                                                setuplode1,
                                                'driver_profile_pic'
                                            );
                                        }}
                                        type="file"
                                    />
                                    {driverprofilepic !== null ? <Grid item md={6} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                                        <img style={{ height: '12rem', width: '90%', borderRadius: '47%' }} src={driverprofilepic} alt="preview image" />
                                        {uplode1.value !== 0 ? <SuiTypography sx={{ fontSize: '0.9rem', }}  >profile picture uploded {uplode1.value}%</SuiTypography > : null}
                                    </Grid> : null}
                                </center>{" "}
                            </Grid >

                            <Grid item md={12} sm={12} sx={12}>
                                <Divider />
                                <SuiTypography varient='h5'  >Document Details</SuiTypography  >
                                <Divider />
                            </Grid >

                            <Grid item md={6} sm={12} sx={12}>

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >LASDRI CARD (for lagos City)</SuiTypography >

                                <SuiInput
                                    value={lasdricard}
                                    onChange={(e) => {
                                        setlasdricard(e.target.value);
                                        changeddata.lasdricard = e.target.value
                                        setchangeddata(changeddata)
                                    }}
                                    placeholder="Enter LASDRI CARD"
                                    type="text"
                                />

                            </Grid >
                            <Grid item md={6} sm={12} sx={12} >

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >
                                    LASDRI CARD validity (for lagos City)
                                </SuiTypography >

                                <SuiInput
                                    value={lasdrivalidity}
                                    onChange={(e) => {
                                        setlasdrivalidity(e.target.value);
                                        changeddata.lasdrivalidity = datesecond(e.target.value)
                                        setchangeddata(changeddata)
                                    }}
                                    type="date"
                                />

                            </Grid >

                            <Grid item md={6} sm={12} sx={12}>

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Document status</SuiTypography >
                                <SuiSelect

                                    value={{ value: document_status, label: document_status }}
                                    options={["Pending", "Approved",
                                        "Rejected"].map((data2, i) => ({ value: data2, label: data2 }))}
                                    onChange={(e) => {
                                        setdocument_status(e.value);
                                        changeddata.document_status = e.value
                                        setchangeddata(changeddata)
                                    }}
                                />


                            </Grid >
                            <Grid item md={6} sm={12} sx={12}>

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >police verification</SuiTypography >
                                <SuiSelect

                                    value={{ value: police_verification, label: police_verification }}
                                    options={["Pending", "Approved",
                                        "Rejected"].map((data2, i) => ({ value: data2, label: data2 }))}
                                    onChange={(e) => {
                                        setpolice_verification(e.value);
                                        changeddata.police_verification = e.value
                                        setchangeddata(changeddata)
                                    }}
                                />


                            </Grid >

                            <Grid item md={6} sm={12} sx={12} >

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >license number</SuiTypography >

                                <SuiInput

                                    value={licenseno}
                                    onChange={(e) => {
                                        setlicenseno(e.target.value);
                                        changeddata.licenseno = e.target.value
                                        setchangeddata(changeddata)
                                    }}
                                    type="text"
                                />

                            </Grid >

                            <Grid item md={6} sm={12} sx={12}>

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >license validity</SuiTypography >

                                <SuiInput

                                    value={licensevalidity}
                                    onChange={(e) => {
                                        setlicensevalidity(e.target.value);
                                        changeddata.licensevalidity = datesecond(e.target.value)
                                        setchangeddata(changeddata)
                                    }}
                                    type="date"
                                />

                            </Grid >
                            <Grid item md={12} xl={12} lg={12}>
                                <Grid container>
                                    <Grid item md={6} xl={6} lg={6} sx={{ mt: 'auto', mb: 'auto' }}>

                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >license front side picture</SuiTypography >


                                        <SuiInput

                                            className="w-50"
                                            onChange={(e) => {
                                                fileuplode(
                                                    e,
                                                    setlicense_front_pic,
                                                    "lfsp",
                                                    setuplode4,
                                                    'license_front_pic'
                                                );
                                            }}
                                            type="file"
                                        />
                                    </Grid>
                                    {license_front_pic !== 0 ? <Grid item md={6} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                                        <img style={{ height: '10rem', width: '100%' }} src={license_front_pic} alt="preview image" />
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode4.value}%</SuiTypography >
                                    </Grid> : null}

                                </Grid>


                            </Grid >
                            <Grid item md={12} xl={12} lg={12}>
                                <Grid container>
                                    <Grid item md={6} xl={6} lg={6} sx={{ mt: 'auto', mb: 'auto' }}>

                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >license back side picture</SuiTypography >

                                        <SuiInput

                                            className="w-50"
                                            onChange={(e) => {
                                                fileuplode(
                                                    e,
                                                    setlicense_back_pic,
                                                    "lbsp",
                                                    setuplode3,
                                                    'license_back_pic'
                                                );
                                            }}
                                            type="file"
                                        />{" "}

                                    </Grid>
                                    {license_back_pic !== null ? <Grid item md={6} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                                        <img style={{ height: '10rem', width: '100%' }} src={license_back_pic} alt="preview image" />
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode3.value}%</SuiTypography >
                                    </Grid> : null}

                                </Grid>


                            </Grid >
                            <Grid item md={12} xl={12} lg={12}>
                                <Grid container>
                                    <Grid item md={6} xl={6} lg={6} sx={{ mt: 'auto', mb: 'auto' }}>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >police verification Document</SuiTypography >
                                        <SuiInput

                                            onChange={(e) => {
                                                fileuplode(
                                                    e,
                                                    setpolice_verificationdoc,
                                                    "pvd",
                                                    setuplode2,
                                                    'police_verification_pic'
                                                );
                                            }}
                                            type="file"
                                        ></SuiInput>

                                    </Grid>
                                    {police_verificationdoc !== undefined ? <Grid item md={6} sm={6} xs={6} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                                        <img style={{ height: '10rem', width: '100%' }} src={police_verificationdoc} alt="preview image" />
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode2.value}%</SuiTypography >
                                    </Grid> : null}

                                </Grid>


                            </Grid >

                            <Grid item md={6} sm={6} sx={6}>
                                {" "}

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  > </SuiTypography >
                                <SuiButton color="info" id="submit" type="submit">
                                    Submit
                                </SuiButton >

                            </Grid >
                            <Grid item md={6} sm={6} sx={6}>
                                {" "}

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  > </SuiTypography >
                                <SuiButton color="info"
                                    className="cancelButton"
                                    onClick={() => history.goBack()}
                                >
                                    Cancel
                                </SuiButton >

                            </Grid >
                        </Grid>
                    </form>
                </Card>

            </SuiBox >
            <Footer />
        </DashboardLayout >
    );
}

export default EditDriver;
