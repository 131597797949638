import React, { useEffect, useState, useReducer } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';

import { addPromocode } from "CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import FormGroup from '@mui/material/FormGroup';
import { useHistory, useLocation } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import { useAuth } from "CustomComponents/Authcontext";

function Updatepromocode(props) {
  let history = useHistory();
  const location = useLocation();
  const { currentUser } = useAuth();

  let datarr = props.location.customNameData
  // if(props.location.customNameData===undefined||datarr===undefined){
  //   history.push('/africar/Promocode')
  // }
  let cities = useSelector((state) => state.Ratecarddata);
  const [messagttouser, setmessagttouser] = useState("Loading Data...")
  const [sipnnershow, setsipnnershow] = useState(false);

  const [islodingset, setislodingset] = useState(true)
  const [alertdata, setalertdata] = useState(null);
  const [couponname, setcouponname] = useState('');
  const [city, setcity] = useState();
  const [amountoff, setamountoff] = useState('');
  const [percentoff, setpercentoff] = useState('');
  const [coupondescription, setcoupondescription] = useState('');
  const [min_fare, setmin_fare] = useState();
  const [maxredAmount, setmaxredAmount] = useState();
  const [discountduration, setdiscountduration] = useState('');
  const [DiscountDurationInMounth, setDiscountDurationInMounth] =
    useState(null);
  const [Duration_in_days, setDuration_in_days] = useState(null)

  const [maxredemptionperuser, setmaxredemptionperuser] = useState('');
  const [redeem_by, setredeem_by] = useState();
  const [maxRedemption, setmaxRedemption] = useState('');

  const [upto, setupto] = useState();
  //tooltip
  const [tooltipOpen, setTooltipOpen] = useState(false);
 
  //
  const toggle = () => setTooltipOpen(!tooltipOpen);
  //week
  //storys
  const [story_desc, setstory_desc] = useState();
  const [story_redemption, setstory_redemption] = useState();
  const [unveils, setunveils] = useState();
  const [unveilable, setunveilable] = useState();
  const [unveils_id, setunveils_id] = useState();
  const [story, setstory] = useState(false);
  const [responsestory, setresponsestory] = useState(['']);
  const [paymentmethod, setpaymentmethod] = useState(null)
  //raw data
  const initialStat2 = {id: datarr!==undefined?datarr.id:null,city:datarr!==undefined?datarr.city:null,"object": "coupon",};
  const [state, updateState] = useReducer(
    (state, updates) => ({
      ...state,
      ...updates,
    }),
    initialStat2
  );
  //updateState({ ocupation: "postman" })
  useEffect(() => {
    if(props.location.customNameData===undefined||datarr===undefined){
      history.push('/africar/Promocode')

    }else{
    // console.log(datarr);
    if (props.location.customNameData && props.location.customNameData !== undefined) {
      if (datarr.unveils) {
        storyCall(datarr.city);
      }
      setstory_desc(datarr.story_desc)
      setstory_redemption(datarr.story_redemption)
      setunveils(datarr.unveils)
      setunveilable(datarr.unveilable)
      setunveils_id(datarr.unveils_id)
      setstory(datarr.story)
      // setresponsestory
      setpaymentmethod(datarr.payment_methods || [])
      setdiscountduration(datarr.duration)
      setcouponname(datarr.id || '')
      setamountoff(datarr.amount_off || '')
      setpercentoff(datarr.percent_off || '')
      setupto(datarr.upto || null)
      setcity(datarr.city)
      setDiscountDurationInMounth(datarr.duration_in_months)
      setmaxredemptionperuser(datarr.max_redemption_per_user)
      setmaxRedemption(datarr.max_redemption);
      setcoupondescription(datarr.description);
      setDuration_in_days(datarr.duration_in_days);
      setredeem_by(new Date(datarr.redeem_by).toISOString().substring(0, 10))
      setislodingset(false)
    }
  }
  }, [0])
  async function storyCall(citys) {
    if (citys === null) {
      alert('Please select city');
    } else {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + currentUser.accessToken
      );
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      await fetch(
        process.env.REACT_APP_BASE_URL + `/coupon?type=unveilable&city=${citys}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true && result.data.length !== 0) {
            setresponsestory(result.data)
          } else {
            alert('No Data To Load');
          }
        })
        .catch((error) => console.log("error", error));
    }
  }
  //handleform submit
  function handleSubmit(event) {
    event.preventDefault();
    setsipnnershow(true)
    if (amountoff !== null || amountoff !== '' && redeem_by !== null || redeem_by !== '') {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + currentUser.accessToken
      ); myHeaders.append("Content-Type", "application/json");
      console.log(state);
      
      var raw = JSON.stringify(state);
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(
        process.env.REACT_APP_BASE_URL + "/coupon",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            alert('Coupon ' + result.coupon_after.id + ' updated');
            setsipnnershow(false)
            history.goBack();
          } else {
            setsipnnershow(false)
            alert(`ERROR ${result.message}`);
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      setsipnnershow(false)
      alert("please Fill The Values ")
    }
  }
  if (props.location.customNameData === null || props.location.customNameData === undefined) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox pt={2} pb={3}>
          <Card>
            <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>No Data has Been Provided...
              {history.goBack()}
            </SuiTypography>
          </Card>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    );
  }
  if (islodingset) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox pt={2} pb={3}>
          <Card>
            <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
          </Card>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={1} pb={3}>
        <Card sx={{ m: 1, p: 2, pt: 0 }}>
          {sipnnershow && (
            <Box sx={{ width: '100%', m: 0, p: 0 }}>
              <LinearProgress color="success" sx={{ width: '100%' }} />
            </Box>
          )}
          <form onSubmit={handleSubmit} >

            <Grid container spacing={3} sx={{ mt: 1, p: 1 }}>
              <Grid item md={3} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Coupon Name/ID</SuiTypography>
                <SuiInput
                  required
                  disabled
                  placeholder="Enter Coupon Name/ID"
                  id="cnam"
                  type="text"
                  value={couponname}
                  onChange={(e) => {
                    setcouponname(e.target.value);
                  }}
                />
              </Grid>
              <Grid item md={3} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select City</SuiTypography>
                <SuiSelect
                  value={{ value: city, label: city }}
                  options={cities.asasa.map((data2, i) => ({ value: data2, label: data2 }))}
                  onChange={(e) => {
                    setcity(e.value);
                    updateState({ city: e.value })
                  }}
                />
              </Grid>
              {datarr.amount_off !== null ?
                <Grid item md={3} >
                  <SuiTypography sx={{ fontSize: '0.9rem', }}  >Amount Off</SuiTypography>

                  <SuiInput
                    required
                    value={amountoff}
                    onChange={(e) => {
                      setamountoff(e.target.value);
                      updateState({ amount_off: e.target.value })

                    }}
                    placeholder="Enter amount off"
                    type="number"
                  />
                </Grid> : null
              }
              {datarr.percent_off !== null ?
                <Grid item md={3} >
                  <SuiTypography sx={{ fontSize: '0.9rem', }}  >Percent Off</SuiTypography>

                  <SuiInput
                    required
                    value={percentoff}
                    onChange={(e) => {
                      updateState({ percent_off: e.target.value })

                      setpercentoff(e.target.value);
                    }}
                    placeholder="Enter percent off"
                    type="number"
                  />
                </Grid> : null}
              <Grid item md={3} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Discount Duration</SuiTypography>
                <SuiSelect
                  defaultValue={{ value: discountduration, label: discountduration }}
                  value={{ value: discountduration, label: discountduration }}
                  options={[{ value: 'once', label: 'Once' }, { value: 'forever', label: 'Forever' }, { value: 'repeating', label: 'Repeating Monthly' }, { value: 'repeating_daily', label: 'Repeating Daily' }]}
                  onChange={(e) => {
                    updateState({ duration: e.value })
                    setdiscountduration(e.value);
                  }}
                />
              </Grid>
              {discountduration === "repeating" ? (
                <Grid item md={3} >
                  <SuiTypography sx={{ fontSize: '0.9rem', }}  >Discount Duration(in Mounths)</SuiTypography>

                  <SuiInput
                    required
                    value={DiscountDurationInMounth}
                    onChange={(e) => {
                      updateState({ duration_in_months: e.target.value })

                      setDiscountDurationInMounth(e.target.value);
                    }}
                    placeholder="Enter Discount Duration"
                    type="number"
                  />
                </Grid>
              ) : null}
              {discountduration === "repeating_daily" ? (
                <Grid item md={3} >
                  <SuiTypography sx={{ fontSize: '0.9rem', }}  >Discount Duration Days</SuiTypography>
                  <SuiInput
                    required
                    value={Duration_in_days}
                    onChange={(e) => {
                      updateState({ duration_in_days: e.target.value })

                      setDuration_in_days(e.target.value);
                    }}
                    placeholder="Enter number of times to repeated"
                    type="number"
                  />
                </Grid>
              ) : null}
              <Grid item md={3} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Max Redemption per user</SuiTypography>

                <SuiInput
                  required
                  value={maxredemptionperuser || ''}
                  onChange={(e) => {
                    updateState({ max_redemption_per_user: e.target.value })

                    setmaxredemptionperuser(e.target.value);
                  }}
                  placeholder="Enter Max Redemption per user"
                  type="number"
                />
              </Grid>

              <Grid item md={3} >
                <SuiTypography sx={{ fontSize: '0.9rem', }} id="maxRedemption">
                  Max Redemption
                </SuiTypography>

                <SuiInput
                  required
                  value={maxRedemption || ''}
                  onChange={(e) => {
                    updateState({ max_redemption: e.target.value })

                    setmaxRedemption(e.target.value);
                  }}
                  placeholder="Enter Max Redemption"
                  type="number"
                />
              </Grid>
              <Grid item md={12} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Coupon Descrition</SuiTypography>

                <SuiInput
                  required
                  value={coupondescription || ''}
                  onChange={(e) => {
                    updateState({ description: e.target.value })

                    setcoupondescription(e.target.value);
                  }}
                  placeholder="Enter country name"
                  type="textarea"
                />
              </Grid>
              <Grid item md={3} >
                <SuiTypography sx={{ fontSize: '0.9rem', }} id="redeem_by">
                  Valid Till
                </SuiTypography>

                <SuiInput
                  required
                  value={redeem_by}
                  onChange={(e) => {
                    setredeem_by(e.target.value);
                  }}
                  placeholder="Enter date"
                  type="date"
                />
              </Grid>
              < Grid item md={3} ><FormGroup>

                <SuiTypography sx={{ fontSize: '0.9rem', }} id="payment_methods">
                  Payment Methods
                </SuiTypography>
                <SuiSelect
                  required
                  isMulti
                  placeholder={'Select payment method'}
                  value={paymentmethod !== null ? ()=>{ 
                   var temp=[]
                    paymentmethod.map(d=>{
                      temp.push({ value: d, label: d })
                    })
                    return temp } : null}
                  options={[
                    "card",
                    "cash",
                    "wallet"
                  ].map((data2, i) => ({ value: data2, label: data2 }))}
                  onChange={(e) => {
                    setpaymentmethod(e);
                    var temp=[]
                    e.map(d=>temp.push(d.value));
                    updateState({ payment_methods: temp })

                  }}
                />
              </FormGroup>
              </Grid>
              <Grid item md={12} sm={12} >
                <FormGroup>
                  <FormControlLabel control={<Switch checked={story} onChange={(e) => setstory(!story)} />} label="This coupon a part of coupon story" />
                </FormGroup>
              </Grid>
              {story ? <>
                <Grid item md={21} sm={12} ><FormGroup>
                  <SuiTypography sx={{ fontSize: '0.9rem', }}  >Coupon Story Description</SuiTypography>
                  <SuiInput
                    required
                    value={story_desc}
                    onChange={(e) => {
                      updateState({ story_desc: e.target.value })
                      setstory_desc(e.target.value);
                    }}
                    placeholder="Coupon Story Description"
                    type="text"
                  /> </FormGroup>
                </Grid>

                <Grid item md={6} sm={6} >
                  <FormControlLabel
                    sx={{ ml: 3 }}
                    label={"unveiled by other coupon"}
                    control={
                      <Checkbox
                        name={''}
                        checked={unveilable}
                        onChange={(e) => {
                          setunveils(!e.target.checked)
                          setunveilable(e.target.checked)
                          updateState({ unveilable: e.target.checked, unveils: !e.target.checked })

                        }
                        }
                      />
                    }
                  />
                </Grid>
                <Grid item md={6} sm={6} >
                  <FormControlLabel
                    sx={{ ml: 3 }}
                    label={"unveil other coupon"}
                    control={
                      <Checkbox
                        name={''}
                        checked={unveils}
                        onChange={async (e) => {
                          setunveilable(!e.target.checked)
                          setunveils(e.target.checked)
                          updateState({ unveilable: !e.target.checked, unveils: e.target.checked })
                          await storyCall(city)

                        }
                        }
                      />
                    }
                  />
                </Grid>
                {unveils ? <Grid item md={6} sm={6} >
                  <SuiTypography sx={{ fontSize: '0.9rem', }}  >Coupon to be unveiled by the current coupon</SuiTypography>
                  <SuiSelect
                    required
                    value={{ value: unveils_id, label: unveils_id }}
                    options={responsestory.map((data2, i) => ({ value: data2.id, label: data2.id }))}
                    onChange={(e) => {
                      updateState({ unveils_id: e.value })
                      setunveils_id(e.value);
                    }}
                  />
                </Grid> : null}
                <Grid item md={3} ><FormGroup>

                  <SuiTypography sx={{ fontSize: '0.9rem', }}  >Redemption period </SuiTypography>

                  <SuiInput
                    required
                    value={story_redemption}
                    onChange={(e) => {
                      updateState({ story_redemption: e.target.value })

                      setstory_redemption(e.target.value);
                    }}
                    placeholder="Redemption period"
                    type="number"
                  /> </FormGroup>
                </Grid>
              </> : ''}
            </Grid>
            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Grid item md={6}>
                <SuiButton onClick={(e) => handleSubmit(e)} type="submit">
                  Submit
                </SuiButton>
              </Grid>
              <Grid item md={6}>
                <SuiButton
                  onClick={() => history.goBack()}
                >
                  Back
                </SuiButton>
              </Grid>
            </Grid>
          </form>

        </Card>

      </SuiBox>
      <Footer />
    </DashboardLayout >
  );
}

export default Updatepromocode;
