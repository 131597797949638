import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import CustomizedSnackbars from "../Alert/Alert";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { addPromocode } from "CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { useHistory } from "react-router-dom";
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import { useAuth } from "CustomComponents/Authcontext";
import CircularProgress from '@mui/material/CircularProgress';

function AddPromocode() {
    const isloading = useSelector((state) => state.Isload);
    const userinfo = useSelector((state) => state.Userinfo);
    let history = useHistory();
    const [isDisabled, setisDisabled] = useState(false);
    const { currentUser } = useAuth();
    var dmin = () => {
        var d = new Date().getDate();
        var m = new Date().getMonth() + 1; //Month from 0 to 11
        var y = new Date().getFullYear();
        return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }
    const [couponname, setcouponname] = useState();
    const [selectCountry, setselectCountry] = useState(null);
    const [selectCity, setselectCity] = useState(null);
    const [amountoff, setamountoff] = useState('');
    const [percentoff, setpercentoff] = useState('');
    const [coupondescription, setcoupondescription] = useState();
    const [discountduration, setdiscountduration] = useState({ value: null, label: null });
    const [DiscountDurationInMonths, setDiscountDurationInMonths] =
        useState();
    const [Duration_in_days, setDuration_in_days] = useState()
    const [sipnnershow, setsipnnershow] = useState(false);
    const [sipnnershow1, setsipnnershow1] = useState(false);

    const [maxredemptionperuser, setmaxredemptionperuser] = useState();
    const [Redeem_by, setRedeem_by] = useState();
    const [maxRedemption, setmaxRedemption] = useState();
    //storys
    const [story_desc, setstory_desc] = useState();
    const [story_redemption, setstory_redemption] = useState();
    const [unveils, setunveils] = useState(false);
    const [unveilable, setunveilable] = useState(true);
    const [unveils_id, setunveils_id] = useState();
    const [story, setstory] = useState(false);
    const [upto, setupto] = useState();
    const [responsestory, setresponsestory] = useState(null);
    const [paymentmethod, setpaymentmethod] = useState(null)
    //tooltip
    //snack bar
    const [snakopen, setsnakopen] = useState(false)
    const [snackmessage, setsnackmessage] = useState(null)
    const handleSnackbar = (messages) => {
        setsnakopen(true)
        setsnackmessage(messages)
    };
    //
    const [typeoff, settypeoff] = useState("");

    //week
    var initialState = false;
    const [sunday, setsunday] = useState(initialState);
    const [monday, setmonday] = useState(initialState);
    const [tuesday, settuesday] = useState(initialState);
    const [wednesday, setwednesday] = useState(initialState);
    const [thursday, setthursday] = useState(initialState);
    const [friday, setfriday] = useState(initialState);
    const [saturday, setsaturday] = useState(initialState);
    // import ReactTooltip from 'react-tooltip';
    //handleform submit
    function handleSubmit(event) {
        event.preventDefault();
        if (unveilable === false && unveils === false) {
            alert('unveilable and unveils cannot be both false')
            return 0;
        }
        if (true) {
            setsipnnershow(true)
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            ); myHeaders.append("Content-Type", "application/json");

            var raw = {
                object: "coupon",
                id: couponname,
                country: selectCountry,
                city: selectCity,
                duration: discountduration.value,
                max_redemption: Number(maxRedemption),
                Redeem_by: new Date(Redeem_by).getTime() / 1000,
                description: coupondescription,
                max_redemption_per_user: Number(maxredemptionperuser),
                valid: true,
                payment_methods: paymentmethod,
            };
            if (paymentmethod !== null) {
                var temp = []
                paymentmethod.map(d => temp.push(d.value));
                raw.payment_methods = temp;
            }
            if (typeoff === "amount"
            ) {
                raw.amount_off = Number(amountoff)
            }
            if (typeoff === "percentage") {
                raw.percent_off = Number(percentoff)
                raw.upto = Number(upto)
            }
            if (discountduration === "repeating") {
                raw.duration_in_months = Number(DiscountDurationInMonths)
            }
            if (discountduration === "repeating_daily") {
                raw.duration_in_days = Number(Duration_in_days)
            }
            if (story) {
                raw.story = story; // A
                    raw.story_desc = story_desc; // B
                    raw.unveilable = unveilable; // C
                    raw.unveils = unveils; // D
                    raw.story_redemption = story_redemption; // E
                    raw.unveils_id = unveils_id;  // F
            };
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(raw),
                redirect: "follow",
            };
            fetch(
                process.env.REACT_APP_BASE_URL + "/coupon",
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === true) {
                        alert('Coupon Added');
                        history.goBack()
                        setsipnnershow(false)
                    } else {
                        handleSnackbar('ERROR Coupon not created. Please Check the values provided and try again');
                        setsipnnershow(false)
                    }
                })
                .catch((error) => { setsipnnershow(false); console.log("error", error) });
        } else {
            handleSnackbar("Please Fill The Values ")

        }
    }
    async function storyCall() {
        if (selectCity === null) {
            if (selectCountry === null) {
                alert('Please select country ');

            } else if (selectCity === null) {
                alert('Please select city');
            }
        } else {
            setresponsestory(null)
            setsipnnershow1(true)
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            await fetch(
                process.env.REACT_APP_BASE_URL + `/coupon?type=unveilable&city=${selectCity}`,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === true && result.data.length !== 0) {
                        setsipnnershow1(false)
                        setresponsestory(result.data)
                    } else {
                        handleSnackbar(result.message);
                        setsipnnershow1(false)
                    }
                })
                .catch((error) => {
                    setsipnnershow1(false)

                    console.log("error", error)
                });
        }
    }
    if (isloading) {

        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{'loading'}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={1} pb={3}>
                <Card sx={{ m: 1, p: 2, pt: 0 }}>
                    {sipnnershow && (
                        <Box sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{ width: '100%' }} />
                        </Box>
                    )}
                    <form onSubmit={handleSubmit} className="w-100">

                        <Grid container spacing={3} sx={{ p: 2 }}>
                            <Grid item md={3} ><FormGroup>


                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Coupon Name/ID</SuiTypography>
                                <SuiInput
                                    required
                                    placeholder="Enter Coupon Name/ID"
                                    id="cnam"
                                    value={couponname}
                                    type="text"
                                    onChange={(e) => {
                                        var re = /^([a-zA-Z0-9]+|[\b]|null|)$/;
                                        if (re.test(e.target.value)) {
                                            setcouponname(e.target.value)
                                        }
                                        else {
                                            handleSnackbar('Special characters are not allowed')
                                        }
                                    }
                                    }

                                />                </FormGroup>

                            </Grid>
                            <Grid item md={3} sm={12}>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select Country</SuiTypography>

                                {userinfo.countries.length !== 0 ? (
                                    <SuiSelect
                                        required
                                        placeholder={'Query by country'}
                                        value={selectCountry !== null ? { value: selectCountry, label: selectCountry } : null}
                                        options={userinfo.countries.map((data2) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            setselectCountry(e.value);
                                            setselectCity(null)
                                        }}
                                    />
                                ) : null}
                            </Grid>

                            {selectCountry !== null ? userinfo.isadmin ? (
                                <Grid item md={3} ><FormGroup>

                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select City</SuiTypography>
                                    <SuiSelect
                                        required
                                        placeholder={'Query by city'}
                                        value={selectCity !== null ? { value: selectCity, label: selectCity } : null}
                                        options={userinfo.allcities[selectCountry].map((data2, i) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            setselectCity(e.value);

                                        }}
                                    />
                                </FormGroup>
                                </Grid>
                            ) : (
                                <Grid item md={3} ><FormGroup>

                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select City</SuiTypography>
                                    <SuiSelect
                                        required
                                        widths={'16vw'}
                                        placeholder={'Query by city'}
                                        value={selectCity !== null ? { value: selectCity, label: selectCity } : null}
                                        options={userinfo.allcities.map((data2, i) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            setselectCity(e.value);

                                        }}
                                    />
                                </FormGroup>
                                </Grid>
                            ) : null}


                            <Grid item md={3} ><FormGroup>

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select Type OFF</SuiTypography>
                                <SuiSelect
                                    value={{ value: typeoff, label: typeoff }}
                                    options={[{ value: 'amount', label: 'Amount Off' }, { value: 'percentage', label: 'Percent Off' }]}
                                    onChange={(e) => {
                                        settypeoff(e.value);
                                    }}
                                />
                            </FormGroup>
                            </Grid>
                            {typeoff === "amount" ? (
                                <Grid item md={3} ><FormGroup>

                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Amount Off</SuiTypography>

                                    <SuiInput
                                        required
                                        inputProps={{ min: 0, }}

                                        onChange={(e) => {
                                            setamountoff(e.target.value);
                                        }}
                                        placeholder="Enter Amount Off"
                                        type="number"
                                    /> </FormGroup>
                                </Grid>
                            ) : null}

                            {typeoff === "percentage" ? (
                                <Grid item md={3} ><FormGroup>

                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Percent Off</SuiTypography>

                                    <SuiInput
                                        required
                                        inputProps={{ min: 0, }}

                                        value={percentoff}
                                        onChange={(e) => {
                                            setpercentoff(e.target.value);
                                        }}
                                        placeholder="Enter percent off"
                                        type="number"
                                    /> </FormGroup>
                                </Grid>
                            ) : null}
                            {typeoff === "percentage" ? (
                                <Grid item md={3} ><FormGroup>

                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >UPTO</SuiTypography>

                                    <SuiInput
                                        value={upto}
                                        inputProps={{ min: 0, }}

                                        onChange={(e) => {
                                            setupto(e.target.value);
                                        }}
                                        placeholder="Enter UPTO amount"
                                        type="number"
                                    /> </FormGroup>
                                </Grid>
                            ) : null}
                            <Grid item md={3} ><FormGroup>

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Discount Duration</SuiTypography>
                                <SuiSelect
                                    value={discountduration}
                                    options={[{ value: 'once', label: 'Once' }, { value: 'forever', label: 'Forever' }, { value: 'repeating', label: 'Repeating Monthly' }, { value: 'repeating_daily', label: 'Repeating Daily' }]}
                                    onChange={(e) => {
                                        setdiscountduration(e);
                                    }}
                                />
                            </FormGroup>
                            </Grid>
                            {discountduration.value === "repeating" ? (
                                <Grid item md={3} ><FormGroup>

                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Discount Duration(in Months)</SuiTypography>

                                    <SuiInput
                                        required
                                        value={DiscountDurationInMonths}
                                        inputProps={{ min: 0, }}

                                        onChange={(e) => {
                                            setDiscountDurationInMonths(e.target.value);
                                        }}
                                        placeholder="Enter Discount Duration"
                                        type="number"
                                    /> </FormGroup>
                                </Grid>
                            ) : null}
                            {discountduration.value === "repeating_daily" ? (
                                <Grid item md={3} ><FormGroup>

                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Discount Duration Days</SuiTypography>
                                    <SuiInput
                                        required
                                        inputProps={{ min: 0, }}

                                        value={Duration_in_days}
                                        onChange={(e) => {
                                            setDuration_in_days(e.target.value);
                                        }}
                                        placeholder="Enter number of times to repeated"
                                        type="number"
                                    /> </FormGroup>
                                </Grid>
                            ) : null}
                            <Grid item md={3} ><FormGroup>

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Max Redemption Per User</SuiTypography>

                                <SuiInput
                                    required
                                    inputProps={{ min: 0, }}

                                    value={maxredemptionperuser}
                                    onChange={(e) => {
                                        setmaxredemptionperuser(e.target.value);
                                    }}
                                    placeholder="Enter Max Redemption Per User"
                                    type="number"
                                /> </FormGroup>
                            </Grid>

                            <Grid item md={3} ><FormGroup>

                                <SuiTypography sx={{ fontSize: '0.9rem', }} id="maxRedemption">
                                    Max Redemption
                                </SuiTypography>
                                {/* <Tooltip
                                    placement="right"
                                    isOpen={tooltipOpen2}
                                    target="maxRedemption"
                                    toggle={() => setTooltipOpen2(!tooltipOpen2)}
                                >
                                    Maximum number of times this coupon can be Redeemed, in
                                    Total
                                </Tooltip> */}
                                <SuiInput
                                    required
                                    inputProps={{ min: 0, }}

                                    value={maxRedemption}
                                    onChange={(e) => {
                                        setmaxRedemption(e.target.value);
                                    }}
                                    placeholder="Enter Max Redemption"
                                    type="number"
                                /> </FormGroup>
                            </Grid>
                            <Grid item md={12} ><FormGroup>

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Coupon Description</SuiTypography>

                                <SuiInput
                                    required
                                    value={coupondescription}
                                    onChange={(e) => {
                                        setcoupondescription(e.target.value);
                                    }}
                                    placeholder="Enter Coupon Description"
                                    type="textarea"
                                /> </FormGroup>
                            </Grid>

                            <Grid item md={3} ><FormGroup>

                                <SuiTypography sx={{ fontSize: '0.9rem', }} id="Redeem_by">
                                    Valid Till
                                </SuiTypography>
                                <SuiInput
                                    required
                                    value={Redeem_by}
                                    inputProps={{ min: dmin(), max: "2045-05-04" }}
                                    onChange={(e) => {
                                        setRedeem_by(e.target.value);
                                    }}
                                    type="date"
                                /> </FormGroup>
                            </Grid>
                            < Grid item md={3} ><FormGroup>

                                <SuiTypography sx={{ fontSize: '0.9rem', }} id="payment_methods">
                                    Payment Methods
                                </SuiTypography>
                                <SuiSelect
                                    required
                                    isMulti
                                    placeholder={'Select payment method'}
                                    value={paymentmethod !== null ? paymentmethod : null}
                                    options={[
                                        "card",
                                        "cash",
                                        "wallet"
                                    ].map((data2, i) => ({ value: data2, label: data2 }))}
                                    onChange={(e) => {
                                        console.log(e);
                                        setpaymentmethod(e);
                                    }}
                                />
                            </FormGroup>
                            </Grid>


                            <Grid md={12} sm={12} />
                            <Grid item md={12} sm={12} >
                                <FormGroup>
                                    <FormControlLabel control={<Switch onChange={(e) => setstory(!story)} />} label="Coupon Story" />
                                </FormGroup>
                            </Grid>
                            {story ? <>


                                <Grid item md={6} sm={6} >
                                    <FormControlLabel
                                        sx={{ ml: 3 }}
                                        label={"unveiled by other coupon"}
                                        control={
                                            <Checkbox
                                                name={''}
                                                checked={unveilable}
                                                onChange={(e) => {
                                                    setunveils(!e.target.checked)
                                                    setunveilable(e.target.checked)
                                                }
                                                }
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item md={6} sm={6} >
                                    <FormControlLabel
                                        sx={{ ml: 3 }}
                                        label={"unveil other coupon"}
                                        control={
                                            <Checkbox
                                                name={''}
                                                checked={unveils}
                                                onChange={async (e) => {
                                                    if (selectCity !== null) {
                                                        setunveilable(!e.target.checked)
                                                        setunveils(e.target.checked)
                                                        await storyCall()
                                                    } else {
                                                        if (selectCountry === null) {
                                                            handleSnackbar('Please Select Country ');
                                                        } else if (selectCity === null) {
                                                            handleSnackbar('Please Select City');
                                                        }
                                                    }
                                                }
                                                }
                                            />
                                        }
                                    />
                                </Grid>

                                {responsestory !== null && unveils ? <Grid item md={6} sm={6} >
                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Coupon to be unveiled by the current coupon</SuiTypography>
                                    <SuiSelect
                                        required
                                        value={{ value: unveils_id, label: unveils_id }}
                                        options={responsestory.map((data2, i) => ({ value: data2.id, label: data2.id }))}
                                        onChange={(e) => {
                                            setunveils_id(e.value);
                                        }}
                                    />
                                </Grid> : null}
                                <Grid item md={5} ><FormGroup>
                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  > Redemption period </SuiTypography>
                                    <SuiInput
                                        required
                                        inputProps={{ min: 0, }}

                                        value={story_redemption}
                                        onChange={(e) => {
                                            setstory_redemption(e.target.value);
                                        }}
                                        placeholder="Redemption period"
                                        type="number"
                                    /> </FormGroup>
                                </Grid>

                                {sipnnershow1 && (<Grid item md={5}>
                                    <SuiTypography sx={{ fontSize: '1.2rem', textAlign: 'left', mt: 3 }}  ><CircularProgress size={19} color='info' /> Loading Data... </SuiTypography>
                                </Grid>
                                )}

                                <Grid item md={21} sm={12} ><FormGroup>
                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Coupon Story Description</SuiTypography>
                                    <SuiInput
                                        required
                                        value={story_desc}
                                        onChange={(e) => {
                                            setstory_desc(e.target.value);
                                        }}
                                        placeholder="Coupon Story Description"
                                        type="text"
                                    /> </FormGroup>
                                </Grid>
                            </> : ''}

                        </Grid>

                        <Grid container sx={{ mt: 2 }} spacing={3}>
                            <Grid item md={6}>
                                <SuiButton color='info' disabled={isDisabled} id='submit' type="submit">
                                    Submit
                                </SuiButton>
                            </Grid>
                            <Grid item md={6}>
                                <SuiButton
                                    color='error'
                                    onClick={() => history.goBack()}
                                >
                                    Back
                                </SuiButton>
                            </Grid>
                        </Grid>
                    </form>
                </Card>
                <CustomizedSnackbars message={snackmessage} open={snakopen} color={'error'} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default AddPromocode;
