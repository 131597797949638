const initailState =null
const DROlinkvehicledata=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_DRO_Linked_SEARCH':
            return action.payload
        default:
            return state;
    }
}

export default DROlinkvehicledata