/* global google
 */
import React, { useState, useEffect, useRef } from "react";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  withScriptjs,
  InfoWindow,
  Polyline,
} from "react-google-maps";
import { useSelector, useDispatch } from "react-redux";
import mapStyles from "./Mapstyles";
import { databaseStore } from "CustomComponents/firebase";
import MapSvgcodeValue from "./MapSvgcodeValue";
import { doc, getDoc } from "firebase/firestore";

var velocity = 100;
var initialDate = new Date();
const getDistance = () => {
  // seconds between when the component loaded and now
  const differentInTime = (new Date() - initialDate) / 1000; // pass to seconds
  return differentInTime * velocity; // d = v*t -- thanks Newton!
};

const Markers = ({ call, places }) => {
  const places2data = useSelector((state) => state.Driverlocation.prearr);

  const findAngle = (place) => {
    var actualAngle = 0;
    var places2 = {}
    places2data.map((dd) => {
      if (dd.id === place.id) {
        places2 = dd
      }
    })
    if (places2.l !== undefined && places2.l.lat !== undefined || places2.l !== undefined && places2.l.lng !== undefined) {
      if (places2data.length !== 0) {
        const distance = getDistance();
        if (!distance) {
          return;
        }

        const point1LatLng = new window.google.maps.LatLng(
          place.l.lat,
          place.l.lng
        );
        const point2LatLng = new window.google.maps.LatLng(
          places2.l.lat,
          places2.l.lng
        );
        const angle = window.google.maps.geometry.spherical.computeHeading(
          point1LatLng,
          point2LatLng
        );
        actualAngle = Math.abs(Math.round(angle - 90));
        // console.log(actualAngle);
      }
    }
    return actualAngle;
  };
  return places.map((place, key) => {
    if (place !== null && place.id !== undefined) {
      return <Marker
        key={place.id}
        id={place.id}
        position={{ lat: place.l.lat, lng: place.l.lng }}
        icon={{
          path: MapSvgcodeValue,
          strokeColor: place.colorCode,
          fillColor: place.colorCode,
          anchor: new google.maps.Point(17, 34),
          origin: new google.maps.Point(0, 0),
          fillOpacity: 1.0,
          strokeWeight: 1.5,
          rotation: findAngle(place),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={() => {
          call(place);
        }}
      />
    }
  });
};
function Map({ latlng }) {
  const dataplaces = useSelector((state) => state.Driverlocation.arr);
  const userinfo = useSelector((state) => state.Userinfo);

  const [selectedmarker, setselectedmarker] = useState(null);
  const [avalablemarker, setavalablemarker] = useState(false);
  const [rnum, setrnum] = useState();
  const [nameset, setnameset] = useState();
  const [modelset, setmodelset] = useState();
  const [gen, setgen] = useState();
  const [stat, setstat] = useState();
  const [ty, setty] = useState(null);
  const [precod, setprecod] = useState();
  const [currentcod, setcurrentcod] = useState();
  const [zoomVal, setzoomVal] = useState(11);
  const isMounted = useRef(false);
  const [minzome, setminzome] = useState(5)
  const [markerData, setmarkerData] = useState(dataplaces)
  const [simNumber, setsimNumber] = useState()
  const firebasecall = async (e) => {
    if (e.id !== undefined) {
      var ids = e.id;
      const docRef = doc(databaseStore, "/users/appUsers/drivers/" + ids,);
      await getDoc(docRef).then((snapshot) => {
        if (snapshot.exists()) {
          var datasnsp = snapshot.data();
          setnameset(datasnsp.fname + " " + datasnsp.lname);
          setgen(datasnsp.contactno);
          setrnum(datasnsp.registration_number);
          setmodelset(datasnsp.model);
          setsimNumber(datasnsp.sim_number);

          setty(e.type);
          setstat(e.status);
        } else {
          alert('No such document')
          console.log("No such document!");
        }
      }).catch((e) => console.log(e))
      setselectedmarker(e);
    }
  };
  const [issetonce, setissetonce] = useState(true)
  useEffect(() => {   
      setcurrentcod({ lat: latlng.cords.lat, lng: latlng.cords.long });
      //console.log({lat: latlng.cords.lat, lng: latlng.cords.long });
  }, [latlng.iscountry]);
  useEffect(() => {
    if (selectedmarker !== null) {
      setprecod(currentcod);
      setzoomVal(17);
      setcurrentcod({ lat: selectedmarker.l.lat, lng: selectedmarker.l.lng });
    }
  }, [selectedmarker]);
  useEffect(() => {
    setmarkerData(dataplaces)
    const { country, city } = latlng.cityCountry;
    if (isMounted.current) {
      var ss = []
      dataplaces.map((doc) => {
        if (userinfo.isadmin) {
          if (userinfo.allcities[country].includes(doc.city)) {
            if (doc.status === latlng.typeSelect) {
              ss.push(doc)
            } else if (latlng.typeSelect === 'All') {
              ss.push(doc)
            } else if (latlng.typeSelect === 'OnGoing') {
              return doc.status !== 'Waiting' ? ss.push(doc) : null;
            }
          }
        }
        else {
          if (userinfo.allcities.includes(doc.city)) {
            if (doc.status === latlng.typeSelect) {
              ss.push(doc)
            } else if (latlng.typeSelect === 'All') {
              ss.push(doc)
            } else if (latlng.typeSelect === 'OnGoing') {
              return doc.status !== 'Waiting' ? ss.push(doc) : null;
            }
          }
        }
      })
      // console.log(ss);
      setmarkerData(ss)
    } else {
      isMounted.current = true;
    }
  }, [dataplaces])

  return (
    <div>
      <GoogleMap
        center={currentcod}
        defaultCenter={{ lat: 6.5244, lng: 3.3792 }}
        zoom={zoomVal}
        defaultOptions={{ minZoom: minzome, scrollwheel: false }}
      >
        <Markers call={firebasecall} places={markerData} />
        {selectedmarker && (
          <InfoWindow
            position={{ lat: selectedmarker.l.lat, lng: selectedmarker.l.lng }}
            onCloseClick={() => {
              // setcurrentcod(precod);
              setzoomVal(11);
              setselectedmarker(null);
            }}
          >
            <>
              {" "}
              <div>Name :{nameset}</div>
              <div>Status : {stat}</div>
              {ty !== null ? <div> Type :{ty}</div> : null}
              <div> Registration Number:{rnum}</div>
              <div> Model:{modelset}</div>
              <div> Contact No:{gen}</div>
              <div>Vehicle Contact No:{simNumber}</div>

              
            </>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
}
const MapComponent = withScriptjs(
  withGoogleMap((cords) => <Map latlng={cords} />)
);

export default function Mapapp({ cord, typeSelect, isCountry, sortdata }) {
  return (
    <MapComponent
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%`, width: '100%' }} />}
      containerElement={<div style={{ height: `65vh`, minWidth: "100%" }} />}
      mapElement={<div style={{ height: `100%`, width: '100%' }} />}
      cords={cord}
      cityCountry={sortdata}
      iscountry={isCountry}
      typeSelect={typeSelect}
    />
  );
}
