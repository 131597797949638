import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import { useAuth } from "CustomComponents/Authcontext";
import CustomizedSnackbars from "../Alert/Alert";
import Divider from "@mui/material/Divider";

import { adduserpermition as add_user_permission, removeuserpermition, Clearuserpermition } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import LinearProgress from '@mui/material/LinearProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
function Userrolesdisplay({ data, keys, updatedata1, removeuser }) {

    const [display, setdisplay] = useState(false)
    const [datas, setdatas] = useState({
        [data[0]]: []
    })
 
    const [state12, 
    ] = useState(data[0])
    function dasdkf(data, state) {
        if (state) {
            datas[state12].push(data)

        } else {
            datas[state12] = datas[state12].filter(item => item !== data)
        }
        updatedata1(datas);
    }

    return (
        <Grid item md={6} key={keys} >
                        <Divider sx={{ margin: 0 }} />
            <FormControlLabel
                label={(data[0].charAt(0).toUpperCase() + data[0].slice(1)).replace("_", " ").replace("_", " ")}
                control={
                    <Checkbox
                        name={data[0]}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setdisplay(!display)
                                dasdkf("R", true)
                            } else {
                                setdisplay(!display);
                                setdatas({ [data[0]]: [] });
                                removeuser(data[0]);
                            }
                        }
                        }
                    />
                }
            />
            {display ? <div >
                <Grid container spacing={2} sx={{ p: 0.5 }}>
                    {data[1].map((roles, key) => {
                        return <Grid item key={key} md={4}>
                            <FormControlLabel
                                key={key}
                                label={roles === 'R' ? 'Read' : roles === 'W' ? 'Write' : roles === 'U' ? 'Update' : roles === 'D' ? 'Delete' : ''}
                                control={
                                    <Checkbox
                                        key={key}
                                        value={roles} name={roles}
                                        defaultChecked={roles === "R" ? true : false}
                                        onChange={(e) => {
                                            dasdkf(e.target.value, e.target.checked)
                                        }} />
                                }
                            />
                        </Grid >
                    })}
                </Grid >
            </div> : null}</Grid >
    )
}
function CreateUserRole() {
    const userPermissionsdata = useSelector(state => state.userrolecreation);
    const { currentUser } = useAuth();

    const [permissiondata, setpermissiondata] = useState([])
    const [parentdata, setparentdata] = useState([])

    const [messagttouser, setmessagttouser] = useState("Loading Data...")
    const [nexttoggle, setnexttoggle] = useState(true);
    const [sipnnershow, setspinnershow] = useState(true);

    //form value
    const [Comes_under, set_comes_under] = useState(null)
    const [RoleName, setRoleName] = useState('')
    const [comesunder, setcomesunder] = useState(false)
    //disatch function
    const dispatch = useDispatch();
 //snack bar
 const [snakopen, setsnakopen] = useState(false)
 const [snackmessage, setsnackmessage] = useState(null)
const [snackcolor, setsnackcolor] = useState('error');
 const handleSnackbar = (messages) => {
     setsnakopen(true)
     setsnackmessage(messages)
 };
 //
    //rudex function call to update userrole values
    function updatedata(data) {
        dispatch(add_user_permission(data, Comes_under))
    }
    function deletedata(data) {
        dispatch(removeuserpermition(data))
    }

    useEffect(() => {
        callPermissition();
        return () => {
            dispatch(Clearuserpermition())
        }
    }, [0])
    function createuserroleaicall() {
        if (userPermissionsdata.length !== 0 && RoleName !== '' && Comes_under !== null) {
            setnexttoggle(false);
            setspinnershow(true);
            var permissionsdata = {}
            userPermissionsdata.map((data) => {
                if (data != null) {
                    var dda = Object.entries(data);
                    permissionsdata[dda[0][0]] = dda[0][1];
                }
            }
            )
            if (comesunder) {
                permissionsdata.mapping = comesunder
            } else {
                permissionsdata.mapping = comesunder
            }
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + currentUser.accessToken);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "name": RoleName,
                "permissions": permissionsdata,
                "parent": Comes_under
            });
            console.log(permissionsdata);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(process.env.REACT_APP_BASE_URL+"/role", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if(result.status){
                    setsnackcolor('success')
                    handleSnackbar("User created successfully")
                    setnexttoggle(true)
                    setspinnershow(false)
                    }else{
                        setsnackcolor('error')
                        handleSnackbar("Error" + result.message)
                    }
                })
                .catch(error => {
                    setsnackcolor('error')
                    handleSnackbar("error" + error)
                    setnexttoggle(false)
                    console.log('Error : ', error)
                });

        } else {
            setsnackcolor('error');
            var mes="Please give the correct values"
            if(Comes_under===null){
                mes='Please Select User Role Comes Under'
            }
            if(RoleName===null||RoleName===''){
                mes='Please Provide a Valid User Role Name'
            }
            if(userPermissionsdata.length === 0){              
                  mes='Please Select The Permissions for The User Role'
        }
            handleSnackbar(mes);
        }



    }
    //apicall for to et permissition list
    function callPermissition() {
        setspinnershow(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + currentUser.accessToken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(process.env.REACT_APP_BASE_URL+"/role?type=permissions", requestOptions)
            .then(response => response.json())
            .then(result => {
                let permissions = result.permissions
                const dda = Object.entries(permissions);
                setparentdata(result.parent)
                setpermissiondata(dda)
                setspinnershow(false)

            })

            .catch(error => {
                setmessagttouser("Unable to get data....ERROR"); 
                console.log('Error : ', error)
            });
    }

    if (parentdata.length === 0) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card sx={{ textAlign: 'center' }}>
                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >{messagttouser}</SuiTypography >
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={3} pb={3}>
                <Card sx={{ m: 1, p: 2, pt: 0 }}>
                    {sipnnershow && (
                        <Box sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{ width: '100%' }} />
                        </Box>
                    )}
                    <SuiTypography varient="h4">Create User Role</SuiTypography>
                    <FormGroup>

                        <SuiTypography sx={{ fontSize: '0.9rem', }}  > User Role Comes Under</SuiTypography >
                        <SuiSelect
                            autoComplete="none"
                            defaultValue={{ value: '', label: '' }}
                            value={{ value: Comes_under, label: Comes_under }}
                            options={parentdata.map((entry) => ({ value: entry, label: entry }))}
                            onChange={(e) => {
                                set_comes_under(e.value)
                            }}
                        />
                        <SuiTypography varient="h4">User Role </SuiTypography>
                        <SuiInput
                            required
                            name="userrole"
                            type="text"
                            value={RoleName}
                            onChange={(e) => setRoleName(e.target.value)}
                        />
                        <Grid container spacing={2} sx={{ p: 0.5 }} >
                            <Grid item md={12}>  <SuiTypography varient="h6">User Role Permissions</SuiTypography></Grid >
                            <Grid item md={6}>
                                <FormControlLabel
                                    label="Drivers/Vehicles can be mapped to this user role."
                                    control={
                                        <Checkbox
                                            checked={comesunder}
                                            onChange={(e) => {
                                                setcomesunder(e.target.checked)
                                            }} />
                                    }
                                />
                            </Grid >
                            {permissiondata.length !== 0 ? permissiondata.map((dataset, key) => {
                                return <Userrolesdisplay key={key} keys={key} removeuser={deletedata} data={dataset} updatedata1={updatedata} />
                            }) : null
                            }
                        </Grid >
                        <Grid container spacing={2} sx={{ mt: 3, }}>
                            <Grid item md={4}>
                                <SuiButton
                                    color='info'
                                    disabled={!nexttoggle}
                                    onClick={() => createuserroleaicall()} >Add User Role</SuiButton>

                            </Grid >
                            <Grid item ></Grid >
                        </Grid >
                    </FormGroup>

                </Card>
                <CustomizedSnackbars message={snackmessage} open={snakopen} color={snackcolor} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default CreateUserRole;
