import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import { useAuth } from "CustomComponents/Authcontext";

import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";

import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from "@mui/material";
import CustomizedSnackbars from "../Alert/Alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'
function TransferDRO() {
    const isloading = useSelector((state) => state.Isload);
    const [messagttouser, setmessagttouser] = useState("Loading Data...");
    const [errormessage, seterrormessage] = useState(null);
    const [errormessage2, seterrormessage2] = useState(null);
    const { currentUser } = useAuth();
    const dd = useSelector((state) => state.Searchdata);
    const UnlinkedVehicle = useSelector((state) => state.UnlinkedVehicle);
    const [sipnnershow, setsipnnershow] = useState(false);
    const [sipnnershow1, setsipnnershow1] = useState(false);
    const [sipnnershow2, setsipnnershow2] = useState(false);

    //UnlinkedVehicle
    const dispatch = useDispatch();
    const [SearchDro2, setSearchDro2] = useState(null);
    const [SearchValueDro, setSearchValueDro] = useState('');
    //response
    const [response1, setresponse1] = useState(null);
    const [response2, setresponse2] = useState(null);
    //selected data
    const [selectedDRO1, setselectedDRO1] = useState(null);
    const [selectedDRO2, setselectedDRO2] = useState(null);
    //from to date
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);
    //color
    const [s1, sets1] = useState(true);
    const [s2, sets2] = useState(true);

    //snack bar
    const [snakopen, setsnakopen] = useState(false);
    const [snackmessage, setsnackmessage] = useState(null);
    const handleSnackbar = (messages) => {
        setsnakopen(true)
        setsnackmessage(messages)
    };
    //
    var dmin = (yi=0,mp=0) => {
        var d = new Date().getDate() - mp;
        var m = new Date().getMonth(); //Month from 0 to 11
        var y = new Date().getFullYear() + yi;
        var h = new Date().getHours();
        var mi = new Date().getMinutes();
        var s = new Date().getSeconds();
        return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d) + 'T' + (h <= 9 ? '0' + h : h) + ':' + (mi <= 9 ? '0' + mi : mi) + ':' + (s <= 9 ? '0' + s : s);
    }
    function handleSubmit() {
        if (selectedDRO1 != null && selectedDRO2 != null && fromdate !== null && todate !== null) {
            setsipnnershow2(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                from: selectedDRO1,
                to: selectedDRO2,
                from_timestamp: new Date(fromdate).getTime() / 1000,
                to_timestamp: new Date(todate).getTime() / 1000
            });
            var requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };
            console.log(raw);

            fetch(
                process.env.REACT_APP_BASE_URL+"/all_mappable",
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === false) {
                        setsipnnershow2(false);

                        alert(result.message);
                    } else {
                        setsipnnershow2(false);
                        alert("Driver Has BeenLinked To The Vehicle");
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    setsipnnershow2(false);
                });
        } else {
            alert("Please select the values properly");
            setsipnnershow2(false);

        }
    }
    async function handleSearchFromDRO(event) {
        event.preventDefault()
        if (SearchValueDro !== '') {
            setsipnnershow1(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            fetch(
                process.env.REACT_APP_BASE_URL+`/search_users?search=${SearchValueDro}&user=mappable&type=linking`,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === false) {
                        handleSnackbar(result.message);
                        setsipnnershow1(false);
                    } else {
                        console.log(result);
                        setsipnnershow1(false);
                        setresponse1(result.data);
                    }
                })
                .catch((error) => console.log("error", error));
        } else {
            handleSnackbar('Please fill the vehical search field')
        }
    }


    async function SearchValueDro2(event) {
        event.preventDefault();
        if (SearchDro2 !== null) {

            setsipnnershow(true);

            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = "";
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            fetch(
                process.env.REACT_APP_BASE_URL+`/search_users?search=${SearchDro2}&user=mappable&type=linking`,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === false) {
                        handleSnackbar(result.message);
                        setsipnnershow(false);
                    } else {
                        setresponse2(result.data)
                        setsipnnershow(false);
                    }
                })
                .catch((error) => console.log("error", error));
        } else {
            handleSnackbar('Please fill the driver search field')
        }
    }
    if (UnlinkedVehicle.arr.length !== 0 && isloading) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={1} pb={3}>

                <Card sx={{ m: 1, p: 2, pt: 0 }}>

                    <SuiTypography
                        p={2}
                        variant="h5"
                    >Transfer DRO </SuiTypography>

                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <Card>
                                {sipnnershow1 && (
                                    <Box sx={{ width: '100%', m: 0, p: 0 }}>
                                        <LinearProgress color="success" sx={{ width: '100%' }} />
                                    </Box>
                                )}
                                <Card sx={{ p: 4 }}>
                                    <div >
                                        <h5 style={{ display: "inline-block", position: 'relative' }}>
                                            From DRO Email Id
                                        </h5>
                                        <form onSubmit={handleSearchFromDRO} >
                                            <SuiTypography
                                                variant="h6"
                                            >
                                                Please Enter a Valid Email Id
                                            </SuiTypography>
                                            <Grid container>
                                                <Grid item md={10}>
                                                    <SuiInput
                                                        sx={{ mb: 2, position: 'relative', ml: 'auto', mr: 'auto' }}
                                                        value={SearchValueDro}
                                                        name="vSearch"
                                                        placeholder="Email Id"
                                                        onChange={(e) => setSearchValueDro(e.target.value)}
                                                        inputProps={{ type: "email" }} />
                                                </Grid>
                                                <Grid item md={2}>
                                                    <SuiButton sx={{ float: 'right' }}
                                                        type="submit"
                                                        id="submits2"
                                                        variant="gradient"
                                                        color="info"  >
                                                        <FontAwesomeIcon icon={faSearch} />
                                                    </SuiButton>
                                                </Grid>

                                            </Grid>
                                            <div>
                                                {errormessage2 != null ? (
                                                    <p style={{ color: "red", fontSize: '1rem' }}>{errormessage2}</p>
                                                ) : null}
                                            </div>
                                        </form>
                                    </div>
                                </Card>
                            </Card>
                        </Grid>
                        <Grid item md={6}>
                            <Card>
                                {sipnnershow && (
                                    <Box sx={{ width: '100%', m: 0, p: 0 }}>
                                        <LinearProgress color="success" sx={{ width: '100%' }} />
                                    </Box>
                                )}
                                <Card sx={{ p: 4 }}>
                                    <h5 style={{ display: "inline-block" }}> To DRO Email Id
                                        {" "}</h5>
                                    <form onSubmit={SearchValueDro2} >
                                        <SuiTypography
                                            variant="h6"
                                        >
                                            Please Enter a Valid Email Id
                                        </SuiTypography>
                                        <Grid container>
                                            <Grid item md={10}>
                                                <SuiInput
                                                    sx={{ mb: 2, position: 'relative', ml: 'auto', mr: 'auto' }}
                                                    value={SearchDro2}
                                                    name="vSearch"
                                                    placeholder="Email Id "
                                                    onChange={(e) => setSearchDro2(e.target.value)}
                                                    inputProps={{ type: "email" }}
                                                />
                                            </Grid>
                                            <Grid item md={2}>
                                                <SuiButton sx={{ float: 'right' }}
                                                    type="submit"
                                                    id="submits2"
                                                    variant="gradient"
                                                    color="info"  >
                                                    <FontAwesomeIcon icon={faSearch} />
                                                </SuiButton>
                                            </Grid>
                                        </Grid>
                                        <div>
                                            {errormessage != null ? (
                                                <p style={{ color: "red", fontSize: '1rem' }}>{errormessage}</p>
                                            ) : null}
                                        </div>
                                    </form>

                                </Card>
                            </Card>
                        </Grid>
                    </Grid>
                </Card>
                <Card sx={{ m: 1, p: 2, pt: 0 }}>
                {sipnnershow2 && (
                                    <Box sx={{ width: '100%', m: 0, p: 0 }}>
                                        <LinearProgress color="success" sx={{ width: '100%' }} />
                                    </Box>
                                )}
                    {response1 !== null ?
                        <SuiTypography sx={{ mx: 2, py: 1 }}>Select DRO Listed Below</SuiTypography > : null}
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            {response1 !== null ?
                                <Card
                                    style={{ backgroundColor: s1 ? "#EFEFEF" : "#cbcbcb", textAlign: "justify" }}
                                    id={response1.id}
                                    onClick={() => {
                                        sets1(!s1);

                                        if (s1) {
                                            setselectedDRO1(response1.id);
                                        } else {
                                            setselectedDRO1(null);
                                        }

                                    }}
                                >
                                    <Grid container sx={{ p: 3 }}>
                                        <Grid item sm={12}>
                                            <span>
                                                <h5>DRO Name :{" "}
                                                    {response1.name}</h5>
                                            </span>
                                        </Grid>

                                        <Grid item sm={12}>
                                            <SuiTypography variant="button">
                                                <span>
                                                    <h5>Email ID: {response1.email}</h5>
                                                </span> </SuiTypography>
                                        </Grid>

                                    </Grid>{" "}
                                </Card> : null}
                        </Grid>

                        <Grid item md={6}>
                            {response2 !== null ?
                                <Card
                                    style={{ backgroundColor: s2 ? "#EFEFEF" : "#cbcbcb", textAlign: "justify" }}
                                    id={response2.id}
                                    onClick={() => {
                                        sets2(!s2);
                                        if (s2) {
                                            setselectedDRO2(response1.id);
                                        } else {
                                            setselectedDRO2(null);
                                        }

                                    }}
                                >
                                    <Grid container sx={{ p: 3 }}>
                                        <Grid item sm={12}>
                                            <span>
                                                <h5>DRO Name :{" "}
                                                    {response2.name}</h5>
                                            </span>
                                        </Grid>

                                        <Grid item sm={12}>
                                            <SuiTypography variant="button">
                                                <span>
                                                    <h5>Email ID: {response2.email}</h5>
                                                </span> </SuiTypography>
                                        </Grid>

                                    </Grid>{" "}
                                </Card> : null}
                        </Grid>
                        <Grid item md={6}>
                            {selectedDRO2 !== null && selectedDRO1 !== null ? <><SuiTypography sx={{ fontSize: '0.9rem', }}  >From Date</SuiTypography >
                                <SuiInput required
                                    value={fromdate}
                                    inputProps={{ min: dmin(0,1), max:  dmin(2,0) }}

                                    onChange={(e) => {
                                        setfromdate(e.target.value);
                                    }}
                                    type="datetime-local"
                                /> </> : null}
                        </Grid>
                        <Grid item md={6}>
                            {selectedDRO2 !== null && selectedDRO1 !== null ? <><SuiTypography sx={{ fontSize: '0.9rem', }}  >To Date</SuiTypography >
                                <SuiInput required
                                    value={todate}
                                    inputProps={{ min: dmin(0,1), max:  dmin(2,0) }}
                                    onChange={(e) => {
                                        console.log(new Date());
                                        settodate(e.target.value);
                                    }}
                                    type="datetime-local"
                                /> </> : null}
                        </Grid>
                    </Grid>
                    {selectedDRO2 !== null && selectedDRO1 !== null ?
                        <SuiButton sx={{ float: 'right', mx: 'auto', my: 2, width: '50%' }}
                            onClick={() => {
                                handleSubmit()
                            }}
                            variant="gradient"
                            color="info"  >
                            Transfer
                        </SuiButton> : null}
                </Card>
                <CustomizedSnackbars message={snackmessage} open={snakopen} color={'error'} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default TransferDRO;
