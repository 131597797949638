import React, { useState, useReducer } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// Data
import { storage } from "../../CustomComponents/firebase";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";

import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import LinearProgress from '@mui/material/LinearProgress';

import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import CustomizedSnackbars from "../Alert/Alert";
import {
    useHistory,
} from "react-router-dom";
import { useAuth } from "CustomComponents/Authcontext";
import FormGroup from '@mui/material/FormGroup';

import { RTdatabase } from "../../CustomComponents/firebase";
import { ref as retS, set } from "firebase/database";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { makeStyles } from "@material-ui/core";
import { Box } from "@mui/system";

function Mobile() {
    const isloading = useSelector((state) => state.Isload);
    const userinfo = useSelector((state) => state.Userinfo);
    var initialState = "";
    const [showPassword, setshowPassword] = useState(false)
    const { currentUser } = useAuth();
    let history = useHistory();
    const [sipnnershow, setspinnershow] = useState(false);

    ///display proress
    const [uplode2, setuplode2] = useState({ label: "", value: 0 });
    const [uplode3, setuplode3] = useState({ label: "", value: 0 });
    const [uplode4, setuplode4] = useState({ label: "", value: 0 });
    const [uplode1, setuplode1] = useState({ label: "", value: 0 });
    //map api call
    const [apiresponce, setapiresponce] = useState(null);
    const [formatedaddress, setformatedaddress] = useState();
    const [alertdata, setalertdata] = useState(null);
    const [address, setaddress] = useState();
    const [fname, setfname] = useState(initialState);
    const [lname, setlname] = useState(initialState);
    const [commission, setcommission] = useState(0);
    const [dob, setdob] = useState();
    const [gender, setgender] = useState(null);
    const [driverprofilepic, setdriverprofilepic] = useState(initialState);
    const [contactno, setcontactno] = useState(initialState);
    const [emailid, setemailid] = useState(initialState);
    const [city, setcity] = useState(null);
    const [joindate, setjoindate] = useState(initialState);
    const [password, setpassword] = useState(initialState);
    const [licensevalidity, setlicensevalidity] = useState(initialState);
    const [license_back_pic, setlicense_back_pic] = useState(initialState);
    const [license_front_pic, setlicense_front_pic] = useState(initialState);
    const [licenseno, setlicenseno] = useState(initialState);
    const [lasdricard, setlasdricard] = useState(initialState);
    const [lasdrivalidity, setlasdrivalidity] = useState(initialState);
    const [police_verification, setpolice_verification] = useState(initialState);
    const [document_status, setdocument_status] = useState(null);
    const [police_verificationdoc, setpolice_verificationdoc] =
        useState(null);
    const [MaxDueAmount, setMaxDueAmount] = useState(20000);
    const [selectCountry, setselectCountry] = useState(null);
    const [selectCity, setselectCity] = useState(null);
    //=============password section==========//
    const handleClickShowPassword = () => {
        setshowPassword(!showPassword)
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    //=======================================//
    //snack bar
    const [snakopen, setsnakopen] = useState(false)
    const [snackmessage, setsnackmessage] = useState(null)
    const handleSnackbar = (messages) => {
        setsnakopen(true)
        setsnackmessage(messages)
    };
    //state
    const initialStat2 = {
        fname: null, lname: null, dob: null, gender: null,
        driver_profile_pic: null, contactno: null, emailid: null,
        password: null, max_due: null, commission: null, city: null,
        joindate: null, license_front_pic: null, license_back_pic: null,
        licenseno: null, licensevalidity: null, lasdricard: null, lasdrivalidity: null,
        police_verification: null, police_verification_pic: police_verificationdoc, document_status: null,
        home_address: null
    };
    const [state, updateState] = useReducer(
        (state, updates) => ({
            ...state,
            ...updates,
        }),
        initialStat2
    );
    //updateState({ ocupation: "postman" })
    async function driverhome(id) {
        await set(retS(RTdatabase, 'drivers/take_me_home/' + id), {
            location: [formatedaddress.geometry.location.lat, formatedaddress.geometry.location.lng],
            location_str: formatedaddress.formatted_address,
            status: false
        }).then((d) => {
            alert("Driver added Successfully");
            window.location.reload()
        }).catch((f) => console.log(f))
    }

    const datesecond = (e) => {
        return new Date(e).getTime() / 1000;
    };
    //SUBMIT FUNCTION
    async function handleSubmit(event) {
        event.preventDefault();
        setspinnershow(true)
        var check = true;
        var messagesnack = 'Please Select '
        if (gender === null) {
            check = false;
            messagesnack = messagesnack + ' "Gender"'
        }
        if (selectCountry === null) {
            check = false;
            messagesnack = messagesnack + ' "Country"'

        }
        if (selectCity === null) {
            check = false;
            messagesnack = messagesnack + ' "City"'

        } if (document_status === null) {
            check = false;
            messagesnack = messagesnack + ' "Document Statue"'

        }
        if (police_verification === null) {
            check = false;
            messagesnack = messagesnack + ' "Police Verification Status"'

        }


        if (messagesnack !== 'Please Select ') {
            handleSnackbar(messagesnack)
            setspinnershow(check)

        }
        if (check && currentUser.accessToken !== undefined) {
            document.getElementById("submitDriver").setAttribute("disabled", "disabled");
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                fname: fname,
                lname: lname,
                dob: datesecond(dob),
                gender: gender,
                driver_profile_pic: driverprofilepic,
                contactno: contactno,
                emailid: emailid,
                password: password,
                max_due: Number(MaxDueAmount),
                commission: Number(commission),
                city: selectCity,
                country: selectCountry,
                joindate: datesecond(joindate),
                license_front_pic: license_front_pic,
                license_back_pic: license_back_pic,
                licenseno: licenseno,
                licensevalidity: datesecond(licensevalidity),
                lasdricard: lasdricard,
                lasdrivalidity: datesecond(lasdrivalidity),
                police_verification: police_verification,
                police_verification_pic: police_verificationdoc,
                document_status: document_status,
                home_address: formatedaddress.formatted_address !== undefined ? formatedaddress.formatted_address : 'Addresss not set',
            });
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };


            fetch(
                process.env.REACT_APP_BASE_URL + "/all_drivers",
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === true) {
                        if (formatedaddress !== null && formatedaddress !== undefined) {
                            driverhome(result.driver_id);
                            document.getElementById("submitDriver").removeAttribute("disabled");
                            cleanform();
                            setspinnershow(false)

                        } else {
                            alert("Error unable to add home address");
                            setspinnershow(false)

                        }

                    } else {
                        alert("Error" + result.message + "" + result.error);
                        document.getElementById("submitDriver").removeAttribute("disabled");
                        setspinnershow(false)
                        //            
                    }
                })
                .catch((error) => {
                    setspinnershow(false)

                    console.log("error", error);
                    cleanform();
                });
        } else {
            if (check) alert("accessToken error");
        }
    }
    function callapigeo(params) {
        setapiresponce(null)
        fetch(
            "https://maps.googleapis.com/maps/api/geocode/json?address=" + encodeURIComponent(address) + `&key=${process.env.REACT_APP_MAP_KEY}`)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 'OK') {
                    setapiresponce(result.results)
                    console.log(result.results);
                } else {
                    alert('search error please give valid address ')
                }
            })
            .catch((error) => console.log("error", error));

    }
    function handeladdresschange(params) {
        updateState({ home_address: apiresponce[params].formatted_address })
        setformatedaddress(apiresponce[params])
    }
    async function fileuplode(e, setdata, id, upoldevalue) {
        e.preventDefault();
        if (e.target.files[0] !== undefined) {
            const file = e.target.files[0];
            const metadata = {
                contentType: file.type,
            };
            var fileurl = URL.createObjectURL(file)

            var timese = Math.floor(new Date().getTime() / 1000);

            const mountainsRef = ref(storage, "driver_info/" + timese + id + file.name);

            // const mountainsRef = ref(storage, "TESTUPLOAD_Dinesh/" + timese + id + file.name);
            const uploadTask = uploadBytesResumable(mountainsRef, file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    upoldevalue({ label: fileurl, value: prog });
                },
                (error) => console.log(error),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setdata(downloadURL)
                    });
                }
            );
        }
    }
    async function cleanform() {
        setapiresponce(null)
        setformatedaddress()
        setaddress()
        setfname(initialState)
        setlname(initialState)
        setcommission(0)
        setdob(initialState)
        setgender(initialState)
        setdriverprofilepic(initialState)
        setcontactno(initialState)
        setemailid(initialState)
        setjoindate(initialState)
        setpassword(initialState)
        setlicensevalidity(initialState)
        setlicense_back_pic(initialState)
        setlicense_front_pic(initialState)
        setlicenseno(initialState)
        setlasdricard(initialState)
        setlasdrivalidity(initialState)
        setpolice_verification(initialState)
        setdocument_status(initialState)
        setpolice_verificationdoc(null)
        setMaxDueAmount(20000)
        setselectCountry(null)
        setselectCity(null)
        //uplode 
        setuplode2({ label: "", value: 0 })
        setuplode3({ label: "", value: 0 })
        setuplode4({ label: "", value: 0 })
        setuplode1({ label: "", value: 0 })
    }
    if (isloading) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{'Loading Data...'}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        )
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <Card sx={{ m: 'auto', p: 3, pb: 0 }}>

                    <form autoComplete="off" onSubmit={handleSubmit} className="w-100">
                        <Grid container spacing={3} >
                            <Grid item sm={12}>
                                <Divider />
                                <SuiTypography varient='h5'  >Personal Details</SuiTypography  >
                                <Divider />
                            </Grid >
                            <Grid item md={4} >
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >First Name</SuiTypography >
                                <SuiInput required
                                    name='fname'
                                    placeholder="Enter first name"
                                    type="text"
                                    value={fname}
                                    inputProps={{
                                        autocomplete: 'something'
                                    }}
                                    onChange={(e) => {
                                        var re = /^([a-zA-Z]+|[\b]|null|)$/;
                                        if (re.test(e.target.value)) {
                                            setfname(e.target.value)
                                            updateState({ fname: e.target.value })
                                        }
                                        else {
                                            handleSnackbar('Special characters and Numbers are not allowed')
                                        }
                                    }
                                    }
                                />

                            </Grid >

                            <Grid item md={4} >

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Last Name</SuiTypography >
                                <SuiInput required
                                    placeholder="Enter last name"
                                    type="text"
                                    value={lname}
                                    onChange={(e) => {
                                        var re = /^([a-zA-Z]+|[\b]|null|)$/;
                                        if (re.test(e.target.value)) {
                                            setlname(e.target.value);
                                            updateState({ lname: e.target.value })

                                        }
                                        else {
                                            handleSnackbar('Special characters and Numbers are not allowed')
                                        }
                                    }
                                    }

                                ></SuiInput>

                            </Grid >
                            <Grid item md={4} >

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Date of Birth</SuiTypography >

                                <SuiInput required
                                    inputProps={{ min: "1960-01-01", max: "2045-05-04" }}
                                    value={dob}
                                    onChange={(e) => {
                                        setdob(e.target.value);
                                        updateState({ dob: e.target.value })

                                    }}
                                    type="date"
                                />

                            </Grid >
                            <Grid item md={4} >

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Gender</SuiTypography >
                                <SuiSelect
                                    required={true}
                                    value={{ value: gender, label: gender }}
                                    options={["Male", "Female",
                                        "Do not wish to specify"].map((data2, i) => ({ value: data2, label: data2 }))}
                                    onChange={(e) => {
                                        setgender(e.value);
                                        updateState({ gender: e.value })

                                    }}
                                />

                            </Grid >
                            <Grid item md={4} >

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Contact Number</SuiTypography >

                                <SuiInput required

                                    value={contactno}
                                    inputProps={{ maxLength: 16, minLength: 10 }}
                                    onChange={(e) => {
                                        // setphoneNumber(e.target.value)

                                        var re = /^(\+{1}|\+[0-9]+|[0-9]+|)$/;
                                        if (re.test(e.target.value)) {
                                            setcontactno(e.target.value);
                                            updateState({ contactno: e.target.value })

                                        }
                                        else {
                                            handleSnackbar('Special characters and Alphabet are not allowed')

                                        }
                                    }
                                    }

                                    placeholder="Enter contact number"
                                    type="text"
                                />

                            </Grid >
                            <Grid item md={6} sm={12} xs={12} xl={6} lg={6} >
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select Country</SuiTypography>

                                {userinfo.countries.length !== 0 ? (
                                    <SuiSelect
                                        required
                                        placeholder={'Query by country'}
                                        value={selectCountry !== null ? { value: selectCountry, label: selectCountry } : null}
                                        options={userinfo.countries.map((data2) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            setselectCountry(e.value);
                                            setselectCity(null)
                                        }}
                                    />
                                ) : null}
                            </Grid>

                            {selectCountry !== null ? userinfo.isadmin ? (
                                <Grid item md={6} sm={12} xs={12} xl={6} lg={6} >
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select City</SuiTypography>
                                        <SuiSelect
                                            required
                                            placeholder={'Query by city'}
                                            value={selectCity !== null ? { value: selectCity, label: selectCity } : null}
                                            options={userinfo.allcities[selectCountry].map((data2, i) => ({ value: data2, label: data2 }))}
                                            onChange={(e) => {
                                                setselectCity(e.value);

                                            }}
                                        />
                                    </FormGroup>
                                </Grid>
                            ) : (
                                <Grid item md={6} sm={12} xs={12} xl={6} lg={6} ><FormGroup>

                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select City</SuiTypography>
                                    <SuiSelect
                                        required
                                        placeholder={'Query by city'}
                                        value={selectCity !== null ? { value: selectCity, label: selectCity } : null}
                                        options={userinfo.allcities.map((data2, i) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            setselectCity(e.value);

                                        }}
                                    />
                                </FormGroup>
                                </Grid>
                            ) : null}

                            <Grid item md={12} sm={12} lg={12} xl={12} >
                                <SuiTypography varient='h5'>Home Address</SuiTypography  >

                                <SuiInput required
                                    value={address}
                                    defaultValue=""
                                    onChange={(e) => {
                                        setaddress(e.target.value);

                                    }}
                                    placeholder="Enter address"
                                    type="text"
                                />
                                <SuiButton sx={{ m: 1 }} onClick={() => callapigeo()}>Set Home Address</SuiButton>
                                {apiresponce !== null ? <SuiBox sx={{ ml: 4, p: 1, pl: 2 }}>
                                    <SuiTypography sx={{ fontSize: '1rem', }}  >Select Home Address </SuiTypography >
                                    <Divider></Divider><FormControl >
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="radio-buttons-group"
                                            // value={value}
                                            onChange={e => { handeladdresschange(e.target.value) }}
                                        >{apiresponce.map((d, key) => {
                                            return <FormControlLabel sx={{ disaply: 'inline' }} value={key.toString()} control={<Radio />} label={d.formatted_address} />
                                        })}</RadioGroup>
                                    </FormControl></SuiBox> : null}
                            </Grid >
                            <Grid item sm={12}>
                                <Divider />
                                <SuiTypography varient='h5'  >Profile Details</SuiTypography  >
                                <Divider />
                            </Grid >

                            <Grid item md={6}>
                                <Grid container spacing={2}>
                                    <Grid item sm={12}>
                                        {" "}

                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Email</SuiTypography >

                                        <SuiInput required
                                            inputProps={{
                                                autocomplete: 'new-password',
                                            }}
                                            value={emailid}
                                            onChange={(e) => {
                                                setemailid(e.target.value);
                                                updateState({ emailid: e.target.value })

                                            }}
                                            placeholder="Enter email id"
                                            type="email"
                                        ></SuiInput>

                                    </Grid >

                                    <Grid item sm={12}>

                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Password</SuiTypography >
                                        <SuiInput
                                            inputProps={{
                                                autoComplete: 'new-password', minLength: 6
                                            }}

                                            type={showPassword ? "text" : "password"}
                                            value={password}
                                            onChange={(e) => {
                                                setpassword(e.target.value);
                                                updateState({ password: e.target.value })

                                            }}
                                            endAdornment={
                                                <InputAdornment sx={{ position: 'absolute', right: 0 }}>
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />


                                    </Grid >
                                    <Grid item sm={12}>

                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Join Date</SuiTypography >

                                        <SuiInput required
                                            inputProps={{ min: "2018-01-01", max: "2045-05-04" }}

                                            value={joindate}
                                            onChange={(e) => {
                                                setjoindate(e.target.value);
                                                updateState({ joindate: e.target.value })

                                            }}
                                            type="date"
                                        />

                                    </Grid >
                                    <Grid item md={12}>

                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >
                                            Maximum Due Amount (Naira &#8358;)
                                        </SuiTypography >

                                        <SuiInput required

                                            value={MaxDueAmount}
                                            onChange={(e) => {
                                                setMaxDueAmount(e.target.value);
                                                updateState({ max_due: e.target.value })

                                            }}
                                            type="number"
                                        />
                                    </Grid >
                                    <Grid item md={12}>

                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >
                                            Commission (%)
                                        </SuiTypography >

                                        <SuiInput required

                                            value={commission}
                                            onChange={(e) => {
                                                setcommission(e.target.value);
                                                updateState({ commission: e.target.value })

                                            }}
                                            type="number"
                                        />
                                    </Grid >
                                </Grid>
                            </Grid >

                            <Grid item md={6}>
                                <center>
                                    {" "}
                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Driver Profile Picture</SuiTypography >

                                    <SuiInput
                                        // required

                                        className="w-50"
                                        onChange={(e) => {
                                            fileuplode(
                                                e,
                                                setdriverprofilepic,
                                                "DriverProfile",
                                                setuplode1
                                            );
                                        }}
                                        type="file"
                                    />
                                    {uplode1.value !== 0 ? <Grid item md={6} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                                        <img style={{ height: '10rem', width: '100%', borderRadius: '50%' }} src={uplode1.label} alt="preview image" />
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Driver Profile Picture Uploaded {uplode1.value}%</SuiTypography >
                                    </Grid> : null}
                                </center>
                            </Grid >
                            <Grid item md={6} ></Grid >
                            <Grid item sm={12}>
                                <Divider />
                                <SuiTypography varient='h5'  >Document Details</SuiTypography  >
                                <Divider />
                            </Grid >
                            <Grid item md={6} >
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >LASDRI CARD (for lagos city only)</SuiTypography >
                                <SuiInput
                                    value={lasdricard}

                                    onChange={(e) => {
                                        var re = /^([a-zA-Z0-9- ]+|[\b]|null|)$/;
                                        if (re.test(e.target.value)) {
                                            setlasdricard(e.target.value)
                                            updateState({ lasdricard: e.target.value })

                                        }
                                        else {
                                            handleSnackbar('Special characters are not allowed')
                                        }
                                    }
                                    }

                                    placeholder="Enter LASDRI CARD"
                                    type="text"
                                />

                            </Grid >
                            <Grid item md={6} >
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >
                                    LASDRI CARD Validity (for lagos city only)
                                </SuiTypography >
                                <SuiInput
                                    inputProps={{ min: "2018-01-01", max: "2045-05-04" }}
                                    value={lasdrivalidity}
                                    onChange={(e) => {
                                        setlasdrivalidity(e.target.value);
                                        updateState({ lasdrivalidity: e.target.value })

                                    }}
                                    type="date"
                                />
                            </Grid >
                            <Grid item md={6} >
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Document Status</SuiTypography >
                                <SuiSelect
                                    // required
                                    value={{ value: document_status, label: document_status }}
                                    options={["Pending", "Approved",
                                        "Rejected"].map((data2, i) => ({ value: data2, label: data2 }))}
                                    onChange={(e) => {
                                        setdocument_status(e.value);
                                        updateState({ document_status: e.value })

                                    }}
                                />
                            </Grid >
                            <Grid item md={6} >

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Police Verification</SuiTypography >
                                <SuiSelect
                                    required
                                    value={{ value: police_verification, label: police_verification }}
                                    options={["Pending", "Approved",
                                        "Rejected"].map((data2, i) => ({ value: data2, label: data2 }))}
                                    onChange={(e) => {
                                        setpolice_verification(e.value);
                                        updateState({ police_verification: e.value })

                                    }}
                                />


                            </Grid >

                            <Grid item md={6} >

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >License Number</SuiTypography >

                                <SuiInput required

                                    value={licenseno}

                                    onChange={(e) => {
                                        var re = /^([A-Z0-9]+|[\b]|null|)$/;
                                        if (re.test(e.target.value)) {
                                            setlicenseno(e.target.value)
                                            updateState({ licenseno: e.target.value })

                                        }
                                        else {
                                            handleSnackbar('Special characters are not allowed')
                                        }
                                    }
                                    }
                                    type="text"
                                />

                            </Grid >

                            <Grid item md={6} >

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >License Validity</SuiTypography >

                                <SuiInput required
                                    inputProps={{ min: "2018-01-01", max: "2045-05-04" }}

                                    value={licensevalidity}
                                    onChange={(e) => {
                                        setlicensevalidity(e.target.value);
                                        updateState({ licensevalidity: e.target.value })

                                    }}
                                    type="date"
                                />

                            </Grid >
                            <Grid item md={12} xl={12} lg={12}>
                                <Grid container>
                                    <Grid item md={6} xl={6} lg={6} sx={{ mt: 'auto', mb: 'auto' }}>

                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >License Front Side Picture</SuiTypography >
                                        <div className="ghor " id="lfsp">
                                            {" "}
                                        </div>

                                        <SuiInput
                                            // required

                                            className="w-50"
                                            onChange={(e) => {
                                                fileuplode(
                                                    e,
                                                    setlicense_front_pic,
                                                    "lfsp",
                                                    setuplode4
                                                );
                                            }}
                                            type="file"
                                        />
                                    </Grid>
                                    {uplode4.value !== 0 ? <Grid item md={6} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                                        <img style={{ height: '10rem', width: '100%' }} src={uplode4.label} alt="preview image" />
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode4.value}%</SuiTypography >
                                    </Grid> : null}

                                </Grid>


                            </Grid >
                            <Grid item md={12} xl={12} lg={12}>
                                <Grid container>
                                    <Grid item md={6} xl={6} lg={6} sx={{ mt: 'auto', mb: 'auto' }}>

                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >License Back Side Picture</SuiTypography >

                                        <SuiInput
                                            //  required

                                            className="w-50"
                                            onChange={(e) => {
                                                fileuplode(
                                                    e,
                                                    setlicense_back_pic,
                                                    "lbsp",
                                                    setuplode3
                                                );
                                            }}
                                            type="file"
                                        />{" "}

                                    </Grid>
                                    {uplode3.value !== 0 ? <Grid item md={6} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                                        <img style={{ height: '10rem', width: '100%' }} src={uplode3.label} alt="preview image" />
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode3.value}%</SuiTypography >
                                    </Grid> : null}

                                </Grid>


                            </Grid >
                            <Grid item md={12} xl={12} lg={12}>
                                <Grid container>
                                    <Grid item md={6} xl={6} lg={6} sx={{ mt: 'auto', mb: 'auto' }}>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Police Verification Document</SuiTypography >
                                        <SuiInput
                                            // required
                                            onChange={(e) => {
                                                fileuplode(
                                                    e,
                                                    setpolice_verificationdoc,
                                                    "pvd",
                                                    setuplode2
                                                );
                                            }}
                                            type="file"
                                        ></SuiInput>

                                    </Grid>
                                    {uplode2.value !== 0 ? <Grid item md={6} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                                        <img style={{ height: '10rem', width: '100%' }} src={uplode2.label} alt="preview image" />
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode2.value}%</SuiTypography >
                                    </Grid> : null}

                                </Grid>


                            </Grid >

                            <Grid item md={6}>
                                {" "}

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  > </SuiTypography >
                                <SuiButton color="info" id="submitDriver" type="submitDriver">
                                    Submit
                                </SuiButton >

                            </Grid >

                            <Grid item md={6}>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  > </SuiTypography >
                                <SuiButton color="info"
                                    className="cancelButton"
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                >
                                    Go Back

                                </SuiButton >

                            </Grid >
                        </Grid>
                    </form>
                    <Box sx={{ mt: 2 }}>
                        {sipnnershow ? <LinearProgress color="success" /> : ''}
                    </Box>
                </Card>
                <CustomizedSnackbars message={snackmessage} open={snakopen} color={'error'} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />
            </SuiBox >
            <Footer />
        </DashboardLayout >
    );
}

export default Mobile;
