/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts
//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUniversity, faUserCircle, faTaxi, faAddressCard, faCar, faPlus,
  faUserShield, faUsers, faCalendar, faMapMarkerAlt,
  faBook, faMoneyBill, faChartArea, faServer, faListAlt, faList
} from '@fortawesome/free-solid-svg-icons'
import Notifications from "./CustomViews/dummy";
import Error404 from "layouts/authentication/error/404";

// Soft UI Dashboard PRO React icons
import Office from "examples/Icons/Office";

//africar routs
import AllRiders from "./CustomViews/Riders/AllRiders";
import Blacklisted from "./CustomViews/Riders/Blacklisted";

import Alldrivers from "./CustomViews/Drivers/Alldrivers";
import CancelledRides from "./CustomViews/Rides/CancelledRides";
import CompletedRides from "./CustomViews/Rides/CompletedRides";
import OnGoingRides from "./CustomViews/Rides/OnGoingRides";
import PendingRides from "./CustomViews/Rides/PendingRides";
import Vehicles from "./CustomViews/Vehicles/Vehicles";

//assets
import Mobile from "./CustomViews/Assets/Mobile";
import UpdateEmail from "./CustomViews/Assets/UpdateEmail";

import LinkMobileToSIM from "./CustomViews/Assets/LinkMobileToSIM";
import MobileVehicleLink from "./CustomViews/Assets/MobileVehicleLink";
import AddVehicle from "./CustomViews/Assets/AddVehicle";
import AddDrivers from "./CustomViews/Assets/AddDrivers";
import EditDrivers from "./CustomViews/Assets/EditDrivers";
import Editvehical from "./CustomViews/Assets/Editvehical";

import Unlinking from "./CustomViews/Assets/unlinkingASSETS";
import UnlinkDROfromVehicle from "./CustomViews/Assets/UnlinkDROfromVehicle";

import Timeline from "./CustomViews/ActionLogs/Timeline";
import SimCard from "./CustomViews/Assets/SimCard";
//localizatuon
import AddCity from "./CustomViews/Localization/AddCity";
import AddCountry from "./CustomViews/Localization/AddCountry";
//reports
import ArrivedButtonTracking from "./CustomViews/DriverAnalytics/ArrivedButtonTracking";
import BookingAcceptedtracking from "./CustomViews/DriverAnalytics/BookingAcceptedtracking";
import DriverAnalytics from "./CustomViews/DriverAnalytics/DriverAnalytics";
//cash collection
import CashCollection from "./CustomViews/CashCollection/CashCollection";
//hr
import userRoleHierarchy from "./CustomViews/HRManagement/userRoleHierarchy";
import HierachyDetail from "./CustomViews/HRManagement/HierachyDetail"
import EditUserRolePermission from "./CustomViews/HRManagement/EditUserRolePermission"

import SalesReferral from "./CustomViews/HRManagement/SalesReferral"
import CreateUserRole from "./CustomViews/HRManagement/CreateUserRole"
import AddUsers from "./CustomViews/HRManagement/AddUsers"
import AddDrivertoVehicle from "./CustomViews/HRManagement/AddDrivertoVehicle"
import AddDROtoVehicle from "./CustomViews/HRManagement/AddDROtoVehicle";
import TransferDRO from "./CustomViews/HRManagement/TransferDRO";

import CustomerCare from "./CustomViews/CustomerCare/CustomerCare";
//city ratecard
import CityRateCard from "./CustomViews/CityRateCard/CityRateCard";
import AddCityRateCard from "./CustomViews/CityRateCard/AddCityRateCard";
import EditCityRateCard from "./CustomViews/CityRateCard/EditCityRateCard";
//accounts 
import {
  CollectionsDrivers,
  CollectionsRides,
  CollectionsVehicle,
  OutStandingReport,
  RevenueDriver,
  RevenueDriverDetails,
  RevenueRides,
  RevenueVehicle,
  RevenueVehicledetails,
} from "./CustomViews/Accounts";
//promocode marketing
import Promocode from "./CustomViews/PromoCode/Promocode";
import UpdatePromocode from "./CustomViews/PromoCode/UpdatePromocode";
import AddPromocode from "./CustomViews/PromoCode/AddPromocode";
import PushNotification from "./CustomViews/PushNotification/PushNotification";
import ImageNotification from "./CustomViews/PushNotification/ImageNotification";

//warroom
import WARRoom from "./CustomViews/WarRoom/WarRoom";
import Allridesdownload from "./CustomViews/allridesdownload";
import Mapable from "./CustomViews/Mapable/Mapable";
const routes = [

  {
    type: "tab",
    name: "War Room",
    key: "WarRoom",
    route: "/WarRoom",
    icon: <FontAwesomeIcon icon={faUniversity} />,
    component: WARRoom,
    noCollapse: true,
    tag: "war_room",
    permission: 'R'
  },
  {
    type: "tab",
    name: "allridesdownload",
    key: "allridesdownload",
    route: "/allridesdownload",
    icon: <FontAwesomeIcon icon={faUniversity} />,
    component: Allridesdownload,
    noCollapse: true,
    tag: "war_room",
    permission: 'R'
  },
  {
    group: [],
    type: "collapse",
    name: "Riders",
    key: "users",
    icon: <FontAwesomeIcon icon={faUserCircle} />,
    group: ['AllRiders', 'BlacklistedRiders', 'BlacklistedDueToNonPaymentRiders'],
    collapse: [
      {
        name: "All Riders",
        key: "AllRiders",
        route: "/AllRiders",
        component: AllRiders,
        tag: "riders",
        permission: "R",
      },
      {
        name: "Blacklisted",
        key: "BlacklistedRiders",
        route: "/BlacklistedRiders",
        component: Blacklisted,
        tag: "riders",
        permission: "R",
      },
      {
        name: "Blacklisted Due To Non-Payment",
        key: "BlacklistedDueToNonPaymentRiders",
        route: "/BlacklistedDueToNonPaymentRiders",
        component: Notifications,
        tag: "riders",
        permission: "R",
      },

    ],
  },
  { type: "title", title: "Drivers", key: "Driverstitle" },
  {
    group: ['AllDrivers'],
    type: "collapse",
    name: "Drivers",
    key: "Drivers",
    icon: <FontAwesomeIcon icon={faCar} />,
    collapse: [
      {
        name: "All Drivers",
        key: "AllDrivers",
        route: "/AllDrivers",
        component: Alldrivers,
        permission: "R",
        tag: "drivers",
      },

    ],
  },


  {
    type: "subtab",
    name: "edit driver",
    key: "editdriver",
    route: "/EditDriver",
    component: EditDrivers,
    noCollapse: true,
    tag: "drivers",
    permission: "U",
  },
  {
    group: ['OnGoingRides', 'CompletedRides', 'PendingRides', 'CancelledRides'],
    type: "collapse",
    name: "Rides",
    key: "Rides",
    icon: <FontAwesomeIcon icon={faAddressCard} />,

    collapse: [
      {
        name: "On Going Rides",
        key: "OnGoingRides",
        route: "/OnGoingRides",
        component: OnGoingRides,
        permission: "R",
        tag: "rides",
      },
      {
        name: "Completed Rides",
        key: "CompletedRides",
        route: "/CompletedRides",
        component: CompletedRides,
        permission: "R",
        tag: "rides",
      },
      {
        name: "Pending Rides",
        key: "PendingRides",
        route: "/PendingRides",
        component: PendingRides,
        permission: "R",
        tag: "rides",
      },
      {
        name: "Cancelled Rides",
        key: "CancelledRides",
        route: "/CancelledRides",
        component: CancelledRides,
        permission: "R",
        tag: "rides",
      },

    ],
  },
  {
    group: ['AllVehicles',
      'ExpiredDocument',
      'Blacklisted'],
    type: "collapse",
    name: "Vehicles",
    key: "Vehicle",
    icon: <FontAwesomeIcon icon={faTaxi} />,

    collapse: [
      {
        name: "Vehicle Details",
        key: "AllVehicles",
        route: "/AllVehicles",
        component: Vehicles,
        permission: "R",
        tag: "vehicles",
      },
      {
        name: "Expired Document",
        key: "ExpiredDocument",
        route: "/ExpiredDocument",
        component: Notifications,
        permission: "R",
        tag: "vehicles",
      },
      {
        name: "Blacklisted Vehicles",
        key: "Blacklisted",
        route: "/Blacklisted",
        component: Notifications,
        permission: "R",
        tag: "vehicles",
      },

    ],
  },
  {
    type: "subtab",
    name: "edit vehicle",
    key: "editvehicle",
    route: "/editVehicles",
    component: Editvehical,
    noCollapse: true,
    permission: "U",
    tag: "vehicles",

  },
  {
    group: [
      'SIMCard',
      'Mobiles',
      'MobileSIMLink',
      'MobileVehicleLink',
      'UnlinkDROFromVehicle',
      'Unlinking',
      'AddVehicle',
      'AddDrivers'],
    type: "collapse",
    name: "Assets",
    key: "Assets",
    icon: <FontAwesomeIcon icon={faPlus} />,
    tag: "vehicles",

    collapse: [
      {
        name: "SIM Card",
        key: "SIMCard",
        route: "/SIMCard",
        component: SimCard,
        permission: "R",
        tag: "sim",
      },
      {
        name: "Mobiles",
        key: "Mobiles",
        route: "/Mobiles",
        component: Mobile,
        permission: "R",
        tag: "mobile",
      },
      {
        name: "Mobile SIM Link",
        key: "MobileSIMLink",
        route: "/MobileSIMLink",
        component: LinkMobileToSIM,
        double_check: true,
        permission: "U",
        tag: "mobile",
        tag2: "sim",
      },
      {
        name: "Mobile Vehicle link",
        key: "MobileVehicleLink",
        route: "/MobileVehicleLink",
        component: MobileVehicleLink,
        permission: "U",
        double_check: true,
        tag2: "vehicles",
        tag: "drivers",
      },
      {
        name: "Unlink DRO-Vehicle",
        key: "UnlinkDROFromVehicle",
        route: "/UnlinkDROFromVehicle",
        component: UnlinkDROfromVehicle,
        tag: "vehicles",
        double_check: true,
        tag2: "mappable",
        permission: "U",
      },
      {
        name: "Unlinking of assets",
        key: "Unlinking",
        route: "/Unlinking",
        component: Unlinking,
        permission: "U",
        double_check: true,
        tag2: "vehicles",
        tag: "mappable",
      },
      {
        name: "Add Vehicle",
        key: "AddVehicle",
        route: "/AddVehicle",
        component: AddVehicle,
        tag: "vehicles",
        permission: "W",
      },
      {
        name: "Add Drivers",
        key: "AddDrivers",
        route: "/AddDrivers",
        component: AddDrivers,
        permission: "W",
        tag: "drivers",
      },
    ],
  },
  {
    type: "collapse",
    name: "HR Management",
    key: "HR",
    icon: <FontAwesomeIcon icon={faUniversity} />,
    group: ['AddDriverToVehicle', 'AddDroToVehicle', 'SetTargets', 'CreateUserRole', 'AddUser', 'Hierarchy', 'Referral'],
    collapse: [
      {
        route: "/AddDriverToVehicle",
        name: "Add Driver To Vehicle",
        key: "AddDriverToVehicle",
        component: AddDrivertoVehicle,
        double_check: true,
        tag2: "mappable",
        permission: "U",
        tag: "drivers",
      },
      {
        route: "/UpdateEmail",
        name: "Update Driver email",
        key: "UpdateEmail",
        component: UpdateEmail,
        double_check: true,
        tag2: "mappable",
        permission: "U",
        tag: "drivers",
      },
      
      {
        route: "/AddDroToVehicle",
        name: "Add DRO To Vehicle",
        key: "AddDroToVehicle",
        component: AddDROtoVehicle,
        double_check: true,
        tag2: "mappable",
        permission: "U",
        tag: "vehicles",

      },
      {
        route: "/TransferDRO",
        name: "Transfer DRO",
        key: "TransferDRO",
        component: TransferDRO,
        double_check: true,
        tag2: "mappable",
        permission: "U",
        tag: "vehicles",

      },
      {
        route: "/CreateUserRole",
        name: "Create User Role",
        key: "CreateUserRole",
        component: CreateUserRole,
        permission: "W",
        tag: "add_users",
      },
      {
        route: "/AddUser",
        name: "Add User",
        key: "AddUser",
        component: AddUsers,
        permission: "W",
        tag: "add_users",

      }, {
        route: "/Hierarchy",
        name: "User Role Hierarchy",
        key: "Hierarchy",
        component: userRoleHierarchy,
        permission: "R",
        tag: "add_users",
        //        tag: "admin",

      },
      {
        name: "SetTargets",
        key: "SetTargets",
        route: "/SetTargets",
        icon: <FontAwesomeIcon icon={faUniversity} />,
        component: Mapable,
        tag: "mappable",
        permission: 'U'
      },
      {
        route: "/Referral",
        name: "Sales Referral",
        key: "Referral",
        component: SalesReferral,
        permission: "R",
        tag: "sales_referral",
      },
    ],
  },
  {
    type: "tab",
    name: "Activities",
    key: "Activities",
    route: "/Activities",
    icon: <FontAwesomeIcon icon={faList} />,
    component: Timeline,
    noCollapse: true,
    tag: "logs",
    permission: 'R'
  },
  {
    type: "subtab",
    name: "edit UserRole",
    key: "editUserRole",
    route: "/editUserRole",
    component: EditUserRolePermission,
    noCollapse: true,
    tag: "add_users",
    permission: "U",
  },
  {
    group: ['CustomerSupport'],
    type: "collapse",
    name: "Customer Care",
    key: "CustomerSupport",
    icon: <FontAwesomeIcon icon={faUsers} />,
    collapse: [
      {
        route: "/CustomerSupport",
        name: "Customer Support",
        key: "CustomerSupport",
        component: CustomerCare,
        tag: "customer_care",
        permission: 'R'
      },
    ]
  },
  {
    type: "tab",
    name: "City Rate Card",
    key: "CityRateCard",
    route: "/CityRateCard",
    icon: <FontAwesomeIcon icon={faCalendar} />,
    component: CityRateCard,
    noCollapse: true,
    tag: "city_rate_card",
    permission: "R",
  },
  {
    type: "subtab",
    name: "City Rate Card",
    key: "AddCityRateCard",
    route: "/AddCityRateCard",
    component: AddCityRateCard,
    noCollapse: true,
    tag: "city_rate_card",
    permission: "W",
  },
  {
    type: "subtab",
    name: "edit City Rate Card",
    key: "EditCityRateCard",
    route: "/EditCityRateCard",
    component: EditCityRateCard,
    noCollapse: true,
    tag: "city_rate_card",
    permission: "U",
  },

  {
    group: ['AddCity',
      'AddCountry'],
    type: "collapse",
    name: "Localization",
    key: "Localization",
    icon: <FontAwesomeIcon icon={faMapMarkerAlt} />,
    collapse: [
      {
        name: "Add City",
        key: "AddCity",
        route: "/AddCity",
        component: AddCity,
        permission: "W",
        tag: "localization",
      },
      {
        name: "Add Country ",
        key: "AddCountry",
        route: "/AddCountry",
        component: AddCountry,
        permission: "W",
        tag: "localization",
      },


    ],
  },
  {
    group: ['DriverAnalytics'],
    type: "collapse",
    name: "Reports",
    key: "Reportskey",
    icon: <FontAwesomeIcon icon={faBook} />,

    collapse: [
      {
        name: "Driver Analytics",
        key: "DriverAnalytics",
        route: "/DriverAnalytics",
        component: DriverAnalytics,
        permission: "R",
        tag: "drivers",

      },
      {
        name: "Booking Accepted tracking",
        key: "BookingAcceptedtracking",
        route: "/BookingAcceptedtracking",
        component: BookingAcceptedtracking,
        permission: "R",
        tag: "drivers",

      },

      {
        name: "Arrived Button Tracking",
        key: "ArrivedButtonTracking",
        route: "/ArrivedButtonTracking",
        component: ArrivedButtonTracking,
        permission: "R",
        tag: "drivers",

      },
    ],
  },
  // {
  //   type: "tab",
  //   name: "Cash Collection",
  //   key: "CashCollection",
  //   route: "/CashCollection",
  //   icon: <FontAwesomeIcon icon={faMoneyBill} />,
  //   component: CashCollection,
  //   noCollapse: true,
  //   tag: "cash_collection",
  //   permission: "R",
  // },
  {
    group: ['Promocode', 'PushNotifications'],
    type: "collapse",
    name: "Marketing",
    key: "Marketing",
    icon: <FontAwesomeIcon icon={faChartArea} />,
    collapse: [
      {
        name: "Promo Code",
        key: "Promocode",
        route: "/Promocode",
        component: Promocode,
        permission: "R",
        tag: "coupons",
      },
      {
        name: "Push Notifications",
        key: "PushNotification",
        route: "/PushNotifications",
        component: PushNotification,
        permission: "R",
        tag: "push_notifications",
      },


    ],
  },
  {
    type: "subtab",
    name: "ImageNotification",
    key: "ImageNotification",
    route: "/ImageNotification",
    component: ImageNotification,
    noCollapse: true,
    tag: "push_notifications",
    permission: "R",
  },
  {
    type: "subtab",
    name: "edit driver",
    key: "AddPromocode",
    route: "/AddPromocode",
    component: AddPromocode,
    permission: "W",
    tag: "coupons",
  },

  {
    type: "subtab",
    name: "edit driver",
    key: "UpdatePromocode",
    route: "/UpdatePromocode",
    component: UpdatePromocode,
    permission: "W",
    tag: "coupons",
  },
  {
    group: ['CollectionsRides', 'CollectionsDriver', 'CollectionsVehicle',
      'RevenueRides',
      'RevenueDriver',
      'RevenueVehicle', 'Out Standing Report'
    ],
    type: "collapse",
    name: "Accounts",
    key: "Accounts",
    icon: <FontAwesomeIcon icon={faUniversity} />,
    tag: "accounts",
    collapse: [
      {
        type: "collapse",
        name: "Collections",
        key: "CollectionsCollapse",
        icon: <FontAwesomeIcon icon={faUniversity} />,
        permission: "R",
        tag: "accounts",
        collapse: [
          {
            name: "Collections Ride Wise",
            key: "Collections-Rides",
            route: "/CollectionsRides",
            component: CollectionsRides,
          }, {
            name: "Collections Driver Wise",
            key: "Collections-Driver",
            route: "/CollectionsDriver",
            component: CollectionsDrivers,
          }, {
            name: "Collections Vehicle Wise",
            key: "Collections-Vehicle",
            route: "/CollectionsVehicle",
            component: CollectionsVehicle,
          },
        ]
      },
      {
        type: "collapse",
        name: "Revenue",
        key: "RevenueCollapse",
        icon: <FontAwesomeIcon icon={faChartArea} />,
        permission: "R",
        tag: "accounts",
        collapse: [
          {
            name: " Revenue Ride Wise",
            key: "RevenueRides",
            route: "/RevenueRides",
            component: RevenueRides,
          }, {
            name: " Revenue Driver Wise",
            key: "RevenueDriver",
            route: "/RevenueDriver",
            component: RevenueDriver,
          }, {
            name: "Revenue Vehicle Wise",
            key: "RevenueVehicle",
            route: "/RevenueVehicle",
            component: RevenueVehicle,
          },
        ]
      },
      {
        name: "Out Standing Report ",
        key: "outstandingReport",
        route: "/Out Standing Report",
        component: OutStandingReport,
        permission: "R",
        tag: "accounts",
      },
    ],

  },

  {
    type: "subtab",
    key: "RevenueDriverDetails",
    route: "/RevenueDriverDetails",
    component: RevenueDriverDetails,
    tag: "accounts",
    permission: "R",
  },
  {
    type: "subtab",
    key: "RevenueVehicledetails",
    route: "/RevenueVehicledetails",
    component: RevenueVehicledetails,
    tag: "accounts",
    permission: "R",
  },
  {
    type: "subtab",
    key: "userRoleHierarchyDetails",
    route: "/userRoleHierarchyDetails",
    component: HierachyDetail,
    tag: "accounts",
    permission: "R",
  },
  //////
];

export default routes;
