import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Divider from "@mui/material/Divider";

import {
  addHierarchyDetails, addHierarchyDetailsClean
} from "CustomComponents/dataStore/actions";
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "CustomComponents/Authcontext";

function RevenueDriverDetails() {
  const dd = useSelector((state) => state.HierarchyDetails);
  const dispatch = useDispatch();
  let history = useHistory();
  const {currentUser}=useAuth();

  const [nextkey, setnextkey] = useState('');
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
  const location = useLocation();
  const [isLoding, setisLoding] = useState(true);
  useEffect(() => {
    dispatch(addHierarchyDetailsClean());
    calldataall_riders('');
  }, [0]);
  async function calldatahierarchyRepeat(e) {
    if (e >= 10) {
      var da = "";
      if (dd.nextKey !== "") {
          da = dd.nextKey;
      } else {
          da = nextkey;
      }
      if (da !== null && nexttoggle) {
          da = await calldataall_riders(da, e);
      }
  }

}
  //function call api
  async function calldataall_riders(nexttokenparam) {
    setspinnershow(true);

    if (nexttokenparam === undefined) {
      nexttokenparam = dd.nextKey || nextkey;
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + currentUser.accessToken
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    console.log(location.state.id);
    await fetch(
      process.env.REACT_APP_BASE_URL+"/role?type=list_users&role=" +
      location.state.id +
      "&start=" +
      nexttokenparam,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true && result.data.length !== 0) {
          setnextkey(result.start);
          dispatch(addHierarchyDetails(result.data, result.start));
          setnexttoggle(true);
          setspinnershow(false);
          setisLoding(false);
          return result.start;
        } else {
          // dispatch(addHierarchyDetailsClean());
          setisLoding(true);
          setspinnershow(false);
          setnexttoggle(false);
          setmessagttouser(result.message);

          return null;
        }
      })
      .catch((error) => {
        setnexttoggle(false);
        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });
  }
  if (isLoding && dd.arr.length === 0) {


    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox pt={2} pb={3}>
          <Card>
            <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
          </Card>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={2} pb={3}>
        <Card sx={{ m: 1, p: 2 }}>
          {sipnnershow && (
            <div style={{ display: "inline-block" }}>
              Loading data... <CircularProgress size={20} color="primary" />
            </div>
          )}

          <Divider />
          <SuiTypography variant="h6" sx={{ textAlign: 'left' }}>{location.state.id}{' '} user {'Details'}</SuiTypography>
          <Divider />
          {messagttouser !== 'Loading Data...' ? <SuiTypography variant="h6" sx={{ textAlign: 'left', color: 'red' }}>Error : {messagttouser}</SuiTypography>
            : null}

          <DataTable table={dd.arr}
            hidecolumnsdata={[]}
            functioncallcount={calldatahierarchyRepeat}
            datacolumns={[
              {
                Header: "User name",
                accessor: "name",
              },
              {
                Header: "Email id",
                accessor: "email",
              },
              {
                Header: "Role",
                accessor: "role",
              },

            ]}
             canSearch />
        </Card>

      </SuiBox>
      <Footer />
    </DashboardLayout >
  );
}

export default RevenueDriverDetails;
