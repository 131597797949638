
const initailState = {
    arr: [],
    nextKey: '',
    tempnextKey: '',
    temp:[]
}
const LogesStore = (state = initailState, action) => {
    switch (action.type) {
        case 'ADD_LOGS':
            return {
                ...state,
                arr: state.arr.concat(action.payload),
                nextKey: action.key,
                
            }
            case 'ADD_LOGS_search':
            return {
                ...state,
                temp:state.temp.concat(action.payload),
                tempnextKey: action.key,
            }
            case 'CLEAR_LOGS_search_temp':
                return {
                    ...state,
                    temp:[],
                    tempnextKey:'',
                }
        case 'CLEAN_STATE_LOG':
            return state = initailState;
        default:
            return state;
    }
}

export default LogesStore