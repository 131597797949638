import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import {
    useHistory,
} from "react-router-dom";
import { adduserpermition as add_user_permission, removeuserpermition, Clearuserpermition } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from "@mui/material/Divider";
import { useAuth } from "CustomComponents/Authcontext";

function Userrolesdisplay({ data, keys, updatedata1, removeuser, editdata }) {
    const myArray = editdata !== undefined ? editdata.split(",") : undefined;

    const [display, setdisplay] = useState(editdata !== undefined)
    const [datas, setdatas] = useState({
        [data[0]]: []
    })
    const [state12, setstate12] = useState(data[0])
    useEffect(() => {
        if (editdata !== undefined) {
            datas[state12] = myArray
            updatedata1(datas)
        }
    }, [0])


    function dasdkf(data, state) {
        if (state12 !== undefined) {
            if (state) {
                datas[state12].push(data)

            } else {
                datas[state12] = datas[state12].filter(item => item !== data)
            }
            updatedata1(datas);
        }
    }
    return (
        <Grid item md={6} sm={6} sx={6} lg={6} xl={6} key={keys} >
            <Divider sx={{ margin: 0 }} />

            <FormControlLabel
                label={(data[0].charAt(0).toUpperCase() + data[0].slice(1)).replace("_", " ").replace("_", " ")}
                control={
                    <Checkbox
                        name={data[0]}
                        defaultChecked={editdata !== undefined}

                        onChange={(e) => {
                            if (e.target.checked) {
                                setdisplay(!display)
                                dasdkf("R", true)
                            } else {
                                setdisplay(!display);
                                setdatas({ [data[0]]: [] });
                                removeuser(data[0]);
                            }
                        }
                        }
                    />
                }
            />
            {display ? <div >
                <Grid container spacing={2} sx={{ p: 0.5 }}>
                    {data[1].map((roles, key) => {
                        return <Grid item key={key} md={3} sm={3} sx={3} lg={3} xl={3}>
                            <FormControlLabel
                                key={key}
                                label={roles === 'R' ? 'Read' : roles === 'W' ? 'Write' : roles === 'U' ? 'Update' : roles === 'D' ? 'Delete' : ''}
                                control={
                                    <Checkbox
                                        key={key}
                                        value={roles} name={roles + key}
                                        defaultChecked={myArray !== undefined ? myArray.includes(roles) : roles === "R" ? true : false}                                        
                                        onChange={(e) => {
                                            dasdkf(e.target.value, e.target.checked)
                                        }} />
                                }
                            />
                        </Grid >
                    })}

                </Grid >

            </div> : null}

        </Grid >
    )
}
function CreateUserRole(props) {
    let datarr = props.location.customCRCData;
    const { currentUser } = useAuth();

    const userPermissionsdata = useSelector(state => state.userrolecreation);

    const [permissiondata, setpermissiondata] = useState([])
    const [parentdata, setparentdata] = useState([])

    const [messagttouser, setmessagttouser] = useState("Loading Data...")
    const [nexttoggle, setnexttoggle] = useState(true);
    const [sipnnershow, setspinnershow] = useState(true);
    let history = useHistory();

    //form value
    const [Comes_under, set_comes_under] = useState()
    const [RoleName, setRoleName] = useState('')
    const [mapable, setmapable] = useState(false)
    //disatch function
    const dispatch = useDispatch();

    //rudex function call to update userrole values
    function updatedata(data) {
        dispatch(add_user_permission(data, Comes_under))
    }
    function deletedata(data) {
        dispatch(removeuserpermition(data))
    }
    useEffect(() => {
        if (datarr === undefined) {
            //   setnexttoggle(true);
            history.goBack();
        } else {
            setRoleName(datarr.name)
            setmapable(datarr.mapping)
            callPermissition()
        }
        return () => {
            dispatch(Clearuserpermition())
        }
    }, [0]);

    function createuserroleaicall() {
        // console.log(userPermissionsdata);
        if (userPermissionsdata.length !== 0 && RoleName !== '' && Comes_under !== null) {
            setnexttoggle(false);
            setspinnershow(true);
            var permissionsdata = {}
            userPermissionsdata.map((data) => {
                if (data != null) {
                    var dda = Object.entries(data);
                    permissionsdata[dda[0][0]] = dda[0][1];
                }
            }
            )
            if (mapable) {
                permissionsdata.mapping = mapable
            } else {
                permissionsdata.mapping = mapable
            }
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + currentUser.accessToken);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(
                {
                    "name": RoleName,
                    "permissions": permissionsdata
                }
            );
            // console.log({
            //     "name": RoleName,
            //     "permissions": permissionsdata
            // });
            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(process.env.REACT_APP_BASE_URL+"/role", requestOptions)
                .then(response => response.json())
                .then(result => {
                    alert("user created succssfull")
                    setnexttoggle(true)
                    setspinnershow(false)

                })
                .catch(error => {
                    alert("error")
                    setnexttoggle(false)

                    console.log('Error : ', error)
                });

        } else {
            alert("please Give the correct values");
        }
    }
    //apicall for to et permissition list
    function callPermissition() {
        setspinnershow(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + currentUser.accessToken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(process.env.REACT_APP_BASE_URL+"/role?type=permissions", requestOptions)
            .then(response => response.json())
            .then(result => {
                let permissions = result.permissions
                const dda = Object.entries(permissions);
                setparentdata(result.parent)
                // console.log(dda);
                setpermissiondata(dda)
                setspinnershow(false)

            })

            .catch(error => {
                setmessagttouser("Unable to get data....ERROR"); console.log('Error : ', error)
            });
    }

    if (parentdata.length === 0) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card sx={{ textAlign: 'center' }}>
                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >{messagttouser}</SuiTypography >
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={3} pb={3}>
                <Card sx={{ m: 1, p: 2 }}>

                    <FormGroup>
                        <SuiTypography varient="h4">User Role </SuiTypography>
                        <SuiInput
                            disabled
                            required
                            name="userrole"
                            type="text"
                            value={RoleName}
                            onChange={(e) => setRoleName(e.target.value)}
                        />

                        <Grid container spacing={2} sx={{ p: 0.5 }} >
                            <Grid item md={12}>  <SuiTypography varient="h6">User Role Permissions</SuiTypography></Grid >
                            <Grid item md={12} sm={12} sx={12} lg={12} xl={12}>
                                <FormControlLabel
                                    label="Drivers/Vehicles can be mapped to this user role."
                                    control={
                                        <Checkbox
                                            checked={mapable}
                                            onChange={(e) => {
                                                setmapable(e.target.checked)
                                            }} />
                                    }
                                />
                            </Grid >
                            {permissiondata.length !== 0 ? permissiondata.map((dataset, key) => {

                                return <Userrolesdisplay key={key} keys={key} editdata={datarr[dataset[0]]} removeuser={deletedata} data={dataset} updatedata1={updatedata} />
                            }) : null
                            }
                        </Grid >
                        <Grid container spacing={2} sx={{ mt: 3, }}>
                            <Grid item md={4} sm={4} sx={4} lg={4} xl={4}>
                                <SuiButton
                                    color='info'
                                    // disabled={!nexttoggle}
                                    onClick={() => createuserroleaicall()} >Add User Role</SuiButton>

                            </Grid >
                            <Grid item ></Grid >
                        </Grid >
                    </FormGroup>

                </Card>

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default CreateUserRole;
