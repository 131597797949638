import React,{ useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import Divider from "@mui/material/Divider";
import SuiInput from "components/SuiInput";

import SuiButton from "components/SuiButton";

export default function Page1({ settop, setbod, settit }) {
    const [title, settitle] = useState();
    const [body, setbody] = useState();
    const [topic, settopic] = useState();
    return <>
        <Grid container spacing={2} px={8} pb={2} >

            <Grid item md={12} >

                <SuiTypography sx={{ fontSize: '0.9rem', }}  > Topic</SuiTypography >

                <SuiInput required

                    value={topic}
                    onChange={(e) => {
                        settop(e.target.value)
                        settopic(e.target.value);
                    }}
                    placeholder="Enter topic"
                    type="text"
                />

            </Grid >
            <Grid item md={12} >

                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Title</SuiTypography >
                <SuiInput required

                    placeholder="Enter title"
                    type="text"
                    value={title}
                    onChange={(e) => {
                        settitle(e.target.value);
                        settit(e.target.value);
                    }}
                />

            </Grid >

            <Grid item md={12} >

                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Body</SuiTypography >

                <SuiInput required

                    placeholder="Enter body"
                    type="text"
                    value={body}
                    onChange={(e) => {
                        setbody(e.target.value);
                        setbod(e.target.value);
                    }}
                ></SuiInput>

            </Grid >
        </Grid >

    </>;
}
// "topic": "General",
// 	"title": "Test Notification",
// 	"body": "Book a cab and get a free ride now.",
// 	"image": "https://firebasestorage.googleapis.com/v0/b/africar-9309f.appspot.com/o/FCMImages%2Ffeature_graphics2.png?alt=media&token=2e444386-7627-40d0-bdd5-7f0c63d5d4e9"
// }