import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import DownloadIcon from '@mui/icons-material/Download';
import Papa from "papaparse";
import { useAuth } from "CustomComponents/Authcontext";

import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import { storage } from "../../CustomComponents/firebase";

import { AddMobileddata } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import {

    useHistory,
} from "react-router-dom";
function EditVehicle(props) {
    let datarr = props.location.customCRCData;

    // const mountainsRef = ref(storage, 'mountains.jpg');

    let cities = useSelector((state) => state.Ratecarddata);
    let history = useHistory();
    const [message, setmessage] = useState("loading data....");
    const [VehicleRegistrationNumber, setVehicleRegistrationNumber] =
        useState("");
    const [vehicle_id, setvehicle_id] = useState()
    const [Vehiclebrand, setVehiclebrand] = useState("");
    const [VehicleModel, setVehicleModel] = useState("");
    const [vehicleCategory, setvehicleCategory] = useState("");
    const [VehicleJoindata, setVehicleJoindata] = useState("");
    const [VehicleCity, setVehicleCity] = useState("");
    const [vehicleregistrationcardimage, setvehicleregistrationcardimage] =
        useState("");
    const [vehicalVendor, setvehicalVendor] = useState({ value: '', label: '' });
    const [insuranceValidity, setinsuranceValidity] = useState()
    const [VehicleInsurance, setVehicleInsurance] = useState("");
    const [VehicleFC, setVehicleFC] = useState("");
    const [vehiclefcValidDate, setvehiclefcValidDate] = useState("");
    const [proofofOwnershipDoc, setproofofOwnershipDoc] = useState("");
    const [autogenius_inspection_report, setautogenius_inspection_report] =
        useState("");
    const [VehicleColor, setVehicleColor] = useState();
    const [vehicleAGIRdate, setvehicleAGIRdate] = useState("");
    const [documentstatus, setdocumentstatus] = useState("");
    const [uplode2, setuplode2] = useState({ label: "", value: 0 });
    const [uplode3, setuplode3] = useState({ label: "", value: 0 });
    const [uplode1, setuplode1] = useState({ label: "", value: 0 });
    const [uplode4, setuplode4] = useState({ label: "", value: 0 });
    const [uplode5, setuplode5] = useState({ label: "", value: 0 });
    const [vendorshow, setvendorshow] = useState(false);
    const [dropvalue, setdropvalue] = useState("");
   //changed data will be stored in this state
   const [changeddata, setchangeddata] = useState({})
   const {currentUser}=useAuth();

    async function fileuplode(e, setdata, id, upoldevalue) {
        e.preventDefault();
        if (e.target.files[0] !== undefined) {
            const file = e.target.files[0];
            // console.log("file", file);
            var fileurl = URL.createObjectURL(file)

            var timese = Math.floor(new Date().getTime() / 1000);
            const mountainsRef = ref(storage, "vehicle_info/" + timese + id + file.name);
            const uploadTask = uploadBytesResumable(mountainsRef, file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    upoldevalue({ label: fileurl, value: prog });
                },
                (error) => console.log(error),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setdata(downloadURL)
                    });
                }
            );
        }
    }
    async function handleSubmit(event) {
        event.preventDefault();
        if (currentUser.accessToken !== undefined && vehicle_id !== null &&changeddata!=={}) {
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            myHeaders.append("Content-Type", "application/json");
            /*{
                    vendor_id: vehicalVendor.value,
                    registration_number: VehicleRegistrationNumber,
                    brand: Vehiclebrand,
                    model: VehicleModel,
                    Category: vehicleCategory,
                    joindate: datesecond(VehicleJoindata),
                    city: VehicleCity,
                    registration_card_image: vehicleregistrationcardimage,
                    insurance: VehicleInsurance,
                    insurance_validity: VehicleInsurancedate,
                    fc: VehicleFC,
                    fc_validity: datesecond(vehiclefcValidDate),
                    autogenius_inspection_report: autogenius_inspection_report,
                    autogenius_inspection_report_validity: datesecond(vehicleAGIRdate),
                    proof_of_ownership: proofofOwnershipDoc,
                    document_status: documentstatus,
                    vehicle_color: VehicleColor,
                  }*/
            var raw = JSON.stringify(changeddata);
            var requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

console.log(changeddata);
            fetch(
                process.env.REACT_APP_BASE_URL+"/all_vehicles?id=" + vehicle_id,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    if (result.status === true) {
                        alert("vehicle updated Successfully");
                    } else {

                        alert("Error :", result.message);
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                });
        } else {

            alert("Token error");
        }
    }
    useEffect(() => {
        console.log(datarr);

        if (datarr === undefined) {
            //   setnexttoggle(true);
            history.goBack();
        } else {
            setvehicle_id(datarr.id)
            setVehiclebrand(datarr.brand)
            setVehicleModel(datarr.model)
            setvehicleCategory(datarr.id)
            setVehicleRegistrationNumber(datarr.registration_number)
            setVehicleJoindata(new Date(datarr.joindate * 1000).toLocaleDateString('en-CA'))
            setVehicleCity(datarr.city)
            setvehicleregistrationcardimage(datarr.registration_card_image)
            setvehicalVendor({ value: '', label: datarr.vendor })
            if(datarr.insurance_validity!==''&&datarr.insurance_validity!==undefined&&datarr.insurance_validity!==null){
            setinsuranceValidity(new Date(datarr.insurance_validity * 1000).toLocaleDateString('en-CA'))
            }
            setVehicleInsurance(datarr.insurance)
            setVehicleFC(datarr.fc)
            setvehiclefcValidDate( new Date(datarr.fc_validity * 1000).toLocaleDateString('en-CA'))
            setproofofOwnershipDoc(datarr.proof_of_ownership)
            setautogenius_inspection_report(datarr.autogenius_inspection_report)
            setVehicleColor(datarr.color)
            setvehicleAGIRdate(new Date(datarr.autogenius_inspection_report_validity * 1000).toLocaleDateString('en-CA'))
            setdocumentstatus(datarr.document_status)
            if(dropvalue!==''){
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            fetch(
                process.env.REACT_APP_BASE_URL+"/add_vehicles",
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === false) {
                        if (result.message !== undefined) {
                            alert(result.message);
                            setmessage(result.message);
                            setvendorshow(false);
                        } else {
                            setdropvalue([]);
                            setvendorshow(false);
                        }
                    } else {
                        setdropvalue(result.vendors);
                        setvendorshow(true);
                    }
                })
                .catch((error) => {
                    setvendorshow(false);
                    console.log("error", error);
                });
        }
    }
    }, [0]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <Card >
                    <form onSubmit={handleSubmit} >
                        <Grid container spacing={2} sx={{ p: 3 }}>
                            <Grid item md={4} xl={6} lg={4}>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Registration Number</SuiTypography >

                                <SuiInput
                                    
                                    placeholder="Enter Vehicle Registration Number"
                                    type="text"
                                    value={VehicleRegistrationNumber}
                                    onChange={(e) => {
                                        setVehicleRegistrationNumber(e.target.value);
                                        changeddata.registration_number = e.target.value
                                        setchangeddata(changeddata)
                                    }}
                                ></SuiInput>
                            </Grid >
                            <Grid item md={4} xl={6} lg={4}>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Brand</SuiTypography >

                                <SuiInput
                                    
                                    value={Vehiclebrand}
                                    onChange={(e) => {
                                        setVehiclebrand(e.target.value);
                                        changeddata.brand = e.target.value
                                        setchangeddata(changeddata)
                                    }}
                                    placeholder="Enter Vehicle Brand"
                                    type="text"
                                />
                            </Grid >
                            <Grid item md={4} xl={6} lg={4}>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Model</SuiTypography >

                                <SuiInput
                                    
                                    value={VehicleModel}
                                    onChange={(e) => {
                                        setVehicleModel(e.target.value);
                                        changeddata.model = e.target.value
                                        setchangeddata(changeddata)
                                    }}
                                    placeholder="Enter vehicle model"
                                    type="text"
                                />
                            </Grid >

                            {/* <Grid item md={4} xl={6} lg={4}>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Category</SuiTypography >
                                <SuiSelect
                                    
                                    value={{ value: vehicleCategory, label: vehicleCategory }}
                                    options={[" ", "single",
                                        "share"].map((data2) => ({ value: data2, label: data2 }))}
                                    onChange={(e) => {
                                        setvehicleCategory(e.value);
                                    }}
                                />

                            </Grid > */}

                            <Grid item md={4} xl={6} lg={4}>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >vehicle join date</SuiTypography >
                                <SuiInput
                                disabled
                                    
                                    value={VehicleJoindata}
                                    onChange={(e) => {
                                        setVehicleJoindata(e.target.value);
                                        // changeddata.fname = e.target.value
                                        // setchangeddata(changeddata)
                                    }}
                                    type="date"
                                />
                            </Grid >
                            <Grid item md={4} xl={6} lg={4}>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle city</SuiTypography >
                                {cities.asasa.length !== 0 ? (
                                    <SuiSelect
                                        
                                        value={{ value: VehicleCity, label: VehicleCity }}
                                        options={cities.asasa.map((data2) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            setVehicleCity(e.value);
                                            changeddata.city = e.value
                                            setchangeddata(changeddata)
                                        }}
                                    />
                                ) : null}
                            </Grid >
                            {vendorshow ? (
                                dropvalue.length !== 0 ? (
                                    <Grid item md={4} xl={6} lg={4}>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vendor Name</SuiTypography >
                                        <SuiSelect
                                            disabled
                                            value={vehicalVendor}
                                            options={dropvalue.map((data2) => ({ value: data2.id, label: data2.name }))}
                                            onChange={(e) => {
                                                setvehicalVendor(e);
                                                // changeddata.fname = e.target.value
                                                // setchangeddata(changeddata)
                                            }}
                                        />

                                    </Grid >
                                ) : null
                            ) : null}
                            <Grid item md={4} xl={6} lg={4}>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Color</SuiTypography >
                                <SuiSelect
                                    
                                    value={{ value: VehicleColor, label: VehicleColor }}
                                    options={[" ", "Green",
                                        "Blue",
                                        "Red",
                                        "Black",
                                        "White",
                                        "Yellow"].map((data2) => ({ value: data2, label: data2 }))}
                                    onChange={(e) => {
                                        setVehicleColor(e.value);
                                        changeddata.vehicle_color = e.value
                                        setchangeddata(changeddata)
                                    }}
                                />

                            </Grid >
                            <Grid item md={12} xl={12} lg={12}>
                                <Grid container>
                                    <Grid item md={6} xl={6} lg={6} sx={{ mt: 'auto', mb: 'auto' }}>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Registration Card Image</SuiTypography >

                                        <SuiInput
                                            
                                            onChange={(e) => {
                                                fileuplode(
                                                    e,
                                                    setvehicleregistrationcardimage,
                                                    "Rcard_image",
                                                    setuplode1,
                                                );
                                                changeddata.registration_card_image = vehicleregistrationcardimage
                                                setchangeddata(changeddata)
                                            }}
                                            placeholder="chose file"
                                            type="file"
                                        />
                                    </Grid>

                                    {vehicleregistrationcardimage !== '' ? <Grid item md={6} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                                        <img style={{ height: '10rem', width: '100%' }} src={vehicleregistrationcardimage} alt="preview image" />
                                        {uplode1.value!==0?<SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode1.value}%</SuiTypography >:null}
                                    </Grid> : null}

                                </Grid>


                            </Grid >
                            <Grid item md={12} xl={12} lg={12}>
                                <Grid container>
                                    <Grid item md={6} xl={6} lg={6} sx={{ mt: 'auto', mb: 'auto' }}>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Insurance</SuiTypography >

                                        <SuiInput
                                            
                                            onChange={(e) => {
                                                fileuplode(e, setVehicleInsurance, "insurance", setuplode5);
                                                changeddata.insurance = VehicleInsurance
                                                setchangeddata(changeddata)
                                            }}
                                            type="file"
                                        />
                                    </Grid>

                                    {VehicleInsurance !== '' ? <Grid item md={6} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                                        <img style={{ height: '10rem', width: '100%' }} src={VehicleInsurance} alt="preview image" />
                                        {uplode5.value?<SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode5.value}%</SuiTypography >:null}
                                    </Grid> : null}
                                </Grid>

                            </Grid>
                            <Grid item md={6} xl={6} lg={6}>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Insurance Validity</SuiTypography >
                                <SuiInput
                                    
                                    value={insuranceValidity}
                                    onChange={(e) => {
                                        setinsuranceValidity(e.target.value);
                                    
                                        changeddata.insurance_validity =new Date(e.target.value).getTime() / 1000 
                                        setchangeddata(changeddata)
                                    }}
                                    type="date"
                                />
                            </Grid >
                            <Grid item md={12} xl={12} lg={12}>
                                <Grid container>
                                    <Grid item md={6} xl={6} lg={6} sx={{ mt: 'auto', mb: 'auto' }}>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >vehicle Fc Document</SuiTypography >


                                        <SuiInput
                                            
                                            onChange={(e) => {
                                                fileuplode(e, setVehicleFC, "fc", setuplode2);
                                                changeddata.fc = VehicleFC
                                                setchangeddata(changeddata)
                                            }}
                                            type="file"
                                        />
                                    </Grid>

                                    {VehicleFC!== '' ? <Grid item md={6} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                                        <img style={{ height: '10rem', width: '100%' }} src={VehicleFC} alt="preview image" />
                                        {uplode2.value?<SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode2.value}%</SuiTypography >:null}
                                    </Grid> : null}

                                </Grid>


                            </Grid >
                            <Grid item md={6} xl={6} lg={6}>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle FC Valid Till</SuiTypography >

                                <SuiInput
                                    
                                    value={vehiclefcValidDate}
                                    onChange={(e) => {
                                        setvehiclefcValidDate(e.target.value);
                                        changeddata.fc_validity = new Date(e.target.value).getTime() / 1000
                                        setchangeddata(changeddata)
                                    }}
                                    type="date"
                                />
                            </Grid >
                            <Grid item md={12} xl={12} lg={12}>
                                <Grid container>
                                    <Grid item md={6} xl={6} lg={6} sx={{ mt: 'auto', mb: 'auto' }}>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  > Vehicle AutoGenius Inspection Report</SuiTypography >


                                        <SuiInput
                                            
                                            onChange={(e) => {
                                                fileuplode(
                                                    e,
                                                    setautogenius_inspection_report,
                                                    "agir",
                                                    setuplode3
                                                );
                                                changeddata.autogenius_inspection_report = autogenius_inspection_report
                                                setchangeddata(changeddata)
                                            }}
                                            type="file"
                                        />
                                    </Grid>

                                    {autogenius_inspection_report !== null ? <Grid item md={6} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                                        <img style={{ height: '10rem', width: '100%' }} src={autogenius_inspection_report} alt="preview image" />
                                       {uplode3.value!==0? <SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode3.value}%</SuiTypography >:null}
                                    </Grid> : null}

                                </Grid>


                            </Grid >
                            <Grid item md={6} xl={6} lg={6}>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >
                                    Vehicle AutoGenius inspection Report Validity
                                </SuiTypography >

                                <SuiInput
                                    
                                    value={vehicleAGIRdate}
                                    onChange={(e) => {
                                        setvehicleAGIRdate(e.target.value);
                                        changeddata.autogenius_inspection_report_validity = new Date(e.target.value).getTime() / 1000
                                        setchangeddata(changeddata)
                                    }}
                                    type="date"
                                />
                            </Grid >
                            <Grid item md={12} xl={12} lg={12}>
                                <Grid container>
                                    <Grid item md={6} xl={6} lg={6} sx={{ mt: 'auto', mb: 'auto' }}>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Proof Of Ownership</SuiTypography >
                                        <SuiInput
                                            
                                            onChange={(e) => {
                                                fileuplode(e, setproofofOwnershipDoc, "POfO", setuplode4);
                                                changeddata.proof_of_ownership = proofofOwnershipDoc
                                                setchangeddata(changeddata)
                                            }}
                                            autogenius_inspection_report
                                            type="file"
                                        />
                                    </Grid>
                                    {proofofOwnershipDoc !== ''? <Grid item md={6} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                                        <img style={{ height: '10rem', width: '100%' }} src={proofofOwnershipDoc} alt="preview image" />
                                       {uplode4.value? <SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode4.value}%</SuiTypography >:null}
                                    </Grid> : null}

                                </Grid>


                            </Grid >
                            <Grid item md={6} xl={6} lg={6}>

                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Document status</SuiTypography >
                                <SuiSelect
                                    
                                    value={{ value: documentstatus, label: documentstatus }}
                                    options={[" ", "Pending",
                                        "Approved",
                                        "Rejected"].map((data2) => ({ value: data2, label: data2 }))}
                                    onChange={(e) => {
                                        setdocumentstatus(e.value);
                                        changeddata.document_status = e.value
                                        setchangeddata(changeddata)
                                    }}
                                />

                                {" "}
                            </Grid >
                            <Grid item sx={{ mb: 3 }} md={12} xl={12} lg={12} />

                            <Grid item md={6} xl={6} lg={6}>
                                <SuiButton id="submit" type="submit">
                                    Submit
                                </SuiButton>
                            </Grid >
                            <Grid item md={6} xl={6} lg={6}>
                                <SuiButton
                                    className="cancelButton"
                                    onClick={() => history.goBack()}
                                >
                                    Cancel
                                </SuiButton>
                            </Grid >

                        </Grid>
                    </form>
                </Card>
            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default EditVehicle;
