const initailState = {
    arr: [],
    nextKey: "",
  };
  const unlinkasseddata = (state = initailState, action) => {
    switch (action.type) {
      case "ADD_ASSET_SEARCHdata":

          return {
            arr: state.arr.concat(action.payload),
            nextKey: action.keynext,
         
        }
        case 'CLEAN_STATE_assets': 
        return state =initailState;
      default:
        return state;
    }
  };
  
  export default unlinkasseddata;
  