import { combineReducers } from "redux";

import All_Riders from './allriders'
import Driverlocation from "./Driverlocation";
import ALLDriverlocation from "./ALLDriverLocation";

import All_vendors from "./Allvendorsstore";
import All_DriversStore from "./AllDriversStore";
import All_Vehiclesstore from "./AllVehiclesstore";
import Fdata from "./fakedata";
import WarRoom from "./warroobdata";
import Isload from "./isloding";
import Ratecarddata from "./ratecarddetails";
import Completedrides from "./CompletedRides";
import Promocode from "./promocode";
import userrolecreation from "./userrolecreation";
import userhie from "./userhie";
import userpermisitions from "./userpermisitions";
import Ongoingridesdata from "./Ongoingridesdata";
import Adminloding from "./adminloding"
import Driveranalytics from './Driveranalytics';
import Urldata from './urldata';
import UnlinkedVehicle from "./UnlinkedVehicle";
import Searchdata from "./Searchdata";
import Unlinkedvehicledro from "./Unlinkedvehicledro";
import Drounlinkedvehicleds from "./Drounlinkedvehicleds";
import CashCollection from "./CashCollection";
import Simdata from "./Simdata";
import MobileData from "./MobileData";
import SalesReferral from "./SalesReferral";
import Permissions from "./Permissions";
import Warroomcity from "./Warroomcity";
import CancelledRides from "./CancelledRides";
import PendingRides from "./PendingRides";
//SEARCH
import AccountsOutStandingReport from "./AccountsOutStandingReport";
import AccountsCollectionDrivers from "./AccountsCollectionDrivers";
import AccountsCollectionRides from "./AccountsCollectionRides";
import AccountsCollectionVehicle from "./AccountsCollectionVehicle";
import AccountsRevenueDrivers from "./AccountsRevenueDrivers";
import AccountsRevenueVehicle from "./AccountsRevenueVehicle";
import AccountsRevenueRides from "./AccountsRevenueRides";
//DETAIL
import AccountsRevenuDriverdetails from "./AccountsRevenueDriverdetails";
import AccountsRevenuVehicleDetail from "./AccountsRevenueVehicleDetail";
import HierarchyDetails from "./HierarchyDetails";
import Unlinkasseddata from "./unlinkasseddata";
import DROsearch from "./DROsearch";
import DROlinkvehicledata from "./DROlinkvehicledata";
import LogesStore from "./LogesStore";
import Permisioncopy from "./Permisioncopy";
import Userinfo from "./Userinfo";
import Localization from "./localization";
import TargetStore from "./TargetStore";
import BlacklisteuUsersStore from "./BlacklisteuUsersStore";

const appReducer = combineReducers({
  All_Riders, CancelledRides, PendingRides,TargetStore, Permissions,Userinfo,ALLDriverlocation, Driverlocation,Localization, All_vendors, All_DriversStore, All_Vehiclesstore,
  WarRoom, Warroomcity, Fdata, Isload, Ratecarddata, Completedrides, Promocode, userrolecreation, userhie, userpermisitions,
  Ongoingridesdata, Adminloding, Driveranalytics, Urldata, Searchdata, UnlinkedVehicle, Unlinkedvehicledro, Drounlinkedvehicleds,
  CashCollection, MobileData, Simdata, SalesReferral, AccountsRevenueRides, AccountsCollectionDrivers, AccountsCollectionRides,
  AccountsCollectionVehicle, AccountsRevenueDrivers, AccountsRevenueVehicle, AccountsRevenuDriverdetails, AccountsRevenuVehicleDetail
  , AccountsOutStandingReport,HierarchyDetails,Unlinkasseddata,DROsearch,DROlinkvehicledata,LogesStore,Permisioncopy,BlacklisteuUsersStore
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

// const rootreduser = combineReducers({
//      All_Riders,Permissions, Driverlocation, All_vendors, All_DriversStore, All_Vehiclesstore,
//     WarRoom, Fdata, Isload, Ratecarddata, Completedrides, Promocode, userrolecreation,userhie,userpermisitions,
//     Ongoingridesdata,Adminloding,Driveranalytics,Urldata,Searchdata,UnlinkedVehicle,Unlinkedvehicledro,Drounlinkedvehicleds,
//     CashCollection,MobileData,Simdata,SalesReferral,

// })
// export default rootreduser;