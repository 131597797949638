
const initailState = {
    arr:[],
    nextKey:'',
    secarr:[],
    secnextKey:'',

}
const CancelledRides=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_CANCELLED_DATA':
            return{ 
                ...state,
                arr: state.arr.concat(action.payload),
                nextKey:action.keynext,

            }
            case 'ADD_SECOND_CANCELLED_DATA':
                return{ 
                    ...state,
                    secarr: state.secarr.concat(action.payload),
                    secnextKey:action.keynext,
    
                }
                case 'CLEAN_SECOND_CANCELLED_DATA':
                return{ 
                    ...state,
                    secarr: [],
                    secnextKey:'',
    
                }
        default:
            return state;
    }
}

export default CancelledRides