import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "CustomComponents/Authcontext";

import { addPromocode } from "CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { useHistory } from "react-router-dom";
import CustomizedSnackbars from "../Alert/Alert";

function PromCode() {
    const dispatch = useDispatch();
    const [messagttouser, setmessagttouser] = useState("Loading Data...")
    const [nexttoggle, setnexttoggle] = useState(true);
    const [sipnnershow, setspinnershow] = useState(false)
    const [selectCountry, setselectCountry] = useState(null);
    const [selectCity, setselectCity] = useState(null); let history = useHistory();
    const dd = useSelector(state => state.Promocode)
    const userinfo = useSelector((state) => state.Userinfo);

    const [nextkey, setnextkey] = useState('')
    //snack bar
    const [snakopen, setsnakopen] = useState(false)
    const [snackmessage, setsnackmessage] = useState(null)
    const handleSnackbar = (messages) => {
        setsnakopen(true)
        setsnackmessage(messages)
    };
    const { currentUser } = useAuth();

    async function handleSearchSubmit() {

        if (selectCity !== null && selectCountry !== null) {
            setspinnershow(true);
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + currentUser.accessToken);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_BASE_URL+`/coupon?city=${selectCity}&start=` + '', requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === true&&result.data.length!==0&&result.data!==undefined) {
                        setnextkey(result.start);
                        result.data.map((data) => {
                            data.redeem_by = new Date(data.redeem_by * 1000).toISOString().split('T')[0];
                            if (data.valid === true) { data.valid1 = <i classname="fa fa-check btn" disabled aria-hidden="true"></i> } else {
                                data.valid1 = <i class="fa fa-times btn" disabled aria-hidden="true"></i>;
                            }
                            data.update = <div><SuiButton color='info'
                                onClick={() => history.push({
                                    pathname: '/africar/UpdatePromocode',
                                    customNameData: data,
                                })}>Edit</SuiButton></div>
                        })
                        dispatch(addPromocode(result.data, result.start))
                        setnexttoggle(true)
                        setspinnershow(false)
                    } else {
                        setspinnershow(false)
                        dispatch(addPromocode([], ''))
                        handleSnackbar('No Promo code was found ');
                    }
                })

                .catch(error => {
                    setnexttoggle(false)
                    setspinnershow(false)
                    handleSnackbar(""+error);
                    console.log('error', error)
                });
        } else {
             if(selectCountry === null){
                handleSnackbar('Please select the country')

            }else if (selectCity === null ) {
                handleSnackbar('Please select the  city')

            }
        }
    }

    if (userinfo === null) {

        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <Card sx={{ m: 1, p: 2, pt: 0 }} >
                    {sipnnershow && (
                        <SuiBox sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{ width: '100%' }} />
                        </SuiBox>
                    )}
                    <form  >
                        <Grid container spacing={3} sx={{ mt: 1 }}>
                            <Grid item md={3}  >
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select Country</SuiTypography>
                                {userinfo.countries.length !== 0 ? (
                                    <SuiSelect
                                        required
                                        placeholder={'Query by country'}
                                        value={selectCountry !== null ? { value: selectCountry, label: selectCountry } : null}
                                        options={userinfo.countries.map((data2) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            setselectCountry(e.value);
                                            setselectCity(null)
                                        }}
                                    />
                                ) : null}
                            </Grid>

                            {selectCountry !== null ? userinfo.isadmin ? (
                                < >
                                    <Grid item md={3}  >
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select City</SuiTypography>
                                        <SuiSelect
                                            required
                                            placeholder={'Query by city'}
                                            value={selectCity !== null ? { value: selectCity, label: selectCity } : null}
                                            options={userinfo.allcities[selectCountry].map((data2, i) => ({ value: data2, label: data2 }))}
                                            onChange={(e) => {
                                                setselectCity(e.value);

                                            }}
                                        />
                                    </Grid>
                                </>
                            ) : (
                                < >
                                    <Grid item md={3}  >

                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select City</SuiTypography>
                                        <SuiSelect
                                            required
                                            widths={'16vw'}
                                            placeholder={'Query by city'}
                                            value={selectCity !== null ? { value: selectCity, label: selectCity } : null}
                                            options={userinfo.allcities.map((data2, i) => ({ value: data2, label: data2 }))}
                                            onChange={(e) => {
                                                setselectCity(e.value);

                                            }}
                                        />
                                    </Grid>
                                </>
                            ) : null}

                            <Grid item md={3} sx={{ mt: 'auto' }} >
                                <SuiButton type="button" onClick={() => { handleSearchSubmit() }}>
                                    Search Promo Code
                                </SuiButton>

                            </Grid>

                            <Grid item md={3} sx={{ mt: 'auto' }} ><SuiButton sx={{ float: 'right' }} onClick={() => { history.push('/africar/AddPromocode') }}>Add Promo Code</SuiButton></Grid>
                        </Grid>
                    </form>
                    <DataTable table={dd.arr}
                    LoadData={false}
                        hidecolumnsdata={[
                            "contactno",
                            "vendor",
                            "joindate",
                            "duration_in_months",
                            "max_redemption",
                            "max_redemption_per_user",
                            "times_redeemed",
                        ]}
                        functioncallcount={() => { }}
                        datacolumns={[
                            {
                                Header: "Coupon Name",
                                accessor: "id",
                            },
                            {
                                Header: "City",
                                accessor: "city",
                            },
                            {
                                Header: "Amount Off",
                                accessor: "amount_off",

                            },
                            {
                                Header: "Apply When",
                                accessor: "contactno",
                            },

                            {
                                Header: "Created On",
                                accessor: "vendor",
                            },
                            {
                                Header: "Description",
                                accessor: "description",
                            }, {
                                Header: "Min Fare",
                                accessor: "joindate",
                            }, {
                                Header: "Percent Off",
                                accessor: "percent_off",
                            }, {
                                Header: "Duration",
                                accessor: "duration",
                            }, {
                                Header: "Duration in Months",
                                accessor: "duration_in_months",
                            }, {
                                Header: "Redeem By",
                                accessor: "redeem_by",
                            }, {
                                Header: "Max Redemption",
                                accessor: "max_redemption",
                            },
                            {
                                Header: "Max Redemption(Per User)",
                                accessor: "max_redemption_per_user",
                            }, {
                                Header: "Times Redeemed",
                                accessor: "times_redeemed",
                            }, {
                                Header: "Total Discount",
                                accessor: "total_discount",
                            }, {
                                Header: "Action",
                                accessor: "update",
                            },

                        ]}
                        canSearch />
                </Card>
                <CustomizedSnackbars message={snackmessage} open={snakopen} color={'error'} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default PromCode;
