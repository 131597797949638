import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'

import { addRevenueVehicle } from "CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { useAuth } from "CustomComponents/Authcontext";


import { useHistory } from "react-router-dom";

function RevenueVehicle() {
    const dd = useSelector((state) => state.AccountsRevenueVehicle);
    const dispatch = useDispatch();
    let history = useHistory();
    const [nextkey, setnextkey] = useState(0);
    const [messagttouser, setmessagttouser] = useState("Loading Data...");
    const [nexttoggle, setnexttoggle] = useState(true);
    const [sipnnershow, setspinnershow] = useState(false);
    const { currentUser } = useAuth();

    useEffect(() => {
        if (dd.arr.length === 0) {
            calldatasetApi(0,100);
        }
    }, [0]);
    async function calldatasetApiRepeat(e) {
        if (e >= 10) {
            var da = "";
            if (dd.nextKey !== "") {
                da = dd.nextKey;
            } else {
                da = nextkey;
            }
            if (!nexttoggle) alert("No Data To Load");

            if (da !== null && nexttoggle) {
                da = await calldatasetApi(da, e);
            }
        }

    }
    const fileJson = (JSONData, ReportTitle, ShowLabel) => {
        var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
        var CSV = "";
        var datasest = [];
        datasest = arrData.map((data) => {
            if (data.last_paid_on) {
                data.last_paid_on = new Date(data.last_paid_on).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }

            var temp = {

                "Vehicle registration number": data.vehicle_number,
                "Vehicle total share rides": data.total_share_rides,
                "Vehicle total single rides": data.total_single_rides,
                "Vehicle cash rides count": data.cash_rides_count,
                "Vehicle wallet rides count": data.wallet_rides_count,
                "Vehicle card rides count": data.card_rides_count,
                "Vehicle total revenue": data.total_revenue !== undefined ? data.total_revenue[1] : 0,
            };
            return temp;
        });


        const items = datasest;
        const replacer = (key, value) => (value === null ? " " : value); // specify how you want to handle null values here
        const header = Object.keys(items[0]);
        CSV = [ShowLabel + ",",
        header.join(","), // header row first
        ...items.map((row) =>
            header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(",")
        ),
        ].join("\r\n");
        return CSV
    };
    function CSVDownload(params, name) {
        var CSV = params;
        if (CSV == "") {
            alert("Invalid data");
            return;
        }
        //Generate a file name
        var fileName = "Report_";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += name.replace(/ /g, "_");

        //Initialize file format you want csv or xls
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // setisDisabled(false);
    }
    async function calldatasetApi(nexttokenparam, limit = 100) {
        setspinnershow(true);
        if (nexttokenparam === undefined) {
            nexttokenparam = dd.nextKey || nextkey;
        }
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        return await fetch(
            process.env.REACT_APP_BASE_URL + "/accounts?type=revenue&sub_type=vehicles&start=" +
            nexttokenparam + '&limit=' + limit,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === true && result.data.length !== 0) {
                    result.data.map((dat) => {
                        dat.action = (
                            <div className="">
                                {/* use this button to remove the data row */}
                                <SuiButton
                                    onClick={() => {
                                        history.push({
                                            pathname: "/africar/RevenueVehicledetails",
                                            state: { id: dat.id },
                                        });
                                    }}
                                    color="info"
                                >
                                    more
                                </SuiButton>{" "}
                            </div>
                        );
                    });
                    dispatch(addRevenueVehicle(result.data, result.start));
                    setnexttoggle(true);
                    setspinnershow(false);
                    return result.start;
                } else {
                    setspinnershow(false);
                    setnexttoggle(false);
                    setmessagttouser("Unable to get data....ERROR : " + result.message);
                    console.log("error", result.message);
                    alert("No Data To Load");
                    return null;
                }
            })
            .catch((error) => {
                setnexttoggle(false);
                setmessagttouser("Unable to get data....ERROR");
                setTimeout(() => {
                    setmessagttouser("");
                }, 3000);
                console.log("error", error);
            });

    }
    if (dd.arr.length === 0) {

        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <Card >
                    {sipnnershow && (
                        <Box sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{ width: '100%' }} />
                        </Box>

                    )}
                    <SuiBox sx={{ mr: 2, mt: 1 }}>
                        <SuiButton sx={{ float: 'right' }} variant="gradient" onClick={() => {
                            var data = fileJson(dd.arr, '');
                            CSVDownload(data, 'Vehicle_wise')
                        }} color="info"  >
                            <FontAwesomeIcon icon={faDownload} />
                        </SuiButton>
                    </SuiBox>
                    <DataTable table={dd.arr}
                        hidecolumnsdata={[]}
                        functioncallcount={calldatasetApiRepeat}

                        datacolumns={[
                            {
                                Header: "vehicle number",
                                accessor: "vehicle_number",
                            },
                            {
                                Header: "total share rides ",
                                accessor: "total_share_rides",
                            },

                            {
                                Header: "total single rides",
                                accessor: "total_single_rides",
                            },
                            {
                                Header: "cash rides counts ",
                                accessor: "cash_rides_count",
                            },

                            {
                                Header: "wallet rides count",
                                accessor: "wallet_rides_count",
                            },
                            {
                                Header: "card rides count",
                                accessor: "card_rides_count",
                            },
                            {
                                Header: "total_revenue",
                                accessor: "total_revenue[1]",
                            },
                            {
                                Header: "View more",
                                accessor: "action",
                            },
                        ]}

                        canSearch />
                </Card>

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default RevenueVehicle;
