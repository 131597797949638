/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import CoverLayout from "../CoverLayout";
import { useAuth } from '../../CustomComponents/Authcontext'

// Images
import curved9 from "assets/images/curved-images/curved9.jpg";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  passwordField: {
    marginBottom: theme.spacing(10),
    "&::-ms-reveal": {
      display: "none"
    }
  }
}));
function Cover() {
  const [rememberMe, setRememberMe] = useState(null);
  const { login } = useAuth()
  const history = useHistory();
  const classes = useStyles();

  const [email, setemail] = useState()
  const [pasw, setpasw] = useState()

  const handellogin = async () => {
    var message = await login(email, pasw);
    if (message === 'true') {
      history.push('/africar/WarRoom')
    }
    setRememberMe(message)
  }
  const [showPassword, setshowPassword] = useState(false)




  const handleClickShowPassword = () => {
    setshowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <CoverLayout
      title="Welcome back"
      description="Enter your email and password to Login"
      image={curved9}
      top={5}
      sx={{
        minHeight: "90vh",
        margin: 0,
      }}
    >
      <SuiBox component="form" role="form">
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SuiTypography>
          </SuiBox>
          <SuiInput onChange={(e) => setemail(e.target.value)} type="email" placeholder="Email" />
        </SuiBox>
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SuiTypography>
          </SuiBox>
          <SuiInput
            type={showPassword ? "text" : "password"}
            value={pasw}
            placeholder="Password"
            onChange={(e) => setpasw(e.target.value)}
            endAdornment={
              <InputAdornment sx={{ position: 'absolute', right: 0 }}>
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {/* google dosent allow to revel password the above cose is custom it show entered password */}
          {/* <SuiInput   type="password" placeholder="Password" /> */}
        </SuiBox>

        <SuiBox mt={4} mb={1}>
          <SuiButton variant="gradient" onClick={() => handellogin()} color="info" fullWidth>
            sign in
          </SuiButton>
        </SuiBox>
        <SuiBox mt={3} textAlign="center">
          <SuiTypography sx={{ color: 'red' }}>
            {rememberMe ? rememberMe : null}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </CoverLayout>
  );
}

export default Cover;
