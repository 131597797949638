
  import { initializeApp } from "firebase/app";import 'firebase/auth'
  import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
  import { getFirestore } from "firebase/firestore";
  import { getDatabase } from "firebase/database";
  import { getStorage } from "firebase/storage";
  
  var firebaseConfig = {
    apiKey: `${process.env.REACT_APP_API_KEY}`,
    authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
    databaseURL: `${process.env.REACT_APP_DATABASE_URL}`,
    projectId: `${process.env.REACT_APP_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_APP_ID}`,
    measurementId: `${process.env.REACT_APP_MEASUREMENT_ID}`
  };
    
    export const firebaseApp = initializeApp(firebaseConfig)
    export const database = getDatabase(firebaseApp);
    export const databaseStore = getFirestore(firebaseApp);
    export const auth = getAuth();
    export const RTdatabase = getDatabase(firebaseApp);
    export const storage = getStorage(firebaseApp);
  