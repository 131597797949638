const initailState = []
const ALLDriverLocation=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_DRIVERLOC':
            return action.payload;
        default:
            return state;
    }
}

export default ALLDriverLocation