import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";

import { database } from "../../CustomComponents/firebase";
import { ref as retS, set, update } from "firebase/database";
import moment from "moment";
import { number } from "prop-types";

function OnGoingRides() {
  const [message, setmessage] = useState('Loading data...')
  const [isloding, setisloding] = useState(true);
  const [domdata, setdomdata] = useState([])
  const dd = useSelector(state => state.Ongoingridesdata)
  let roles = useSelector((state) => state.Userinfo.role);

  function updateRide(params) {
    console.log(params);
    var text = "like to cancel ride" + params.ARN
    if (window.confirm(text) == true) {
      update(retS(database, '/rider_queue/' + params.id), {
        cancelled_by: 'admin', isCancelled: true
      }).then(() => {
        alert("successfully");
      })
        .catch((e) => {
          alert(e);
          console.log(e);
        });
    }
  }
  function padTo2Digits(num) {
    return num.toFixed(1).toString();
  }

  function get_time_diff(datetime) {
    const endDate = new Date();
    const today = new Date(datetime);
    const dayss = parseInt((endDate - today) / (1000 * 60 * 60 * 24));
    const hours = parseInt(Math.abs(endDate - today) / (1000 * 60 * 60) % 24);
    const minutes = parseInt(Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60) % 60);
    const seconds = parseInt(Math.abs(endDate.getTime() - today.getTime()) / (1000) % 60);

    // var datetime = new Date(datetime).getTime();
    // var now = new Date().getTime();

    // if (isNaN(datetime)) {
    //   return "";
    // }

    // if (datetime < now) {
    //   var milisec_diff = now - datetime;
    // } else {
    //   var milisec_diff = datetime - now;
    // }

    // var days = padTo2Digits(milisec_diff / 1000 / 60 / (60 * 24));
    // var date_diff = new Date(milisec_diff);

    return dayss + " D - " + hours + " H - " + minutes + " M ";
  }
  useEffect(() => {
    if (dd.length === 0) {
      setTimeout(() => {
        setmessage('No Data To Display')
      }, 4000);
    }

    dd.map((dat) => {
      dat.isAccepted = dat.isAccepted ? 'true' : 'false'
      dat.isArrived = dat.isArrived ? 'true' : 'false'
      dat.isCancelled = dat.isCancelled ? 'true' : 'false'
      dat.isCompleted = dat.isCompleted ? 'true' : 'false'
      dat.isGenerated = dat.isGenerated ? 'true' : 'false'
      dat.isPending = dat.isPending ? 'true' : 'false'
      dat.isVerified = dat.isVerified ? 'true' : 'false'
      var diferenc = get_time_diff(dat.booked_onsec)

      dat.booked_onhours = diferenc;


      // if (parseFloat(diferenc.slice(0, 3)) >= 0.5) {
      dat.action = (
        <div >
           {roles !== undefined && roles==='admin' ? <Button key={dat.id} onClick={() => updateRide(dat)}>Cancel Ride</Button>:'-'}
        </div>
      );
      // }
    });

    setdomdata(dd)
    setisloding(false)
  }, [dd])


  if (isloding) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox pt={2} pb={3}>
          <Card>
            <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{message}</SuiTypography>
          </Card>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={2} pb={3}>
        <Card >
          <DataTable table={domdata}
            hidecolumnsdata={[
              "duration_str",
              "pickup_str", 'driver_email'
            ]}
            functioncallcount={() => { }}
            LoadData={false}

            datacolumns={[
              {
                Header: "action",
                accessor: "action",
              },
              {
                Header: "Date  and  time",
                accessor: "booked_on",
              },
              {
                Header: " time diff",
                accessor: "booked_onhours",
              },
              {
                Header: "ARN",
                accessor: "ARN",
              },
             
              {
                Header: "rider name",
                accessor: "rider_fname",
              },
              {
                Header: "isAccepted",
                accessor: "isAccepted",
              },
              {
                Header: "isArrived",
                accessor: "isArrived",
              },
              {
                Header: "isCancelled",
                accessor: "isCancelled",
              },
              {
                Header: "isCompleted",
                accessor: "isCompleted",
              },
              {
                Header: "isGenerated",
                accessor: "isGenerated",
              },
              {
                Header: "isPending",
                accessor: "isPending",
              }, {
                Header: "isVerified",
                accessor: "isVerified",
              },
              {
                Header: "firebase id",
                accessor: "id",
              },
              {
                Header: "Driver name",
                accessor: "driver_name",
              },
              {
                Header: "Driver contact number",
                accessor: "driver_contactno",
              },
              {
                Header: "Driver email",
                accessor: "driver_email",
              },
              {
                Header: "registration number",
                accessor: "registration_number",
              },



            ]}
            canSearch />
        </Card>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OnGoingRides;
