import React, { useState } from "react";
import SuiButton from "components/SuiButton";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useAuth } from "../../../CustomComponents/Authcontext";

import SuiInput from "components/SuiInput";
import { useSelector, useDispatch } from "react-redux";

// Data
import Modal from '@mui/material/Modal';
import Grid from "@mui/material/Grid";
import defaultprofile from "../../../assets/img/defaultprofile.png";
export default function BlacklistUser({ data, setcolor, setmessage, setstate, title, type }) {
    const [blacklistselectdata, setblacklistselectdata] = useState(data)
    const [reason, setreason] = useState(null)
    const Permissions = useSelector((state) => state.Permisioncopy.riders);
    const { currentUser } = useAuth();

    //model
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    function handelblacklist(params) {
        if (Permissions.includes('U')) {
            setblacklistselectdata(params)
            handleOpen();
        } else {
            setcolor('error')
            setmessage('Access Denied (User does not have permission)')
        }
    }
    function handelblacklistSubmit(event) {
        // event.preventDefault();
        if (reason !== null) {
            if (blacklistselectdata === null) {
                setcolor('error')
                setmessage('Please Select blacklist User')
            } else if (currentUser.accessToken !== undefined) {
                document.getElementById("submit").setAttribute("disabled", "disabled");
                var myHeaders = new Headers();
                myHeaders.append(
                    "Authorization",
                    "Bearer " + currentUser.accessToken
                );
                myHeaders.append("Content-Type", "application/json");
                var raw = JSON.stringify({
                    "rider_id": blacklistselectdata.id,
                    "blacklisted": type === 'blacklist' ? true : false,
                    "reason": reason
                });
                var requestOptions = {
                    method: "PUT",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow",
                };
                fetch(
                    process.env.REACT_APP_BASE_URL + "/blacklist_riders",
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.status === true) {
                            setcolor('success')
                            setmessage('User Blacklisted');
                            setblacklistselectdata(null);
                            handleClose();
                        } else {
                            setcolor('error')
                            setmessage(result.message);
                            document.getElementById("submit").removeAttribute("disabled");
                        }
                    })
                    .catch((error) => console.log("error", error));
            } else {

                alert("AccessToken error");
            }
        } else {
            setcolor('error')
            setmessage('Reason must not be empty');
        }
    }
    return (
        <div>
            <SuiButton
                onClick={() => {
                    handelblacklist(data)
                }}
                color="error"
            >
                {title}
            </SuiButton>
            {/*model*/}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition

            >
                <SuiBox sx={
                    {
                        border: 0,
                        borderRadius: '10px',
                        boxShadow: '0 0 15px rgb(0 0 0 / 15%), 0 0 1px 1px rgb(0 0 0 / 10%)',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50vw',
                        pointerEvents: 'auto',
                        backgroundColor: '#fff',
                        backgroundClip: 'padding-box',
                        outline: 0,
                        padding: '20px 50px'
                    }
                } >
                    <SuiTypography variant='h4' sx={{ textAlign: 'center', mb: 1, p: 1 }}>
                        User Details
                    </SuiTypography>
                    <SuiBox sx={{ alignContent: 'center', textAlign: 'center' }}>
                        <img style={{ height: '9rem', borderRadius: '50%' }} src={blacklistselectdata && (blacklistselectdata.profile_pic !== 'null' && blacklistselectdata.profile_pic !== undefined && blacklistselectdata.profile_pic !== '' && blacklistselectdata.profile_pic !== null) ? blacklistselectdata.profile_pic : defaultprofile} alt="profile image" />
                    </SuiBox>
                    <SuiTypography sx={{ mb: 2, textAlign: 'center' }}>
                        Name  :  {blacklistselectdata && blacklistselectdata.full_name}
                    </SuiTypography>
                    <SuiTypography sx={{ mb: 2, textAlign: 'center' }}>
                        Email  :  {blacklistselectdata && blacklistselectdata.email_id}
                    </SuiTypography>
                    <SuiTypography sx={{ mb: 2, textAlign: 'center' }}>
                        DOB  :  {blacklistselectdata && blacklistselectdata.dob}
                    </SuiTypography>
                    <SuiTypography sx={{ fontSize: '0.9rem', color: 'red', textAlign: 'center' }}>
                        * Note:if the user is suspended the user will no longer can make a booking.
                    </SuiTypography>
                    <SuiBox sx={{ alignContent: 'center', textAlign: 'center', width: '70%', mx: 'auto' }}>
                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Reason For Blacklisting the user</SuiTypography >
                        <SuiInput required
                            value={reason}
                            placeholder="Enter the reason"
                            multiline
                            rows={2}
                            onChange={(e) => {
                                setreason(e.target.value);
                            }}
                            type="textarea"
                        />
                    </SuiBox>
                    <Grid container spacing={3} sx={{ alignContent: 'center', textAlign: 'center', mt: 1 }} >
                        <Grid item md={6}>
                            <SuiTypography sx={{ fontSize: '0.9rem', }}  > </SuiTypography >
                            <SuiButton color="info" id="submit" type="submit"
                                onClick={() => handelblacklistSubmit()}
                            >
                                Submit
                            </SuiButton >
                        </Grid >
                        <Grid item md={6}>
                            <SuiTypography sx={{ fontSize: '0.9rem', }}  > </SuiTypography >
                            <SuiButton color="info"
                                className="cancelButton"
                                onClick={() => handleClose()}
                            >
                                Close

                            </SuiButton >

                        </Grid >
                    </Grid>
                </SuiBox>
            </Modal>
        </div>
    )
}
