import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";

import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";

import { addDriversData } from "../../CustomComponents/dataStore/actions";

import LinearProgress from '@mui/material/LinearProgress';
import { useLocation, useHistory, Link, useRouteMatch } from "react-router-dom";
import { useAuth } from "CustomComponents/Authcontext";
import { Typography } from "@mui/material";
import SuiInput from "components/SuiInput";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'
function Alldrivers() {
  const { currentUser } = useAuth();

  const dd = useSelector((state) => state.All_DriversStore);
  const Permision = useSelector((state) => state.Permisioncopy);

  const Userinfo = useSelector((state) => state.Userinfo);

  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState(dd.nextKey || "");
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
  let { path, url } = useRouteMatch();
  let history = useHistory();
  useEffect(() => {
    if (dd.arr.length === 0) {
      calldataall_driver('', 200);
    }
  }, [0]);
  async function calldatasetApiRepeat(e) {
    if (e >= 10) {
      var da = "";
      if (dd.nextKey !== "") {
        da = dd.nextKey;
      } else {
        da = nextkey;
      }
      if (!nexttoggle) alert("No Data To Load");

      if (da !== null && nexttoggle) {
        da = await calldataall_driver(da, e);
      }
    }

  }
 
  const fileJson = async (JSONData, ReportTitle, ShowLabel) => {
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
    var CSV = "";
    var datasest = [];
    var datasest2 = [];

    arrData.map((data) => {
      var temp;
      datasest.push({
        id: data.id,
        contactno: data.contactno || ' ',
        driver_Fname: data.fname ,
        driver_Lname: data.lname,
        emailid: data.emailid || ' ',
        max_due:data.max_due,
        gender: data.gender,
        joindate: data.joindate || ' ',
        model: data.model || ' ',
        color: data.vehicle_color || ' ',
        registration_number: data.registration_number || ' ',
        brand: data.brand || ' ',
        city: data.city || ' ',
        linked_to_vehicle: data.linked_to_vehicle || ' ',
        linked_to_dro: data.linked_to_dro || ' ',
        dro_name: data.dro_name || ' ',
        linked_to_mobile: data.linked_to_mobile || ' ',
        IMEI: data.IMEI || ' ',
        mobile_build: data.mobile_build,
        brand_name: data.mobile_brand,
        company: data.company,
        sim_company: data.sim_company,
        sim_number: data.sim_number || ' ',
        linked_to_dro: data.linked_to_dro,
        dro_name: data.dro_name,
        DRO: data.DRO
      })

    });

    const items = datasest;
    const replacer = (key, value) => (value === null ? " " : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    CSV = [
      header.map(d => d.replace('_', ' ')).join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\r\n");
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    var fileName = "Report_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  async function calldataall_driver(nexttokenparam, limit = 100) {
    setnexttoggle(false);
    setspinnershow(true);
    if (nexttokenparam === undefined) {
      nexttokenparam = dd.nextKey || nextkey;
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + currentUser.accessToken
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return await fetch(
      process.env.REACT_APP_BASE_URL + "/all_drivers?start=" +
      nexttokenparam + '&limit=' + limit,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          setnextkey(result.start);
          //
          if (Permision && Permision.drivers.includes('U')) {
            result.data.map((dat) => {
              dat.action = (
                <div >
                  {/* use this button to add a edit kind of action */}
                  <SuiButton
                    onClick={() => {
                      history.push({
                        pathname: '/' + url.split('/')[1] + '/editDriver',
                        customCRCData: dat,
                      })
                    }}
                    color="warning"
                  >
                    edit
                  </SuiButton>{" "}

                </div>
              );
              if (dat.joindate) {
                dat.joindate = new Date(dat.joindate * 1000).toLocaleString("en-US", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",

                });
              }
              if (dat.dob) {
                dat.dob = new Date(dat.dob * 1000).toLocaleString("en-US", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",

                });
              }
              if (!dat.linked_to_vehicle === true) {

                dat.vehicleditelink = (<>
                  <div  >
                    <SuiInput
                      placeholder="vehicle Firebase id"
                      type="text"
                      id={"i" + dat.id}
                      onChange={(e) => {
                        document.getElementById(
                          "i" + dat.id
                        ).innerHTML = e.target.value;
                      }}
                    />
                  </div></>
                )
              } else {
                dat.vehicleditelink = <>--</>

              }

              if (Userinfo.isadmin) {
                dat.unlinkaction = (
                  <SuiButton variant="gradient"
                    color="info" sx={{ width: '100%' }} onClick={() => {
                      if (dat.linked_to_vehicle === true) {
                        var text = `unlink ${dat.emailid} from ${dat.registration_number}`
                        if (window.confirm(text) == true) {
                          var myHeaders = new Headers();
                          myHeaders.append("Authorization", "Bearer " + currentUser.accessToken);
                          myHeaders.append("Content-Type", "application/json");
                          var raw = JSON.stringify({
                            "type": "vehicle_driver",
                            "unlink": [
                              {
                                "vehicle_id": dat.vehicleUID,
                                "driver_id": dat.id
                              }
                            ],
                            "reason": "Admin unlinked the vehicle"
                          });
                          console.log(raw);
                          var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                          };

                          fetch(process.env.REACT_APP_BASE_URL + "/unlink", requestOptions)
                            .then(response => response.text())
                            .then(result => { alert(result) })
                            .catch(error => console.log('error', error));
                        }
                      } else {
                        var vid = document.getElementById("i" + dat.id).value;
                        var text = `link ${dat.emailid} to ${vid}`
                        if (vid !== undefined && vid !== null && vid !== '') {
                          if (window.confirm(text) == true) {
                            var myHeaders = new Headers();
                            myHeaders.append("Authorization", "Bearer " + currentUser.accessToken);
                            myHeaders.append("Content-Type", "application/json");
                            var raw = JSON.stringify({
                              driver: dat.id,
                              vehicle: vid,
                            });

                            console.log(raw);
                            var requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: raw,
                              redirect: 'follow'
                            };

                            fetch(process.env.REACT_APP_BASE_URL + "/link_driver_vehicle", requestOptions)
                              .then(response => response.text())
                              .then(result => { alert(result) })
                              .catch(error => console.log('error', error));
                          }
                        } else {
                          alert('fill the form')
                        }
                      }
                    }}>{dat.linked_to_vehicle === true ? 'unlink vehicle' : 'Link vehicle'}</SuiButton>);
              } else {
                dat.unlinkaction = (
                  <div >
                    {/* use this button to add a edit kind of action */}
                    <Typography variant="h6">Access Denied</Typography>

                  </div>
                );
              }
            });
          } else {
            result.data.map((dat) => {
              if (dat.joindate) {
                dat.joindate = new Date(dat.joindate * 1000).toLocaleString("en-US", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",

                });
              }
              if (dat.dob) {
                dat.dob = new Date(dat.dob * 1000).toLocaleString("en-US", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",

                });
              }

              if (dat.joindate) {
                dat.joindate = new Date(dat.joindate * 1000).toLocaleString("en-US", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",

                });
              }
              if (dat.lasdrivalidity) {
                dat.lasdrivalidity = new Date(dat.lasdrivalidity * 1000).toLocaleString("en-US", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",

                });
              }
              if (dat.licensevalidity) {
                dat.licensevalidity = new Date(dat.licensevalidity * 1000).toLocaleString("en-US", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",

                });
              }
              if (dat.dob) {
                dat.dob = new Date(dat.dob * 1000).toLocaleString("en-US", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",

                });
              }

            });
          }

          dispatch(addDriversData(result.data, result.start, result.count || result.data.length))
          setnexttoggle(true);
          setspinnershow(false);
          return result.start;
        } else {
          alert(result.message)
          setnexttoggle(false);
          setspinnershow(false);
          return null;
        }
      })
      .catch((error) => {
        setnexttoggle(false);
        setspinnershow(false);

        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
        return null;

      });

  }
  if (dd.arr.length === 0) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox pt={2} pb={3}>
          <Card>
            <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
          </Card>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={0} pb={3}>
        <Card >
          {sipnnershow && (
            <SuiBox sx={{ width: '100%', m: 0, p: 0 }}>
              <LinearProgress color="success" sx={{ width: '100%' }} />
            </SuiBox>
          )}
          <SuiButton sx={{ float: 'right' }} variant="gradient" onClick={() => {

            fileJson(dd.arr, `Collection_vehicle`);
          }} color="info"  >
            <FontAwesomeIcon icon={faDownload} />
          </SuiButton>
          <DataTable table={dd.arr}
            hidecolumnsdata={[
              "insuranceno",
              "insurancevalidity",
              "licenseno",
              "licensevalidity",
              "badgeno",
              "badgevalidity",
              "lasdricard",
              "lasdrivalidity", "max_due", "vendor", "commission", "joindate", "driver_status", "joindate",
              "dob",
              "licenseno",
              "licensevalidity",
              "commission",
              "max_due",
            ]}
            functioncallcount={calldatasetApiRepeat}
            datacolumns={[


              {
                Header: "sim_number",
                accessor: "sim_number",
              },
              {
                Header: "IMEI",
                accessor: "IMEI",
              },

              {
                Header: "registration_number",
                accessor: "registration_number",
              },
              {
                Header: "first name",
                accessor: "fname",
              },
              {
                Header: "lname",
                accessor: "lname",
              },
              {
                Header: "email_id",
                accessor: "emailid",
                maxWidth: "100",
                minWidth: 90,
                width: "100px",
                tooltip: true

              },
              {
                Header: "driver id",
                accessor: "id",
              },


              {
                Header: "contact no",
                accessor: "contactno",
              },

              {
                Header: "vehicle firebase id",
                accessor: "vehicleditelink",
              },

              {
                Header: "Edit Driver",
                accessor: "action",
              },
              {
                Header: "unlink vehicle",
                accessor: "unlinkaction",
              },

            ]}

            canSearch />
        </Card>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Alldrivers;
