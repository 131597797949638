const initailState = {
    arr:[],
    nextKey:''
}
const Ratecarddata = (state = initailState, action) => {
    switch (action.type) {
        case 'ADD_RATE_CARD':
            return {arr:action.payload,asasa:action.cities}
        default:
            return state;
    }
}

export default Ratecarddata