import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { searchDROdata, adddunlinkedvehicledroData } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { useAuth } from "CustomComponents/Authcontext";
import CustomizedSnackbars from "../Alert/Alert";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

function AddDrivertoVehicle() {
  const isloading = useSelector((state) => state.Isload);
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [errormessage, seterrormessage] = useState(null);
  const [dataselected, setdataselected] = useState([]);
  const [dataselected2, setdataselected2] = useState([]);

  const [sipnnershow, setsipnnershow] = useState(false);
  const { currentUser } = useAuth();
  const classes = useStyles();

  const dd = useSelector((state) => state.DROsearch);
  const UnlinkedVehicle = useSelector((state) => state.Unlinkedvehicledro);

  const [nextkey, setnextkey] = useState(UnlinkedVehicle.nextKey || '');

  //UnlinkedVehicle
  const dispatch = useDispatch();
  const [search_value, setsearch_value] = useState(null);
  const [selecterdro, setselecterdro] = useState(null);
  //snack bar
  const [snakopen, setsnakopen] = useState(false)
  const [snackmessage, setsnackmessage] = useState(null)
  const [snackcolor, setsnackcolor] = useState('error');
  const [nexttoggle, setnexttoggle] = useState(true);

  const handleSnackbar = (messages) => {
    setsnakopen(true)
    setsnackmessage(messages)
  };
  //
  function handleSubmit(event) {
    event.preventDefault();
    if (dataselected.length !== 0 && selecterdro !== null) {
      setsipnnershow(true)
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + currentUser.accessToken
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        dro: selecterdro,
        vehicles: dataselected,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.REACT_APP_BASE_URL + "/link_driver_vehicle?type=dro", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === false) {
            alert(result.message);
            setsipnnershow(false)
          } else {
            setsnackcolor('success')
            handleSnackbar("DRO Has Been Linked To The Vehicle");
            setsipnnershow(false)
            setTimeout(() => {
              window.location.reload()
            }, 1000);

          }
        })
        .catch((error) => {
          handleSnackbar(" " + error);

          console.log("error", error); setsipnnershow(false)
        });
    } else {
      if (dataselected.length === 0) {
        handleSnackbar("Please select the Vehicles ");

      }
      if (selecterdro === null) {
        handleSnackbar("Please select the DRO");

      }
      setsipnnershow(false)

    }
  }

  useEffect(() => {
    if (UnlinkedVehicle.arr.length === 0) {
      callsimRepeat(100);
    }
  }, [0]);
  async function callsimRepeat(e) {
    if (e >= 10) {
      var da = "";
      if (UnlinkedVehicle.nextKey !== "") {
        da = UnlinkedVehicle.nextKey;
      } else {
        da = nextkey;
      }
      if (da !== null && nexttoggle) {
        da = await callunlinked(da, e);
      }
    }

  }
  function callunlinked(key, e) {
    setsipnnershow(true)
    var nextkey = UnlinkedVehicle.nextKey || "";
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + currentUser.accessToken
    );
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_BASE_URL + "/all_vehicles?type=unlinked_dro&start=" +
      nextkey + '&limit=' + e,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === false) {
          setsipnnershow(false)
          setnexttoggle(false)

          console.log(result);
          alert("Error : " + result.message);
        } else {
          var arrayvalue = []

          result.data.map((data) => {
            data.action = <div >
              <Checkbox
                key={data.id}
                color="primary"
                onChange={(e) => checkdata(e.target.checked, data.id, data.registration_number)}
              />
            </div>

            arrayvalue.push(data)
          });
          dispatch(adddunlinkedvehicledroData(arrayvalue, result.start));
          setsipnnershow(false)

        }
      })
      .catch((error) => {
        console.log("error :", error);
        setsipnnershow(false);
      });
  }
  //check box function
  const checkdata = (e, data, rnum) => {
    if (e) {
      if (!dataselected.filter(function (e) { return e === data; }).length > 0) {
        /* vendors contains the element we're looking for */
        dataselected.push(data)
        setdataselected(dataselected)

      }
      if (!dataselected2.filter(function (e) { return e.key === data; }).length > 0) {
        /* vendors contains the element we're looking for */
        dataselected2.push({ key: data, label: rnum })
        setdataselected2(dataselected2)
      }
    } else {
      for (var i = dataselected.length - 1; i >= 0; --i) {
        if (dataselected[i] === data) {
          dataselected.splice(i, 1);
          setdataselected(dataselected)
        }
      }
      for (var i = dataselected2.length - 1; i >= 0; --i) {
        if (dataselected2[i].key === data) {
          dataselected2.splice(i, 1);
          setdataselected2(dataselected2)
        }
      }
    }

  }

  async function handleSearchSubmit(event) {
    event.preventDefault();
    if (search_value !== null) {
      setsipnnershow(true)

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + currentUser.accessToken
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = "";
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_BASE_URL + "/search_users?search=" +
        search_value +
        "&user=mappable&type=linking",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === false) {
            setsipnnershow(false)

            seterrormessage(result.message);
            setTimeout(() => {
              seterrormessage(null);
            }, 2000);
          } else {
            setsipnnershow(false)
            dispatch(searchDROdata(result.data));
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      handleSnackbar('Please fill the search field')
    }

  }

  const vehiclecol = [
    {
      Header: "Action",
      accessor: "action",
    },
    {
      Header: "registration number",
      accessor: "registration_number",
    },

    {
      Header: "model",
      accessor: "model",
      canFilter: false,
    },
    {
      Header: "brand",
      accessor: "brand",
      canFilter: false,
    },
    {
      Header: "city",
      accessor: "city",
    },
  ];

  function handeldelete(params) {
    for (var i = dataselected.length - 1; i >= 0; --i) {
      if (dataselected[i] === params) {
        dataselected.splice(i, 1);
        setdataselected(dataselected)
      }
    }
    for (var i = dataselected2.length - 1; i >= 0; --i) {
      if (dataselected2[i].key === params) {
        dataselected2.splice(i, 1);
        setdataselected2(dataselected2)
      }
    }

  }

 function Chiplayer({dataselected2,handeldelete1}) {
const [dataset, setdataset] = useState(dataselected2)

const [stste, setstste] = useState(false)
function deletecgip(data) {
  for (var i = dataset.length - 1; i >= 0; --i) {
    if (dataset[i].key === data) {
      dataset.splice(i, 1);
      setdataset(dataset)
    }
  }

}
  return (
    <div className={classes.root}>
    {
      dataset.map((d) => (
        <ChipCustom  deletecgip={deletecgip} handeldelete1={handeldelete1} variant="outlined" d={d} />
      ))
    }

  </div>  )
}
function ChipCustom({d,deletecgip,handeldelete1}) {
  const [state, setstate] = useState(false)
  return (  <Chip item sx={{ width: '50%' }} disabled={state} label={d.label} variant="outlined" onDelete={() => { 
    deletecgip(d.key)
    handeldelete1(d.key)
    setstate(true)
  }} />
  )
}
  if (UnlinkedVehicle.arr.length === 0 && isloading) {

    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox pt={2} pb={3}>
          <Card>
            <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
          </Card>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={2} pb={3}>
        <Card sx={{ m: 1, p: 2 }}>

          <SuiTypography
            variant="h6"
          >Vehicle to DRO </SuiTypography>

          <Grid container spacing={2}>
            <Grid item md={12} sm={12} lg={12} xl={12}>
              <Card>
                {sipnnershow && (
                  <Box sx={{ width: '100%', m: 0, p: 0 }}>
                    <LinearProgress color="success" sx={{ width: '100%' }} />
                  </Box>
                )}
                <Card sx={{ p: 4 }}>
                  <h5 style={{ display: "inline-block" }}>DRO Search{" "}</h5>
                  <form onSubmit={handleSearchSubmit} >
                    <SuiTypography
                      variant="h6"
                    >
                      Please Enter a Email ID
                    </SuiTypography>
                    <SuiInput
                      sx={{ mb: 2, position: 'relative', ml: 'auto', mr: 'auto' }}

                      value={search_value}
                      name="vSearch"
                      placeholder="Email Id"
                      onChange={(e) => setsearch_value(e.target.value)}
                      inputProps={{ type: "text" }}
                    />

                    <SuiButton
                      type="submit"
                      id="submits2"
                      variant="gradient" color="info"  >
                      Search DRO
                    </SuiButton>
                    <div>
                      {errormessage != null ? (
                        <p style={{ color: "red", fontSize: '1rem' }}>{errormessage}</p>
                      ) : null}
                    </div>
                  </form>

                </Card>
              </Card>
            </Grid>
            <Grid item md={12} sm={12} lg={12} xl={12}>
              <Card>
                <Card sx={{ p: 4 }}>
                  <div >
                    <h5 style={{ display: "inline-block", position: 'relative' }}>
                      Unlinked Vehicle List
                    </h5>
                    <DataTable table={UnlinkedVehicle.arr}
                      hidecolumnsdata={[]}
                      functioncallcount={callunlinked}
                      datacolumns={vehiclecol}
                      canSearch />
                  </div>
                </Card>
              </Card>
            </Grid>


          </Grid>
        </Card>
        <Card sx={{ m: 1, p: 3 }}>
          <SuiTypography variant="h6">Search Results</SuiTypography>
          <form onSubmit={handleSubmit} >

            <Grid container spacing={2} >

              <Grid item md={12} sm={12} lg={5} xl={5}>
                {" "}
                {dd.arr.length !== 0
                  ? dd.arr.map((data) => {
                    return (
                      <Card
                        style={{ backgroundColor: "#EFEFEF", textAlign: "justify" }}
                        id={data.id}
                        onClick={() => {
                          setselecterdro(data.id);
                          document.getElementById(
                            data.id
                          ).style.backgroundColor = "#EFEF";
                        }}
                      >
                        <Grid container sx={{ p: 3 }}>
                          <Grid item sm={12}>
                            <span>
                              <h5></h5>DRO Name :{" "}
                              {data.name}
                            </span>
                          </Grid>
                          <Grid item sm={12}>
                            <span>
                              <h5></h5>Country :{" "}
                              {data.countries}
                            </span>
                          </Grid>
                          <Grid item sm={12}>
                            <span>
                              <h5></h5>City :{" "}
                              {data.cities}
                            </span>
                          </Grid>
                          <Grid item sm={12}>
                            <span>
                              <h5></h5>Role :{" "}
                              {data.role}
                            </span>
                          </Grid>
                          <Grid item sm={12}>
                            <SuiTypography variant="button">
                              <span>
                                <h5></h5>Email ID: {data.email}
                              </span> </SuiTypography>
                          </Grid>

                        </Grid>{" "}
                      </Card>
                    )
                  }
                  ) : null
                }

              </Grid>
              {dataselected.length !== 0 ? <Grid item md={6} sm={6} lg={5} xl={5}>
                <Card sx={{ m: 1, p: 3 }}>
                <Chiplayer dataselected2={dataselected2} handeldelete1={handeldelete}/>
                </Card>
              </Grid> : ''}
              <Grid item md={12}>
                <SuiButton disabled={sipnnershow} id="submit" type="submit" >
                  Submit
                </SuiButton>
              </Grid>{" "}
            </Grid>
          </form>
          <CustomizedSnackbars message={snackmessage} open={snakopen} color={snackcolor} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

        </Card>
      </SuiBox>
      <Footer />
    </DashboardLayout >
  );
}

export default AddDrivertoVehicle;
