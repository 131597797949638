import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { adddriver_statsData } from "../../CustomComponents/dataStore/actions";
import Grid from "@mui/material/Grid";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import ThinBarChart from "../Components/ThinBarChart";
import DefaultCounterCard from "../Components/DefaultCounterCard";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAuth } from "CustomComponents/Authcontext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'
import SuiButton from "components/SuiButton";

function DriverAnalytics() {
    const [colorCode, setcolorCode] = useState('success')
    const dd = useSelector((state) => state.Driveranalytics);
    const { currentUser } = useAuth();

    dd.arr.map((data) => {
        data.action = (
            <div className="">
                {/* use this button to remove the data row */}
                <Button
                    onClick={() => {
                        toggle(data);
                    }}
                    color={colorCode}
                    size="lg"
                >
                    <VisibilityIcon />
                </Button>{" "}
            </div>
        );
    });
    const dispatch = useDispatch();
    const [nextkey, setnextKey] = useState("");
    const [messagttouser, setmessagttouser] = useState("Loading Data...");
    const [alertdata, setalertdata] = useState(null);
    //chart
    const [isDisabled, setisDisabled] = useState(true);
    const [nexttoggle, setnexttoggle] = useState(true);
    const [sipnnershow, setspinnershow] = useState(false);

    const [cahrtdata, setcahrtdata] = useState();
    const [modal, setModal] = useState(false);
    const [modaldata, setmodaldata] = useState("");
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const toggle = (e) => {
        setmodaldata(e);
        setOpen(!open)
    };
    useEffect(() => {
        if (dd.arr.length === 0) {
            calldataall_driversAnalytics('', 100);
        }
    }, [0]);
    async function calldriverRepeat(e) {
        if (e >= 10) {
            var da = "";
            if (dd.nextKey !== "") {
                da = dd.nextKey;
            } else {
                da = nextkey;
            }
            if (!nexttoggle) alert("No Data To Load");
            if (da !== null && nexttoggle) {
                da = await calldataall_driversAnalytics(da, e);
            }
        }

    }
    const fileJson = (JSONData, ReportTitle, ShowLabel) => {
        var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
        var CSV = "";
        var datasest = [];
        datasest = arrData.map((data) => {
            if (data.last_paid_on) {
                data.last_paid_on = new Date(data.last_paid_on).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }

            var temp = {
                "Driver name": data.name,
                "Vehicle registration_number": data.registration_number,
                "contact_number": data.contact_number,
                " Total Rides": data.total,
                "Rides Accepting failed": data.accepting_failed,
                "Rides Accepted successfully": data.accepted_successfully,
                "Rides Cancelled": data.cancelled,
                "Rides Ignored": data.ignored,
                "Rides Rejected": data.rejected,
            };
            return temp;
        });


        const items = datasest;
        const replacer = (key, value) => (value === null ? " " : value); // specify how you want to handle null values here
        const header = Object.keys(items[0]);
        CSV = [ShowLabel + ",",
        header.join(","), // header row first
        ...items.map((row) =>
            header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(",")
        ),
        ].join("\r\n");
        return CSV
    };
    function CSVDownload(params, name) {
        var CSV = params;
        if (CSV == "") {
            alert("Invalid data");
            return;
        }
        //Generate a file name
        var fileName = "Report_";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += name.replace(/ /g, "_");

        //Initialize file format you want csv or xls
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // setisDisabled(false);
    }
    function calldataall_driversAnalytics(nextaccessTokenparam, limit = 100) {
        setspinnershow(true);
        if (nextaccessTokenparam === undefined) {
            nextaccessTokenparam = nextkey;
        }
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BASE_URL + "/driver_stats?start=" +
            nextaccessTokenparam + '&limit=' + limit,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === true) {
                    setnextKey(result.last);
                    if (result.total !== undefined) {
                        setcahrtdata([result.total, result.accepting_failed, result.accepted_successfully, result.cancelled, result.rejected, result.ignored]);
                    }
                    var data = {
                        total: result.total,
                        accepting_failed: result.accepting_failed,
                        accepted_successfully: result.accepted_successfully,
                        cancelled: result.cancelled,
                        rejected: result.rejected,
                        ignored: result.ignored,

                    };

                    result.driver_details.map((item) => {
                        var dataget = [];
                        item.login.map((data) => {
                            var l = 0
                            var onlinelength = data.online !== undefined ? data.online.length : 0;
                            var offlength = data.offline !== undefined ? data.offline.length : 0;
                            if (onlinelength > offlength) l = onlinelength; else l = offlength;
                            for (let index = 0; index < l; index++) {

                                dataget.push({
                                    dateon: new Date(data.date * 1000).toISOString().split("T")[0],
                                    onlinedatae: data.online !== undefined ? data.online[index] !== null || undefined
                                        ? new Date(data.online[index] * 1000).toLocaleString(
                                            "en-US",
                                            {
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                            }
                                        )
                                        : "-" : "-",
                                    offlinedate:
                                        data.offline !== undefined ? data.online[index] !== null || undefined
                                            ? new Date(data.offline[index] * 1000).toLocaleString(
                                                "en-US",
                                                {
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                    hour12: true,
                                                }
                                            )
                                            : "-" : "-",
                                });
                            }
                        });
                        item.login = dataget;
                    });
                    dispatch(adddriver_statsData(result.driver_details, result.last, data));
                    setspinnershow(false);

                } else {
                    setnexttoggle(false);
                    setspinnershow(false);

                    alert(result.message);
                    document.getElementById("submit").removeAttribute("disabled");
                }
            })
            .catch((error) => {
                setmessagttouser("Unable to get data....ERROR");
                setalertdata("No More Data To Fetch...");
                setTimeout(() => {
                    setalertdata("");
                }, 3000);
                console.log("error", error);
            });
    }


    if (dd.arr.length === 0) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox mb={3}>

                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={1}>

                            <Grid item xs={12} md={4}>
                                <DefaultCounterCard
                                    count={dd.totalcount.total}
                                    title="BOOKINGS RECEIVED"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DefaultCounterCard
                                    count={dd.totalcount.accepting_failed}
                                    title="BOOKINGS ACCEPTING FAILED"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DefaultCounterCard
                                    count={dd.totalcount.accepted_successfully}
                                    title="BOOKINGS CONFIRMED"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DefaultCounterCard
                                    count={dd.totalcount.cancelled}
                                    title="BOOKINGS CANCELLED "
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DefaultCounterCard
                                    count={dd.totalcount.rejected}
                                    title="BOOKINGS REJECTED "
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DefaultCounterCard
                                    count={dd.totalcount.ignored}
                                    title="BOOKINGS NO ACTION  "
                                />
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} md={6}>

                        <ThinBarChart
                            height={'300px'}
                            title="Statistics"
                            color={colorCode}
                            chart={{
                                labels: [
                                    "Received",
                                    "Accepting Failed",
                                    "Confirmed",
                                    "Cancelled",
                                    "Rejected",
                                    "No Action",
                                ],
                                datasets: {
                                    label: "",
                                    data: cahrtdata,
                                },
                            }} />

                    </Grid>
                </Grid>
                <Card sx={{mt:2,m:1,p:2}}>

                    <SuiBox sx={{ mt: 2 }}>
                        <SuiBox sx={{ mr: 2, mt: 1 }}>
                            <SuiButton sx={{ float: 'right' }} variant="gradient" onClick={() => {
                                var data = fileJson(dd.arr, 'CollectionDriver');
                                CSVDownload(data, 'DriverAnalytics')
                            }} color="info"  >
                                <FontAwesomeIcon icon={faDownload} />
                            </SuiButton>
                        </SuiBox>
                        <DataTable table={dd.arr}
                            functioncallcount={calldriverRepeat}
                            hidecolumnsdata={[
                                "cancelled",
                                "candcelled",
                            ]}
                            datacolumns={[
                                {
                                    Header: "Driver name",
                                    accessor: "name",
                                },
                                {
                                    Header: "Driver contact No",
                                    accessor: "contact_number",
                                },
                                {
                                    Header: "Vehicle No",
                                    accessor: "registration_number",
                                },
                                {
                                    Header: "Bookings Accepted",
                                    accessor: "accepting_failed",
                                },
                                {
                                    Header: "Booking Confirmed",
                                    accessor: "accepted_successfully",
                                },
                                {
                                    Header: "Bookings No Action",
                                    accessor: "ignored",
                                },
                                {
                                    Header: "Bookings Rejected ",
                                    accessor: "rejected",
                                },
                                {
                                    Header: "Bookings Cancelled ",
                                    accessor: "cancelled",
                                },

                                // {
                                //     Header: "Total Revenue ",
                                //     accessor: "canceslleed",
                                // },
                                {
                                    Header: "Actions ",
                                    accessor: "action",
                                },
                            ]}

                            canSearch />
                    </SuiBox>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        sx={{ overflow: 'auto' }}
                    >
                        <Fade in={open}>
                            <Box sx={
                                {

                                    border: 0,
                                    borderRadius: '10px',
                                    boxShadow: '0 0 15px rgb(0 0 0 / 15%), 0 0 1px 1px rgb(0 0 0 / 10%)',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '60vw',
                                    pointerEvents: 'auto',
                                    backgroundColor: '#fff',
                                    backgroundClip: 'padding-box',
                                    outline: 0,
                                    padding: '20px 50px'
                                }
                            } >

                                <SuiTypography sx={{ textAlign: 'center' }}>
                                    Driver Details
                                </SuiTypography>
                                <hr />
                                <Grid container spacing={1} sx={{ p: 2, overflow: 'scroll', }}>
                                    <Grid item md={12} xl={6} style={{ borderLeft: "2px solid black" }}>
                                        <Grid container spacing={1}>
                                            <Grid item md={6}>
                                                <SuiTypography sx={{ fontSize: '0.9rem', m: 2 }}> Name :</SuiTypography >
                                            </Grid >
                                            <Grid item md={6}>
                                                <SuiTypography sx={{ fontSize: '0.9rem', m: 2 }}>{modaldata.name}</SuiTypography >
                                            </Grid >
                                            <Grid item md={6}>
                                                <SuiTypography sx={{ fontSize: '0.9rem', m: 2 }}> Driver contact No :</SuiTypography >
                                            </Grid >
                                            <Grid item md={6}>
                                                <SuiTypography sx={{ fontSize: '0.9rem', m: 2 }}>{modaldata.contact_number}</SuiTypography >
                                            </Grid >
                                            <Grid item md={6}>
                                                <SuiTypography sx={{ fontSize: '0.9rem', m: 2 }}> Vehicle No :</SuiTypography >
                                            </Grid >
                                            <Grid item md={6}>
                                                <SuiTypography sx={{ fontSize: '0.9rem', m: 2 }}>{modaldata.registration_number}</SuiTypography >
                                            </Grid >
                                            <Grid item md={6}>
                                                <SuiTypography sx={{ fontSize: '0.9rem', m: 2 }}> Bookings Accepted :</SuiTypography >
                                            </Grid >
                                            <Grid item md={6}>
                                                <SuiTypography sx={{ fontSize: '0.9rem', m: 2 }}>{modaldata.accepting_failed}</SuiTypography >
                                            </Grid >
                                            <Grid item md={6}>
                                                <SuiTypography sx={{ fontSize: '0.9rem', m: 2 }}> Booking Confirmed :</SuiTypography >
                                            </Grid >
                                            <Grid item md={6}>
                                                <SuiTypography sx={{ fontSize: '0.9rem', m: 2 }}>{modaldata.accepted_successfully}</SuiTypography >
                                            </Grid >
                                            <Grid item md={6}>
                                                <SuiTypography sx={{ fontSize: '0.9rem', m: 2 }}> Bookings No Action :</SuiTypography >
                                            </Grid >
                                            <Grid item md={6}>
                                                <SuiTypography sx={{ fontSize: '0.9rem', m: 2 }}>{modaldata.ignored}</SuiTypography >
                                            </Grid >
                                            <Grid item md={6}>
                                                <SuiTypography sx={{ fontSize: '0.9rem', m: 2 }}> Bookings Rejected :</SuiTypography >
                                            </Grid >
                                            <Grid item md={6}>
                                                <SuiTypography sx={{ fontSize: '0.9rem', m: 2 }}>{modaldata.rejected}</SuiTypography >
                                            </Grid >
                                        </Grid >
                                    </Grid >
                                    <Grid item md={12} xl={6} style={{ borderLeft: "2px solid black" }}>
                                        <Grid container spacing={1} className="w-100">
                                            <Grid item md={6}>
                                                <SuiTypography sx={{ fontSize: '0.9rem', m: 2 }}> Bookings Cancelled :</SuiTypography >
                                            </Grid >
                                            <Grid item md={6}>
                                                <SuiTypography sx={{ fontSize: '0.9rem', m: 2 }}>{modaldata.cancelled}</SuiTypography >
                                            </Grid >
                                            <Grid item
                                                md={12}
                                                style={{ overflowY: "auto", height: "200px" }}
                                            >
                                                {modaldata.login !== undefined ? (
                                                    <Tablelogin_logout datalogin={modaldata.login} />
                                                ) : null}
                                            </Grid >
                                        </Grid >
                                    </Grid >
                                </Grid >

                            </Box>
                        </Fade>
                    </Modal>
                </Card>
            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

function Tablelogin_logout({ datalogin }) {
    return (
        <table style={{ width: '100%', backgroundColor: '#f8f9fa' }}>
            <thead>
                <tr style={{ textAlign: 'left' }}>
                    <th><SuiTypography sx={{ fontSize: '0.9rem', p: 1, textAlign: 'center' }}> Date</SuiTypography ></th>
                    <th><SuiTypography sx={{ fontSize: '0.9rem', p: 1 }}> Login Time</SuiTypography ></th>
                    <th><SuiTypography sx={{ fontSize: '0.9rem', p: 1 }}> Logout Time</SuiTypography ></th>
                </tr>
            </thead>
            <tbody>
                {datalogin.length !== 0
                    ? datalogin.map((data, key) => (
                        <tr key={key}>
                            <th scope="row"><SuiTypography sx={{ fontSize: '0.9rem', m: 1 }}> {data.dateon}</SuiTypography > </th>
                            <td><SuiTypography sx={{ fontSize: '0.9rem', m: 1 }}> {data.onlinedatae}</SuiTypography ></td>
                            <td><SuiTypography sx={{ fontSize: '0.9rem', m: 1 }}> {data.offlinedate}</SuiTypography ></td>
                        </tr>
                    ))
                    : null}
            </tbody>
        </table>
    );
}

export default DriverAnalytics;
