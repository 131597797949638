import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';

import {
    addCollectionRides,
    addCollectionRidesSearch,
    CLEANCollectionRidesSearch
} from "CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from "@mui/material/Divider";
import LinearProgress from '@mui/material/LinearProgress';
import { useAuth } from "CustomComponents/Authcontext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'
import CustomizedSnackbars from "../../Alert/Alert";

function CollectionsRides() {
    const dd = useSelector((state) => state.AccountsCollectionRides);
    const userinfo = useSelector((state) => state.Userinfo);
    const [selectCountry, setselectCountry] = useState(null);
    const dispatch = useDispatch();
    const [nextkey, setnextkey] = useState(0);
    const [messagttouser, setmessagttouser] = useState("Loading Data...");
    const [nexttoggle, setnexttoggle] = useState(true);
    const [sipnnershow, setspinnershow] = useState(false);
    //data date
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);
    const [isDisabled, setisDisabled] = useState(false);
    const [setclear, setsetclear] = useState(true);
    //dataexchange
    const [arrayData, setarrayData] = useState(dd.arr);
    const { currentUser } = useAuth();
    const [issetCC, setissetCC] = useState(false)
    const [selectCity, setselectCity] = useState(null);
    //snack bar
    const [snakopen, setsnakopen] = useState(false)
    const [snackmessage, setsnackmessage] = useState(null)

    const handleSnackbar = (messages) => {
        setsnakopen(true)
        setsnackmessage(messages)
    };
    //min date
    var dmin = (dateselected) => {
        var temp = dateselected ? new Date(dateselected) : new Date()
        var d = temp.getDate();
        var m = temp.getMonth() + 1; //Month from 0 to 11
        var y = temp.getFullYear();
        return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }
    const DownloadCSV = async () => {

        if (todate !== null && fromdate !== null) {
            var todateseconds = new Date(todate).getTime() / 1000;
            var fromdateseconds = new Date(fromdate).getTime() / 1000;
            var url = process.env.REACT_APP_BASE_URL+`/accounts?type=collections&sub_type=rides&from=${fromdateseconds}&to=${todateseconds}`;
            if (selectCity !== null) {
                url = url + '&city=' + selectCity
            } else if (selectCountry !== null) {
                url = url + '&country=' + selectCountry
            }
            if (dd.secnextKey !== 0) {
                url = url + "&start=" + dd.secnextKey
            } else {
                url = url + "&start="
            }
            setspinnershow(true);
            setisDisabled(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            await fetch(
                url,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === true) {
                        setisDisabled(false);
                        fileJson(result.data, 'Collection Rides')
                    } else {
                        handleSnackbar(result.message);
                    }
                    setspinnershow(false);
                })
                .catch((error) => {
                    setspinnershow(false);
                    handleSnackbar(error);
                    console.log("error", error);
                });
        } else {
            if (fromdate === null) {
                handleSnackbar('Please select from date')
            }
            if (todate === null) {
                handleSnackbar('Please select to date')
            }        }
    };
    const fileJson = async (JSONData, ReportTitle, ShowLabel) => {
        var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
        var CSV = "";
        var datasest = arrData.map((data) => {

            return {
                Id: data.id,
                Name: data.name,
                email: data.email,
                ARN: data.ARN,
                'Driver Pays To Africar': data.driver_pays_to_africar ? 'Yes' : 'No',
                'Driver Due': data.driver_due,
                'Due Paid': data.due_paid
            };
        });

        const items = datasest;
        const replacer = (key, value) => (value === null ? " " : value); // specify how you want to handle null values here
        const header = Object.keys(items[0]);
        CSV = [
            header.join(","), // header row first
            ...items.map((row) =>
                header
                    .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                    .join(",")
            ),
        ].join("\r\n");
        if (CSV == "") {
            alert("Invalid data");
            return;
        }
        //Generate a file name
        var fileName = "Report_";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += ReportTitle.replace(/ /g, "_");

        //Initialize file format you want csv or xls
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setisDisabled(false);
        setfromdate(0)
        settodate(0)
    };

    //search date
    const handleSubmitSearch = async (dsds, limit = 10) => {
        if (todate !== null && fromdate !== null) {
            var todateseconds = new Date(todate).getTime() / 1000;
            var fromdateseconds = new Date(fromdate).getTime() / 1000;
            var url = process.env.REACT_APP_BASE_URL+`/accounts?type=collections&sub_type=rides&from=${fromdateseconds}&to=${todateseconds}`;
            if (selectCity !== null) {
                url = url + '&city=' + selectCity
            } else if (selectCountry !== null) {
                url = url + '&country=' + selectCountry
            }
            if (dd.secnextKey !== 0) {
                url = url + "&start=" + dd.secnextKey
            } else {
                url = url + "&start="
            }
            setspinnershow(true);
            setisDisabled(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            await fetch(
                url,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === true) {
                        setisDisabled(false);
                        setsetclear(false);
                        setissetCC(true);

                        dispatch(
                            addCollectionRidesSearch(result.data, result.start)
                        );
                    } else {
                        handleSnackbar(result.message)
                    }
                    setspinnershow(false);
                })
                .catch((error) => {
                    setspinnershow(false);
                    handleSnackbar(error)
                    setmessagttouser("Unable to get data....ERROR");
                    console.log("error", error);
                });
        } else {

            if (selectCountry===null) {
                handleSnackbar('Please select Country')

            }else if(selectCity===null){
                handleSnackbar('Please select Country')

            }else if (fromdate === null||todate === null) {
                handleSnackbar('Please Select Date')
            }
        }
    };

    //runes after opens page

    useEffect(() => {
        console.log('dd');
        if (dd.arr.length === 0) {
            calldataall_riders(0);
        } else {
            if (issetCC) {
                setarrayData(dd.secarr);
            } else {
                setarrayData(dd.arr);
            }
        }
    }, [dd.secarr,dd.arr]);

    async function calldatasetApiRepeat(e) {
        if (e >= 10) {
            var da = 0;
            if (dd.nextKey !== 0) {
                da = dd.nextKey;
            } else {
                da = nextkey;
            }
            if (da !== null && nexttoggle) {
                if (!issetCC) {
                    da = await calldataall_riders(da, e);
                } else {
                    handleSubmitSearch(da, e)
                }
            }

        }

    }

    async function calldataall_riders(nexttokenparam, limit = 10) {
        setspinnershow(true);
        if (nexttokenparam === undefined) {
            nexttokenparam = dd.nextKey || nextkey;
        }
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        return await fetch(
            process.env.REACT_APP_BASE_URL+"/accounts?type=collections&sub_type=rides&start=" +
            nexttokenparam,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === true && result.data.length !== 0) {
                    setnextkey(result.start);
                    dispatch(addCollectionRides(result.data, result.start));
                    setnexttoggle(true);
                    setspinnershow(false);
                    return result.start;
                } else {
                    setspinnershow(false);
                    setnexttoggle(false);
                    if (result.data.length !== 0) {
                        setmessagttouser('No data to load');
                    }
                    console.log("error" + 'Response is empty');
                    return null;
                }
            })
            .catch((error) => {
                setnexttoggle(false);
                setmessagttouser("Unable to get data....ERROR");
                console.log("error", error);
            });
    }
    if (dd.arr.length === 0) {

        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={1} pb={3}>
                <Card sx={{ m: 1, p: 2, pt: 2 }}>
                    {sipnnershow && (
                        <Box sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{
                                width: '100%',
                                transition: 'none'
                            }} />
                        </Box>
                    )}
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={3} xl={3}>
                            <SuiTypography sx={{ fontSize: '0.9rem', }} >
                                Select Country
                            </SuiTypography >
                            {userinfo.countries.length !== 0 ? (
                                <SuiSelect
                                    required
                                    placeholder={'Query by country'}
                                    value={selectCountry !== null ? { value: selectCountry, label: selectCountry } : null}
                                    options={userinfo.countries.map((data2) => ({ value: data2, label: data2 }))}
                                    onChange={(e) => {
                                        setselectCountry(e.value);
                                        dispatch(CLEANCollectionRidesSearch());
                                        setselectCity(null)
                                    }}
                                />
                            ) : null}
                        </Grid>

                        {selectCountry !== null ? userinfo.isadmin ? (
                            <Grid item xs={12} sm={12} md={3} xl={3}>
                                <SuiTypography sx={{ fontSize: '0.9rem', }} >
                                    Select City
                                </SuiTypography >
                                <SuiSelect
                                    required
                                    placeholder={'Query by city'}
                                    value={selectCity !== null ? { value: selectCity, label: selectCity } : null}
                                    options={userinfo.allcities[selectCountry].map((data2, i) => ({ value: data2, label: data2 }))}
                                    onChange={(e) => {
                                        setselectCity(e.value);
                                        dispatch(CLEANCollectionRidesSearch());
                                    }}
                                />
                            </Grid>
                        ) : (<Grid item xs={12} sm={12} md={3} xl={3}>
                            <SuiTypography sx={{ fontSize: '0.9rem', }} >
                                Select City
                            </SuiTypography >
                            <SuiSelect
                                required
                                placeholder={'Query by city'}
                                value={selectCity !== null ? { value: selectCity, label: selectCity } : null}
                                options={userinfo.allcities.map((data2, i) => ({ value: data2, label: data2 }))}
                                onChange={(e) => {
                                    setselectCity(e.value);
                                    dispatch(CLEANCollectionRidesSearch());
                                }}
                            />
                        </Grid>) : null}
                        <Grid item md={3} >
                            <SuiTypography sx={{ fontSize: '0.9rem', }} >
                                From Date{" "}
                            </SuiTypography >
                            <SuiInput
                                required
                                inputProps={{ max: dmin(), min: "2010-05-04" }}

                                value={fromdate}
                                onChange={(e) => {
                                    setfromdate(e.target.value);
                                }}
                                type="date"
                            />{" "}

                        </Grid>
                        <Grid item md={3} >
                            <SuiTypography sx={{ fontSize: '0.9rem', }} >
                                To Date{" "}
                            </SuiTypography >
                            <SuiInput
                                required
                                value={todate}
                                inputProps={{ max: dmin(), min: dmin(fromdate) }}

                                onChange={(e) => {
                                    settodate(e.target.value);
                                }}
                                type="date"
                            />{" "}

                        </Grid>
                        <Grid item md={6} sx={{ mt: 'auto' }}>
                            {setclear ? (
                                <SuiButton onClick={() => { handleSubmitSearch() }} sx={{ width: '100%' }}>
                                    Search
                                </SuiButton>
                            ) : null}

                            {!setclear ? (
                                <SuiButton
                                    type="button"
                                    onClick={() => {
                                        setarrayData(dd.arr);
                                        dispatch(CLEANCollectionRidesSearch());
                                        setsetclear(true);
                                        setfromdate(null)
                                        settodate(null)
                                        setselectCity(null)
                                        setselectCountry(null)
                                        setissetCC(false)
                                    }}
                                >
                                    Clear
                                </SuiButton>
                            ) : null}
                        </Grid>
                        <Grid item md={6} sx={{ mt: 'auto' }}>
                            {setclear ? (
                                <SuiButton sx={{ width: '100%' }} onClick={() => { DownloadCSV() }}>
                                    Download csv file
                                </SuiButton>
                            ) : null}
                        </Grid>
                    </Grid>
                    <Divider />
                    <DataTable table={arrayData}
                        hidecolumnsdata={[]}
                        functioncallcount={calldatasetApiRepeat}
                        datacolumns={[
                            {
                                Header: "ride number",
                                accessor: "ARN",
                            },
                            {
                                Header: "driver name",
                                accessor: "name",
                            },
                            {
                                Header: "driver email id",
                                accessor: "email",
                            },
                            {
                                Header: "Driver Due",
                                accessor: "driver_due",
                            },
                            {
                                Header: "Due Paid",
                                accessor: "due_paid",
                            },

                        ]}
                        canSearch />
                </Card>
                <CustomizedSnackbars message={snackmessage} open={snakopen} color={'error'} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default CollectionsRides;