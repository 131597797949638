import React, { useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";

// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import { adddlinkedvehicledroData } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import LinearProgress from '@mui/material/LinearProgress';
import { useAuth } from "CustomComponents/Authcontext";

function UnlinkDROfromVehicle() {
    const dd = useSelector((state) => state.DROlinkvehicledata);
    const dispatch = useDispatch();
    const [errormessage1, seterrormessage1] = useState(null);
    const [sipnnershow, setsipnnershow] = useState(false);

    //searck key word store
    const [DROemailid, setDROemailid] = useState("");
    //selected id
    const [dataselected, setdataselected] = useState([]);
    const [droid, setdroid] = useState(null);
    const {currentUser}=useAuth();

    function handleSubmit(event) {
        event.preventDefault();
        setsipnnershow(true);
        if (dataselected.length !== 0 && DROemailid!=="") {
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(
                {
                    "dro": droid,
                    "type": "dro_vehicle",
                    "vehicles": dataselected
                }
               );
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            fetch(
                process.env.REACT_APP_BASE_URL+"/unlink",
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === false) {
                        seterrormessage1(result.message);
                        // console.log(result);
                        setTimeout(() => {
                            seterrormessage1(null);
                        }, 2000);
                        setsipnnershow(false);
                    } else {
                        setsipnnershow(false);
                        setdroid(null)
                        setdataselected([])
                        setDROemailid('')
                        dispatch(adddlinkedvehicledroData(null));

                        alert("DRO and vehicle Unlinked Successfully");
                    }
                })
                .catch((error) => {
                    setsipnnershow(false);
                    console.log("error", error)});
        } else {
            alert("Please select the Search Result.");
            setsipnnershow(false);

        }
    }

    const checkdata = (e, data) => {
        if (e) {
            if (!dataselected.filter(function (e) { return e === data; }).length > 0) {
                /* vendors contains the element we're looking for */
                dataselected.push(data)
                setdataselected(dataselected)
            }


        } else {
            for (var i = dataselected.length - 1; i >= 0; --i) {
                if (dataselected[i] === data) {
                    dataselected.splice(i, 1);
                    setdataselected(dataselected)
                }


            }

        }

        // console.log(dataselected);
    }

    async function handleSearchDROAssets(event) {
        event.preventDefault();
        setsipnnershow(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BASE_URL+`/search_users?user=mappable&type=unlinking&search=${DROemailid}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((results) => {
                var result=results
                if (result.status === false) {
                    seterrormessage1(result.message);
                    setTimeout(() => {
                        seterrormessage1(null);
                    }, 3000);
                    setsipnnershow(false);
                } else {
                    setsipnnershow(false);
                    setdroid(result.dro.id)
                    var arrayvalue = [];
                    result.vehicles.map((data) => {
                        data.action = <div >
                            <Checkbox
                                color="primary"
                                onChange={(e) => checkdata(e.target.checked, data.id)}
                            />
                        </div>
                        arrayvalue.push(data)
                    });
                    // if (result.vehicles.length !== 0) {
                    //     result.vehicles.map(data => {
                    //         data.action = <div >
                    //             <Checkbox
                    //                 color="primary"
                    //                 onChange={(e) => checkdata(e.target.checked, data.id)}
                    //             />
                    //         </div>
                    //         arrayvalue.push(data)
                    //     })
                    result.vehicles = arrayvalue;
                    // }
                    dispatch(adddlinkedvehicledroData(result));

                }
            })
            .catch((error) => console.log("error", error));
    }

  
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <Card >
                    {sipnnershow && (
                        <Box sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{ width: '100%' }} />
                        </Box>

                    )}
                    <Grid container spacing={2}>
                        <Grid item md={12} sm={12} lg={12} xl={12}>
                            <Card sx={{ p: 4 }}>

                                <h5 style={{ display: "inline-block" }}> Search DRO </h5>
                                <form onSubmit={handleSearchDROAssets} >
                                    <SuiTypography
                                        variant="h6"
                                    >
                                        Please Enter Email ID
                                    </SuiTypography>
                                    <SuiInput
                                        sx={{ mb: 2, minWidth: '100px', position: 'relative', ml: 'auto', mr: 'auto' }}
                                        required
                                        value={DROemailid}
                                        name="vSearch"
                                        placeholder="Example@mail.com"
                                        onChange={(e) => setDROemailid((e.target.value).trim().toLowerCase())}
                                        inputProps={{ type: "text" }}
                                    />

                                    <SuiButton
                                        type="submit"
                                        id="submits2"
                                        variant="gradient" color="info"  >
                                        Search DRO

                                    </SuiButton>

                                    <div>
                                        {errormessage1 != null ? (
                                            <p style={{ color: "red" }}>{errormessage1}</p>
                                        ) : null}
                                    </div>
                                </form>

                            </Card>
                        </Grid>

                    </Grid>
                </Card>
                {dd!==null ? <Card sx={{ m: 1, p: 3 }}>
                    <SuiTypography variant="h6">Search Results</SuiTypography>
                    <form onSubmit={handleSubmit} >
                        <Grid container spacing={1} >
                            <Grid item md={4} sm={4} xl={4} lg={4} >
                                <Card sx={{ height: '100%', p: 2, mt: 'auto', mb: 'auto', textAlign: 'center' }}>
                                    <Typography sx={{ mt: 'auto', mb: 'auto' }} variant="h7"></Typography>
                                    < Typography sx={{ textAlign: 'center' }} variant="h4">{dd.dro.role}</Typography>
                                    <Typography sx={{ fontSize: '1rem' }}>Name :{dd.dro.name}</Typography>
                                    <Typography sx={{ fontSize: '1rem' }}>Email :{dd.dro.email}</Typography>
                                    <Typography sx={{ fontSize: '1rem' }}>Under  :{dd.dro.parent[0]}</Typography>
                                    <Typography sx={{ mt: 'auto', mb: 'auto' }} variant="h7"></Typography>

                                </Card>
                            </Grid>
                            {dd && dd.vehicles.length !== 0 ? <Grid item md={8} sm={8} xl={8} lg={8} >
                                <Card>
                                    <DataTable
                                        table={dd.vehicles}
                                        hidecolumnsdata={[]}
                                        functioncallcount={() => { }}
                                        datacolumns={[
                                            {
                                                Header: "Select ",
                                                accessor: "action",
                                            },
                                            {
                                                Header: "Driver Name ",
                                                accessor: "driver_name",
                                            },
                                            {
                                                Header: "email  ",
                                                accessor: "email",
                                            },
                                            {
                                                Header: "registration number ",
                                                accessor: "registration_number",
                                            },
                                            
                                            {
                                                Header: "vehicle brand ",
                                                accessor: "brand",
                                            },
                                        ]}
                                        datalength={dd.vehicles.length} canSearch
                                    />
                                </Card>
                            </Grid> : <Grid item md={8} sm={8} xl={8} lg={8} >
                                <Card>
                                    <SuiTypography variant="h6" sx={{ mx: 2 }}>NO vehicles found under this DRO {DROemailid}</SuiTypography>

                                </Card>
                            </Grid>}

                            <Grid item md={12}>
                                <SuiButton id="submit" type="submit" color="info">
                                    Submit
                                </SuiButton>
                            </Grid>{" "}
                        </Grid>            </form>

                </Card> : <></>}


            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default UnlinkDROfromVehicle;
