
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useAuth } from "CustomComponents/Authcontext";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import { useHistory, useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";

import {
  addRevenueVehicleDetails,
  addRevenueVehicleDetailsSearch,
  addRevenuedVehicleDetailsClean,
} from "CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

function RevenueVehicledetails() {
  const dd = useSelector((state) => state.AccountsRevenuVehicleDetail);
  const dispatch = useDispatch();
  let history = useHistory();
  const [setclear, setsetclear] = useState(true);
  const [fromdate, setfromdate] = useState();
  const [todate, settodate] = useState();
  const [arrayData, setarrayData] = useState(dd.arr);
  const [isDisabled, setisDisabled] = useState(false);
  const [nextkey, setnextkey] = useState(0);
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
  const location = useLocation();
  const [isLoding, setisLoding] = useState(true);
  const {currentUser}=useAuth();

  const handleSubmitSearch = async (event) => {
    event.preventDefault();
    setspinnershow(true);

    setisDisabled(true);
    var todateseconds = new Date(todate).getTime() / 1000;
    var fromdateseconds = new Date(fromdate).getTime() / 1000;
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + currentUser.accessToken
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    await fetch(
      process.env.REACT_APP_BASE_URL+`/accounts?type=revenue&sub_type=vehicles&id=${location.state.id}&from=${fromdateseconds}&to=${todateseconds}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setisDisabled(false);
          setsetclear(false);

          dispatch(
            addRevenueVehicleDetailsSearch(
              result.data,
              result.start,
              result.count
            )
          );
        } else {
          alert(result.message);
        }
        setspinnershow(false);
      })
      .catch((error) => {
        setspinnershow(false);

        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });
  };
  const DownloadCSV = async () => {
    setspinnershow(true);
    setisDisabled(true);
    if (todate !== null && fromdate !== null && todate !== '' && fromdate !== '') {
      var todateseconds = new Date(todate).getTime() / 1000;
      var fromdateseconds = new Date(fromdate).getTime() / 1000;
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + currentUser.accessToken
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      await fetch(
        process.env.REACT_APP_BASE_URL+`/accounts?type=revenue&sub_type=vehicles&id=${location.state.id}&from=${fromdateseconds}&to=${todateseconds}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            setisDisabled(false);

            fileJson(result.data, "Revenue Vehicle Details");
          } else {
            alert(result.message);
          }
          setspinnershow(false);
        })
        .catch((error) => {
          setspinnershow(false);
          setmessagttouser("Unable to get data....ERROR");
          console.log("error", error);
        });
    } else {
      setspinnershow(false);
      alert("Please select the date !");
    }
  };

  const fileJson = async (JSONData, ReportTitle, ShowLabel) => {
  
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

    var CSV = "";
    var datasest = [];
    datasest = arrData.map((data) => {

      return {

        id: data.id,
        ARN: data.ARN,
        Name: data.name,
        'Email Id': data.email,
        'Vehicle Number': data.vehicle_number,
        'Net Amount': data.net_amount,
        Vat: data.vat,
        'Final Amount': data.final_amount


      };
    });
  
    const items = datasest;
    var header;
    const replacer = (key, value) => (value === null ? " " : value); // specify how you want to handle null values here
    if (items[0]!==undefined&&items[0]!==null) {
     header = Object.keys(items[0]);

    }else{
      alert('Error Please provide correct data')
      return null
    }
    CSV = [
      header.join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\r\n");
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    var fileName = "Report_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setisDisabled(false);
    setfromdate(0)
    settodate(0)
  };
  useEffect(() => {
    dispatch(addRevenuedVehicleDetailsClean());
    if (dd.arr.length === 0) {
      calldataall_riders(0,100);
    }
  }, [0]);
  async function calldataall_ridersRepeat(e) {
    if (e >= 10) {
      var da = "";
      if (dd.nextKey !== "") {
          da = dd.nextKey;
      } else {
          da = nextkey;
      }
      if (da !== null && nexttoggle) {
          da = await calldataall_riders(da, e);
      }
  }

}
  useEffect(() => {
    if (setclear) {
      setarrayData(dd.arr);
    } else {
      setarrayData(dd.arrsearch);
    }
  }, [dd.arrsearch, dd.arr]);
  //function call api
  async function calldataall_riders(nextaccessTokenparam,limit=100) {
    setspinnershow(true);
    setsetclear(true);

    if (nextaccessTokenparam === undefined) {
      nextaccessTokenparam = dd.nextKey || nextkey;
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + currentUser.accessToken
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return await fetch(
      process.env.REACT_APP_BASE_URL+"/accounts?type=revenue&sub_type=vehicles&id=" +
      location.state.id +
      "&start=" +
      nextaccessTokenparam+'&limit='+limit,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true && result.data.length !== 0) {
          setnextkey(result.start);
          dispatch(addRevenueVehicleDetails(result.data, result.start));
          setnexttoggle(true);
          setspinnershow(false);
          setisLoding(false);
          return result.start;
        } else {
          dispatch(addRevenuedVehicleDetailsClean());
          setisLoding(true);
          setspinnershow(false);
          setnexttoggle(false);
          setmessagttouser("No data found for this ID");
          return null;
        }
      })
      .catch((error) => {
        setnexttoggle(false);
        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });
  }
  if (isLoding && dd.arr.length === 0) {

    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox pt={2} pb={3}>
          <Card>
            <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
          </Card>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={2} pb={3}>
        <Card sx={{ m: 1, p: 2 }}>
          {sipnnershow && (
            <div style={{ display: "inline-block" }}>
              Loading data... <CircularProgress size={20} color="primary" />
            </div>
          )}
          <Divider />
          <div>
            <form onSubmit={handleSubmitSearch} >
              <Grid container spacing={3}>
                <Grid item md={3} >

                  <p className="-Formlable" id="fromdate">
                    From Date{" "}
                  </p>
                  <SuiInput
                    required
                    value={fromdate}
                    onChange={(e) => {
                      setfromdate(e.target.value);
                    }}
                    type="date"
                  />{" "}

                </Grid >
                <Grid item md={3} >

                  <p className="-Formlable" id="todate">
                    To Date{" "}
                  </p>
                  <SuiInput
                    required
                    value={todate}
                    onChange={(e) => {
                      settodate(e.target.value);
                    }}
                    type="date"
                  />{" "}

                </Grid >
                <Grid item md={2} sx={{ mt: 'auto' }}>
                  {setclear ? (
                    <SuiButton sx={{ mt: 'auto' }} id="search" type="submit">
                      Submit
                    </SuiButton>
                  ) : null}

                  {!setclear ? (
                    <SuiButton

                      type="button"
                      onClick={() => {
                        dispatch(addRevenueVehicleDetailsSearch([]));
                        setsetclear(true);
                        setfromdate('')
                        settodate('')
                      }}
                    >
                      clear
                    </SuiButton>
                  ) : null}
                </Grid >
                <Grid item md={3} sx={{ mt: 'auto' }} >
                  {setclear ? (
                    <SuiButton sx={{ mt: 'auto' }}

                      onClick={() => {
                        DownloadCSV();
                      }}
                    >
                      Download csv file
                    </SuiButton>
                  ) : null}
                </Grid >
              </Grid >
            </form>
          </div>
          <Divider />

          <DataTable table={arrayData}
            hidecolumnsdata={[]}
            functioncallcount={calldataall_ridersRepeat}

            datacolumns={[
              {
                Header: "Driver name",
                accessor: "name",
              },
              {
                Header: "driver  id",
                accessor: "email",
              },
              {
                Header: "vehicle number",
                accessor: "vehicle_number",
              },
              {
                Header: "ride number",
                accessor: "ARN",
              },
              {
                Header: "net amount",
                accessor: "net_amount",
              },
              {
                Header: "vat",
                accessor: "vat",
              },
              {
                Header: "final amount",
                accessor: "final_amount",
              },
            ]}
             canSearch />
        </Card>

      </SuiBox>
      <Footer />
    </DashboardLayout >
  );
}

export default RevenueVehicledetails;
