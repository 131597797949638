import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// Data
import Box from '@mui/material/Box';

import { AddSimdata } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { useAuth } from "CustomComponents/Authcontext";

function MobileVehicleLink() {
    const [errormessage1, seterrormessage1] = useState(null);
    const [errormessage2, seterrormessage2] = useState(null);
    const [sipnnershow, setsipnnershow] = useState(false);
    //response mobile
    const [mobileResponse, setmobileResponse] = useState(null);
    const [VehicalResponse, setVehicalResponse] = useState(null);
    //searck key word store
    const [IMEINUMBER, setIMEINUMBER] = useState("");
    const [vehicleRN, setvehicleRN] = useState("");
    //selected id
    const [selectedVehical, setselectedVehical] = useState(null);
    const [selectedMobile, setselectedMobile] = useState(null);
    const { currentUser } = useAuth();

    function handleSubmit(event) {
        event.preventDefault();
        setsipnnershow(true);
        if (selectedVehical !== null && selectedMobile !== null) {
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                type: "mobile_vehicle",
                vehicle_id: selectedVehical,
                mobile_id: selectedMobile,
            });
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            fetch(
                process.env.REACT_APP_BASE_URL + "/asset_link",
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === false) {
                        seterrormessage1(result.message);
                        setTimeout(() => {
                            seterrormessage1(null);
                        }, 2000);
                        setsipnnershow(false);
                    } else {
                        setsipnnershow(false);
                        setvehicleRN('')
                        setIMEINUMBER('')
                        setVehicalResponse(null)
                        setmobileResponse(null)
                        setselectedMobile(null)
                        setselectedVehical(null)
                        alert("Mobile vehicle linked Successfully");
                    }
                })
                .catch((error) => console.log("error", error));
        } else {
            alert("Please select the Search Result.");
        }
    }

    async function handleSearchvehicle(event) {
        event.preventDefault();
        setsipnnershow(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BASE_URL + "/search_asset?asset=vehicle&search=" +
            vehicleRN +
            "&type=vehicle_mobile",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === false) {
                    seterrormessage2(result.message);
                    setTimeout(() => {
                        seterrormessage2(null);
                    }, 2000);
                    setsipnnershow(false);
                } else {
                    setsipnnershow(false);
                    setVehicalResponse(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    }
    async function handleSearchMobile(event) {
        event.preventDefault();
        setsipnnershow(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BASE_URL + "/search_asset?asset=mobile&search=" +
            IMEINUMBER +
            "&type=mobile_vehicle",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === false) {
                    seterrormessage1(result.message);
                    setTimeout(() => {
                        seterrormessage1(null);
                    }, 2000);
                    setsipnnershow(false);
                } else {
                    setsipnnershow(false);
                    setmobileResponse(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <Card >
                    {sipnnershow && (
                        <Box sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{ width: '100%' }} />
                        </Box>

                    )}
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <Card sx={{ p: 4 }}>

                                <h5 style={{ display: "inline-block" }}>Mobile Search </h5>
                                <form onSubmit={handleSearchMobile} >
                                    <SuiTypography
                                        variant="h6"
                                    >
                                        Please Enter IMEI Number
                                    </SuiTypography>
                                    <SuiInput
                                        sx={{ mb: 2, position: 'relative', ml: 'auto', mr: 'auto' }}

                                        required
                                        value={IMEINUMBER}
                                        name="vSearch"
                                        placeholder="IMEI..."
                                        onChange={(e) => setIMEINUMBER(e.target.value)}
                                        inputProps={{ type: "text" }}
                                    />

                                    <SuiButton
                                        type="submit"
                                        id="submits2"
                                        variant="gradient" color="info"  >
                                        Search Mobile

                                    </SuiButton>

                                    <div>
                                        {errormessage1 != null ? (
                                            <p style={{ color: "red" }}>{errormessage1}</p>
                                        ) : null}
                                    </div>
                                </form>

                            </Card>
                        </Grid>

                        <Grid item md={6}>
                            <Card sx={{ p: 4 }}>
                                <h5 style={{ display: "inline-block" }}>Vehicle Search </h5>
                                <form onSubmit={handleSearchvehicle} >
                                    <SuiTypography
                                        variant="h6"
                                    >
                                        Please Enter a Valid Vehicle Registration Number
                                    </SuiTypography>
                                    <SuiInput
                                        sx={{ mb: 2, position: 'relative', ml: 'auto', mr: 'auto' }}

                                        required
                                        value={vehicleRN}
                                        name="vSearch"
                                        placeholder="Registration Number"
                                        onChange={(e) => setvehicleRN(e.target.value)}
                                        inputProps={{ type: "text" }}
                                    />

                                    <SuiButton
                                        type="submit"
                                        id="submits2"
                                        variant="gradient" color="info"  >
                                        Search Vehicle

                                    </SuiButton>

                                    <div>
                                        {errormessage2 != null ? (
                                            <p style={{ color: "red" }}>{errormessage2}</p>
                                        ) : null}
                                    </div>
                                </form>

                            </Card>
                        </Grid>
                    </Grid>
                </Card>
                <Card sx={{ m: 1, p: 3 }}>
                    <SuiTypography variant="h6">Search Results</SuiTypography>
                    <form onSubmit={handleSubmit} >

                        <Grid container spacing={2} >
                            <Grid item md={5}>
                                {" "}
                                {mobileResponse !== null ? (
                                    <Card
                                        style={{ backgroundColor: "#EFEFEF", textAlign: "justify" }}
                                        id={mobileResponse.id}
                                        onClick={() => {
                                            setselectedMobile(mobileResponse.id);
                                            document.getElementById(
                                                mobileResponse.id
                                            ).style.backgroundColor = "#EFEF";
                                        }}
                                    >
                                        <Grid container sx={{ p: 3 }}>
                                            <Grid item sm={12}>
                                                <SuiTypography variant="button">
                                                    Brand Name:
                                                    <span>{mobileResponse.brand_name}</span>
                                                </SuiTypography>
                                            </Grid>

                                            <Grid item sm={12}>
                                                <SuiTypography variant="button">
                                                    Build Number :{" "}
                                                    <span>{mobileResponse.build_number}</span>
                                                </SuiTypography>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <SuiTypography variant="button">
                                                    IMEI :
                                                    <span>{mobileResponse.IMEI}</span>
                                                </SuiTypography>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <SuiTypography variant="button">
                                                    Phone Number :
                                                    <span>{mobileResponse.number}</span>

                                                </SuiTypography>

                                            </Grid>
                                        </Grid>{" "}
                                    </Card>
                                ) : null}
                            </Grid>
                            <Grid item md={5}>
                                {" "}
                                {VehicalResponse !== null ? (
                                    <Card

                                        style={{ backgroundColor: "#EFEFEF", textAlign: "justify" }}
                                        id={VehicalResponse.id}
                                        onClick={() => {
                                            setselectedVehical(VehicalResponse.id);
                                            document.getElementById(
                                                VehicalResponse.id
                                            ).style.backgroundColor = "#EFEF";
                                        }}
                                    >
                                        <Grid container sx={{ p: 3 }}>
                                            <Grid item sm={12}>
                                                <SuiTypography variant="button">
                                                    Registration Number:{" "}
                                                    <span>{VehicalResponse.registration_number}</span>
                                                </SuiTypography>
                                            </Grid>

                                            <Grid item sm={12}>
                                                <SuiTypography variant="button">
                                                    Model :
                                                    <span>{VehicalResponse.model}</span>
                                                </SuiTypography>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <SuiTypography variant="button">
                                                    Brand :
                                                    <span>{VehicalResponse.brand}</span>
                                                </SuiTypography>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <SuiTypography variant="button">
                                                    city :
                                                    <span>{VehicalResponse.city}</span>
                                                </SuiTypography>
                                            </Grid>
                                        </Grid>{" "}
                                    </Card>
                                ) : null}
                            </Grid>
                            <Grid item md={12}>
                                <Button id="submit" type="submit">
                                    Submit
                                </Button>
                            </Grid>{" "}
                        </Grid>            </form>

                </Card>

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default MobileVehicleLink;
