import React, { useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// Data
import { useSelector } from "react-redux";

import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Autocomplete from "react-google-autocomplete";
import { useHistory } from "react-router-dom";
import { database } from "../../CustomComponents/firebase";
import { ref as sRef, set, update } from "firebase/database";
import CustomizedSnackbars from "../Alert/Alert";

function AddCity() {

    let history = useHistory();

    const [messagttouser, setmessagttouser] = useState("Loading Data...");
    const [sipnnershow, setspinnershow] = useState(false);
    const userinfo = useSelector((state) => state.Userinfo);
    const Localization = useSelector((state) => state.Localization);

    const [countryname, setcountryname] = useState(null);
    const [cityname, setcityname] = useState(null);
    const [autocompleteOption, setautocompleteOption] = useState({})
    const [cityGeoData, setcityGeoData] = useState(null)
    const [navigationvalue, setnavigationvalue] = useState(false)
    //snack bar
    const [snakopen, setsnakopen] = useState(false)
    const [snackmessage, setsnackmessage] = useState(null)

    const handleSnackbar = (messages) => {
        setsnakopen(true)
        setsnackmessage(messages)
    };
    //
    const optionset = (e) => {
        var code = ''
        if (Localization[e] !== undefined) {
            setautocompleteOption({
                types: ["(cities)"],
                componentRestrictions: { country: Localization[e].code },
            })
        }

    }
    const handleSubmit1 = async (e) => {
        e.preventDefault();
        if (cityname !== null && countryname !== null) {
            var cities1 = []
            var isset = true;
            if (Localization[countryname].cities !== undefined) {
                cities1 = Localization[countryname].cities;
                if (Localization[countryname].cities.includes(cityname)) {
                    isset = false
                    alert('This city already exists')
                }
            }


            if (isset) {
                cities1.push(cityname);
                update(sRef(database, "localization/" + countryname), { cities: cities1 }).then(() => {
                    set(sRef(database, 'war_room/' + cityname.toLowerCase()), {
                        country: countryname,
                        coordinates: {
                            lat: cityGeoData.lat(),
                            long: cityGeoData.lng(),
                        },
                        currency: '',
                    })
                        .then(() => {
                            alert('City added successfull')
                            setcountryname(null)
                            setcityname(null)
                            setautocompleteOption({})
                            setcityGeoData(null)
                        })


                    if (navigationvalue) {
                        history.push({
                            pathname: '/africar/AddCityRateCard',
                        })
                    }
                })
                    .catch((e) => alert(e));


            }


        } else {
            // console.log(cityname, countryname);
            if (countryname === null) {
                handleSnackbar('Please Select Country')
            } else if (cityname === null) {
                handleSnackbar('Please enter a valid city')
            }
        }
    };

    if (Localization === null) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <Card sx={{ m: 2, p: 2, pl: 4, height: '50vh' }}>
                    <SuiTypography variant="h3" sx={{ textAlign: 'center' }}>Add City</SuiTypography>

                    <form onSubmit={handleSubmit1} >
                        <Grid container sx={{ mt: 3, height: '100%' }}>
                            <Grid item xs={6} sm={5} md={6} xl={6} >
                                <p className="Labelstyle">Country Name</p>
                                <SuiSelect
                                    width={'60%'}
                                    defaultValue={{ value: '', label: '' }}
                                    options={userinfo.countries.map((entry) => ({ value: entry, label: entry }))}
                                    onChange={(e) => {
                                        optionset(e.value);
                                        setcountryname(e.value);
                                        setcityname(null)

                                    }}
                                />

                            </Grid >
                            <Grid item xs={12} sm={12} xl={12} md={12} />

                            <Grid item xs={6} sm={5} xl={6} md={6} >

                                <p className="Labelstyle">City Name</p>
                                <Autocomplete
                                    disabled={countryname === null}
                                    apiKey={'AIzaSyD6IFjP0WDoxI6sYhMp4qQZdmjGtGS5_Ws'}
                                    style={{
                                        borderRadius: '7px',
                                        border: '2px solid #ced4da',
                                        fontSize: 16,
                                        padding: '10px 12px',
                                        width: 'calc(100%)',

                                    }}
                                    onPlaceSelected={(place) => {
                                        setcityGeoData(place.geometry.location)
                                        setcityname(place.address_components[0].long_name);
                                    }}
                                    options={autocompleteOption}
                                    placeholder='Search city'
                                />


                            </Grid >
                            <Grid item xs={12} sm={12} xl={12} md={12} sx={{ m: 2 }} />
                            <Grid item xs={12} sm={12} xl={3} md={3} pr={2}
                            >
                                {" "}
                                <SuiButton
                                    sx={{ width: '100%' }}
                                    id="submit" type="submit" >
                                    Save
                                </SuiButton>

                            </Grid >
                            <Grid item xs={12} sm={12} xl={3} md={3} pr={2}
                            >
                                {" "}
                                <SuiButton
                                    sx={{ width: '100%' }}

                                    id="submit" type="submit" onClick={() => {
                                        setnavigationvalue(true);
                                    }} >
                                    Save & Add City Rate Card
                                </SuiButton>

                            </Grid >
                            <Grid item xs={6} sm={5} xl={3} md={3} pr={3}
                            >
                                {" "}

                                <SuiButton
                                    sx={{ float: 'right' }}
                                    className="cancelButton"
                                    onClick={() => history.goBack()}
                                >
                                    Back
                                </SuiButton>

                            </Grid >
                        </Grid>
                    </form>
                </Card>
                <CustomizedSnackbars message={snackmessage} open={snakopen} color={'error'} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />
            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default AddCity;
