import React,{ useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import Divider from "@mui/material/Divider";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { storage } from "CustomComponents/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

export default function Account({imgurl}) {
    const [uplode1, setuplode1] = useState({ label: "", value: 0 });
    async function fileuplode(e,  id, upoldevalue) {
        e.preventDefault();
        if (e.target.files[0] !== undefined) {
            const file = e.target.files[0];
            const metadata = {
                contentType: file.type,
            };
            var fileurl = URL.createObjectURL(file)

            var timese = Math.floor(new Date().getTime() / 1000);

            const mountainsRef = ref(storage, "notificationImage/" + timese + id + file.name);

            // const mountainsRef = ref(storage, "TESTUPLOAD_Dinesh/" + timese + id + file.name);
            const uploadTask = uploadBytesResumable(mountainsRef, file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    upoldevalue({ label: fileurl, value: prog });
                },
                (error) => console.log(error),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        imgurl(downloadURL)
                    });
                }
            );
        }
    }

    return <div>
        <Grid container spacing={2} p={2}>
        <center>

            <Grid item  md={12} xl={12} lg={12}>
                    {" "}
                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Uplode image</SuiTypography >

                    <SuiInput required

                        className="w-50"
                        onChange={(e) => {
                            fileuplode(
                                e,
                                "Notification",
                                setuplode1
                            );
                        }}
                        type="file"
                    />
                                </Grid >

                    {uplode1.value !== 0 ? <Grid item md={12} xl={12} lg={12} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                        <img style={{ height: '10rem', width: '100%', borderRadius: '50%' }} src={uplode1.label} alt="preview image" />
                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >profile picture uploded {uplode1.value}%</SuiTypography >
                    </Grid> : null}
                </center>{" "}
        </Grid>
    </div>;
}
