const initailState = {
    arr:[],
}
const DROsearch=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_DRO_SEARCH':
            return{ 
                arr: [action.payload],
            }
        default:
            return state;
    }
}

export default DROsearch