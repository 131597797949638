import React, { useEffect, useState } from "react";


// Soft UI Dashboard PRO React example components

import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";
import Grid from "@mui/material/Grid";

// Data
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


function Notifications() {

 
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <Card >
                <SuiTypography sx={{textAlign:'center'}}>No Data Found</SuiTypography>
                </Card>
            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Notifications;

