import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'
import { addvehicalsData } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { useLocation, useHistory, Link, useRouteMatch } from "react-router-dom";
import { useAuth } from "CustomComponents/Authcontext";
import { fontSize } from "@mui/system";

function Alldrivers() {
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [errormessage, seterrormessage] = useState(null);
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
  const dd = useSelector((state) => state.All_Vehiclesstore);
  const Permision = useSelector((state) => state.Permisioncopy);

  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState("");
  let { path, url } = useRouteMatch();
  let history = useHistory();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (dd.arr.length === 0) {
      calldataall_vehicles("", 100);
    }
  }, [0]);
  async function callvehicleRepeat(e) {
    if (e >= 10) {
      var da = "";
      if (dd.nextKey !== "") {
        da = dd.nextKey;
      } else {
        da = nextkey;
      }
      if (da !== null && nexttoggle) {
        da = await calldataall_vehicles(da, e);
      }
    }

  }
  const fileJson = async (JSONData, ReportTitle, ShowLabel) => {
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
    var CSV = "";
    var datasest = [];
    datasest = arrData.map((data) => {
      var temp = {
        id: data.id,
        model: data.model || ' ',
        color: data.color || ' ',
        registration_number: data.registration_number || ' ',
        brand: data.brand || ' ',
        city: data.city || ' ',
        linked_to_driver: data.linked_to_driver || ' ',
        driverUID: data.driverUID || ' ',
        contactno: data.contactno || ' ',
        driver_name: data.driver_name || ' ',
        emailid: data.emailid || ' ',
        joindate: data.joindate || ' ',
        linked_to_dro: data.linked_to_dro || ' ',
        dro_name: data.dro_name || ' ',
        linked_to_mobile: data.linked_to_mobile || ' ',
        IMEI: data.IMEI || ' ',
        brand_name: data.brand_name,
        company: data.company,
        number: data.number || ' ',
        total_single_rides: data.total_single_rides !== undefined ? data.total_single_rides[1] : ' ',
      }
      return temp
    });

    const items = datasest;
    const replacer = (key, value) => (value === null ? " " : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    CSV = [
      header.map(d => d.replace('_', ' ')).join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\r\n");
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    var fileName = "Report_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  async function calldataall_vehicles(nexttokenparam, limit = 100) {

    if (nexttokenparam === undefined) {
      nexttokenparam = nextkey;
    }
    setspinnershow(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + currentUser.accessToken
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_BASE_URL + "/all_vehicles?start=" +
      nexttokenparam + '&limit=' + limit,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setnextkey(result.start);
          result.data.map((dat) => {
            if (Permision && Permision.vehicles.includes('U')) {
              dat.action = (<SuiButton
                onClick={() => {
                  history.push({
                    pathname: '/' + url.split('/')[1] + '/editVehicles',
                    customCRCData: dat,
                  })
                }}
                color="warning"
              >
                edit
              </SuiButton>)
            } else {
              dat.action = <SuiButton sx={{ fontSize: 'smaller' }} >Access Denied</SuiButton>
            }
            dat.joindate = new Date(dat.joindate * 1000).toLocaleString("en-US", {
              day: "numeric",
              month: "numeric",
              year: "numeric",

            });
            //   dat.insurance_validity=new Date(dat.insurance_validity*1000).toLocaleString("en-US", {
            //     day: "numeric",
            //     month: "numeric",
            //     year: "numeric",

            // });
            dat.autogenius_inspection_report_validity = new Date(dat.autogenius_inspection_report_validity * 1000).toLocaleString("en-US", {
              day: "numeric",
              month: "numeric",
              year: "numeric",

            });
            dat.fc_validity = new Date(dat.fc_validity * 1000).toLocaleString("en-US", {
              day: "numeric",
              month: "numeric",
              year: "numeric",

            });
          });
          dispatch(addvehicalsData(result.data, result.start));
          setnexttoggle(true);
          setspinnershow(false);
          return true;
        } else {
          dispatch(addvehicalsData(null, null));
          alert(result.message);
          setnexttoggle(false);
          setspinnershow(false);
          return false;

        }
      })

      .catch((error) => {
        setnexttoggle(false);
        setspinnershow(false);
        setmessagttouser("Unable to get data....ERROR");
        seterrormessage("No data left to load");
        setTimeout(() => {
          seterrormessage("");
        }, 3000);
        return false;
        console.log("error", error);
      });
  }
  if (dd.arr.length === 0) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox pt={2} pb={3}>
          <Card>
            <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
          </Card>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={2} pb={3}>
        <Card >
          {sipnnershow && (
            <Box sx={{ width: '100%', m: 0, p: 0 }}>
              <LinearProgress color="success" sx={{ width: '100%' }} />
            </Box>
          )}
          <SuiBox>
            <SuiButton sx={{ float: 'right' }} variant="gradient" onClick={() => {

              fileJson(dd.arr, `Collection_vehicle`);
            }} color="info"  >
              <FontAwesomeIcon icon={faDownload} />
            </SuiButton>
          </SuiBox>
          <DataTable
            table={dd.arr}
            hidecolumnsdata={[
            

            ]}
            functioncallcount={callvehicleRepeat}
            datacolumns={[

              {
                Header: "number",
                accessor: "number",
              },
              {
                Header: "company",
                accessor: "company",
              },
              {
                Header: "brand_name",
                accessor: "brand_name",
              },
              {
                Header: "IMEI",
                accessor: "IMEI",
              },
              {
                Header: "vehicle Registration number",
                accessor: "registration_number",
              },
              {
                Header: " driver Fname",
                accessor: "driver_name",
              }, 
              {
                Header: "emailid",
                accessor: "emailid",
              },


              {
                Header: "vehicle id",
                accessor: "id",
              },
              {
                Header: "Edit",
                accessor: "action",
              },
            ]}


            canSearch />
        </Card>

      </SuiBox>
      <Footer />
    </DashboardLayout >
  );
}

export default Alldrivers;
