import React, { useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import CustomizedSnackbars from "../Alert/Alert";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import {
    useHistory,
} from "react-router-dom";
import { useLocation, Link, useRouteMatch } from "react-router-dom";

function CityRateCards() {
    let Ratecarddata = useSelector((state) => state.Ratecarddata.arr);
    const Permissions = useSelector((state) => state.Permisioncopy.city_rate_card);
    const [colorCode, setcolorCode] = useState('error')
    const [snakopen, setsnakopen] = useState(false)
    const [snackmessage, setsnackmessage] = useState(null);

    const handleSnackbar = (messages) => {
        setsnakopen(true)
        setsnackmessage(messages)
    };

    const isloading = useSelector((state) => state.Isload);
    const [messagttouser, setmessagttouser] = useState("Loading Data...")
    let history = useHistory();
    let { path, url } = useRouteMatch();
    Ratecarddata.map((data) => {

        data.action = (
            <div >
                {/* use this button to add a edit kind of action */}
                <SuiButton
                    onClick={() => {
                        if (Permissions !== undefined && Permissions.includes('U')) {
                            history.push({
                                pathname: '/' + url.split('/')[1] + '/EditCityRateCard',
                                customCRCData: data,
                            })
                        } else {
                            setcolorCode('error')
                            handleSnackbar('Access Denied (User does not have permission)')
                        }

                    }}
                    color="warning"
                >
                    edit
                </SuiButton>{" "}

            </div>
        );
    });

    if (Ratecarddata === null) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <Card sx={{ m: 1, p: 2 }}>
                    <Grid container>
                        <Grid item md={9} >
                            <SuiTypography sx={{ fontSize: '1.5rem', ml: 2 }}  >Rate Card Details</SuiTypography>{" "}
                        </Grid >
                        <Grid item md={3} >
                            {Permissions !== undefined && Permissions.includes('U') ? <Link to={`/${url.split('/')[1]}/AddCityRateCard`} >
                                <SuiButton sx={{ float: 'right' }}>
                                    Add City Rate Card
                                </SuiButton>
                            </Link> : ''}

                        </Grid >
                    </Grid>
                    <DataTable table={Ratecarddata}
                        functioncallcount={() => { }}
                        LoadData={false}

                        hidecolumnsdata={[
                            "base_fare",
                            "lasg_fee",
                            "time_multiplier",
                            "share_percentage_2",
                            "0-4",
                            "4-8",
                            "8-12",
                            "12-16",
                            "16>",
                        ]}
                        datacolumns={[
                            {
                                Header: "city name",
                                accessor: "city_name",
                            },
                            {
                                Header: "ride Type",
                                accessor: "type",
                            },
                            {
                                Header: "base fare",
                                accessor: "base_fare",
                            },
                            {
                                Header: "GOVT. FEE",
                                accessor: "lasg_fee",
                            },

                            {
                                Header: "Minimum fare",
                                accessor: "min_fare",
                            },
                            {
                                Header: "Convenience multiplier",
                                accessor: "convenience_multiplier",
                            },
                            {
                                Header: "peak time charge",
                                accessor: "peak_multiplier",
                            },
                            {
                                Header: "time multiplier",
                                accessor: "time_multiplier",
                            },

                            {
                                Header: "Share Percentage for 2 seats",
                                accessor: "share_percentage_2",
                            },

                            {
                                Header: "Taxes",
                                accessor: "taxes",
                            },
                            {
                                Header: "distance_multiplier 0-4 KM",
                                accessor: "0-4",
                            }, {
                                Header: "distance_multiplier 4-8 KM",
                                accessor: "4-8",
                            }, {
                                Header: "distance_multiplier 8-12 KM",
                                accessor: "8-12",
                            },
                            {
                                Header: "distance_multiplier 12-16 KM",
                                accessor: "12-16",
                            },
                            {
                                Header: "distance_multiplier 16> KM",
                                accessor: "16>",
                            },
                            {
                                Header: "Toll Charges",
                                accessor: "toll_charges",
                            },
                            {
                                Header: "action",
                                accessor: "action",
                            },
                        ]}
                        datalength={Ratecarddata.TotalCount}
                        canSearch />
                </Card>
                <CustomizedSnackbars color={colorCode} message={snackmessage} open={snakopen} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default CityRateCards;
