import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import DownloadIcon from '@mui/icons-material/Download';
import Papa from "papaparse";

import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';

import { addReferalData } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from "CustomComponents/Authcontext";

function Mobile() {
    const dd = useSelector((state) => state.SalesReferral);

    const dispatch = useDispatch();
    const [sipnnershow, setspinnershow] = useState(false);
    const [nexttoggle, setnexttoggle] = useState(true);
    const {currentUser}=useAuth();

    const [nextkey, setnextkey] = useState("");
    const [messagttouser, setmessagttouser] = useState("Loading Data...");
    const [isDisabled, setisDisabled] = useState();
    const [referralName, setreferralName] = useState('')
    const [referralNumber, setreferralNumber] = useState('')
    const [referralCode, setreferralCode] = useState()
    const [referralCodeToken, setreferralCodeToken] = useState()
    //model handlers
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    useEffect(() => {
        if (dd.arr.length === 0) {
            callReferrealdata("");
        }
    }, [0]);
    async function callreferralRepeat(e) {
        if (e >= 10) {
            var da = "";
            if (dd.nextKey !== "") {
                da = dd.nextKey;
            } else {
                da = nextkey;
            }
            if (da !== null && nexttoggle) {
                da = await callReferrealdata(da, e);
            }
        }
      
      }
    function callReferrealdata(nexttokenparam,limit=10) {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(
            process.env.REACT_APP_BASE_URL+"/sales_referral?start=" +
            nexttokenparam+"&limit="+limit,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.data.length !== 0) {
                    result.data.map((doc) => {
                        doc.added_on =
                            new Date(doc.added_on * 1000).toLocaleString(
                                "en-US",
                                {
                                    day: "numeric",
                                    month: "numeric",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                }
                            )
                    });
                    if (result.start !== null) {

                        setnextkey(result.start);
                        setmessagttouser("");
                        dispatch(addReferalData(result.data, result.start));
                        return result.start;
                    }
                } else {
                    setnexttoggle(false)
                    alert('No More Data To Load');
                    setmessagttouser('No More Data To Load');

                    setTimeout(() => {
                        setmessagttouser('');

                    }, 2500);
                    return result.start === null ? false : undefined;
                }
            })
            .catch((error) => console.log("error", error));
    }
    function referralCodeGenerator() {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(
            process.env.REACT_APP_BASE_URL+"/sales_referral?type=code",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === true) {
                    setreferralCodeToken(result.referral_code)
                    setreferralCode(JSON.parse(atob(result.referral_code.split('.')[1])).code)
                    handleOpen()
                }

            })
            .catch((error) => console.log("error", error));
    }


    function handleSubmit1(e) {
if(referralName!==''&&referralNumber!==''){        setspinnershow(true);

        document.getElementById("submit").setAttribute("disabled", "disabled");

        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            code: referralCodeToken,
            name: referralName,
            phone_number: referralNumber,
        });
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        fetch(
            process.env.REACT_APP_BASE_URL+"/sales_referral",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === true) {
                    alert("Referral Created Successfully");
                    document.getElementById("submit").removeAttribute("disabled");
                    setreferralCode("");
                    setreferralName("");
                    setreferralNumber("");
                    setspinnershow(false);
                } else {
                    alert("Error", result.message);
                    setspinnershow(false);
                    setmessagttouser(result.message);
                    document.getElementById("submit").removeAttribute("disabled");
                }
            })
            .catch((error) => console.log("error", error));
        }else{
            alert('Please fill the form')
        }
    }


   
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <Card >
                    <Grid container spacing={2} sx={{ p: 3 }}>

                        <Grid item xs={6} sm={4} md={4} xl={4}>
                            <SuiTypography>
                                Referral  Details
                            </SuiTypography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={4} xl={4}/>

                        <Grid item xs={6} sm={4} md={4} xl={4}>
                            <SuiBox>
                                <SuiButton sx={{ float:'right' }} variant="gradient" onClick={() => {
                                    referralCodeGenerator()
                                }}
                                    color="info"  >
Add Referral
                                </SuiButton>
                            </SuiBox>
                        </Grid>

                    </Grid>
                    {dd.arr.length !== 0 ? (

                        <DataTable table={dd.arr}
                            functioncallcount={callreferralRepeat}

                            hidecolumnsdata={[]}
                            datacolumns={[
                                {
                                    Header: "name",
                                    accessor: "name",
                                },


                                {
                                    Header: "phone_number",
                                    accessor: "phone_number",
                                },
                                {
                                    Header: "added_on",
                                    accessor: "added_on",
                                },
                                {
                                    Header: "referral",
                                    accessor: "referral",
                                },
                                {
                                    Header: "referred count",
                                    accessor: "referred_count",
                                },

                            ]}
                             canSearch />) : null}

                </Card>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition

                >
                    <Fade in={open}>
                        <Box sx={
                            {
                                border: 0,
                                borderRadius: '10px',
                                boxShadow: '0 0 15px rgb(0 0 0 / 15%), 0 0 1px 1px rgb(0 0 0 / 10%)',
                                position: 'absolute',
                                top: '40%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '60vw',
                                pointerEvents: 'auto',
                                backgroundColor: '#fff',
                                backgroundClip: 'padding-box',
                                outline: 0,
                                padding: '20px 50px'
                            }
                        } >
                            <SuiTypography sx={{ textAlign: 'center',float:'right' }}>
                                Add Referral
                            </SuiTypography>
                            <Divider />

                                <Grid container spacing={2}>
                                    <Grid item md={12} xl={12}>

                                        <SuiInput
                                            required
                                            disabled
                                            type="text"
                                            value={referralCode || ''}
                                            onChange={(e) => {
                                                setreferralCode(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={6} xl={6}>
                                        Name
                                        <SuiInput
                                            required
                                            maxLength="14"
                                            placeholder="Enter Name"
                                            type="text"
                                            value={referralName}
                                            onChange={(e) => {
                                                setreferralName(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={6} xl={6}>
                                        Phone Number
                                        <SuiInput
                                            required
                                            placeholder="Contact Number... +234 XXX XXX XXXX"
                                            type="text"
                                            value={referralNumber}
                                            onChange={(e) => {
                                                setreferralNumber(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} xl={12} />
                                    <Grid item xs={6} sm={6} md={6} xl={6}>
                                        <SuiButton
                                            disabled={isDisabled}
                                            id="multiple"
                                            type="submit"
                                            onClick={() => {
                                                handleSubmit1()
                                            }}
                                            
                                        >
                                            Submit
                                        </SuiButton>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} xl={6}>
                                        <SuiButton
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                                        >
                                            Close
                                        </SuiButton>
                                    </Grid>
                                </Grid>
                        </Box>
                    </Fade>
                </Modal>
            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default Mobile;
