import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import Box from '@mui/material/Box';

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Modal from '@mui/material/Modal';
import Grid from "@mui/material/Grid";
import CustomizedSnackbars from "../Alert/Alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'

import {
  addall_ridersData,
} from "../../CustomComponents/dataStore/actions";
import LinearProgress from '@mui/material/LinearProgress';
import defaultprofile from "../../assets/img/defaultprofile.png";
import { useAuth } from "../../CustomComponents/Authcontext";
import BlacklistUser from "../Components/CustomModel/BlacklistUserModel";
function Riders() {
  const dd = useSelector((state) => state.All_Riders);
  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState(dd.nextKey || "");
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
  const [colorCode, setcolorCode] = useState('error')

  //snack bar
  const [snakopen, setsnakopen] = useState(false)
  const [snackmessage, setsnackmessage] = useState(null);

  const { currentUser } = useAuth();
  //dataexchange
  const [arrayData, setarrayData] = useState(dd.arr);
  const [isDisabledsearch, setisDisabledsearch] = useState(false);
  const [issetCC, setissetCC] = useState(false)

  const handleSnackbar = (messages) => {
    setsnakopen(true)
    setsnackmessage(messages)
  };
  //search states
  const [searchparam, setsearchparam] = useState("");
  // useeffect
  useEffect(() => {
    if (dd.arr.length === 0) {
      calldataall_riders("",100);
    } else {
      setarrayData(dd.arr);
    }
  }, [dd]);

  ///function call search
  async function handleSubmitSearch(event) {
    if (searchparam && searchparam !== null && searchparam !== '') {
      setisDisabledsearch(true);
      setspinnershow(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + currentUser.accessToken
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        process.env.REACT_APP_BASE_URL + `/search_users?user=rider&search=` +
        encodeURIComponent(searchparam),
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            result.data.full_name = result.data.first_name + " " + result.data.last_name;
            result.data.joined_on = new Date(result.data.joined_on * 1000).toLocaleString("en-US", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
            if (result.data.blacklisted !== undefined && result.data.blacklisted === true) {
              result.data.action = (
                <div >
                  <p>User Blacklistd due to</p>
                  <p>{result.data.reason}</p>
                </div>
              );
            } else {
              result.data.action = (
                <div >
                  <BlacklistUser title={'Black List User'} type='blacklist' data={result.data} setcolor={setcolorCode} setmessage={handleSnackbar} />
                </div>
              );
            }

            setarrayData([result.data]);
            setspinnershow(false);
            setnexttoggle(true);
            setissetCC(true);

          } else {
            setisDisabledsearch(false);
            setsearchparam('')
            setspinnershow(false);
            handleSnackbar(result.message);
          }
        })
        .catch((error) => {
          setisDisabledsearch(false);
          setsearchparam('')
          handleSnackbar("Unable to get data....ERROR");
          console.log("error", error);
        });
    } else {
      handleSnackbar('Please fill the search form')
    }
  }

  async function calldataall_ridersRepeat(e) {
    if (e >= 10) {
      var da = "";
      if (dd.nextKey !== "") {
        da = dd.nextKey;
      } else {
        da = nextkey;
      }
      if (da !== null && nexttoggle) {
          da = await calldataall_riders(da, e);
       
      }
    }

  }
  async function calldataall_riders(nexttokenparam, limit = 100) {
    setnexttoggle(false);
    setspinnershow(true);
    setisDisabledsearch(false);

    if (nexttokenparam === undefined) {
      nexttokenparam = dd.nextKey || nextkey;
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + currentUser.accessToken
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return await fetch(
      process.env.REACT_APP_BASE_URL + "/all_riders?start=" +
      nexttokenparam + '&limit=' + limit,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          setnextkey(result.start);
          //
          result.data.map((dat) => {
            dat.full_name = dat.first_name + " " + dat.last_name;
            dat.joined_on = new Date(dat.joined_on * 1000).toLocaleString("en-US", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
            if (dat.blacklisted !== undefined && dat.blacklisted === true) {
              dat.action = (
                <div >
                  <p>User Blacklistd due to</p>
                  <p>{dat.reason}</p>
                </div>
              );
            } else {
              dat.action = (
                <div >
                  <BlacklistUser title={'Black List User'} type='blacklist' data={dat} setcolor={setcolorCode} setmessage={handleSnackbar} />
                </div>
              );
            }

          });
          dispatch(addall_ridersData(result.data, result.start, result.count));
          setnexttoggle(true);
          setspinnershow(false);
          return result.start;
        } else {
          alert(result.message)
          setnexttoggle(false);
          setmessagttouser("Unable to get data....ERROR : " + result.message);
          console.log("error", result.message);
          setspinnershow(false);

        }
      })
      .catch((error) => {
        setnexttoggle(false);
        setspinnershow(false);
        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });

  }
  //blacklist functions


  if (dd.arr.length === 0) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox pt={2} pb={3}>
          <Card>
            <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
          </Card>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={3} pb={3}>
        <Card >
          {sipnnershow && (
            <Box sx={{ width: '100%', m: 0, p: 0 }}>
              <LinearProgress color="success" sx={{ width: '100%' }} />
            </Box>
          )}
          <Grid container spacing={2} sx={{ p: 2, pt: 3 }}>

            <Grid item xs={12} sm={5} md={5} xl={5}>
              <SuiBox sx={{ display: 'flex', gap: 2 }}>
                <SuiInput
                  value={searchparam}
                  onChange={(e) => {
                    setsearchparam(e.target.value);
                  }}
                  inputProps={{ type: "text" }} placeholder='Enter Email Id or Contact number' />
                {!isDisabledsearch ? (
                  <SuiButton
                    variant="gradient" onClick={() => {
                      handleSubmitSearch()
                    }} color="info"  >
                    <FontAwesomeIcon icon={faSearch} />
                  </SuiButton>
                ) : null}

                {isDisabledsearch ? (
                  <SuiButton
                    variant="gradient" onClick={() => {
                      setsearchparam("");
                      setisDisabledsearch(false);
                      setarrayData(dd.arr);
                      setspinnershow(false);

                    }} color="info"  >
                    Clear
                  </SuiButton>

                ) : null}
              </SuiBox>
            </Grid>
          </Grid>
          <DataTable table={arrayData}
            hidecolumnsdata={['joined_on', 'action']}
            showTotalEntries={true}
            functioncallcount={calldataall_ridersRepeat}
            datacolumns={[
              {
                Header: "Rider name",
                accessor: "full_name",
              },
              {
                Header: "gender",
                accessor: "gender",
              },
              {
                Header: "email Id",
                accessor: "email_id",
              },
              {
                Header: "phone number",
                accessor: "phone_number",
              },
              {
                Header: "joined on",
                accessor: "joined_on",
              },
              {
                Header: "DOB",
                accessor: "dob",
              },
              {
                Header: "action",
                accessor: "action",
              },

            ]}
            datalength={dd.TotalCount} canSearch />
        </Card>


        <CustomizedSnackbars color={colorCode} message={snackmessage} open={snakopen} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Riders;
