import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { useAuth } from "CustomComponents/Authcontext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSearch, faCar, faClock } from '@fortawesome/free-solid-svg-icons'
import dataset from "./August_Ibadan_Revenue.json";
import { addCompletedRidesData, addSEARCHCompletedRidesData, CLEANSEARCHCompletedRidesData } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import CustomizedSnackbars from "../Alert/Alert";
import { Button, Divider } from "@mui/material";
import Pdf from "react-to-pdf";
import logo from "../../assets/img/logo.png";
import Table from '@mui/material/Table';
import { async } from "@firebase/util";
const ref = React.createRef();
function CompletedRides() {
    const dd = useSelector((state) => state.Completedrides);
    const userinfo = useSelector((state) => state.Userinfo);
    const dispatch = useDispatch();
    const [nextkey, setnextkey] = useState(dd.nextKey || "");
    const [messagttouser, setmessagttouser] = useState("Loading Data...");
    const [nexttoggle, setnexttoggle] = useState(true);
    const [sipnnershow, setspinnershow] = useState(false);
    const { currentUser } = useAuth();
    //state country and city
    const [issetCC, setissetCC] = useState(false)
    const [selectMonth, setselectMonth] = useState(null);
    const [fromdays, setfromdays] = useState([])
    const [selectedfromdate, setselectedfromdate] = useState(null);
    const [todays, settodays] = useState([]);
    const [selectedtodays, setselectedtodays] = useState(null);

    const montharray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    //admin 
    const [selectCountry, setselectCountry] = useState(null);
    //data date
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);
    const [isDisabled, setisDisabled] = useState(false);
    const [selectCity, setselectCity] = useState(null);
    const [searchparam, setsearchparam] = useState("");
    //dataexchange
    const [arrayData, setarrayData] = useState(dd.arr);
    const [isDisabledsearch, setisDisabledsearch] = useState(false);
    const [isDisabledsearch2, setisDisabledsearch2] = useState(false);
    //download
    const [selectCitydownload, setselectCitydownload] = useState(null);
    const [selectCountrydownload, setselectCountrydownload] = useState(null);

    //model handlers
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    //model handlers
    const [modeldata, setmodeldata] = useState(null)
    const [openpdf, setOpenpdf] = React.useState(false);
    const handleOpenpdf = () => setOpenpdf(true);
    const handleClosepdf = () => {
        setOpenpdf(false)
        setmodeldata(null);

    };
    //snack bar
    const [snakopen, setsnakopen] = useState(false)
    const [snackmessage, setsnackmessage] = useState(null)

    const handleSnackbar = (messages) => {
        setsnakopen(true)
        setsnackmessage(messages)
    };

    const DownloadDataSubmit = (event) => {
        if (fromdate !== null && todate !== null && fromdate !== undefined && todate !== undefined) {
            var todateseconds = new Date((new Date(todate).setHours(23, 59, 59))).getTime() / 1000;
            var fromdateseconds = new Date((new Date(fromdate).setHours(0, 0, 0, 0))).getTime() / 1000;
            var url = process.env.REACT_APP_BASE_URL + `/completed_rides?from=${fromdateseconds}&to=${todateseconds}`;
            if (selectCitydownload !== null) {
                url = url + '&city=' + selectCitydownload
            } else if (selectCountrydownload !== null) {
                url = url + '&country=' + selectCountrydownload
            }
            setisDisabled(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            fetch(
                url,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === true) {
                        fileJson(result.data, `completed_rides${fromdateseconds}-${todateseconds}`, true);
                        setisDisabled(false);

                    } else {
                        setisDisabled(false);
                        handleSnackbar(result.message)
                    }
                })
                .catch((error) => {
                    handleSnackbar('Unable to get data....ERROR')
                    console.log("error", error);
                });
        } else {
            handleSnackbar('Please select date')
        }
    };
    useEffect(() => {
        setOpenpdf(false)
        setmodeldata(null);
        if (dd.arr.length === 0) {
            calldataall_riders("", 100);
        } else {
            if (issetCC) {
                setarrayData(dd.secarr);
            } else {
                setarrayData(dd.arr);
            }
        }
    }, [dd]);
    const fileJson = async (JSONData, ReportTitle, ShowLabel) => {
        var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
        var CSV = "";
        var datasest = [];
        var ddrt = arrData.length
        datasest = arrData.map((data) => {
            data.rider_name = data.rider_lname + " " + data.rider_fname;
            const header = Object.keys(data);
            var rt = data.rate_card;
            var coupon = data.coupon_used;
            var cstate = data.coupon;
            delete data.rate_card;
            var ss = data.drop_str ? data.drop_str : ["Undefined"];
            var dd = data.type;
            data.ride_type = dd;
            data.drop_str = ss[0];
            ss = data.pickup_str ? data.pickup_str : ["Undefined"];
            data.pickup_str = ss[0];

            var con,
                aon,
                bon,
                von = "";

            if (data.accepted_on) {
                aon = new Date(data.accepted_on).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (data.completed_on) {
                con = new Date(data.completed_on * 1000).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (data.booked_on) {
                bon = new Date(data.booked_on).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (data.verified_on) {
                von = new Date(data.verified_on * 1000).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (cstate) {
                data.coupon_name = coupon.id;
                data.coupon_discount = coupon.discount;
            } else {
                data.coupon_name = "";
                data.coupon_discount = "";
            }

            return {
                ARN: data.ARN,
                "Booking Time": bon,
                "Accepted Time": aon,
                "Rider name": data.rider_name,
                "Driver name": data.driver_name,
                "Driver email": data.driver_email,
                "Registration number": data.registration_number,
                "Actual Drop Time": con,
                "Distance Travelled": Number(data.distance).toFixed(2),
                "Base Fare": rt.base_fare,
                "Minimum fare": rt.min_fare_flag ? rt.min_fare : 0,
                "Network charges": rt.network_charges,
                "Distance Fare": rt.distance_fare,
                "Time Fare": rt.time_fare,
                VAT: rt.tax,
                Rounded: rt.rounded,
                "Ride Cost": data.ride_cost,
                Coupon: data.coupon ? '1' : '0',
                "Coupon Name": data.coupon_name,
                "Coupon Discount": rt.discount,
                "Rider Ratings": data.ratings,
                "payment method": data.payment_method,
                "Invoice ID   ": data.invoice,
                "Paystack ID   ": data.pystk_id,
            };
        });
        console.log(ddrt);
        const items = datasest;
        const replacer = (key, value) => (value === null ? " " : value); // specify how you want to handle null values here
        const header = Object.keys(items[0]);
        CSV = [
            header.join(","), // header row first
            ...items.map((row) =>
                header
                    .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                    .join(",")
            ),
        ].join("\r\n");
        if (CSV == "") {
            alert("Invalid data");
            return;
        }
        //Generate a file name
        var fileName = "Report_";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += ReportTitle.replace(/ /g, "_");

        //Initialize file format you want csv or xls
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        await document.body.removeChild(link);
        setisDisabled(false);
    };
    async function calldataall_ridersRepeat(e) {
        if (e >= 10) {
            var da = "";
            if (dd.nextKey !== "") {
                da = dd.nextKey;
            } else {
                da = nextkey;
            }
            if (da !== null && nexttoggle) {
                if (!issetCC) {
                    da = await calldataall_riders(da, e);
                } else {
                    handleSubmitSearchcitycountry(da, e)
                }
            }
        }

    }
    async function calldataall_riders(nexttokenparam, limit = 100) {
        setnexttoggle(false);
        setspinnershow(true);

        if (nexttokenparam === undefined) {
            nexttokenparam = dd.nextKey || nextkey;
        }
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        var resultfetch = {};

        await fetch(
            process.env.REACT_APP_BASE_URL + "/completed_rides?start=" +
            nexttokenparam + "&limit=" + limit,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                resultfetch = result;
            })
            .catch((error) => {
                setnexttoggle(false);
                setmessagttouser("Unable to get data....ERROR");
                console.log("error", error);
            });
        //
        await setnextkey(resultfetch.start);
        if (resultfetch.status) {
            resultfetch.data.map((pro) => {
                if (pro.coupon_used !== undefined) {
                    pro.coupon_name = pro.coupon_used.id;
                }
                pro.rider_fname = pro.rider_fname + " " + pro.rider_lname;

                if (pro.coupon === true) {
                    pro.coupon = "1";
                } else {
                    pro.coupon = "0";
                }
                if (pro.reference_id) {
                    pro.reference_id = pro.reference_id
                } else {
                    pro.reference_id = ''
                }
                if (pro.accepted_on) {
                    pro.accepted_on = new Date(pro.accepted_on).toLocaleString("en-US", {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                    });
                }

                if (pro.completed_on) {
                    pro.completed_on = new Date(pro.completed_on * 1000).toLocaleString("en-US", {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                    });
                }
                if (pro.booked_on) {
                    pro.booked_on = new Date(pro.booked_on).toLocaleString("en-US", {
                        day: "numeric",

                        month: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                    });
                }
                if (pro.verified_on) {
                    pro.verified_on = new Date(pro.verified_on * 1000).toLocaleString("en-US", {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                    });
                }
                pro.active = <Button onClick={() => {
                    setmodeldata(pro)
                    handleOpenpdf()
                }}>Invoice</Button>
            });
            dispatch(addCompletedRidesData(resultfetch.data, resultfetch.start, resultfetch.count));
            setnexttoggle(true);
            setspinnershow(false);
        } else {
            setmessagttouser(resultfetch.message)
            handleSnackbar('No data to load')
        }

        return resultfetch.start;
    }
    async function callrepeatfun(from, too, mon) {
        console.log(from, too);
        var datadd = [];
        for (let i = from; i <= too; i++) {
            await setInterval(() => {

            }, 8000);

            var todateseconds = new Date((new Date(`${mon} ${i} 2022`).setHours(23, 59, 59, 999))).getTime() / 1000;
            var fromdateseconds = new Date((new Date(`${mon} ${i} 2022`).setHours(0, 0, 0, 0))).getTime() / 1000;

            var url = process.env.REACT_APP_BASE_URL + `/completed_rides?from=${fromdateseconds}&to=${todateseconds}`;
            url = url + '&city=' + 'ibadan'
            console.log(fromdateseconds, todateseconds,);

            setisDisabled(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            await fetch(
                url,
                requestOptions
            )
                .then((response) => response.json())
                .then(async (result) => {
                    if (result.status === true) {
                        // datadd = [...datadd, ...result.data];
                        fileJson(result.data, `C_R_${new Date(`${mon} ${i} 2022`).toLocaleString("en-US", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",

                        })}-${new Date(`${mon} ${i} 2022`).toLocaleString("en-US", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",

                        })}`, true);
                        setisDisabled(false);

                    } else {
                        setisDisabled(false);
                        handleSnackbar(result.message)
                    }
                })
                .catch((error) => {
                    handleSnackbar('Unable to get data....ERROR')
                    console.log("error", error);
                });
            await setInterval(() => {

            }, 8000);
        }
        if (datadd.length > 0) {

        } else {
            handleSnackbar('No records found')

        }
    }
    ///function call search
    async function handleSubmitSearch(event) {
        if (searchparam && searchparam !== null && searchparam !== '') {
            setisDisabledsearch(true);
            setspinnershow(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            fetch(
                process.env.REACT_APP_BASE_URL + `/completed_rides?ARN=` +
                searchparam,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    if (result.status === true) {
                        result.data.map((pro) => {
                            if (pro.coupon_used !== undefined) {
                                pro.coupon_name = pro.coupon_used.id;
                            }
                            pro.rider_fname = pro.rider_fname + " " + pro.rider_lname;

                            if (pro.coupon === true) {
                                pro.coupon = "1";
                            } else {
                                pro.coupon = "0";
                            }
                            if (pro.reference_id) {
                                pro.reference_id = pro.reference_id
                            } else {
                                pro.reference_id = ''
                            }
                            if (pro.accepted_on) {
                                pro.accepted_on = new Date(pro.accepted_on).toLocaleString("en-US", {
                                    day: "numeric",
                                    month: "numeric",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                });
                            }

                            if (pro.completed_on) {
                                pro.completed_on = new Date(pro.completed_on * 1000).toLocaleString("en-US", {
                                    day: "numeric",
                                    month: "numeric",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                });
                            }
                            if (pro.booked_on) {
                                pro.booked_on = new Date(pro.booked_on).toLocaleString("en-US", {
                                    day: "numeric",

                                    month: "numeric",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                });
                            }
                            if (pro.verified_on) {
                                pro.verified_on = new Date(pro.verified_on * 1000).toLocaleString("en-US", {
                                    day: "numeric",
                                    month: "numeric",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                });
                            }
                            pro.active = <Button onClick={() => {
                                setmodeldata(pro)
                                handleOpenpdf()
                            }}>Invoice</Button>
                        });
                        setarrayData(result.data);
                        setspinnershow(false);

                    } else {
                        setisDisabledsearch(false);
                        setsearchparam('')
                        setspinnershow(false);
                        handleSnackbar(result.message);
                    }
                })
                .catch((error) => {
                    setisDisabledsearch(false);
                    setsearchparam('')
                    handleSnackbar("Unable to get data....ERROR");
                    console.log("error", error);
                });
        } else {
            handleSnackbar('Please provide valid data')
        }
    }
    async function handleSubmitSearchcitycountry(led, limit = 10) {

        if (selectCountry !== null || selectCity !== null) {
            setnexttoggle(false);
            setspinnershow(true);
            setisDisabledsearch2(false);
            setisDisabledsearch2(true);
            setspinnershow(true);
            var url = process.env.REACT_APP_BASE_URL + `/completed_rides?limit=${limit}`;
            if (selectCity !== null) {
                url = url + '&city=' + selectCity
            } else if (selectCountry !== null) {
                url = url + '&country=' + selectCountry
            }
            if (dd.secnextKey !== '') {
                url = url + "&start=" + dd.secnextKey
            } else {
                url = url + "&start="

            }
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            fetch(
                url,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    // console.log(result);
                    if (result.status === true) {
                        result.data.map((pro) => {
                            if (pro.coupon_used !== undefined) {
                                pro.coupon_name = pro.coupon_used.id;
                            }
                            pro.rider_fname = pro.rider_fname + " " + pro.rider_lname;

                            if (pro.coupon === true) {
                                pro.coupon = "1";
                            } else {
                                pro.coupon = "0";
                            }

                            if (pro.accepted_on) {
                                pro.accepted_on = new Date(pro.accepted_on).toLocaleString("en-US", {
                                    day: "numeric",
                                    month: "numeric",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                });
                            }
                            if (pro.booked_on) {
                                pro.booked_on = new Date(pro.booked_on).toLocaleString("en-US", {
                                    day: "numeric",

                                    month: "numeric",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                });
                            }
                            if (pro.verified_on) {
                                pro.verified_on = new Date(pro.verified_on).toLocaleString("en-US", {
                                    day: "numeric",

                                    month: "numeric",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                });
                            }
                            pro.active = <Button onClick={() => {
                                setmodeldata(pro)
                                handleOpenpdf()
                            }}>Invoice</Button>
                        });
                        dispatch(addSEARCHCompletedRidesData(result.data, result.start));
                        setnexttoggle(true);
                        setspinnershow(false);
                        setissetCC(true);
                        setisDisabledsearch2(false);
                        return result.start;
                    } else {
                        setisDisabledsearch2(false);
                        setsearchparam('')
                        setspinnershow(false);
                        handleSnackbar(result.message);
                    }
                })
                .catch((error) => {
                    setisDisabledsearch2(false);
                    setspinnershow(false);
                    setsearchparam('')
                    handleSnackbar("Unable to get data....ERROR" + error);
                    console.log("error", error);
                });
        } else {
            handleSnackbar('Please provide valid data')
        }
    }

    function funcall(params) {
        var temp = dataset;
        var res = [];
        temp.map(data => {
            const temp2 = data.rides;
            delete data.rides
            var dateset = {}
            for (let i = 1; i <= 31; i++) {
                dateset['aug_' + i] = 0;
                dateset['aug_rev_' + i] = 0;

            }
            temp2.map(ddd => {
                var datetemp = new Date(ddd.time * 1000).getDate()
                dateset['aug_' + datetemp] = dateset['aug_' + datetemp] + 1;
                dateset['aug_rev_' + datetemp] = dateset['aug_rev_' + datetemp] + ddd.cost;
            })


            res.push({ ...data, ...dateset })
            console.log({ ...data, ...dateset });
        })
        var CSV = "";
        var ReportTitle = 'Driver report'
        console.log(res);
        const items = res;
        const replacer = (key, value) => (value === null ? " " : value); // specify how you want to handle null values here
        const header = Object.keys(items[0]);
        CSV = [
            header.join(","), // header row first
            ...items.map((row) =>
                header
                    .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                    .join(",")
            ),
        ].join("\r\n");
        if (CSV == "") {
            alert("Invalid data");
            return;
        }
        //Generate a file name
        var fileName = "Report_";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += ReportTitle.replace(/ /g, "_");

        //Initialize file format you want csv or xls
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";
        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    if (dd.arr.length === 0) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <SuiButton
                    sx={{ ml: 3 }}
                    variant="gradient" onClick={() => {
                        funcall();
                    }} color="info"  >
                    Download funcall
                </SuiButton>
                <Card >
                    {sipnnershow && (
                        <Box sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{ width: '100%' }} />
                        </Box>
                    )}
                    <Box >
                        <Grid container spacing={2} sx={{ p: 3 }}>
                            <Grid item xs={12} sm={12} md={3} xl={3}>
                                <SuiSelect
                                    required
                                    placeholder={'Select Month'}
                                    value={selectMonth !== null ? { value: selectMonth, label: selectMonth } : null}
                                    options={montharray.map(d => { return { value: d, label: d } })}
                                    onChange={(e) => {
                                        setfromdays([])
                                        settodays([])
                                        var numMunth = montharray.indexOf(e.value)
                                        var temp = new Date(e.value + ' 1 2022');
                                        let days = [];
                                        console.log(temp.getMonth(), e.value);
                                        while (temp.getMonth() === numMunth) {
                                            days.push(temp.getDate());
                                            temp.setDate(temp.getDate() + 1);
                                        }
                                        setselectMonth(e.value);
                                        setfromdays(days)
                                        setselectedfromdate(null)
                                        setselectedtodays(null)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} xl={3}>
                                <SuiSelect
                                    required
                                    placeholder={'Select From Date'}
                                    value={selectedfromdate !== null ? { value: selectedfromdate, label: selectedfromdate } : null}
                                    options={fromdays.map(d => { return { value: d, label: d } })}
                                    onChange={(e) => {
                                        setselectedfromdate(e.value);
                                        var temp = []
                                        for (let i = fromdays.indexOf(e.value); i < fromdays.length; i++) {
                                            temp.push(fromdays[i])
                                        }
                                        settodays(temp)
                                        setselectedtodays(null)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} xl={3}>
                                <SuiSelect
                                    required
                                    placeholder={'Select To Date'}
                                    value={selectedtodays !== null ? { value: selectedtodays, label: selectedtodays } : null}
                                    options={todays.map(d => { return { value: d, label: d } })}
                                    onChange={(e) => {
                                        setselectedtodays(e.value);
                                        // settodays()
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} xl={6} >
                            <SuiButton
                                sx={{ ml: 3 }}
                                variant="gradient" onClick={() => {
                                    if (selectedfromdate !== null && selectedtodays !== null) {
                                        callrepeatfun(selectedfromdate, selectedtodays, selectMonth);
                                    } else {
                                        handleSnackbar('from and to date cannot be null')
                                    }
                                }} color="info"  >
                                Download
                            </SuiButton>
                        </Grid>
                    </Box>
                    <Grid container spacing={2} sx={{ p: 3 }}>

                        {/* <SuiButton
                            variant="gradient" onClick={() => {
                                callrepeatfun();
                            }} color="info"  >
                            demo
                        </SuiButton> */}
                        <Grid item xs={12} sm={4} md={4} xl={4}>
                            <SuiBox sx={{ display: 'flex', gap: 2 }}>
                                <SuiInput
                                    value={searchparam}

                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        setsearchparam(e.target.value);
                                    }}
                                    inputProps={{ type: "text" }} placeholder='Enter ARN number' />
                                {!isDisabledsearch ? (
                                    <SuiButton
                                        variant="gradient" onClick={() => {
                                            handleSubmitSearch()
                                        }} color="info"  >
                                        <FontAwesomeIcon icon={faSearch} />
                                    </SuiButton>
                                ) : null}

                                {arrayData.length === 1 ? (
                                    <SuiButton
                                        variant="gradient" onClick={() => {
                                            setsearchparam("");
                                            setisDisabledsearch(false);
                                            setarrayData(dd.arr);
                                            setspinnershow(false);

                                        }} color="info"  >
                                        Clear
                                    </SuiButton>

                                ) : null}
                            </SuiBox>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={6}>
                            <SuiBox sx={{ display: 'flex', gap: 2, width: '100%' }}>
                                {userinfo.countries.length !== 0 ? (
                                    <SuiSelect
                                        widths={'15vw'}
                                        required
                                        placeholder={'Query by country'}
                                        value={selectCountry !== null ? { value: selectCountry, label: selectCountry } : null}
                                        options={userinfo.countries.map((data2) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            setselectCountry(e.value);
                                            dispatch(CLEANSEARCHCompletedRidesData());
                                            setselectCity(null)
                                        }}
                                    />
                                ) : null}
                                {selectCountry !== null ? userinfo.isadmin ? (
                                    <SuiSelect
                                        required
                                        widths={'16vw'}
                                        placeholder={'Query by city'}
                                        value={selectCity !== null ? { value: selectCity, label: selectCity } : null}
                                        options={userinfo.allcities[selectCountry].map((data2, i) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            setselectCity(e.value);
                                            dispatch(CLEANSEARCHCompletedRidesData());

                                        }}
                                    />
                                ) : (
                                    <SuiSelect
                                        required
                                        widths={'16vw'}
                                        placeholder={'Query by city'}
                                        value={selectCity !== null ? { value: selectCity, label: selectCity } : null}
                                        options={userinfo.allcities.map((data2, i) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            setselectCity(e.value);
                                            dispatch(CLEANSEARCHCompletedRidesData());

                                        }}
                                    />
                                ) : null}

                                <SuiButton
                                    isDisabled={isDisabledsearch2}
                                    variant="gradient" onClick={() => {
                                        handleSubmitSearchcitycountry()
                                    }} color="info"  >
                                    <FontAwesomeIcon icon={faSearch} />
                                </SuiButton>

                                {issetCC ? (
                                    <SuiButton
                                        variant="gradient" onClick={() => {
                                            setselectCountry(null);
                                            setselectCity(null);
                                            setisDisabledsearch2(false);
                                            setissetCC(false)
                                            dispatch(CLEANSEARCHCompletedRidesData());
                                            setarrayData(dd.arr);
                                            setspinnershow(false);

                                        }} color="info"  >
                                        Clear
                                    </SuiButton>

                                ) : null}
                            </SuiBox>
                        </Grid>


                        <Grid item xs={12} sm={12} md={2} xl={2}>
                            <SuiBox>
                                <SuiButton sx={{ float: 'right' }} variant="gradient" onClick={() => {
                                    handleOpen()
                                }} color="info"  >
                                    <FontAwesomeIcon icon={faDownload} />
                                </SuiButton>
                            </SuiBox>
                        </Grid>

                    </Grid>
                    <DataTable
                        table={arrayData}
                        hidecolumnsdata={[
                            "coupon",
                            "verified_on",
                            "completed_on",
                            "type",
                            "required_seats",
                            "couxpon",
                            "coupon",
                            "rate_card.tax",
                            "ride_cost",
                            "africar_net_gain",
                            "driver_commission",
                            "africar_gain",
                            "vendoxr",
                            "ratings",
                            "payment_method",
                            "rate_card.base_fare",
                            "rate_card.distance_fare",
                            "rate_card.time_fare",
                            'rate_card.rounded',
                            "distance_str",
                            "city", "coupon_name", "payment_id"
                        ]}
                        functioncallcount={calldataall_ridersRepeat}
                        datacolumns={[
                            {
                                Header: "ARN number   ",
                                accessor: "ARN",
                            },
                            {
                                Header: "Booking time",
                                accessor: "booked_on",
                            },
                            {
                                Header: "Accepted Time",
                                accessor: "accepted_on",
                            },
                            {
                                Header: "rider name",
                                accessor: "rider_fname",
                            },
                            {
                                Header: "Driver name",
                                accessor: "driver_name",
                            },
                            {
                                Header: "registration number",
                                accessor: "registration_number",
                            },
                            {
                                Header: "pickup location",
                                accessor: "pickup_str",
                                tooltip: true

                            },
                            {
                                Header: "drop location",
                                accessor: "drop_str",
                                tooltip: true

                            },
                            {
                                Header: "Type of Booking",
                                accessor: "type",
                            },
                            {
                                Header: "Seats-1/2",
                                accessor: "required_seats",
                            },

                            {
                                Header: "Actual Pickup Time",
                                accessor: "verified_on",
                            },
                            {
                                Header: "Actual Drop Time",
                                accessor: "completed_on",
                            },
                            {
                                Header: "Total Distance Travelled",
                                accessor: "distance_str",
                            },
                            {
                                Header: "payment method",
                                accessor: "payment_method",
                            },
                            {
                                Header: "Paystack   ID   ",
                                accessor: "pystk_id",
                                tooltip: true

                            },
                            {
                                Header: "base fare",
                                accessor: "rate_card.base_fare",
                            },
                            {
                                Header: "Distance Fare",
                                accessor: "rate_card.distance_fare",
                            },
                            {
                                Header: "Time Fare",
                                accessor: "rate_card.time_fare",
                            },

                            {
                                Header: "VAT",
                                accessor: "rate_card.tax",
                            },

                            {
                                Header: "Total Fare",
                                accessor: "ride_cost",
                            },

                            {
                                Header: "coupon used",
                                accessor: "coupon",
                            },
                            {
                                Header: "Coupon Name",
                                accessor: "coupon_name",
                            },
                            // {
                            //     Header: "Refund To Wallet",
                            //     accessor: "rate_card.rounded",
                            // },
                            {
                                Header: "Rider Ratings",
                                accessor: "vendoxr",
                            },

                            {
                                Header: "Driver Ratings",
                                accessor: "ratings",
                            },
                            {
                                Header: "City",
                                accessor: "city",
                            },
                            {
                                Header: "invoice",
                                accessor: "active",
                            },
                        ]}
                        datalength={dd.TotalCount} canSearch />
                </Card>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                >
                    <Fade in={open}>
                        <Box sx={
                            {
                                border: 0,
                                borderRadius: '10px',
                                boxShadow: '0 0 15px rgb(0 0 0 / 15%), 0 0 1px 1px rgb(0 0 0 / 10%)',
                                position: 'absolute',
                                top: '40%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '50vw',
                                pointerEvents: 'auto',
                                backgroundColor: '#fff',
                                backgroundClip: 'padding-box',
                                outline: 0,
                                padding: '20px 50px'
                            }
                        } >
                            <Typography style={{
                                borderBottom: '1px solid #ddd',
                                padding: '20px',
                                textAlign: 'center',
                                display: 'block!important'
                            }}>
                                Revenue Rides  data

                            </Typography>
                            <p style={{ textAlign: 'center' }}>
                                Please Select the Ride time line. Ride Data will be Download in CSV File...
                            </p>
                            {isDisabled && (<>
                                <Typography sx={{ mt: 2 }}>
                                    Processing data... <CircularProgress color="primary" />
                                </Typography></>
                            )}
                            <Grid container spacing={2} sx={{ p: 3 }}>
                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                    {userinfo.countries.length !== 0 ? (
                                        <SuiSelect
                                            required
                                            placeholder={'Query by country'}
                                            value={selectCountrydownload !== null ? { value: selectCountrydownload, label: selectCountrydownload } : null}
                                            options={userinfo.countries.map((data2) => ({ value: data2, label: data2 }))}
                                            onChange={(e) => {
                                                setselectCountrydownload(e.value);
                                                setselectCitydownload(null)
                                            }}
                                        />
                                    ) : null}
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                    {selectCountrydownload !== null && userinfo.isadmin && userinfo.allcities[selectCountrydownload].length !== 0 ? (
                                        <SuiSelect
                                            required
                                            placeholder={'Query by city'}
                                            value={selectCitydownload !== null ? { value: selectCitydownload, label: selectCitydownload } : null}
                                            options={userinfo.allcities[selectCountrydownload].map((data2, i) => ({ value: data2, label: data2 !== '' ? data2 : 'All city' }))}
                                            onChange={(e) => {
                                                setselectCitydownload(e.value);

                                            }}
                                        />
                                    ) : null}
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                    <SuiInput
                                        value={fromdate}
                                        onChange={(e) => {
                                            setfromdate(e.target.value);
                                        }}
                                        inputProps={{ type: "datetime-local" }} placeholder='' />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                    <SuiInput
                                        value={todate}
                                        onChange={(e) => {
                                            settodate(e.target.value);
                                        }}
                                        inputProps={{ type: "datetime-local" }} placeholder='' />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} xl={6}>

                                    <SuiButton sx={{ float: 'left' }} variant="gradient" onClick={() => {
                                        setselectCountrydownload(null)
                                        setselectCitydownload(null)
                                        settodate(null)
                                        setfromdate(null);
                                        handleClose()
                                    }} color="info"  >
                                        close
                                    </SuiButton>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} xl={6}>

                                    <SuiButton sx={{ float: 'right' }}
                                        disabled={isDisabled}
                                        variant="gradient" onClick={() => {
                                            DownloadDataSubmit();
                                        }} color="info"  >
                                        Download CSV File
                                    </SuiButton>
                                </Grid>




                            </Grid>

                        </Box>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openpdf}
                    onClose={handleClosepdf}
                    closeAfterTransition
                ><Box>
                        {modeldata !== null ? <Box sx={
                            {
                                border: 0,
                                borderRadius: '10px',
                                position: 'absolute',
                                top: '40%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '70vw',
                                height: '79vh',
                                pointerEvents: 'auto',
                                overflow: 'auto',
                                backgroundColor: '#fff',
                                backgroundClip: 'padding-box',
                                outline: 0,
                                padding: '20px 50px'
                            }
                        } >
                            <div className="Appasd">
                                <Pdf targetRef={ref} filename={`Invoice_${modeldata.ARN}.pdf`}>
                                    {({ toPdf }) => <SuiButton variant="gradient" color="info" onClick={toPdf}>Download Pdf</SuiButton>}
                                </Pdf>
                                <div style={{ marginBottom: 10 }}></div>
                                <div ref={ref} style={{
                                    width: '210mm',
                                    height: '297mm'
                                }}>
                                    <Grid container sx={{}} spacing={0}>
                                        <Grid sx={{ mb: 1 }} item xs={12}><img src={logo} style={{
                                            backgroundColor: 'black',
                                            height: '100px',
                                            display: 'block',
                                            width: 'auto',
                                            marginTop: '20px',
                                            marginRight: 'auto',
                                            marginLeft: 'auto'
                                        }}></img></Grid>
                                        <Grid item xs={12} sx={{ mx: 5 }}>
                                            <SuiTypography variant='h3' sx={{ textAlign: 'center' }}>Invoice</SuiTypography>
                                            ARN : {modeldata.ARN}
                                            <Typography sx={{ fontWeight: '600' }}>Rider Details :</Typography>
                                            <Typography sx={{
                                                my: 1, fontSize: '1.10rem',
                                                marginLeft: '10px'
                                            }}> Rider name: {modeldata.rider_fname} {modeldata.rider_lname}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ mx: 5, mt: 0 }}>
                                            <Typography sx={{ fontWeight: '600' }}>Ride Details :</Typography>
                                            <Typography sx={{
                                                my: 1, fontSize: '1.10rem',
                                                marginLeft: '10px'
                                            }}>
                                                Vehicle
                                                : {modeldata.brand} . {modeldata.model} . {modeldata.vehicle_color}</Typography>
                                            <Typography sx={{
                                                my: 1, fontSize: '1.10rem',
                                                marginLeft: '10px'
                                            }}>
                                                Distance
                                                : {modeldata.distance_str}</Typography>
                                            <Typography sx={{
                                                my: 1, fontSize: '1.10rem',
                                                marginLeft: '10px'
                                            }}>
                                                Time
                                                :  {modeldata.duration_str}</Typography>

                                            <Divider sx={{ margin: '5px' }}></Divider>

                                            <Table sx={{
                                                my: 1, fontSize: '1.10rem',
                                                marginLeft: '10px'
                                            }}>
                                                <tr >
                                                    <td style={{ width: '20%' }}>Pickup Location</td>
                                                    <td >{modeldata.pickup_str[0]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '20%' }}>Drop Location</td>
                                                    <td>{modeldata.drop_str[0]}</td>

                                                </tr>

                                            </Table>
                                            <Divider sx={{ margin: '5px' }}></Divider>

                                        </Grid>
                                        <Grid item xs={12} sx={{ mx: 5, mt: 0 }}>

                                            <Typography sx={{ fontWeight: '600' }}>Bill Details :</Typography>
                                            <Table sx={{
                                                my: 1, fontSize: '1.10rem',
                                                marginLeft: '10px'
                                            }}>                                                <tr style={{
                                                my: 1, fontSize: '1.10rem',
                                                marginLeft: '10px'
                                            }}>
                                                    <td style={{ width: '50%' }}>Base Fare</td>
                                                    <td >{modeldata.symbol}{modeldata.rate_card.base_fare}</td>
                                                </tr>

                                                <tr>
                                                    <td>Distance Fare</td>
                                                    <td>{modeldata.symbol}{modeldata.rate_card.distance_fare}</td>
                                                </tr>
                                                <tr>
                                                    <td>Time Fare</td>
                                                    <td>{modeldata.symbol}{modeldata.rate_card.time_fare}</td>
                                                </tr>
                                            </Table> </Grid>
                                        <Grid item xs={12} sx={{ mx: 5, mt: 0 }}>

                                            <Typography sx={{ fontWeight: '600' }}>Discounts :</Typography>
                                            <Table sx={{
                                                my: 1, fontSize: '1.10rem',
                                                marginLeft: '10px'
                                            }}>                                                <tr >
                                                    <td style={{ width: '50%' }}>Coupon Name</td>
                                                    <td >{modeldata.coupon && modeldata.coupon_used !== undefined ? modeldata.coupon_used.id : 'No Coupon Used'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Discount Amount</td>
                                                    <td >{modeldata.symbol}{modeldata.coupon && modeldata.coupon_used !== undefined ? modeldata.coupon_used.discount : '0'}</td>
                                                </tr>

                                            </Table> </Grid>
                                        <Grid item xs={12} sx={{ mx: 5, mt: 0 }}>

                                            <Typography sx={{ fontWeight: '600' }}>Other Charges :</Typography>
                                            <Table sx={{
                                                my: 1, fontSize: '1.10rem',
                                                marginLeft: '10px'
                                            }}>                                                   <tr >
                                                    <td style={{ width: '50%' }}>Convenience fee </td>
                                                    <td >{modeldata.symbol}{modeldata.rate_card.convenience_fee}</td>
                                                </tr>
                                                <tr>
                                                    <td>Toll & Parking Charges</td>
                                                    <td>{modeldata.symbol}{modeldata.rate_card.toll_charges}</td>
                                                </tr>
                                                <tr>
                                                    <td>Governement Charges</td>
                                                    <td>{modeldata.symbol}{ }</td>
                                                </tr>
                                                <tr>
                                                    <td>Network Charges</td>
                                                    <td>{modeldata.symbol}{modeldata.rate_card.network_charges}</td>
                                                </tr>
                                            </Table> </Grid>
                                        <Grid item xs={12} sx={{ mx: 5, mt: 0 }}>

                                            <Typography sx={{ fontWeight: '600' }}> TAX</Typography>
                                            <Table sx={{
                                                my: 1, fontSize: '1.10rem',
                                                marginLeft: '10px'
                                            }}>                                                 <tr >
                                                    <td style={{ width: '50%' }}>VAT </td>
                                                    <td >{modeldata.symbol}{modeldata.rate_card.tax}</td>
                                                </tr>

                                            </Table>
                                        </Grid>

                                        <Grid item xs={12} sx={{ mx: 5, mt: 0 }}>
                                            <Divider sx={{ margin: '5px' }}></Divider>
                                            <Table sx={{
                                                my: 1, fontSize: '1.10rem',
                                                marginLeft: '10px'
                                            }}>
                                                <tr >
                                                    <td style={{ width: '50%' }}>
                                                        <Typography sx={{ fontWeight: '600' }}> Total Fare</Typography>
                                                    </td>
                                                    <td >{modeldata.symbol}{modeldata.ride_cost}</td>
                                                </tr>

                                            </Table>
                                            <Divider sx={{ margin: '5px' }}></Divider>

                                        </Grid>

                                        <Grid item xs={12} sx={{ mx: 5, m: 0 }}>
                                            <Table sx={{
                                                fontSize: '1.10rem',
                                                marginLeft: '10px'
                                            }}>
                                                <tr >
                                                    <td style={{ width: '50%' }}>
                                                        <Typography sx={{ fontWeight: '600' }}> Payment Method ( {modeldata.payment_method} )</Typography>
                                                        {modeldata.payment_method === 'card' ? <Typography sx={{ fontWeight: '400', fontSize: '0.9rem' }}> Payment Id:  {modeldata.pystk_id}</Typography> : ''}
                                                    </td>
                                                </tr>

                                            </Table>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Box> : 'no data selected'}
                    </Box >
                </Modal >
                <CustomizedSnackbars message={snackmessage} open={snakopen} color={'error'} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

            </SuiBox >
            <Footer />
        </DashboardLayout >
    );
}

export default CompletedRides;
