const initailState = null;
const TargetStore = (state = initailState, action) => {
    switch (action.type) {
        case "SET_TARGET":
            return action.payload;
        default:
            return state;
    }
};

export default TargetStore;
