import React, { useEffect, useState, useRef } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSimCard } from '@fortawesome/free-solid-svg-icons'
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CustomizedSnackbars from "../Alert/Alert";

import { AddSimdata } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Switch from '@mui/material/Switch';
import Papa from "papaparse";
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/Download';
import { makeStyles } from '@mui/material/styles';
import { useAuth } from "CustomComponents/Authcontext";


function SimCard({ classes }) {
    const dd = useSelector((state) => state.Simdata);
    const cities = []
    // useSelector((state) => state.Ratecarddata.asasa||[]);
    const dispatch = useDispatch();
    const [nextkey, setnextkey] = useState(dd.nextKey || "");
    const [nexttoggle, setnexttoggle] = useState(true);
    const [sipnnershow, setspinnershow] = useState(false);
    const { currentUser } = useAuth();

    const [companyName, setcompanyName] = useState(null);
    const [Simnumber, setSimnumber] = useState(null);
    const [showMultiple, setshowMultiple] = useState(true);
    const [fileselectedjson, setfileselectedjson] = useState({ data: [] });
    const [fileuploded, setfileuploded] = useState(null)
    const ref = useRef();

    //data date
    const [isDisabled, setisDisabled] = useState(false);
    const [searchparam, setsearchparam] = useState("");
    //dataexchange
    const [arrayData, setarrayData] = useState(dd.arr);
    const [isDisabledsearch, setisDisabledsearch] = useState(false);
    //model handlers
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    //modal form

    //snack bar
    const [snakopen, setsnakopen] = useState(false)
    const [snackmessage, setsnackmessage] = useState(null)

    const handleSnackbar = (messages) => {
        setsnakopen(true)
        setsnackmessage(messages)
    };
    //
    const CSVToJsonData = (e) => {
        const files = e.target.files;
        if (files) {
            Papa.parse(files[0], {
                header: true,
                complete: function (results) {
                    var submit = false;
                    var errdate = ''
                    if (results.data.length !== 0) {
                        results.data.map((data) => {
                            var re = /^(\+{1}|\+[0-9]+|[0-9]+|)$/;
                            if (re.test(data.number)) {
                                submit = true
                            } else {
                                submit = false
                                errdate = errdate + ' ' + data.number
                            }
                        })
                        if(results.data[results.data.length-1].number===''){
                            results.data.pop();

                        }
                        console.log(results.data);
                        if (submit && errdate === '') {
                            setfileselectedjson({ data: results.data });
                        } else {
                            ref.current.value = "";
                            setisDisabled(true)
                            setfileselectedjson({ data: [] });
                            alert('This numbers dosen`t match the required format' + errdate)
                        }
                    } else {
                        ref.current.value = "";
                        setisDisabled(true)
                        errdate = ''
                        setfileselectedjson({ data: [] });
                        handleSnackbar('Uploded File is Empty')
                    }

                },
            });
        }
    };
    function CallSimData(nexttokenparam, limit = 100) {
        setspinnershow(true);
        if (nexttokenparam === undefined) {
            nexttokenparam = dd.nextKey || nextkey;
        }
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(
            process.env.REACT_APP_BASE_URL+"/asset?start=" +
            nexttokenparam +
            "&type=sim&limit=" + limit,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === true && result.data.length !== 0) {
                    result.data.map((doc) => {
                        doc.linked_to_vehicle = doc.linked_to_vehicle ? "Yes" : "No";
                        doc.linked_to_mobile = doc.linked_to_mobile ? "Yes" : "No";

                        doc.added_on = new Date(doc.added_on * 1000).toLocaleString(
                            "en-US",
                            {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                            }
                        );
                        console.log(doc.linked_on);
                        if (doc.linked_on) {
                            if(doc.linked_on.length>1){
                            doc.linked_on = new Date(doc.linked_on[doc.linked_on.length-1] * 1000).toLocaleString(
                              "en-US",
                              {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            );
                            }else{
                              doc.linked_on = new Date(doc.linked_on * 1000).toLocaleString(
                                "en-US",
                                {
                                  day: "numeric",
                                  month: "numeric",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                }
                              );
                            }
                          } else {
                            doc.linked_on = "--";
                          }
                    });
                    if (result.start === null) {
                        // alert("No More Data To Load.");
                        setspinnershow(false)
                        handleSnackbar("No Data To Load");
                    } else {
                        setspinnershow(false)
                        setnextkey(result.start);
                        dispatch(AddSimdata(result.data, result.start));
                        return result.start;
                    }
                } else {
                    setspinnershow(false)
                    setnexttoggle(false)
                    handleSnackbar('no more data to load');
                    return null;
                }
            })
            .catch((error) => {
                setspinnershow(false)
                console.log("error", error);
                return null;
            });
    }
    function DownloadSampleCSV() {
        const header = ["number", "company"];
        var CSV = [header.join(",")].join("\r\n");
        //Generate a file name
        var fileName = "Sample";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += "SIM Upload".replace(/ /g, "_");
        //Initialize file format you want csv or xls
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);
        var link = document.createElement("a");
        link.href = uri;
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    function handleSubmitMultiple(e) {
        e.preventDefault();
        setspinnershow(true);

        document.getElementById("multiple").setAttribute("disabled", "disabled");
        if (fileselectedjson.data.length !== 0) {
            var heads = Object.keys(fileselectedjson.data[0]);
            if (
                heads.includes("number") &&
                heads.includes("company") &&
                heads.length === 2
            ) {
                var myHeaders = new Headers();
                myHeaders.append(
                    "Authorization",
                    "Bearer " + currentUser.accessToken
                );
                myHeaders.append("Content-Type", "application/json");
                var raw = JSON.stringify(fileselectedjson);
                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow",
                };
                fetch(
                    process.env.REACT_APP_BASE_URL+"/asset?type=sim",
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.status === true) {
                            alert("Sim added Successfully");
                            document.getElementById("multiple").removeAttribute("disabled");
                            setspinnershow(false);
                            setfileselectedjson({ data: [] });
                            setshowMultiple(!showMultiple);
                            handleClose()

                        } else {
                            handleSnackbar("Error" + result.message);
                            document.getElementById("multiple").removeAttribute("disabled");
                            setspinnershow(false);
                        }

                    })
                    .catch((error) => console.log("error", error));
            } else {
                document.getElementById("multiple").removeAttribute("disabled");
                setspinnershow(false);
                handleSnackbar(
                    "The Uploaded File Contains foreign fields.Please Check the file format"
                );
            }
        } else {
            document.getElementById("multiple").removeAttribute("disabled");
            handleSnackbar("The uploaded file contain Zero Fields");
            setspinnershow(false);
        }
    }
    function handleSubmit1(e) {
        e.preventDefault();
        if (companyName !== null && Simnumber !== null) {
            setspinnershow(true);
            document.getElementById("submit").setAttribute("disabled", "disabled");
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            myHeaders.append("Content-Type", "application/json");
            setSimnumber(Simnumber.replace(' ', ''))
            var raw = JSON.stringify({
                type: "sim",
                company: companyName,
                number: Simnumber.trim()
            });
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };
            fetch(
                process.env.REACT_APP_BASE_URL+"/asset",
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === true) {
                        alert("Sim added Successfully");
                        document.getElementById("submit").removeAttribute("disabled");
                        setcompanyName(null);
                        setSimnumber(null);
                        setspinnershow(false);
                        handleClose()
                    } else {
                        handleSnackbar("Error : unable to process the file uploded " + result.message);
                        setspinnershow(false);
                        setcompanyName(null);
                        setSimnumber(null);
                        document.getElementById("submit").removeAttribute("disabled");
                    }
                })
                .catch((error) => {
                    setspinnershow(false);
                    console.log("error", error)
                });
        } else {
            if (companyName === null) handleSnackbar('Please Select SIM Provider.')
            else handleSnackbar('Please provide valid data.')
        }
    }

    useEffect(() => {
        if (dd.arr.length === 0) {
            CallSimData('',100);
        }
    }, []);
    async function callsimRepeat(e) {
        if (e >= 10) {
            var da = "";
            if (dd.nextKey !== "") {
                da = dd.nextKey;
            } else {
                da = nextkey;
            }
            if (da !== null && nexttoggle) {
                da = await CallSimData(da, e);
            }
        }

    }
    const setEntriesPerPage = ({ value }) => setcompanyName(value);

    // if (dd.arr.length === 0) {
    //     return (
    //         <DashboardLayout>
    //             <DashboardNavbar />
    //             <SuiBox pt={2} pb={3}>
    //                 <Card>
    //                     {sipnnershow && (
    //                         <Box sx={{ width: '100%', m: 0, p: 0 }}>
    //                             <LinearProgress color="success" sx={{ width: '100%' }} />
    //                         </Box>

    //                     )}
    //                     <Grid container spacing={2} sx={{ p: 3 }}>

    //                         <Grid item xs={12} sm={4} md={4} xl={4}>
    //                             <p style={{ marginLeft: '10px' }}>{'Loading...'}</p>

    //                         </Grid>
    //                         <Grid item xs={12} sm={4} md={4} xl={4}>

    //                         </Grid>
    //                         <Grid item xs={12} sm={4} md={4} xl={4}>
    //                             <SuiBox>
    //                                 <SuiButton sx={{ float: 'right' }} variant="gradient" onClick={() => {
    //                                     handleOpen()
    //                                 }} color="info"  >
    //                                     Add SIM <span style={{ marginLeft: '5px' }}> <FontAwesomeIcon sx={{ ml: 2 }} icon={faSimCard} /></span>
    //                                 </SuiButton>
    //                             </SuiBox>
    //                         </Grid>

    //                     </Grid>
    //                 </Card>
    //             </SuiBox>
    //             <Footer />
    //         </DashboardLayout>
    //     );
    // }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={3} pb={3}>
                <Card >
                    {sipnnershow && (
                        <Box sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{ width: '100%' }} />
                        </Box>

                    )}

                    <Grid container spacing={2} sx={{ p: 2 }}>

                        <Grid item xs={12} sm={4} md={4} xl={4}>

                        </Grid>
                        <Grid item xs={12} sm={4} md={4} xl={4}>

                        </Grid>
                        <Grid item xs={12} sm={4} md={4} xl={4}>
                            <SuiBox>
                                <SuiButton sx={{ float: 'right' }} variant="gradient" onClick={() => {
                                    handleOpen()
                                }} color="info"  >
                                    Add SIM<span style={{ marginLeft: '5px' }}> <FontAwesomeIcon sx={{ ml: 2 }} icon={faSimCard} /></span>
                                </SuiButton>
                            </SuiBox>
                        </Grid>

                    </Grid>
                    {dd.arr.length !== 0 ? (

                        <DataTable table={dd.arr}
                            showTotalEntries={false}
                            functioncallcount={callsimRepeat}
                            hidecolumnsdata={[
                                "linked_to_mobile",
                                "linked_on",
                                "IMEI",
                                "build_number",
                                "brand_name",
                            ]}
                            datacolumns={[
                                {
                                    Header: "SIM Provider",
                                    accessor: "company",
                                },
                                {
                                    Header: "Phone number",
                                    accessor: "number",
                                },
                                {
                                    Header: "added on",
                                    accessor: "added_on",
                                },
                                {
                                    Header: "linked to vehicle",
                                    accessor: "linked_to_vehicle",
                                },
                                {
                                    Header: "linked to mobile",
                                    accessor: "linked_to_mobile",
                                },
                                {
                                    Header: "linked On",
                                    accessor: "linked_on",
                                },
                                {
                                    Header: "IMEI",
                                    accessor: "IMEI",
                                },
                                {
                                    Header: "build number",
                                    accessor: "build_number",
                                },
                                {
                                    Header: "brand Name",
                                    accessor: "brand_name",
                                },
                            ]}
                            canSearch />) : null}

                </Card>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition

                >
                    <Box sx={
                        {
                            border: 0,
                            borderRadius: '10px',
                            boxShadow: '0 0 15px rgb(0 0 0 / 15%), 0 0 1px 1px rgb(0 0 0 / 10%)',
                            position: 'absolute',
                            top: '40%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            display: 'flex',
                            flexDirection: 'column',
                            width: showMultiple ? '50vw' : '60vw',
                            pointerEvents: 'auto',
                            backgroundColor: '#fff',
                            backgroundClip: 'padding-box',
                            outline: 0,
                            padding: '20px 50px'
                        }
                    } >
                        <Typography sx={{ textAlign: 'center' }}>
                            Add SIM
                        </Typography>
                        <hr />
                        <Typography sx={{ fontSize: '0.9rem', m: 2 }}>
                        Upload Multiple
                            <Switch
                                onChange={() => {
                                    setshowMultiple(!showMultiple);
                                }
                                }
                            />
                        </Typography>


                        {showMultiple === true ? (
                            <div>
                                {sipnnershow ? (
                                    <div style={{ display: "inline-block" }}>
                                        Processing data... <CircularProgress size={20} color="primary" />
                                    </div>
                                ) : null}

                                <form onSubmit={handleSubmit1} >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} xl={12}>
                                            <p className="Labelstyle">Phone number pattern must be followed</p>
                                            "+911231231231"
                                            "+2349387394238"
                                            "+919387394238"
                                            "09387394238"
                                            "09387394238"
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} xl={6} >
                                            <p className="Labelstyle">SIM Provider</p>
                                            <SuiSelect
                                                required
                                                value={{ value: companyName, label: companyName }}
                                                onChange={setEntriesPerPage}
                                                placeholder="Select city"
                                                options={[
                                                    { value: "", label: "Select provider" },
                                                    { value: "MTN", label: "MTN" },
                                                    { value: "Airtel", label: "Airtel" },
                                                    { value: "9mobile", label: "9mobile" },
                                                ]}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6} xl={6}>
                                            <p className="Labelstyle">Phone number</p>
                                            <SuiInput
                                                required
                                                placeholder="E.g - +234 XXX XXX XXXX"
                                                type="text"
                                                value={Simnumber}
                                                inputProps={{ maxLength: 14, minLength: 12 }}
                                                onChange={(e) => {
                                                    var re = /^(\+{1}|\+[0-9]+|[0-9]+|)$/;
                                                    if (re.test(e.target.value)) {
                                                        setSimnumber((e.target.value))
                                                    }
                                                    else {
                                                        handleSnackbar('Special characters and Alphabets are not allowed')

                                                    }
                                                }
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} xl={6}>
                                            <SuiButton
                                                className=""
                                                disabled={isDisabled}
                                                id="submit"
                                                type="submit"
                                            >
                                                Submit
                                            </SuiButton>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} xl={6}>
                                            <SuiButton
                                                sx={{ float: 'right' }} onClick={() => {
                                                    setOpen(false)
                                                }}
                                            >
                                                Close
                                            </SuiButton>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        ) : (
                            <div>
                                {sipnnershow ? (
                                    <div style={{ display: "inline-block" }}>
                                        Processing data... <CircularProgress size={20} color="primary" />
                                    </div>
                                ) : null}
                                <form onSubmit={handleSubmitMultiple} className="w-100">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} xl={12}>
                                            <p>
                                                Please follow the format given while uploading the
                                                .csv
                                            </p>
                                            <p>
                                                Download sample CSV
                                                <Button
                                                    style={{ width: "100px" }}
                                                    onClick={() => {
                                                        DownloadSampleCSV();
                                                    }}
                                                >
                                                    <DownloadIcon sx={{ width: '2rem', height: '2rem', color: 'green' }} />
                                                </Button>
                                            </p>

                                            <table style={{ backgroundColor: 'black', color: 'white', width: "75%", textAlign: 'center' }}>
                                                <thead style={{ padding: '.1rem' }}>
                                                    <tr>
                                                        <th style={{ textTransform: "lowercase" }}>
                                                            number
                                                        </th>
                                                        <th style={{ textTransform: "lowercase", borderLeft: '2px solid white' }}>
                                                            company
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    <tr >
                                                        <td>+243/+91XXXXXXXXXX</td>
                                                        <td style={{ borderLeft: '2px solid white' }}>Airtel</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} xl={12}>
                                            <hr />

                                            <SuiInput
                                                style={{ borderLeft: "6px solid red" }}
                                                required
                                                ref={ref}
                                                type="file"
                                                accept=".csv"
                                                onChange={(e) => {
                                                    setfileuploded(e.target.files[0])
                                                    CSVToJsonData(e);
                                                }}
                                            />
                                            <hr />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} xl={6}>
                                            <SuiButton
                                                className=""
                                                disabled={isDisabled}
                                                id="multiple"
                                                type="submit"
                                            >
                                                Submit
                                            </SuiButton>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} xl={6}>
                                            <SuiButton
                                                sx={{ float: 'right' }} onClick={() => {
                                                    setOpen(false)
                                                }}
                                            >
                                                Close
                                            </SuiButton>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        )}
                    </Box>
                </Modal>
                <CustomizedSnackbars color={'error'} message={snackmessage} open={snakopen} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />
            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default SimCard;
