
const initailState = {
    arr:[],
    nextKey:0,
    secarr:[],
    secnextKey:0,

}
const AccountsCollectionDrivers=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_COLLECTION_DRIVER_DATA':
            return{ 
                ...state,
                arr: state.arr.concat(action.payload),
                nextKey:action.keynext,

            }
            case 'ADD_SECOND_COLLECTION_DRIVER_DATA':
                return{ 
                    ...state,
                    secarr: state.secarr.concat(action.payload),
                    secnextKey:action.keynext,
    
                }
                case 'CLEAN_SECOND_COLLECTION_DRIVER_DATA':
                return{ 
                    ...state,
                    secarr: [],
                    secnextKey:'',
    
                }
        default:
            return state;
    }
}

export default AccountsCollectionDrivers