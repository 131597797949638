import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import { useAuth } from "CustomComponents/Authcontext";

import { searchdata } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from "@mui/material";
import CustomizedSnackbars from "../Alert/Alert";
function AddDrivertoVehicle() {
  const isloading = useSelector((state) => state.Isload);
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [errormessage, seterrormessage] = useState(null);
  const [errormessage2, seterrormessage2] = useState(null);
  const { currentUser } = useAuth();

  const [nexttoggle, setnexttoggle] = useState(true);
  const dd = useSelector((state) => state.Searchdata);
  const UnlinkedVehicle = useSelector((state) => state.UnlinkedVehicle);
  const [sipnnershow, setsipnnershow] = useState(false);
  const [sipnnershow1, setsipnnershow1] = useState(false);
  const [VehicalResponse, setVehicalResponse] = useState(null);

  //UnlinkedVehicle
  const dispatch = useDispatch();
  const [selectedVehical, setselectedVehical] = useState(null);

  const [vehiclesetdata1, setvehiclesetdata1] = useState(null);
  const [search_value, setsearch_value] = useState(null);
  const [vehicleRN, setvehicleRN] = useState('');

  const [selecterdriver, setselecterdriver] = useState(null);
  //snack bar
  const [snakopen, setsnakopen] = useState(false)
  const [snackmessage, setsnackmessage] = useState(null)
  const [snackColor, setsnackColor] = useState('error')

  const handleSnackbar = (messages) => {
    setsnakopen(true)
    setsnackmessage(messages)
  };
  //
  function handleSubmit(event) {
    event.preventDefault();

    if (vehiclesetdata1 != null && selecterdriver != null) {
      setsipnnershow(true);

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + currentUser.accessToken
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        driver: selecterdriver,
        vehicle: vehiclesetdata1,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log(raw);

      fetch(
        process.env.REACT_APP_BASE_URL+"/link_driver_vehicle",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === false) {
            setsipnnershow(false);

            alert(result.message);
          } else {
            setsipnnershow(false);
            setselectedVehical(null);
            setsnackColor('success')
            handleSnackbar("Driver Has Been Linked To The Vehicle");
            setselecterdriver(null);
            setvehiclesetdata1("");
            setVehicalResponse(null);
          }
        })
        .catch((error) => {
          console.log("error", error);
          setsipnnershow(false);
        });
    } else {
      setsnackColor('error')
      handleSnackbar("Please select the values properly");
      setsipnnershow(false);

    }
  }
  async function handleSearchvehicle(event) {
    event.preventDefault()
    if (vehicleRN !== '') {
      setsipnnershow1(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + currentUser.accessToken
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_BASE_URL+"/search_asset?asset=vehicle&search=" +
        vehicleRN +
        "&type=vehicle_driver",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === false) {
            seterrormessage2(result.message);
            setTimeout(() => {
              seterrormessage2(null);
            }, 2000);
            setsipnnershow1(false);
          } else {
            setsipnnershow1(false);
            setVehicalResponse(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      handleSnackbar('Please fill the vehical search field')
    }
  }


  async function handleSearchSubmit(event) {
    event.preventDefault();
    if (search_value !== null) {

      setsipnnershow(true);

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + currentUser.accessToken
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = "";
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_BASE_URL+"/search_users?search=" +
        search_value +
        "&user=driver&type=linking",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === false) {
            seterrormessage(result.message);
            setsipnnershow(false);
            setTimeout(() => {
              seterrormessage(null);
            }, 2000);
          } else {
            if (dd.arr.length !== 0) {
              var dd_id = [];
              dd_id = dd.arr.map((d) => d.id);
              if (dd_id.includes(result.data.id)) {
                handleSnackbar("Data already present");
              } else {
                dispatch(searchdata(result.data));
              }
            } else {
              dispatch(searchdata(result.data));
            }
            setsipnnershow(false);
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      handleSnackbar('Please fill the driver search field')
    }
  }
  if (UnlinkedVehicle.arr.length !== 0 && isloading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox pt={2} pb={3}>
          <Card>
            <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
          </Card>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={2} pb={3}>

        <Card sx={{ m: 1, p: 2, pt: 0 }}>

          <SuiTypography
          p={2}
            variant="h5"
          >Vehicle to Driver </SuiTypography>

          <Grid container spacing={2}>
            <Grid item md={6}>
              <Card>
                {sipnnershow1 && (
                  <Box sx={{ width: '100%', m: 0, p: 0 }}>
                    <LinearProgress color="success" sx={{ width: '100%' }} />
                  </Box>
                )}
                <Card sx={{ p: 4 }}>
                  <div >
                    <h5 style={{ display: "inline-block", position: 'relative' }}>
                      Vehicle Search
                    </h5>
                    <form onSubmit={handleSearchvehicle} >
                      <SuiTypography
                        variant="h6"
                      >
                        Please Enter a Valid Vehicle Registration Number
                      </SuiTypography>
                      <SuiInput
                        sx={{ mb: 2, position: 'relative', ml: 'auto', mr: 'auto' }}
                        value={vehicleRN}
                        name="vSearch"
                        placeholder="Registration Number"
                        onChange={(e) => setvehicleRN(e.target.value)}
                        inputProps={{ type: "text" }} />
                      <SuiButton
                        type="submit"
                        variant="gradient" color="info"  >
                        Search Vehicle

                      </SuiButton>

                      <div>
                        {errormessage2 != null ? (
                          <p style={{ color: "red", fontSize: '1rem' }}>{errormessage2}</p>
                        ) : null}
                      </div>
                    </form>
                  </div>
                </Card>
              </Card>
            </Grid>

            <Grid item md={6}>
              <Card>
                {sipnnershow && (
                  <Box sx={{ width: '100%', m: 0, p: 0 }}>
                    <LinearProgress color="success" sx={{ width: '100%' }} />
                  </Box>
                )}
                <Card sx={{ p: 4 }}>
                  <h5 style={{ display: "inline-block" }}>Driver Search{" "}</h5>
                  <form onSubmit={handleSearchSubmit} >
                    <SuiTypography
                      variant="h6"
                    >
                      Please Enter a Valid Contact Number Or Email ID
                    </SuiTypography>
                    <SuiInput
                      sx={{ mb: 2, position: 'relative', ml: 'auto', mr: 'auto' }}

                      value={search_value}
                      name="vSearch"
                      placeholder="Email Id / Contact Number"
                      onChange={(e) => setsearch_value(e.target.value)}
                      inputProps={{ type: "text" }}
                    />

                    <SuiButton
                      type="submit"
                      id="submits2"
                      variant="gradient" color="info"  >
                      Search Drivers
                    </SuiButton>
                    <div>
                      {errormessage != null ? (
                        <p style={{ color: "red", fontSize: '1rem' }}>{errormessage}</p>
                      ) : null}
                    </div>
                  </form>

                </Card>
              </Card>
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ m: 1, p: 3 }}>
          <SuiTypography variant="h6">Search Results</SuiTypography>
          <form onSubmit={handleSubmit} className='w-100'>

            <Grid container spacing={2} className='w-100'>
              <Grid item md={5}>
                {" "}
                {VehicalResponse !== null ? (
                  <Card
                    style={{ backgroundColor: "#EFEFEF", textAlign: "justify" }}
                    id={VehicalResponse.id}
                    onClick={() => {
                      setvehiclesetdata1(VehicalResponse.id);
                      document.getElementById(
                        VehicalResponse.id
                      ).style.backgroundColor = "#EFEF";
                    }}
                  >
                    <Grid container sx={{ p: 3 }} >
                      <Grid item sm={12}>
                        <SuiTypography variant="button">
                          Registration Number:{" "}
                          <span>{VehicalResponse.registration_number}</span>
                        </SuiTypography>
                      </Grid>

                      <Grid item sm={12}>
                        <SuiTypography variant="button">
                          Model : <span>{VehicalResponse.model}</span>
                        </SuiTypography>
                      </Grid>
                      <Grid item sm={12}>
                        <SuiTypography variant="button">
                          Brand :<span>{VehicalResponse.brand}</span>
                        </SuiTypography>
                      </Grid>
                      <Grid item sm={12}>
                        <SuiTypography variant="button">
                          city : <span>{VehicalResponse.city}</span>
                        </SuiTypography>
                      </Grid> 
                      <Grid item sm={12}>
                        <SuiTypography variant="button">
                        IMEI : <span>{VehicalResponse.IMEI}</span>
                        </SuiTypography>
                      </Grid> 
                      <Grid item sm={12}>
                        <SuiTypography variant="button">
                          Phone Number : <span>{VehicalResponse.number}</span>
                        </SuiTypography>
                      </Grid>
                    </Grid>{" "}
                  </Card>
                ) : null}
              </Grid>
              <Grid item md={5}>
                {" "}
                {dd.arr.length !== 0
                  ? dd.arr.map((data) => {
                    return (
                      <Card
                        style={{ backgroundColor: "#EFEFEF", textAlign: "justify" }}
                        id={data.id}
                        onClick={() => {
                          setselecterdriver(data.id);
                          document.getElementById(
                            data.id
                          ).style.backgroundColor = "#EFEF";
                        }}
                      >
                        <Grid container sx={{ p: 3 }}>
                          <Grid item sm={12}>
                            <img
                              height={"150px"}
                              width={"200px"}
                              src={data.license_back_pic || null}
                            ></img>
                          </Grid>

                          <Grid item sm={12}>
                            <SuiTypography variant="button">
                              Driver Name :{" "}
                              <span> {data.fname + " " + data.lname}</span>
                            </SuiTypography>
                          </Grid>
                          <Grid item sm={12}>
                            <SuiTypography variant="button">
                              Gender : {data.gender}
                            </SuiTypography>
                          </Grid>
                          <Grid item sm={12}>
                            <SuiTypography variant="button">
                              Email ID: {data.emailid}
                            </SuiTypography>
                          </Grid> <Grid item sm={12}>
                            <SuiTypography variant="button">
                              Vendor : {data.vendor}
                            </SuiTypography>
                          </Grid>
                        </Grid>{" "}
                      </Card>
                    )
                  }
                  ) : null
                }

              </Grid>
              <Grid item md={12}>
                <SuiButton disabled={sipnnershow} id="submit" type="submit" >
                  Submit
                </SuiButton>
              </Grid>{" "}
            </Grid>            </form>
          <CustomizedSnackbars message={snackmessage} open={snakopen} color={snackColor} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

        </Card>
      </SuiBox>
      <Footer />
    </DashboardLayout >
  );
}

export default AddDrivertoVehicle;
