import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


function CreateUserRole() {

    useEffect(() => {
        window.open('https://africar-org.freshchat.com/a/520468640593475/inbox/0/0', '_blank');

    }, [0])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox sx={{height:'70vh'}}>
            <a target="_blank" href='https://africar-org.freshchat.com/a/520468640593475/inbox/0/0'>  <Card variant="button" sx={{
                    p:3,color:'info',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)'
                }} >
                    Go To Support

                </Card></a>
            </SuiBox>


            <Footer />
        </DashboardLayout >
    );
}

export default CreateUserRole;
