
const initailState = null

const Userinfo=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_USER_INFO':
            return action.payload;
            
        default:
            return state;
    }
}

export default Userinfo