/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useAuth } from "CustomComponents/Authcontext";

// Wizard page components
import About from "./wizardpages/Page1";
import Account from "./wizardpages/Account";
// import Address from "./wizardpages";

function getSteps() {
  return ["Notification Info", "Notification Image", ];
}



function Wizard() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const { currentUser } = useAuth();

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1)
  //values
  const [title, settitle] = useState(null);
  const [body, setbody] = useState(null);
  const [topic, settopic] = useState(null);
  const [pageurl, setpageurl] = useState('');
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <About settop ={settopic}setbod={setbody}settit={settitle} />;
      case 1:
        return <Account imgurl={setpageurl} />;
    //   case 2:
    //     return <Address />;
      default:
        return null;
    }
  }
 function finishButtonClick() {
    if (title!==null&&body!==null&&topic!==null) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer "+currentUser.accessToken);
      myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          topic:topic,
          title:title,
          body:body,
          image:pageurl,
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

      fetch(
        process.env.REACT_APP_BASE_URL+"/push_notification",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if(result.status!==false){
          alert("Notification will be sent to the Users.")
    }else{
      alert('please fill the form')
    }
  })
        .catch((error) => console.log("error", error));
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={3} pb={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <SuiBox mt={6} mb={1} textAlign="center">
              <SuiBox >
                <SuiTypography variant="h3" fontWeight="bold">
                  Push Notification
                </SuiTypography>
              </SuiBox>
              {/* <SuiTypography variant="h5" fontWeight="regular" color="secondary">
                This information will let us know more about you.
              </SuiTypography> */}
            </SuiBox>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card>
              <SuiBox  p={2}>
                <SuiBox>
                  {getStepContent(activeStep)}
                  <SuiBox  width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <SuiBox />
                    ) : (
                      <SuiButton variant="gradient" color="light" onClick={handleBack}>
                        back
                      </SuiButton>
                    )}
                    <SuiButton
                      variant="gradient"
                      color="dark"
                      onClick={!isLastStep ? handleNext : finishButtonClick}
                    >
                      {isLastStep ? "send" : "next"}
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Wizard;
