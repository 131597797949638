import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import { useAuth } from "CustomComponents/Authcontext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'
import { addCanceledRidesData, addSEARCHCanceledRidesData, CLEANSEARCHCanceledRidesData } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import CustomizedSnackbars from "../Alert/Alert";
import { CookieRounded } from "@mui/icons-material";

function CancelledRides() {
    const dd = useSelector((state) => state.CancelledRides);
    const userinfo = useSelector((state) => state.Userinfo);
    const dispatch = useDispatch();
    const [nextkey, setnextkey] = useState(dd.nextKey || "");
    const [messagttouser, setmessagttouser] = useState("Loading Data...");
    const [nexttoggle, setnexttoggle] = useState(true);
    const [sipnnershow, setspinnershow] = useState(false);
    const { currentUser } = useAuth();
    //state country and city
    const [issetCC, setissetCC] = useState(false)

    //admin 
    const [selectCountry, setselectCountry] = useState(null);
    //data date
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);
    const [isDisabled, setisDisabled] = useState(false);
    const [selectCity, setselectCity] = useState(null);
    const [searchparam, setsearchparam] = useState("");
    //dataexchange
    const [arrayData, setarrayData] = useState(dd.arr);
    const [isDisabledsearch, setisDisabledsearch] = useState(false);
    const [isDisabledsearch2, setisDisabledsearch2] = useState(false);
    //download
    const [selectCitydownload, setselectCitydownload] = useState(null);
    const [selectCountrydownload, setselectCountrydownload] = useState(null);

    //model handlers
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    //snack bar
    const [snakopen, setsnakopen] = useState(false)
    const [snackmessage, setsnackmessage] = useState(null)

    const handleSnackbar = (messages) => {
        setsnakopen(true)
        setsnackmessage(messages)
    };
    const DownloadDataSubmit = (event) => {
        if (fromdate !== null && todate !== null && fromdate !== undefined && todate !== undefined) {
            var todateseconds = new Date(todate).getTime() / 1000;
            var fromdateseconds = new Date(fromdate).getTime() / 1000;
            var url = process.env.REACT_APP_BASE_URL + `/cancelled_rides?from=${fromdateseconds}&to=${todateseconds}`;
            if (selectCitydownload !== null) {
                url = url + '&city=' + selectCitydownload
            } else if (selectCountrydownload !== null) {
                url = url + '&country=' + selectCountrydownload
            }
            setisDisabled(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            fetch(
                url,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === true) {
                        fileJson(result.data, "Canceledrides", true);
                        setisDisabled(false);

                    } else {
                        setisDisabled(false);
                        handleSnackbar(result.message)
                    }
                })
                .catch((error) => {
                    handleSnackbar('Unable to get data....ERROR')
                    console.log("error", error);
                });
        } else {
            handleSnackbar('Please select date')
        }
    };
    useEffect(() => {


        if (dd.arr.length === 0) {
            calldataall_riders("", 100);
        } else {
            if (issetCC) {
                setarrayData(dd.arr);
            } else {
                setarrayData(dd.arr);
            }
        }
    }, [dd]);

    const fileJson = async (JSONData, ReportTitle, ShowLabel) => {
        var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
        var headings = [
            "ARN",
            "booked_on",
            "accepted_on",
            "rider_name",
            "driver_name",
            "registration_number",
            "pickup_str",
            "drop_str",
            "type",
            "required_seats",
            "verified_on",
            "completed_on",
            "distance_str",
            "payment_method",
            "rate_card.base_fare",
            "rate_card",
            "distance_str",
            "coupon",
            "rate_card.tax",
            "ride_cost",
            "africar_net_gain",
            "driver_commission",
            "africar_gain",
            "ratings",
            "coupon_used", "cancelled_by", "rider_phone_number", "driver_contactno"
        ];
        var CSV = "";
        var datasest = [];
        datasest = arrData.map((data) => {
            data.rider_name = data.rider_lname + " " + data.rider_fname;
            const header = Object.keys(data);
            var rt = data.rate_card;
            var coupon = data.coupon_used;
            var cstate = data.coupon;
            delete data.rate_card;
            var ss = data.drop_str ? data.drop_str : ["Undefined"];
            var dd = data.type;
            data.ride_type = dd;
            data.drop_str = ss[0];
            ss = data.pickup_str ? data.pickup_str : ["Undefined"];
            data.pickup_str = ss[0];
            header.map((dddd) => {
                if (!headings.includes(dddd)) {
                    delete data[dddd];
                }
            });
            var con,
                aon,
                bon,
                von = "";

            if (data.accepted_on) {
                aon = new Date(data.accepted_on).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (data.booked_on) {
                bon = new Date(data.booked_on).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (data.verified_on) {
                von = new Date(data.verified_on * 1000).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (data.cancelled_on) {
                data.cancelled_on = new Date(data.cancelled_on * 1000).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (cstate == 'Yes') {
                data.coupon_name = coupon.id;
                data.coupon_discount = coupon.discount;
            } else {
                data.coupon_name = "";
                data.coupon_discount = "";
            }
            return {
                ARN: data.ARN,
                "Cancelled by": data.cancelled_by,
                "Booking Time": bon,
                "Accepted Time": aon,
                "Rider name": data.rider_name,
                "Rider Contact number": data.rider_phone_number,
                "Driver name": data.driver_name,
                "Registration number": data.registration_number,
                "Pickup location": data.pickup_str,
                "Drop location": data.drop_str,
                "Ride Type": data.type,
                "Required seats": data.required_seats,
                "Actual Pickup Time": von,
                "Actual Drop Time": con,
                "Distance Travelled": data.distance_str,
                "Payment Method": data.payment_method,
                "Base Fare": rt.base_fare,
                "Distance Fare": rt.distance_fare,
                "Time Fare": rt.time_fare,
                VAT: rt.tax,
                Rounded: rt.rounded,
                "Ride Cost": data.ride_cost,
                Coupon: data.coupon,
                "Coupon Name": data.coupon_name,
                "Coupon Discount": data.coupon_discount,
                cancelled_on: data.cancelled_on
            };
        });



        const items = datasest;
        const replacer = (key, value) => (value === null ? " " : value); // specify how you want to handle null values here
        const header = Object.keys(items[0]);
        CSV = [
            header.join(","), // header row first
            ...items.map((row) =>
                header
                    .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                    .join(",")
            ),
        ].join("\r\n");
        if (CSV == "") {
            alert("Invalid data");
            return;
        }
        //Generate a file name
        var fileName = "Report_";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += ReportTitle.replace(/ /g, "_");

        //Initialize file format you want csv or xls
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setisDisabled(false);
    };

    async function calldataall_ridersRepeat(e) {
        if (e >= 10) {
            var da = "";
            if (dd.nextKey !== "") {
                da = dd.nextKey;
            } else {
                da = nextkey;
            }
            if (da !== null && nexttoggle) {
                if (!issetCC) {
                    da = await calldataall_riders(da, e);
                } else {
                    handleSubmitSearchcitycountry(da, e)
                }
            }
        }

    }
    async function calldataall_riders(nexttokenparam, limit = 100) {
        setnexttoggle(false);
        setspinnershow(true);
        setisDisabledsearch(false);

        if (nexttokenparam === undefined) {
            nexttokenparam = dd.nextKey || nextkey;
        }
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        var resultfetch = {};

        await fetch(
            process.env.REACT_APP_BASE_URL + "/cancelled_rides?start=" +
            nexttokenparam + "&limit=" + limit,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                resultfetch = result;
            })
            .catch((error) => {
                setnexttoggle(false);
                handleSnackbar("Unable to get data....ERROR" + error);
                console.log("error", error);
            });
        //
        await setnextkey(resultfetch.start);
        if (resultfetch.status) {

            resultfetch.data.map((pro) => {
                if (pro.coupon_used !== undefined) {
                    pro.coupon_name = pro.coupon_used.id;
                }
                pro.rider_fname = pro.rider_fname + " " + pro.rider_lname;

                if (pro.coupon === true) {
                    pro.coupon = "Yes";
                } else {
                    pro.coupon = "No";
                }

                if (pro.accepted_on) {
                    pro.accepted_on = new Date(pro.accepted_on).toLocaleString("en-US", {
                        day: "numeric",

                        month: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                    });
                }
                if (pro.booked_on) {
                    pro.booked_on = new Date(pro.booked_on).toLocaleString("en-US", {
                        day: "numeric",

                        month: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                    });
                }
                if (pro.verified_on) {
                    pro.verified_on = new Date(pro.verified_on).toLocaleString("en-US", {
                        day: "numeric",

                        month: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                    });
                }
            });
            dispatch(addCanceledRidesData(resultfetch.data, resultfetch.start));
            setnexttoggle(true);
            setspinnershow(false);
        } else {
            setmessagttouser(resultfetch.message)

            handleSnackbar('No data to load')
        }
        //

        return resultfetch.start;
    }
    ///function call search
    async function handleSubmitSearch(event) {
        if (searchparam && searchparam !== null && searchparam !== '') {
            setisDisabledsearch(true);
            setspinnershow(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            fetch(
                process.env.REACT_APP_BASE_URL + `/cancelled_rides?ARN=` +
                searchparam,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    if (result.status === true) {

                        setarrayData(result.data);
                        setspinnershow(false);

                    } else {
                        setisDisabledsearch(false);
                        setsearchparam('')
                        setspinnershow(false);
                        handleSnackbar(result.message);
                    }
                })
                .catch((error) => {
                    setisDisabledsearch(false);
                    setsearchparam('')
                    setspinnershow(false);
                    handleSnackbar("Unable to get data....ERROR");
                    console.log("error", error);
                });
        } else {
            handleSnackbar('Please provide valid data')
        }
    }

    async function handleSubmitSearchcitycountry(ghbg, limit = 10) {

        if (selectCountry !== null || selectCity !== null) {
            setnexttoggle(false);
            setspinnershow(true);
            setisDisabledsearch2(false);
            setisDisabledsearch2(true);
            setspinnershow(true);
            var url = process.env.REACT_APP_BASE_URL + `/cancelled_rides?limit=${limit}`;
            if (selectCity !== null) {
                url = url + '&city=' + selectCity
            } else if (selectCountry !== null) {
                url = url + '&country=' + selectCountry
            }
            if (dd.secnextKey !== '') {
                url = url + "&start=" + dd.secnextKey
            } else {
                url = url + "&start="

            }
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            fetch(
                url,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    // console.log(result);
                    if (result.status === true) {
                        result.data.map((pro) => {
                            if (pro.coupon_used !== undefined) {
                                pro.coupon_name = pro.coupon_used.id;
                            }
                            pro.rider_fname = pro.rider_fname + " " + pro.rider_lname;

                            if (pro.coupon === true) {
                                pro.coupon = "Yes";
                            } else {
                                pro.coupon = "No";
                            }

                            if (pro.accepted_on) {
                                pro.accepted_on = new Date(pro.accepted_on).toLocaleString("en-US", {
                                    day: "numeric",
                                    month: "numeric",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                });
                            }
                            if (pro.booked_on) {
                                pro.booked_on = new Date(pro.booked_on).toLocaleString("en-US", {
                                    day: "numeric",

                                    month: "numeric",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                });
                            }
                            if (pro.verified_on) {
                                pro.verified_on = new Date(pro.verified_on).toLocaleString("en-US", {
                                    day: "numeric",

                                    month: "numeric",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                });
                            }
                        });
                        dispatch(addSEARCHCanceledRidesData(result.data, result.start));
                        setnexttoggle(true);
                        setspinnershow(false);
                        setissetCC(true);
                        setisDisabledsearch2(false);
                        return result.start;
                    } else {
                        setisDisabledsearch2(false);
                        setsearchparam('')
                        setspinnershow(false);
                        handleSnackbar(result.message);
                    }
                })
                .catch((error) => {
                    setisDisabledsearch2(false);
                    setspinnershow(false);
                    setsearchparam('')
                    handleSnackbar("Unable to get data....ERROR" + error);
                    console.log("error", error);
                });
        } else {
            handleSnackbar('Please provide valid data')
        }
    }
    if (dd.arr.length === 0) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <Card >
                    {sipnnershow && (
                        <Box sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{ width: '100%' }} />
                        </Box>
                    )}
                    <SuiButton onClick={() => {
                        fileJson(dd.secarr, "cancelled", true)
                    }

                    }>Download data by city</SuiButton>
                    <Grid container spacing={2} sx={{ p: 3 }}>

                        <Grid item xs={12} sm={4} md={4} xl={4}>
                            <SuiBox sx={{ display: 'flex', gap: 2 }}>
                                <SuiInput
                                    value={searchparam}

                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        setsearchparam(e.target.value);
                                    }}
                                    inputProps={{ type: "text" }} placeholder='Enter ARN number' />
                                {!isDisabledsearch ? (
                                    <SuiButton
                                        variant="gradient" onClick={() => {
                                            handleSubmitSearch()
                                        }} color="info"  >
                                        <FontAwesomeIcon icon={faSearch} />
                                    </SuiButton>
                                ) : null}

                                {arrayData.length === 1 ? (
                                    <SuiButton
                                        variant="gradient" onClick={() => {
                                            setsearchparam("");
                                            setisDisabledsearch(false);
                                            setarrayData(dd.arr);
                                            setspinnershow(false);

                                        }} color="info"  >
                                        Clear
                                    </SuiButton>

                                ) : null}
                            </SuiBox>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={6}>
                            <SuiBox sx={{ display: 'flex', gap: 2, width: '100%' }}>
                                {userinfo.countries.length !== 0 ? (
                                    <SuiSelect
                                        widths={'15vw'}
                                        required
                                        placeholder={'Query by country'}
                                        value={selectCountry !== null ? { value: selectCountry, label: selectCountry } : null}
                                        options={userinfo.countries.map((data2) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            setselectCountry(e.value);
                                            dispatch(CLEANSEARCHCanceledRidesData());
                                            setselectCity(null)
                                        }}
                                    />
                                ) : null}
                                {selectCountry !== null ? userinfo.isadmin ? (
                                    <SuiSelect
                                        required
                                        widths={'16vw'}
                                        placeholder={'Query by city'}
                                        value={selectCity !== null ? { value: selectCity, label: selectCity } : null}
                                        options={userinfo.allcities[selectCountry].map((data2, i) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            setselectCity(e.value);
                                            dispatch(CLEANSEARCHCanceledRidesData());

                                        }}
                                    />
                                ) : (
                                    <SuiSelect
                                        required
                                        widths={'16vw'}
                                        placeholder={'Query by city'}
                                        value={selectCity !== null ? { value: selectCity, label: selectCity } : null}
                                        options={userinfo.allcities.map((data2, i) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            setselectCity(e.value);
                                            dispatch(CLEANSEARCHCanceledRidesData());

                                        }}
                                    />
                                ) : null}

                                <SuiButton
                                    isDisabled={isDisabledsearch2}
                                    variant="gradient" onClick={() => {
                                        handleSubmitSearchcitycountry()
                                    }} color="info"  >
                                    <FontAwesomeIcon icon={faSearch} />
                                </SuiButton>

                                {issetCC ? (
                                    <SuiButton
                                        variant="gradient" onClick={() => {
                                            setselectCountry(null);
                                            setselectCity(null);
                                            setisDisabledsearch2(false);
                                            setissetCC(false)
                                            dispatch(CLEANSEARCHCanceledRidesData());
                                            setarrayData(dd.arr);
                                            setspinnershow(false);

                                        }} color="info"  >
                                        Clear
                                    </SuiButton>

                                ) : null}
                            </SuiBox>
                        </Grid>


                        <Grid item xs={12} sm={12} md={2} xl={2}>
                            <SuiBox>
                                <SuiButton sx={{ float: 'right' }} variant="gradient" onClick={() => {
                                    handleOpen()
                                }} color="info"  >
                                    <FontAwesomeIcon icon={faDownload} />                                 </SuiButton>            </SuiBox>
                        </Grid>

                    </Grid>
                    <DataTable table={arrayData}

                        hidecolumnsdata={[
                            "coupon",
                            "verified_on",
                            "completed_on",
                            "type",
                            "required_seats",
                            "coupon",
                            "rate_card.tax",
                            "ride_cost",
                            "africar_net_gain",
                            "driver_commission",
                            "africar_gain",
                            "vendoxr",
                            "ratings",
                            "couxpon",
                            "payment_method",
                            "rate_card.base_fare",
                            "rate_card.distance_fare",
                            "rate_card.time_fare",
                            "distance_str",
                            "city",
                            "coupon_name",
                        ]}
                        functioncallcount={calldataall_ridersRepeat}

                        datacolumns={[
                            {
                                Header: "ARN number   ",
                                accessor: "ARN",
                            },
                            {
                                Header: "Cancelled By ",
                                accessor: "cancelled_by",
                            },
                            {
                                Header: "Booking time",
                                accessor: "booked_on",
                            },
                            {
                                Header: "Accepted Time",
                                accessor: "accepted_on",
                            },
                            {
                                Header: "rider name",
                                accessor: "rider_fname",
                            },
                            {
                                Header: "Driver name",
                                accessor: "driver_name",
                            },
                            {
                                Header: "registration number",
                                accessor: "registration_number",
                            },
                            {
                                Header: "pickup location",
                                accessor: "pickup_str",
                                tooltip: true

                            },
                            {
                                Header: "drop location",
                                accessor: "drop_str",
                                tooltip: true

                            },
                            {
                                Header: "Type of Booking",
                                accessor: "type",
                            },
                            {
                                Header: "Seats-1/2",
                                accessor: "required_seats",
                            },

                            {
                                Header: "Total Distance ",
                                accessor: "distance_str",
                            },
                            {
                                Header: "payment method",
                                accessor: "payment_method",
                            },
                            {
                                Header: "base fare",
                                accessor: "rate_card.base_fare",
                            },
                            {
                                Header: "Distance Fare",
                                accessor: "rate_card.distance_fare",
                            },
                            {
                                Header: "Time Fare",
                                accessor: "rate_card.time_fare",
                            },

                            {
                                Header: "VAT",
                                accessor: "rate_card.tax",
                            },
                            {
                                Header: "Total Fare",
                                accessor: "ride_cost",
                            },
                            {
                                Header: "Commissions",
                                accessor: "africar_net_gain",
                            },
                            //
                            {
                                Header: "Driver earnings",
                                accessor: "driver_commission",
                            },
                            {
                                Header: "coupon charges",
                                accessor: "coupon",
                            },
                            {
                                Header: "Coupon Name",
                                accessor: "coupon_name",
                            },
                            {
                                Header: "Rider Ratings",
                                accessor: "vendoxr",
                            },
                            {
                                Header: "Driver Ratings",
                                accessor: "ratings",
                            },
                            {
                                Header: "City",
                                accessor: "city",
                            },
                        ]}


                        datalength={dd.TotalCount} canSearch />
                </Card>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition

                >
                    <Fade in={open}>
                        <Box sx={
                            {
                                border: 0,
                                borderRadius: '10px',
                                boxShadow: '0 0 15px rgb(0 0 0 / 15%), 0 0 1px 1px rgb(0 0 0 / 10%)',
                                position: 'absolute',
                                top: '40%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '50vw',
                                pointerEvents: 'auto',
                                backgroundColor: '#fff',
                                backgroundClip: 'padding-box',
                                outline: 0,
                                padding: '20px 50px'
                            }
                        } >
                            <Typography style={{
                                borderBottom: '1px solid #ddd',
                                padding: '20px',
                                textAlign: 'center',
                                display: 'block!important'
                            }}>
                                Cancelled Ride data

                            </Typography>
                            <p style={{ textAlign: 'center' }}>
                                Please Select the Ride time line. Ride Data will be Download in CSV File...
                            </p>
                            {isDisabled && (<>
                                <Typography sx={{ mt: 2, textAlign: 'center' }}>
                                    Processing data... <CircularProgress size={20} color="primary" />
                                </Typography></>
                            )}

                            <Grid container spacing={2} sx={{ p: 3 }}>
                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                    {userinfo.countries.length !== 0 ? (
                                        <SuiSelect
                                            required
                                            placeholder={'Query by country'}
                                            value={selectCountrydownload !== null ? { value: selectCountrydownload, label: selectCountrydownload } : null}
                                            options={userinfo.countries.map((data2) => ({ value: data2, label: data2 }))}
                                            onChange={(e) => {
                                                setselectCountrydownload(e.value);
                                                setselectCitydownload(null)
                                            }}
                                        />
                                    ) : null}
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                    {selectCountrydownload !== null && userinfo.isadmin && userinfo.allcities[selectCountrydownload].length !== 0 ? (
                                        <SuiSelect
                                            required
                                            placeholder={'Query by city'}
                                            value={selectCitydownload !== null ? { value: selectCitydownload, label: selectCitydownload } : null}
                                            options={userinfo.allcities[selectCountrydownload].map((data2, i) => ({ value: data2, label: data2 !== '' ? data2 : 'All city' }))}
                                            onChange={(e) => {
                                                setselectCitydownload(e.value);

                                            }}
                                        />
                                    ) : null}
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                    <SuiInput
                                        value={fromdate}
                                        onChange={(e) => {
                                            setfromdate(e.target.value);
                                        }}
                                        inputProps={{ type: "date" }} />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} xl={6}>
                                    <SuiInput
                                        value={todate}
                                        onChange={(e) => {
                                            settodate(e.target.value);
                                        }}
                                        inputProps={{ type: "date" }} />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} xl={6}>

                                    <SuiButton sx={{ float: 'middel' }}
                                        disabled={isDisabled}
                                        variant="gradient" onClick={() => {
                                            DownloadDataSubmit();
                                        }} color="info"  >
                                        Download csv file  <span style={{ marginLeft: '10px' }}><FontAwesomeIcon sx={{ ml: 2 }} icon={faDownload} />  </span>   </SuiButton>
                                </Grid>

                                <Grid item xs={6} sm={6} md={6} xl={6}>

                                    <SuiButton sx={{ float: 'right' }} variant="gradient" onClick={() => {
                                        handleClose()
                                    }} color="info"  >
                                        close
                                    </SuiButton>
                                </Grid>

                            </Grid>

                        </Box>
                    </Fade>
                </Modal>
                <CustomizedSnackbars message={snackmessage} open={snakopen} color={'error'} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default CancelledRides;
