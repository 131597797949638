
/////////////////////////////////////////////////////////
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { useAuth } from "CustomComponents/Authcontext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'
import { addCollectionDrivers, addSEARCHCollectionsDriversData, CLEANSEARCHCollectionsDriversData } from "../../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import CustomizedSnackbars from "../../Alert/Alert";

function CollectionsDrivers() {
    const dd = useSelector((state) => state.AccountsCollectionDrivers);
    const userinfo = useSelector((state) => state.Userinfo);
    const dispatch = useDispatch();
    const [nextkey, setnextkey] = useState(dd.nextKey || "");
    const [messagttouser, setmessagttouser] = useState("Loading Data...");
    const [nexttoggle, setnexttoggle] = useState(true);
    const [sipnnershow, setspinnershow] = useState(false);
    const { currentUser } = useAuth();
    //state country and city
    const [issetCC, setissetCC] = useState(false)

    //admin 
    const [selectCountry, setselectCountry] = useState(null);
    //data date
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);
    const [isDisabled, setisDisabled] = useState(false);
    const [selectCity, setselectCity] = useState(null);
    const [searchparam, setsearchparam] = useState("");
    //dataexchange
    const [arrayData, setarrayData] = useState(dd.arr);
    const [isDisabledsearch, setisDisabledsearch] = useState(false);
    const [isDisabledsearch2, setisDisabledsearch2] = useState(false);
    //download
    const [selectCitydownload, setselectCitydownload] = useState(null);
    const [selectCountrydownload, setselectCountrydownload] = useState(null);

    //model handlers
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    //snack bar
    const [snakopen, setsnakopen] = useState(false)
    const [snackmessage, setsnackmessage] = useState(null)

    const handleSnackbar = (messages) => {
        setsnakopen(true)
        setsnackmessage(messages)
    };
    const datesecond = (e) => {
        return new Date(e).getTime() / 1000;
    };

    useEffect(() => {
        if (dd.arr.length === 0) {
            calldataall_riders(0, 10);
        } else {
            if (issetCC) {
                setarrayData(dd.secarr);
            } else {
                setarrayData(dd.arr);
            }
        }
    }, [dd]);
    const fileJson = async (JSONData, ReportTitle, ShowLabel) => {
        var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

        var CSV = "";
        var datasest = [];
        datasest = arrData.map((data) => {

            if (!issetCC) {
                return {
                    "Driver name": data.name,
                    "Driver id": data.email,
                    "Amount Collected": data.amount_collected,
                    "Amount Due": data.amount_due,
                    "Travelled today (km)": data.travelled_today,
                    "Travelled till date (km)": data.travelled_till_date,
                    "last paid on": data.last_paid_on,
                };
            } else {
                return {
                    "Driver name": data.name,
                    "Driver id": data.email,
                    "Amount Collected": data.amount_collected,
                    "Amount Due": data.amount_due,
                    "Travelled today (km)": data.travelled_today,
                    "Travelled till date (km)": data.travelled_till_date,
                    "Amount unpaid till date": data.amount_unpaid_till_date,
                    "Amount unpaid": data.amount_unpaid,
                    "last paid on": data.last_paid_on,
                };
            }

        });

        const items = datasest;
        const replacer = (key, value) => (value === null ? " " : value); // specify how you want to handle null values here
        const header = Object.keys(items[0]);
        CSV = [
            header.join(","), // header row first
            ...items.map((row) =>
                header
                    .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                    .join(",")
            ),
        ].join("\r\n");
        if (CSV == "") {
            alert("Invalid data");
            return;
        }
        //Generate a file name
        var fileName = "Report_";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += ReportTitle.replace(/ /g, "_");

        //Initialize file format you want csv or xls
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setisDisabled(false);
    };
    async function calldataall_ridersRepeat(e) {
        if (e >= 10) {
            var da = 0;
            if (dd.nextKey !== 0) {
                da = dd.nextKey;
            } else {
                da = nextkey;
            }
            if (da !== null && nexttoggle) {
                if (!issetCC) {
                    da = await calldataall_riders(da || dd.nextKey, e);
                } else {
                    handleSubmitSearchcitycountry(dd.secnextKey, e)
                }
            }
        }

    }
    async function calldataall_riders(nexttokenparam, limit = 20) {
        setnexttoggle(false);
        setspinnershow(true);

        if (nexttokenparam === undefined) {
            nexttokenparam = dd.nextKey || nextkey;
        }
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        var resultfetch;

        await fetch(
            process.env.REACT_APP_BASE_URL + "/accounts?type=collections&sub_type=drivers&start=" +
            nexttokenparam + '&limit=' + limit + '&till_date=',
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === true && result.data.length !== 0) {
                    result.data.map((dat) => {
                        if (dat.last_paid_on !== null) {
                            dat.last_paid_on = new Date(dat.last_paid_on * 1000).toLocaleString("en-US", {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                            });
                        }
                        //.toFixed(2)
                        dat.travelled_till_date = dat.travelled_till_date.toFixed(2);
                        dat.travelled_today = dat.travelled_today.toFixed(2)

                    })
                    dispatch(addCollectionDrivers(result.data, result.start));
                    setnextkey(result.start);
                    setnexttoggle(true);
                    setspinnershow(false);
                    resultfetch = result.start;
                    setnextkey(resultfetch.start);

                } else {
                    setspinnershow(false);
                    setnexttoggle(false);
                    alert("No Data To Load");
                    resultfetch = null;
                }
            })
            .catch((error) => {
                setnexttoggle(false);
                setmessagttouser("Unable to get data....ERROR");
                console.log("error", error);
            });
        //
        return resultfetch;
    }
    ///function call search

    async function handleSubmitSearchcitycountry(led, limit = 10) {

        if (todate !== null) {
            setnexttoggle(false);
            setspinnershow(true);
            setisDisabledsearch2(false);
            setisDisabledsearch2(true);
            setspinnershow(true);
            var url = process.env.REACT_APP_BASE_URL + "/accounts?type=collections&sub_type=drivers&start=" +
                led + '&limit=' + limit + '&till_date=' + datesecond(todate)

            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            fetch(
                url,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    if (result.status === true && result.data.length !== 0) {
                        result.data.map((dat) => {
                            if (dat.last_paid_on !== null) {
                                dat.last_paid_on = new Date(dat.last_paid_on * 1000).toLocaleString("en-US", {
                                    day: "numeric",
                                    month: "numeric",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                });
                            }
                            //.toFixed(2)
                            dat.travelled_till_date = dat.travelled_till_date.toFixed(2);
                            dat.travelled_today = dat.travelled_today.toFixed(2)

                        })
                        setissetCC(true);

                        dispatch(addSEARCHCollectionsDriversData(result.data, result.start));
                        setnexttoggle(true);
                        setspinnershow(false);

                        setisDisabledsearch2(false);

                        return result.start;
                    } else {
                        setisDisabledsearch2(false);
                        handleSnackbar('No data to uplode' + result.message);

                        setspinnershow(false);
                        setnexttoggle(false);
                        return null;
                    }

                })
                .catch((error) => {
                    setisDisabledsearch2(false);
                    setspinnershow(false);
                    setsearchparam('')
                    handleSnackbar("Unable to get data....ERROR" + error);
                    console.log("error", error);
                });
        } else {
            handleSnackbar('Please provide valid data')
        }
    }
    if (dd.arr.length === 0) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <Card >
                    {sipnnershow && (
                        <Box sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{ width: '100%' }} />
                        </Box>
                    )}
                    <Grid container spacing={2} sx={{ p: 3 }}>

                        <Grid item xs={12} sm={4} md={4} xl={4}>
                            <SuiInput
                                value={todate}
                                onChange={(e) => {
                                    settodate(e.target.value);
                                }}
                                inputProps={{ type: "date" }} placeholder='' />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={6}>
                            <SuiButton
                                isDisabled={isDisabledsearch2}
                                variant="gradient" onClick={() => {
                                    handleSubmitSearchcitycountry(0)
                                }} color="info"  >
                                <FontAwesomeIcon icon={faSearch} />
                            </SuiButton>

                            {issetCC ? (
                                <SuiButton
                                    variant="gradient" onClick={() => {
                                        setselectCountry(null);
                                        setselectCity(null);
                                        setisDisabledsearch2(false);
                                        setissetCC(false)
                                        dispatch(CLEANSEARCHCollectionsDriversData());
                                        setarrayData(dd.arr);
                                        setspinnershow(false);

                                    }} color="info"  >
                                    Clear
                                </SuiButton>) : ''}
                        </Grid>


                        <Grid item xs={12} sm={12} md={2} xl={2}>
                            <SuiBox>
                                <SuiButton sx={{ float: 'right' }} variant="gradient" onClick={() => {
                                    if (issetCC) {
                                        fileJson(dd.secarr, `Collection_Driver${todate}`);
                                    } else {
                                        fileJson(dd.arr, `Collection_Driver`);
                                    }
                                }} color="info"  >
                                    <FontAwesomeIcon icon={faDownload} />
                                </SuiButton>
                            </SuiBox>
                        </Grid>

                    </Grid>
                    <DataTable
                        table={arrayData}
                        hidecolumnsdata={[

                        ]}
                        functioncallcount={calldataall_ridersRepeat}
                        datacolumns={!issetCC ? [
                            {
                                Header: "Driver name",
                                accessor: "name",
                            },
                            {
                                Header: "Driver id",
                                accessor: "email",
                            },
                            {
                                Header: "Amount Collected",
                                accessor: "amount_collected",
                            },

                            {
                                Header: "Amount Due",
                                accessor: "amount_due",
                            },
                            {
                                Header: "amount paid ",
                                accessor: "amount_paid",
                            },
                            {
                                Header: "travelled today (km)",
                                accessor: "travelled_today",
                            },
                            {
                                Header: "travelled till date (km)",
                                accessor: "travelled_till_date",
                            },

                            {
                                Header: "last paid on",
                                accessor: "last_paid_on",
                            },
                        ] : [
                            {
                                Header: "Driver name",
                                accessor: "name",
                            },
                            {
                                Header: "Driver id",
                                accessor: "email",
                            },
                            {
                                Header: "Amount Collected",
                                accessor: "amount_collected",
                            },

                            {
                                Header: "Total Amount Due Till date",
                                accessor: "amount_due",
                            },
                            {
                                Header: `Amount  unpaid date ${todate}`,
                                accessor: "amount_unpaid_till_date",
                                width:'500px'
                            }, {
                                Header: "Amount unpaid",
                                accessor: "amount_unpaid",
                            },
                            {
                                Header: "travelled today (km)",
                                accessor: "travelled_today",
                            },
                            {
                                Header: "travelled till date (km)",
                                accessor: "travelled_till_date",
                            },

                            {
                                Header: "last paid on",
                                accessor: "last_paid_on",
                            },
                        ]}
                        canSearch />
                </Card>

                <CustomizedSnackbars message={snackmessage} open={snakopen} color={'error'} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default CollectionsDrivers;
