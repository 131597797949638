
const initailState = null
  
const Localization=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_Location_DATA':
            return action.payload
        default:
            return state;
    }
}

export default Localization