import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import { addpermition } from "../../CustomComponents/dataStore/actions";
import SuiTypography from "components/SuiTypography";
import { useLocation, Link, useRouteMatch } from "react-router-dom";
import SuiButton from "components/SuiButton";
import {
    useHistory,
} from "react-router-dom";
import { useAuth } from "CustomComponents/Authcontext";

function UserRoleHierarchy() {
    const dd = useSelector(state => state.userhie)
    const dispatch = useDispatch();
    const [messagttouser, setmessagttouser] = useState("Loading Data...")
    let history = useHistory();
    let { path, url } = useRouteMatch();
    const { currentUser } = useAuth();

    useEffect(() => {
        if (dd.arr.length === 0) {
            HierarchydataCall();
        }

    }, [0]);
    function HierarchydataCall() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + currentUser.accessToken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_BASE_URL+"/role?type=roles", requestOptions)
            .then(response => response.json())
            .then(result => {

                var dataresult = result.data;
                var arrayvalue = []
                var objectvalue = {}
                result.data.roles_created.map((roles) => {
                    const dda = Object.entries(dataresult[roles]);
                    const hierarchy_reverse = Object.entries(dataresult.hierarchy_reverse);
                    hierarchy_reverse.map((hir) => {
                        if (roles === hir[0]) {
                            objectvalue.parent = hir[1]
                        }
                    })


                    dda.map((dd) => {
                        objectvalue.name = roles
                        objectvalue[dd[0]] = dd[1].toString();
                    })
                    arrayvalue.push(objectvalue)
                    objectvalue = {}
                })
                arrayvalue.map((data) => {
                    data.edit = (
                        <div >
                            {/* use this button to add a edit kind of action */}
                            <SuiButton
                                onClick={() => {
                                    history.push({
                                        pathname: '/'+url.split('/')[1]+'/editUserRole',
                                        customCRCData: data,
                                    })
                                }}
                                color="warning"
                            >
                                edit
                            </SuiButton>{" "}
                           
                        </div>
                    );
                    data.action = (
                        <div >
                            <SuiButton
                                onClick={() => {
                                    history.push({
                                        pathname: '/' + url.split('/')[1] + '/userRoleHierarchyDetails',
                                        state: { id: data.name },
                                    })
                                }}
                                color="info"
                            >
                                view
                            </SuiButton>{" "}
                        </div>
                    );
                   
                });
                dispatch(addpermition(arrayvalue));

            })
            .catch(error => {
                setmessagttouser('error');

                console.log('error', error)
            });

    }



    if (dd.arr.length === 0) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card sx={{ px: 3, m: 1 }}>
                        <h5 style={{ display: 'inline-block' }}>User Role Hierarchy </h5>

                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={1} pb={3}>
                <SuiTypography variant="h4" sx={{ ml: 2, p: 0.5 }}>Hierarchy details
                </SuiTypography>
                <Card sx={{ mt: 2 }}>

                    <DataTable
                        table={dd.arr}
                        hidecolumnsdata={[

                        ]}
                        functioncallcount={() => { }}
                        LoadData={false}
                        datacolumns={[
                            {
                                Header: "User Under Role",
                                accessor: "action",
                            },
                            {
                                Header: "User Role Name",
                                accessor: "name",
                            },
                            {
                                Header: "war room",
                                accessor: "war_room",
                            },
                            {
                                Header: "add users",
                                accessor: "add_users",
                            },
                            {
                                Header: "city rate card",
                                accessor: "city_rate_card",

                            },
                            {
                                Header: "Push notifications",
                                accessor: "push_notifications",
                            },

                            {
                                Header: "vehicles",
                                accessor: "vehicles",
                            },
                            {
                                Header: "coupons",
                                accessor: "coupons",
                            }, {
                                Header: "rides",
                                accessor: "rides",
                            }, {
                                Header: "vendor",
                                accessor: "vendor",
                            }, {
                                Header: "drivers",
                                accessor: "drivers",
                            }, {
                                Header: "riders",
                                accessor: "riders",
                            },
                            {
                                Header: "parent",
                                accessor: "parent",
                            },
                            {
                                Header: "edit",
                                accessor: "edit",
                            },
                            
                        ]}
                        datalength={0} />
                </Card>

            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default UserRoleHierarchy;
