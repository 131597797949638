/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Fab from '@mui/material/Fab';
import SuiInput from "components/SuiInput";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useAuth } from "CustomComponents/Authcontext";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';// Wizard page components
// import Address from "./wizardpages";
import Box from '@mui/material/Box';
import { storage } from "CustomComponents/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

function Wizard1() {
    const [activeStep, setActiveStep] = useState(0);
 
    const [uplode1, setuplode1] = useState({ label: "", value: 0 });
const [imgurl, setimgurl] = useState()
    async function fileuplode(e, id, upoldevalue) {
        e.preventDefault();
        if (e.target.files[0] !== undefined) {
            const file = e.target.files[0];
            const metadata = {
                contentType: file.type,
            };
            var fileurl = URL.createObjectURL(file)

            var timese = Math.floor(new Date().getTime() / 1000);

            // const mountainsRef = ref(storage, "notificationImage/" + timese + id + file.name);

            const mountainsRef = ref(storage, "TESTUPLOAD_Dinesh/" + timese + id + file.name);
            const uploadTask = uploadBytesResumable(mountainsRef, file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    upoldevalue({ label: fileurl, value: prog });
                },
                (error) => console.log(error),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setimgurl(downloadURL)
                    });
                }
            );
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={3} pb={8}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={8}>
                        <SuiBox mt={6} mb={1} textAlign="center">
                            <SuiBox >
                                <SuiTypography variant="h3" fontWeight="bold">
                                    Image Notification
                                </SuiTypography>
                            </SuiBox>
                        
                        </SuiBox>
                    </Grid>
                    <Grid item xs={12} lg={12} sx={{ px: 5, mx: 'auto' }}
                    >
                        <Box                             sx={{width:'50%',mx:'auto' }}
>
                            <SuiTypography sx={{ fontSize: '0.9rem', display: 'flex', justifyContent: 'center', }}  >Uplode image</SuiTypography >
                            <SuiInput required
                                onChange={(e) => {
                                    fileuplode(
                                        e,
                                        "Notification",
                                        setuplode1
                                    );
                                }}
                                type="file"
                            />
                        </Box>
                        {uplode1.value !== 0 ? <Grid item md={12} xl={12} lg={12} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                            <img style={{ height: '10rem', width: '100%', borderRadius: '50%' }} src={uplode1.label} alt="preview image" />
                            <SuiTypography sx={{ fontSize: '0.9rem', }}  >profile picture uploded {uplode1.value}%</SuiTypography >
                        </Grid> : null}
                        {uplode1.value !== 0 ? <Grid item md={6} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                                        <img style={{ height: '10rem', width: '100%' }} src={uplode1.label} alt="preview image" />
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode1.value}%</SuiTypography >
                                    </Grid> : null}
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <SuiTypography sx={{ fontSize: '0.9rem', }}  > Topic</SuiTypography >

                        <SuiInput required

                            value={''}
                            onChange={(e) => {

                            }}
                            placeholder="Enter topic"
                            type="text"
                        />


                    </Grid>

                </Grid>
            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default Wizard1;
