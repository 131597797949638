import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// Data
import { useAuth } from "CustomComponents/Authcontext";
import CustomizedSnackbars from "../Alert/Alert";


import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import { useSelector, useDispatch } from "react-redux";

function AddUsers() {
    const userinfo = useSelector((state) => state.Userinfo);

    const [headdata, setheaddata] = useState(null);
    const [parentdataselected, setparentdataselected] = useState(null)
    const [errormessage, seterrormessage] = useState(null)
    //userdetails
    const [emailid, setemailid] = useState('')
    const [password, setpassword] = useState('')
    const [username, setusername] = useState('')
    const [buttonstatus, setbuttonstatus] = useState(false)
    ///response data
    const [Responsedata, setResponsedata] = useState()
    const [Secondparametershow, setSecondparametershow] = useState(false)

    //second set of data
    const [secondselectvalue, setsecondselectvalue] = useState(null)
    const [secondselectvaluename, setsecondselectvaluename] = useState(null)

    //third setdata
    const [show3, setshow3] = useState(false)
    const [selectvalue3, setselectvalue3] = useState()
    const [Responsedata3, setResponsedata3] = useState()

    //fourth setdata
    const [show4, setshow4] = useState()
    const [selectvalue4, setselectvalue4] = useState()
    const [Responsedata4, setResponsedata4] = useState()

    //five setdata
    const [show5, setshow5] = useState()
    const [selectvalue5, setselectvalue5] = useState()
    const [Responsedata5, setResponsedata5] = useState()
    //rawdata
    const [datafetch2, setdatafetch2] = useState()
    const [datafetch3, setdatafetch3] = useState()
    const [datafetch4, setdatafetch4] = useState()
    const { currentUser } = useAuth();
    //city country
    const [selectCountry, setselectCountry] = useState(null);
    const [selectCity, setselectCity] = useState([]);
    //snack bar
    const [snakopen, setsnakopen] = useState(false)
    const [snackmessage, setsnackmessage] = useState(null)
    const [snackcolor, setsnackcolor] = useState('error');
    const handleSnackbar = (messages) => {
        setsnakopen(true)
        setsnackmessage(messages)
    };
    //


    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BASE_URL+"/add_users",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === true) {
                    setheaddata(result);
                }
                else {
                    seterrormessage(result.message)
                }
            })
            .catch((error) => console.log("error", error));
    }, [0]);

    const apicall = (data, type) => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            role_name: data,
            type: type,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BASE_URL+"/add_users",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === false) {
                    handleSnackbar(result.message)
                   
                } else {
                    setResponsedata(result)
                    setSecondparametershow(true);
                    setsecondselectvalue(null)
                    setselectvalue4(null)
                    setselectvalue3(null)
                    setshow3(false)
                    setshow4(false)
                }
            })
            .catch((error) => console.log("error", error));
    }
    const apicall2 = (data, val, type, seatefunction) => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            role_name: parentdataselected,
            type: type,
            [data]: val
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        fetch(
            process.env.REACT_APP_BASE_URL+"/add_users",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === false) {

                    handleSnackbar(result.message);

                } else {
                    seatefunction(result)
                    setselectvalue4(null)
                    setselectvalue3(null)
                    setshow4(false)
                    setshow3(true);
                }
            })
            .catch((error) => console.log("error", error));
    }
    const apicall3 = (data, val, type, seatefunction4) => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            role_name: parentdataselected,
            type: type,
            [data]: val
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        fetch(
            process.env.REACT_APP_BASE_URL+"/add_users",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === false) {

                    handleSnackbar(result.message);
                   
                } else {
                    seatefunction4(result)
                    setselectvalue4(null)
                    setshow4(true)
                }
            })
            .catch((error) => {
                handleSnackbar(error.message)
                console.error();
                console.log("error", error)
            });
    }

    const apicall4 = (data, val, type, seatefunction5) => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            role_name: parentdataselected,
            type: type,
            [data]: val
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        fetch(
            process.env.REACT_APP_BASE_URL+"/add_users",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === false) {
                    handleSnackbar(result.message);
                   
                } else {
                    seatefunction5(result)
                    setshow5(true)
                }
            })
            .catch((error) => {
                handleSnackbar(error.message)
                console.error();
                console.log("error", error)
            });
    }

    const handleFirstselect = (e) => {
        if (e.value != '') {
            apicall(e.value, 'one')
            setparentdataselected(e.value);
            setshow3(false)
            setshow4(false)

        } else {
            setparentdataselected(null);
            setSecondparametershow(false)
            setshow3(false)
            setshow4(false)
        }

    }
    const handleSecondselect = (e) => {
        if (e.value !== '') {
            setdatafetch2({ [Responsedata.data_role]: e.value })
            apicall2(Responsedata.data_role, e.value, 'one', setResponsedata3)
            setsecondselectvalue(e.value)
            setsecondselectvaluename(e.label)
        } else {
            setsecondselectvalue(null);
            setsecondselectvaluename(null)

            setdatafetch2({})
            setshow3(false)
            setshow4(false)
            setshow5(false)


        }
    }
    const handlethirdselect = (e) => {
        if (e.value !== '') {
            setdatafetch3({ [Responsedata3.data_role]: e.value })
            apicall3(Responsedata3.data_role, e.value, 'one', setResponsedata4)
            setselectvalue3(e.value)
        } else {
            setselectvalue3('')
            setdatafetch3({})

        }

    }
    const handlefourthselect = (e) => {
        if (e.value != '') {
            setdatafetch4({ [Responsedata4.data_role]: e.value })
            apicall4(Responsedata4.data_role, e.value, 'one', setResponsedata5)
            setselectvalue4(e.value)

        } else {
            setselectvalue4('')
            setdatafetch4({})

        }

    }
    //form submition
    const handelsubmisition = (e) => {
        e.preventDefault()
        if (emailid !== '' && password !== '' && username !== '' && parentdataselected !== null) {
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            myHeaders.append("Content-Type", "application/json");



            var dataselect = {
                ...datafetch2,
                ...datafetch3,
                ...datafetch4,
            }
          var  cities=[]
            selectCity.map((d)=>cities.push(d.value))
            var dataBody = {
            
                role_name: parentdataselected,
                data: dataselect,
                type: 'two'
            }
            var userdetails = {
                email: emailid,
                password: password,
                name: username,
                cities:cities,
                countries:[selectCountry],
            }
            dataBody.user_details = userdetails
            var raw = JSON.stringify(dataBody);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };
            fetch(
                process.env.REACT_APP_BASE_URL+"/add_users",
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === false) {
                        handleSnackbar(result.message);

                    } else {
                        setsnackcolor('success');
                        handleSnackbar("User Created Successfully");
                        setselectCity(null)
                        setselectCountry(null)
                        setparentdataselected(null);
                        setSecondparametershow(false)
                        setsecondselectvalue(null);
                        setsecondselectvaluename(null)
                        setselectvalue3('')
                        setdatafetch3({})
                        setdatafetch2({})
                        setshow3(false)
                        setshow4(false)
                        setshow5(false)
                        setselectvalue4('')
                        setdatafetch4({})
                    }
                })
                .catch((error) => console.log("error", error));
        } else {
            var message = parentdataselected === null ? "Please Select User Role " : "Please fill the form"
            handleSnackbar(message)
        }
    }
    if (headdata === null) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{"Loading data..."}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>)
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <SuiBox pt={2} pb={3}>
                <form autoComplete="off" onSubmit={handelsubmisition} className="w-100">

                    <Card sx={{ m: 'auto', p: 2, py: 4 }}>
                        <h4>Create User</h4>
                        <Grid container spacing={2} px={2}>

                            <Grid item md={12} px={2}>
                                <span>{errormessage !== null ? errormessage : null}</span>

                                <Grid container spacing={2} px={2}>
                                    <Grid item md={6} px={2} sm={12} xs={12} xl={6} lg={6} >
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select Country</SuiTypography>

                                        {userinfo.countries.length !== 0 ? (
                                            <SuiSelect
                                                required
                                                placeholder={'Query by country'}
                                                value={selectCountry !== null ? { value: selectCountry, label: selectCountry } : null}
                                                options={userinfo.countries.map((data2) => ({ value: data2, label: data2 }))}
                                                onChange={(e) => {
                                                    setselectCountry(e.value);
                                                    setselectCity(null)
                                                }}
                                            />
                                        ) : null}
                                    </Grid>

                                    {selectCountry !== null ?
                                        <Grid item md={6} px={2} sm={12} xs={12} xl={6} lg={6} >
                                            <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select City</SuiTypography>
                                            <SuiSelect
                                                required
                                                isMulti
                                                placeholder={'Query by city'}
                                                value={selectCity !== null ? selectCity : null}
                                                options={userinfo.allcities[selectCountry].map((data2, i) => ({ value: data2, label: data2 }))}
                                                onChange={(e) => {
                                                    console.log(e);
                                                    setselectCity(e);
                                                }}
                                            />
                                        </Grid> : <Grid item md={6} px={2} sm={12} xs={12} xl={6} lg={6} />
                                    }
                                    <Grid item md={6} px={2} >
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select User Role</SuiTypography>
                                        <SuiSelect
                                            defaultValue={{ value: '', label: '' }}
                                            // isDisabled={parentdataselected !== null}
                                            opacity={parentdataselected === null ? 1 : .6}
                                            value={{ value: parentdataselected, label: parentdataselected }}
                                            options={headdata.response.map((entry) => ({ value: entry, label: entry }))}
                                            onChange={handleFirstselect}
                                            inputProps={{
                                                autocomplete: 'new-password',
                                            }}
                                        />
                                    </Grid>
                                    {Secondparametershow && Responsedata && Responsedata.data_role !== undefined ? (
                                        <Grid item md={6} px={2}>
                                            <SuiTypography sx={{ fontSize: '0.9rem', }}  >{Responsedata.data_role}</SuiTypography>
                                            <SuiSelect
                                                // isDisabled={secondselectvalue !== null}
                                                opacity={secondselectvalue === null ? 1 : .6}
                                                value={{ value: secondselectvalue, label: secondselectvaluename }}
                                                options={Responsedata.data[Responsedata.data_role].map((data2, i) => ({ value: data2.id, label: data2.name }))}
                                                onChange={handleSecondselect}
                                            />
                                        </Grid>) : null
                                    }
                                    {show3 && Responsedata3 && Responsedata3.data_role !== undefined ? (<Grid item md={6} px={2}>

                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >{Responsedata3.data_role}</SuiTypography>
                                        <SuiSelect
                                            // isDisabled={selectvalue3 !== null}
                                            opacity={selectvalue3 === null ? 1 : .6}
                                            value={{ value: selectvalue3, label: selectvalue3 }}
                                            options={Responsedata3.data[Responsedata3.data_role].map((data2, i) => ({ value: data2.id, label: data2.name }))}
                                            onChange={handlethirdselect}
                                        />
                                    </Grid>) : null

                                    }
                                    {show4 && Responsedata4 && Responsedata4.data_role !== undefined ? (<Grid item md={6} px={2}>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >{Responsedata4.data_role}</SuiTypography>
                                        <SuiSelect
                                            // isDisabled={selectvalue4 !== null}
                                            opacity={selectvalue4 === null ? 1 : .6}
                                            value={{ value: selectvalue4, label: selectvalue4 }}
                                            options={Responsedata4.data[Responsedata4.data_role].map((data2, i) => ({ value: data2.id, label: data2.name }))}
                                            onChange={handlefourthselect}
                                        />
                                    </Grid>) : null
                                    }
                                    {show5 && Responsedata5 && Responsedata5.data_role !== undefined ? (<Grid item md={6} px={2}>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >{Responsedata4.data_role}</SuiTypography>
                                        <SuiSelect
                                            // isDisabled={selectvalue5 !== null}
                                            opacity={selectvalue5 === null ? 1 : .6}
                                            value={{ value: selectvalue5, label: selectvalue5 }}
                                            options={Responsedata5.data[Responsedata5.data_role].map((data2, i) => ({ value: data2.id, label: data2.name }))}
                                            onChange={(e) => {
                                                apicall4(Responsedata5.data_role, e.value, 'one', setResponsedata5)
                                                setselectvalue5(e.value)
                                            }}
                                        />
                                    </Grid>) : null
                                    }
                                </Grid>

                            </Grid>
                            <Grid item md={12}>
                                <Card item sx={{ p: 2, mt: 2 }}>
                                    <SuiTypography variant='h4' >User Profile Login Defails *</SuiTypography>

                                    <Grid container spacing={4}>

                                        <Grid item md={6}>
                                            <SuiTypography sx={{ fontSize: '0.9rem', }} >Email Address *</SuiTypography>
                                            <SuiInput required
                                                value={emailid}
                                                inputProps={{
                                                    autocomplete: 'something'
                                                }}
                                                onChange={(e) => {
                                                    setemailid(e.target.value);
                                                }}
                                                name="email" type="email" />
                                        </Grid>{" "}
                                        <Grid item md={6}>
                                            <SuiTypography sx={{ fontSize: '0.9rem', }} >Password *</SuiTypography>
                                            <SuiInput required
                                                inputProps={{
                                                    autocomplete: 'new-password',
                                                }}
                                                value={password}
                                                onChange={(e) => {
                                                    setpassword(e.target.value);
                                                }}
                                                id="Password"
                                                name="password"
                                                type="password"
                                            />
                                        </Grid>{" "}
                                        <Grid item md={6}>
                                            <SuiTypography sx={{ fontSize: '0.9rem', }} >Name</SuiTypography>
                                            <SuiInput required
                                                name="name"
                                                value={username}
                                                onChange={(e) => {
                                                    setusername(e.target.value);
                                                }}
                                                type="text"
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                        < >
                            <SuiButton sx={{ mt: 3 }} type="submit" disabled={buttonstatus}  >Add User</SuiButton>
                        </>
                    </Card>
                </form>
                <CustomizedSnackbars message={snackmessage} open={snakopen} color={snackcolor} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default AddUsers;
