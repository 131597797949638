import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { useAuth } from "CustomComponents/Authcontext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import CustomizedSnackbars from "./Alert/Alert";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { async } from "@firebase/util";


function Arides() {
    const userinfo = useSelector((state) => state.Userinfo);

    const [sipnnershow, setspinnershow] = useState(false);
    const { currentUser } = useAuth();
    const [checked, setChecked] = React.useState([true, false, false]);

    //state country and city

    //admin 
    //data date
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);
    const [isDisabled, setisDisabled] = useState(false);
    const [searchparam, setsearchparam] = useState("");
    //dataexchange
    const [isDisabledsearch, setisDisabledsearch] = useState(false);
    //download
    const [selectCitydownload, setselectCitydownload] = useState(null);
    const [selectCountrydownload, setselectCountrydownload] = useState(null);

    //model handlers
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    //snack bar
    const [snakopen, setsnakopen] = useState(false)
    const [snackmessage, setsnackmessage] = useState(null)

    const handleSnackbar = (messages) => {
        setsnakopen(true)
        setsnackmessage(messages)
    };
    async function divnlodemuitiple(params) {
        var temp = [];
        var data = [];
        if (checked[0]) temp.push('completed_rides')
        if (checked[1]) temp.push('pending_rides')
        if (checked[2]) temp.push('cancelled_rides')
        console.log(temp);
        let asdasd = ''
        await temp.map(async (d) => {
            await DownloadDataSubmit(d).then(async (dd) => {
                await data.push(dd);
            })
        })
        setTimeout(() => {
            var ss = [
                data[0], data[1], data[2]
            ].join("\r\n");
            name(ss)
        }, 3000);

    }
    function name(params) {

        var CSV = params;
        if (CSV == "") {
            alert("Invalid data");
            return;
        }
        //Generate a file name
        var fileName = "Report_";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += 'ReportTitle'.replace(/ /g, "_");

        //Initialize file format you want csv or xls
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setisDisabled(false);
    }
    const DownloadDataSubmit = async (event) => {
        if (fromdate !== null && todate !== null && fromdate !== undefined && todate !== undefined) {
            var todateseconds = new Date(todate).getTime() / 1000;
            var fromdateseconds = new Date(fromdate).getTime() / 1000;
            var url = process.env.REACT_APP_BASE_URL + `/${event}?from=${fromdateseconds}&to=${todateseconds}`;
            if (selectCitydownload !== null) {
                url = url + '&city=' + selectCitydownload
            } else if (selectCountrydownload !== null) {
                url = url + '&country=' + selectCountrydownload
            }
            setisDisabled(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            var resultfetch = {};

            await fetch(
                url,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    resultfetch = result;
                })
                .catch((error) => {
                    console.log("error", error);
                });
            if (resultfetch.status) {
                resultfetch.data.map((pro) => {
                    if (pro.coupon_used !== undefined) {
                        pro.coupon_name = pro.coupon_used.id;
                    }
                    pro.rider_fname = pro.rider_fname + " " + pro.rider_lname;

                    if (pro.coupon === true) {
                        pro.coupon = "Yes";
                    } else {
                        pro.coupon = "No";
                    }
                    if (pro.reference_id) {
                        pro.reference_id = pro.reference_id
                    } else {
                        pro.reference_id = ''
                    }
                    if (pro.accepted_on) {
                        pro.accepted_on = new Date(pro.accepted_on).toLocaleString("en-US", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                        });
                    }

                    if (pro.completed_on) {
                        pro.completed_on = new Date(pro.completed_on * 1000).toLocaleString("en-US", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                        });
                    }
                    if (pro.booked_on) {
                        pro.booked_on = new Date(pro.booked_on).toLocaleString("en-US", {
                            day: "numeric",

                            month: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                        });
                    }
                    if (pro.verified_on) {
                        pro.verified_on = new Date(pro.verified_on * 1000).toLocaleString("en-US", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                        });
                    }
                });
                return fileJson(resultfetch.data, `completed_rides`, event)

            } else {
                handleSnackbar('No data to load')
            }

        } else {
            handleSnackbar('Please select date')

            return null;
        }
    };

    const fileJson = async (JSONData, ReportTitle, ShowLabel) => {
        var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
        var headings = [
            "ARN",
            "booked_on",
            "accepted_on",
            "rider_name",
            "driver_name",
            "registration_number",
            "pickup_str",
            "drop_str",
            "type",
            "required_seats",
            "verified_on",
            "completed_on",
            "distance_str",
            "payment_method",
            "rate_card.base_fare",
            "rate_card",
            "distance_str",
            "coupon",
            "rate_card.tax",
            "ride_cost",
            "africar_net_gain",
            "driver_commission",
            "africar_gain",
            "ratings",
            "coupon_used", "cancelled_on", "cancelled_by"
        ];
        var CSV = "";
        var datasest = [];
        datasest = arrData.map((data) => {
            data.rider_name = data.rider_lname + " " + data.rider_fname;
            const header = Object.keys(data);
            var rt = data.rate_card;
            var ccard = Object.entries(data.rate_card)
            var pccard = null;
            if (data.prev_rate_card !== undefined) {
                pccard = Object.entries(data.prev_rate_card)

            }


            var ss = data.drop_str ? data.drop_str : ["Undefined"];
            var dd = data.type;
            data.ride_type = dd;
            data.drop_str = ss[0];
            ss = data.pickup_str ? data.pickup_str : ["Undefined"];
            data.pickup_str = ss[0];
            // header.map((dddd) => {
            //     if (!headings.includes(dddd)) {
            //         delete data[dddd];
            //     }
            // });

            var dam = {}
            ccard.map((df) => {
                if (typeof (df[1]) === 'object') {
                    dam = { ...dam, ...df[1] }
                } else {
                    dam['rate_card_' + df[0]] = df[1];
                }
            })
            var damp = {}
            if (pccard !== null) {
                pccard.map((df) => {
                    if (typeof (df[1]) === 'object') {
                        damp = { ...damp, ...df[1] }
                    } else {
                        damp['prev_rate_card_' + df[0]] = df[1];
                    }
                })
            }
            var damlaln = {}
            if (data.drop !== undefined) {
                Object.entries(data.drop).map((df) => {
                    if (typeof (df[1]) === 'object') {
                        damlaln = { ...damlaln, ...df[1] }
                    } else {
                        damlaln['drop_' + df[0]] = df[1];
                    }
                })
            }
            var damlaln1 = {}
            if (data.prev_drop !== undefined) {
                Object.entries(data.prev_drop).map((df) => {
                    if (typeof (df[1]) === 'object') {
                        damlaln1 = { ...damlaln1, ...df[1] }
                    } else {
                        damlaln1['prev_drop_' + df[0]] = df[1];
                    }
                })
            }
            var damlaln1pickup = {}
            if (data.pickup !== undefined) {
                Object.entries(data.pickup).map((df) => {
                    if (typeof (df[1]) === 'object') {
                        damlaln1pickup = { ...damlaln1pickup, ...df[1] }
                    } else {
                        damlaln1pickup['pickup_' + df[0]] = df[1];
                    }
                })
            }

            if (data.accepted_on) {
                data.accepted_on = new Date(data.accepted_on).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (data.completed_on) {
                data.completed_on = new Date(data.completed_on).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (data.booked_on) {
                data.booked_on = new Date(data.booked_on).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (data.verified_on) {
                data.verified_on = new Date(data.verified_on).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (data.arrived_on) {
                data.arrived_on = new Date(data.arrived_on * 1000).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (data.cancelled_on) {
                data.cancelled_on = new Date(data.cancelled_on * 1000).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            var temp = {
                ARN: data.ARN,
                "Booking Time": data.booked_on,
                "Accepted Time": data.accepted_on || '',
                "Rider name": data.rider_name,
                "Driver name": data.driver_name,
                "Pickup location": data.pickup_str,
                "Drop location": data.drop_str,
                "Ride Type": data.type,
                "Required seats": data.required_seats,
                "Actual Pickup Time": data.verified_on,
                "Actual Drop Time": data.completed_on,
                "Distance Travelled": data.distance_str,
                "Payment Method": data.payment_method,
                "Base Fare": rt.base_fare,
                "Distance Fare": rt.distance_fare,
                "Time Fare": rt.time_fare,
                VAT: rt.tax,
                "Ride Cost": data.ride_cost,
                "Africar Net Gain": data.africar_net_gain,
                "Driver Commission": data.driver_commission,
                Coupon: data.coupon,
                "Coupon Name": data.coupon_name,
                "Rider Ratings": data.ratings,
                "OTP": data.OTP,
                "ride_id": data.ride_id,
                "rider_email_id": data.rider_email_id,
                "rider_name": data.rider_fname + ' ' + data.rider_lname,
                "rider_phone_number": data.rider_phone_number,
                "App version": data.version,
                // "rider_feedback": data.rider_feedback.ratings ,
                "vehicle_brand_model": data.vehicle_brand_model,
                "vehicle_color": data.vehicle_color,
                "vehicle_id": data.vehicle_id,
                "registration_number": data.registration_number,
                "recalculated": data.recalculated ? 'true' : 'false',
                "driver_id": data.driver_id,
                "driver_name": data.driver_name,
                "dro_id": data.dro_id,
                "driver_email": data.driver_email,
                "driver_contactno": data.driver_contactno,
                "distance_from_app": data.distance_from_app,
                "distance_str": data.distance_str,
                "duration_str": data.duration_str,
                "prev_distance": data.prev_distance,
                "prev_distance_str": data.prev_distance_str,
                "prev_duration": data.prev_duration,
                "prev_original_cost": data.prev_original_cost,
                "prev_ride_cost": data.prev_ride_cost,
                'canceled by': data.cancelled_by || '',
                'canceled time': data.cancelled_on || '',
                ...dam,
                ...damp,
                ...damlaln1pickup,
                ...damlaln1,
                ...damlaln,

            };

            return temp;
        });


        const items = datasest;
        const replacer = (key, value) => (value === null ? " " : value); // specify how you want to handle null values here
        const header = Object.keys(items[0]);
        CSV = [ShowLabel + ",",
        header.join(","), // header row first
        ...items.map((row) =>
            header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(",")
        ),
        ].join("\r\n");
        return CSV
    };



    const handleChange1 = (event) => {
        setChecked([event.target.checked, checked[1], checked[2]]);
    };

    const handleChange2 = (event) => {
        setChecked([checked[0], event.target.checked, checked[2]]);
    };

    const handleChange3 = (event) => {
        setChecked([checked[0], checked[1], event.target.checked]);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={6} pb={3}>
                <Card >
                    {sipnnershow && (
                        <Box sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{ width: '100%' }} />
                        </Box>
                    )}
                    <Grid container spacing={2} sx={{ p: 3 }}>
                        <Grid item xs={6} sm={6} md={6} xl={6}>
                            {userinfo.countries.length !== 0 ? (
                                <SuiSelect
                                    required
                                    placeholder={'Query by country'}
                                    value={selectCountrydownload !== null ? { value: selectCountrydownload, label: selectCountrydownload } : null}
                                    options={userinfo.countries.map((data2) => ({ value: data2, label: data2 }))}
                                    onChange={(e) => {
                                        setselectCountrydownload(e.value);
                                        setselectCitydownload(null)
                                    }}
                                />
                            ) : null}
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} xl={6}>
                            {selectCountrydownload !== null && userinfo.isadmin && userinfo.allcities[selectCountrydownload].length !== 0 ? (
                                <SuiSelect
                                    required
                                    placeholder={'Query by city'}
                                    value={selectCitydownload !== null ? { value: selectCitydownload, label: selectCitydownload } : null}
                                    options={userinfo.allcities[selectCountrydownload].map((data2, i) => ({ value: data2, label: data2 !== '' ? data2 : 'All city' }))}
                                    onChange={(e) => {
                                        setselectCitydownload(e.value);

                                    }}
                                />
                            ) : null}
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} xl={6}>
                            <SuiInput
                                value={fromdate}
                                onChange={(e) => {
                                    setfromdate(e.target.value);
                                }}
                                inputProps={{ type: "datetime-local" }} placeholder='Enter ARN number' />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} xl={6}>
                            <SuiInput
                                value={todate}
                                onChange={(e) => {
                                    settodate(e.target.value);
                                }}
                                inputProps={{ type: "datetime-local" }} placeholder='Enter ARN number' />
                        </Grid>
                        <Grid item sm={12} md={12} xl={12} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            <FormControlLabel
                                label="completed"
                                control={<Checkbox checked={checked[0]} onChange={handleChange1} />}
                            />
                            <FormControlLabel
                                label="pending"
                                control={<Checkbox checked={checked[1]} onChange={handleChange2} />}
                            />
                            <FormControlLabel
                                label="Canceled"
                                control={<Checkbox checked={checked[2]} onChange={handleChange3} />}
                            />

                        </Grid>
                        <Grid item xs={6} sm={6} md={6} xl={6}>

                            <SuiButton sx={{ float: 'middel' }}
                                // disabled={isDisabled}
                                variant="gradient" onClick={() => {
                                    divnlodemuitiple();
                                }} color="info"  >
                                Download CSV File
                            </SuiButton>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} xl={6}>

                            <SuiButton sx={{ float: 'right' }} variant="gradient" onClick={() => {
                                handleClose()
                            }} color="info"  >
                                close
                            </SuiButton>
                        </Grid>

                    </Grid>
                </Card>
                <CustomizedSnackbars message={snackmessage} open={snakopen} color={'error'} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default Arides;