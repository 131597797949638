import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';

import { addOutstandingDetails } from "../../CustomComponents/dataStore/actions";
import LinearProgress from '@mui/material/LinearProgress';
import { useAuth } from "CustomComponents/Authcontext";


function OutStandingReport() {
    const dd = useSelector((state) => state.AccountsOutStandingReport);
    const dispatch = useDispatch();
    const [nextkey, setnextkey] = useState(0);
    const [messagttouser, setmessagttouser] = useState("Loading Data...");
    const [nexttoggle, setnexttoggle] = useState(true);
    const [sipnnershow, setspinnershow] = useState(false);
    const {currentUser}=useAuth();

    useEffect(() => {
      if (dd.arr.length === 0) {
        calldatasetApi(0);
      }
    }, []);
    async function calldataallRepeat(e) {
      if (e >= 10) {
        var da = "";
        if (dd.nextKey !== "") {
            da = dd.nextKey;
        } else {
            da = nextkey;
        }
        if (da !== null && nexttoggle) {
            da = await calldatasetApi(da, e);
        }
    }

}
    async function calldatasetApi(nextaccessTokenparam,limit=10) {
      setspinnershow(true);
      if (nextaccessTokenparam === undefined) {
        nextaccessTokenparam = dd.nextKey || nextkey;
      }
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + currentUser.accessToken
      );
  
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      return await fetch(
        process.env.REACT_APP_BASE_URL+"/accounts?type=outstanding&start=" +
          nextaccessTokenparam+'&limit='+limit,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true && result.data.length !== 0) {
            result.data.map((doc)=>{
              doc.last_paid_date=new Date(doc.last_paid_date*1000).toLocaleString("en-US", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              });
            })
            dispatch(addOutstandingDetails(result.data, result.start));
            setnextkey(result.start);
  
            setnexttoggle(true);
            setspinnershow(false);
            return result.start;
          } else {
            setspinnershow(false);
            setnexttoggle(false);
            setmessagttouser("No Data To Load ");
            console.log("error", result.message);
            return null;
          }
        })
        .catch((error) => {
          setspinnershow(false);
          setnexttoggle(false);
          setmessagttouser("Unable to get data....ERROR");
          console.log("error", error);
        });
    }
    if (dd.arr.length === 0) {
     
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                    <SuiTypography variant="h6" sx={{textAlign:'center'}}>{messagttouser}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <Card >
                {sipnnershow && (
                    <Box sx={{ width: '100%', m: 0, p: 0 }}>
                        <LinearProgress color="success" sx={{ width: '100%' }} />
                    </Box>
                )}
                    <DataTable table={dd.arr}
                        hidecolumnsdata={[ ]}
                        functioncallcount={calldataallRepeat}

                        datacolumns={[
           
                            {
                              Header: "Driver name",
                              accessor: "name",
                            },
                            
                            {
                              Header: "Driver email id",
                              accessor: "email",
                            },
                
                            {
                              Header: "last paid date",
                              accessor: "last_paid_date",
                            },
                            
                            {
                              Header: "Balance Amount",
                              accessor: "balance",
                            },
                            
                          ]}

                         canSearch />
                </Card>
          
            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default OutStandingReport;
