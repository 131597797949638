import React, { useContext, useState, useEffect } from "react";
import { auth } from "./firebase.js";
import { database } from "./firebase.js";
import { useSelector, useDispatch } from "react-redux";
import {
  onAuthStateChanged, signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { ref as sRef, child, get, onValue } from "firebase/database";
import {
  addDatalocation,
  addwarroomData,
  setloding,
  addratecardData,
  addOngoingRidesData, setpermissions,
  Logoutreset, setLocalization,
  addwarroomCity, addpermisionData,
  addUSERinfo, setTarget, setALLDriverlocation,
} from "./dataStore/actions";
import routes from "../routes"

import {
  useHistory,
} from "react-router-dom";
const AuthContext = React.createContext();
const setSession = async (accessToken) => {
  if (accessToken) {
    await localStorage.setItem('accessToken', accessToken.accessToken)
  } else {
    await localStorage.removeItem('accessToken')
  }
}
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  let history = useHistory();
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  var ongoingrededriverid = [];
  const islodingforwarroom = useSelector((state) => state.Isload);
  const locCityData = useSelector((state) => state.ALLDriverlocation);

  const dispatch = useDispatch();
  function signup(email, password) {
    // auth.createUserWithEmailAndPassword(email, password).then(() => { return '' }).catch((error) => {
    //   const errorMessage = error.message;
    //   return errorMessage + '.';
    // })
  }

  async function lisen() {
    /////All driver city/////
    var datadrivercity = {}
    if (locCityData.length === 0) {
      const dbRef1 = sRef(database);
      await get(child(dbRef1, `/drivers/city`)).then((snapshot) => {
        if (snapshot.exists()) {
          const datarate = snapshot.val();

          dispatch(setALLDriverlocation(datarate));
          datadrivercity = datarate;
        } else {
          console.log("drivers/city null");
        }
      }).catch((error) => {
        console.error(error);
      });
    }
    /////rider_queue ongoing rides/////

    var ddds = await onValue(sRef(database, 'rider_queue'), (snapshot) => {
      if (snapshot.val() !== null) {
        var dda = Object.entries(snapshot.val());
        var con = [];
        con = dda.map((data) => {
          data[1].booked_onsec = data[1].booked_on;
          data[1].booked_on = new Date(data[1].booked_on).toLocaleString("en-US", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          if (data[1].isAccepted == false &&
            data[1].isCancelled === false &&
            data[1].isCompleted === false &&
            data[1].isVerified === false) {
            data[1].status = "Incoming";

          } else if (data[1].isAccepted == true &&
            data[1].isCancelled === false &&
            data[1].isCompleted === false &&
            data[1].isVerified === false) {
            data[1].status = "Accepted"
          } else if (data[1].isAccepted == true &&
            data[1].isCancelled === false &&
            data[1].isCompleted === false &&
            data[1].isVerified === true) {
            data[1].status = "OnGoing"
          } else if (data[1].isAccepted == true &&
            data[1].isCancelled === false &&
            data[1].isCompleted === true &&
            data[1].isVerified === true) {
            data[1].status = " Completed"
          } else if (data[1].isCancelled === true) {
            data[1].status = " Cancelled"
          }


          data[1].id = data[0];

          if (data[1].driver_id !== undefined) {
            ongoingrededriverid[data[1].driver_id] = {
              driverid: data[1].driver_id,
              name: data[1].driver_name,
              status: data[1].status,
              type: data[1].type,
              seat_req: data[1].required_seats,
            };
          }
          return data[1];
        });
        dispatch(addOngoingRidesData(con));

      }
    }, {
      onlyOnce: true
    });


    /////price_calculation//////
    const dbRef = sRef(database);
    await get(child(dbRef, `price_calculation`)).then((snapshot) => {
      if (snapshot.exists()) {
        const datarate = snapshot.val();
        const dda = Object.entries(datarate);
        const dda1 = Object.entries(datarate);
        var datamod = [];
        let cities = [];
        dda1.map((pro) => {
          cities.push(pro[0]);
        });
        dda.map((dd, key) => {
          dd[1].share.city_name = cities[key];
          dd[1].share.type = "share";
          var distancemul1 = Object.entries(dd[1].share.distance_multiplier);
          distancemul1.map((data) => {
            dd[1].share[data[0]] = data[1];
          });
          datamod.push(dd[1].share);
          dd[1].single.city_name = cities[key];
          dd[1].single.type = "single";
          var distancemul2 = Object.entries(dd[1].single.distance_multiplier);
          distancemul2.map((data) => {
            dd[1].single[data[0]] = data[1];
          });
          datamod.push(dd[1].single);
        });
        dispatch(addratecardData(datamod, cities));
      } else {
        console.log("No data available in price_calculation");
      }
    }).catch((error) => {
      console.error(error);
    });

    await onValue(sRef(database, 'drivers/available_drivers'), (snapshot) => {
      if (snapshot.val() !== null) {
        // console.log(snapshot.val());
        const data = snapshot.val();
        if (data !== null) {
          const dd = Object.keys(data).length;
          const dda = Object.entries(data);
          const oncalldrivers = Object.entries(ongoingrededriverid);
          const oncalldriverskeys = Object.keys(ongoingrededriverid);

          var colorCar = "green";

          var allDriverscities = null
          if (locCityData.length !== 0) {
            allDriverscities = locCityData;
          } else {
            allDriverscities = datadrivercity;
          }
          let das = dda.map((pop) => {
            var ids = pop[0].split("@");
            ids = ids[0];
            if (oncalldrivers.length !== 0) {
              if (oncalldriverskeys.includes(ids)) {
                const indexd = oncalldriverskeys.indexOf(ids);
                if (true) {
                  if (ongoingrededriverid[ids].status !== undefined) {
                    switch (ongoingrededriverid[ids].status) {
                      case 'Incoming':
                        colorCar = "green";
                        break;
                      case "Accepted":
                        colorCar = "orange";

                        break;
                      case "OnGoing":
                        colorCar = "red";

                        break;
                      default:
                        colorCar = "yellow";
                        break;
                    }
                  }
                  return {
                    id: ids,
                    l: {
                      lat: pop[1].l[0],
                      lng: pop[1].l[1],
                    },
                    city: allDriverscities[ids] !== undefined ? allDriverscities[ids].toLowerCase() : null,
                    name: oncalldrivers[indexd][1].name,
                    status: oncalldrivers[indexd][1].status,
                    colorCode: colorCar,
                    type: oncalldrivers[indexd][1].type,
                  };
                }
              }
            }
            return {
              id: ids,
              l: {
                lat: pop[1].l[0],
                lng: pop[1].l[1],
              },
              city: allDriverscities[ids] !== undefined ? allDriverscities[ids].toLowerCase() : null,
              name: null,
              status: 'Waiting',
              colorCode: "green",
              type: null,
            };


          });
          dispatch(addDatalocation(das, dd));
        }

      }
    }, {
      onlyOnce: true
    });
    await onValue(sRef(database, 'war_room'), (snapshot) => {
      if (snapshot.exists()) {
        const datawar = snapshot.val();
        var citiesWar = Object.keys(datawar);
        var dataset = {}
        citiesWar.map((dd) => {
          dataset[dd] = datawar[dd].coordinates;
        })
        // console.log(datawar);
        dispatch(addwarroomData(datawar));
        if (islodingforwarroom) {
          dispatch(addwarroomCity(dataset));
        }
      }
      dispatch(setloding());

    })
    // const war_roomdbRef = sRef(database);
    // await get(child(war_roomdbRef, ``)).then((snapshot) => {
    //   if (snapshot.exists()) {
    //     const datawar = snapshot.val();
    //     var citiesWar = Object.keys(datawar);
    //     var dataset = {}
    //     citiesWar.map((dd) => {
    //       dataset[dd] = datawar[dd].coordinates;
    //     })
    //     dispatch(addwarroomData(datawar));
    //     if (islodingforwarroom) {
    //       dispatch(addwarroomCity(dataset));
    //     }
    //   }
    // }).catch((error) => {
    //   console.error(error);
    // });

  }
  async function login(email, password) {
    return await signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      // setSession(user)
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      })
      return 'true';
    })
      .catch((error) => {
        return (String(error.code).split("/")[1].replaceAll('-', ' '));

      })
  }
  async function Logout() {
    dispatch(Logoutreset());
    signOut(auth).then(() => {
      console.log('Sign-out successful.');
      localStorage.clear()
      history.push('/login');
      window.location.reload();
    }).catch((error) => {
      console.log(error);
    });


  }
  function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    var userrole = JSON.parse(jsonPayload)
    return userrole.role;
  };
  async function routesf(tok) {
    var role = parseJwt(tok);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + tok);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    await fetch(
      process.env.REACT_APP_BASE_URL + "/permissions",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const per = result.permissions;
        dispatch(addUSERinfo({
          role: role,
          userid: result.authority_id,
          countries: result.countries,
          allcities: result.cities,
          isadmin: role === 'admin' ? true : false
        }));

        const darr = routes;
        const darrout = routes;
        var routsse = []
        darr.map(((ked, ke) => {
          switch (ked.type) {
            case 'collapse':
              var colArr = []
              ked.collapse.map(dat => {
                if (dat.double_check) {
                  if (per.hasOwnProperty([dat.tag]) && per.hasOwnProperty([dat.tag2])) {
                    if (per[dat.tag].includes(dat.permission) && per[dat.tag2].includes(dat.permission)) {
                      colArr.push(dat)
                    }
                  }
                } else {
                  if (per.hasOwnProperty([dat.tag])) {
                    if (per[dat.tag].includes(dat.permission)) {
                      colArr.push(dat)
                    }
                  }
                }

              })
              if (colArr.length !== 0) {
                ked.collapse = colArr
                routsse.push(ked)
              }
              break;

            case 'tab':
              if (per.hasOwnProperty([ked.tag])) {
                if (per[ked.tag].includes(ked.permission)) {
                  routsse.push(ked)
                }
              }
              break;
            case 'subtab':
              if (per.hasOwnProperty([ked.tag])) {
                if (per[ked.tag].includes(ked.permission)) {
                  routsse.push(ked)
                }
              }

              break;
            default:
              break;
          }

        }))

        dispatch(addpermisionData(per));
        dispatch(setpermissions(routsse));
        const dbRef = sRef(database);
        get(child(dbRef, "localization")).then((snapshot) => {
          if (snapshot.exists()) {
            const datarate = snapshot.val();
            dispatch(setLocalization(datarate));
          } else {
            console.log("No data available");
          }
        }).catch((error) => {
          console.error(error);
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(async () => {
    await onAuthStateChanged(auth, async (user) => {
      if (user) {
        await routesf(user.accessToken);
        // console.log(user.accessToken);
        setCurrentUser(user);
        // await setSession(user)
        await lisen();
      } else {
        setSession(null)
        history.push('/login');

      }
    })

  }, [])
  const value = {
    currentUser,
    login,
    signup,
    Logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading && children}
    </AuthContext.Provider>
  );
}