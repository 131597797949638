import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { AddSimdata } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from "@mui/material";
import { color } from "@mui/system";
import { useAuth } from "CustomComponents/Authcontext";

function Alldrivers() {
    const [errormessage1, seterrormessage1] = useState(null);
    const [sipnnershow, setsipnnershow] = useState(false);
    //response mobile
    const [mobileResponse, setmobileResponse] = useState(null);
    const [SimResponse, setSimResponse] = useState(null);
    //searck key word store
    const [IMEINUMBER, setIMEINUMBER] = useState("");
    const [phoneNumber, setphoneNumber] = useState("");
    //selected id
    const [selectedSim, setselectedSim] = useState(null);
    const [selectedMobile, setselectedMobile] = useState(null);
    const [matchs, setmatchs] = useState(false)
    const [matchs1, setmatchs1] = useState(false)
    const { currentUser } = useAuth();

    function handleSubmit(event) {
        event.preventDefault();
        setsipnnershow(true);
        if (selectedSim !== null && selectedMobile !== null) {
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                type: "mobile_sim",
                sim_id: selectedSim,
                mobile_id: selectedMobile,
            });
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            fetch(
                process.env.REACT_APP_BASE_URL + "/asset_link",
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === false) {
                        seterrormessage1(result.message);
                        setTimeout(() => {
                            seterrormessage1(null);
                        }, 2000);
                        setsipnnershow(false);

                    } else {
                        setsipnnershow(false);
                        //response mobile
                        setmobileResponse(null)
                        setSimResponse(null)
                        setIMEINUMBER('')
                        setphoneNumber('')
                        setselectedSim(null)
                        setselectedMobile(null)
                        alert("Mobile Sim linked Successfully");
                    }
                })
                .catch((error) => console.log("error", error));
        } else {
            alert("Please select the Search Result.");
        }
    }

    async function handleSearchSIM(event) {
        event.preventDefault();
        setsipnnershow(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BASE_URL + "/search_asset?asset=sim&search=" + phoneNumber,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === false) {
                    setmatchs1(result.message);
                    setphoneNumber(null)
                    setTimeout(() => {
                        setmatchs1(null);
                    }, 2000);
                    setsipnnershow(false);

                } else {
                    setsipnnershow(false);
                    setSimResponse(result.data);
                }
            })
            .catch((error) => {

                setsipnnershow(false);

                console.log("error", error)
                setmatchs1('Data Fetch error')
                setTimeout(() => {
                    setmatchs1(null)
                }, 2000);
            });
    }
    async function handleSearchMobile(event) {
        event.preventDefault();
        setsipnnershow(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BASE_URL + "/search_asset?asset=mobile&search=" +
            IMEINUMBER +
            "&type=mobile_sim",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === false) {
                    seterrormessage1(result.message);
                    setTimeout(() => {
                        seterrormessage1(null);
                    }, 2000);
                    setsipnnershow(false);

                } else {
                    setsipnnershow(false);
                    setmobileResponse(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    }




    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <form onSubmit={handleSearchMobile} >

                    <Card >
                        {sipnnershow && (
                            <Box sx={{ width: '100%', m: 0, p: 0 }}>
                                <LinearProgress color="success" sx={{ width: '100%' }} />
                            </Box>

                        )}
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <Card>
                                <h5 style={{ display: "inline-block", position: 'relative' }}>
                                              Driver  Email And password updata{" "}
                                            </h5>
                                    <Card sx={{ p: 4 }}>
                                        <div >
                                          
                                            <SuiTypography
                                                variant="h6"
                                            >
                                                Please Enter Driver existing email id
                                            </SuiTypography>
                                            <SuiInput
                                                sx={{ mb: 2, position: 'relative', ml: 'auto', mr: 'auto' }}
                                                value={IMEINUMBER}
                                                required
                                                name="vSearch"
                                                placeholder="IMEI..."
                                                onChange={(e) => {
                                                    var re = /^([0-9]+|[\b]|null|)$/;
                                                    if (re.test(e.target.value)) {
                                                        setIMEINUMBER(e.target.value)
                                                    }
                                                    else {
                                                        setmatchs('Special characters and Alphabets are not allowed')
                                                        setTimeout(() => {
                                                            setmatchs(null)
                                                        }, 1600);
                                                    }
                                                }
                                                }
                                                inputProps={{ type: "text" }} />
                                            <SuiTypography variant="h6" sx={{ color: 'red', fontSize: '1rem' }}>{matchs}</SuiTypography>


                                            <div>
                                                {errormessage1 != null ? (
                                                    <p style={{ color: "red" }}>{errormessage1}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    </Card>
                                </Card>
                            </Grid>

                            <Grid item md={6}>
                                <Card>

                                    <Card sx={{ p: 4 }}>
                                        <SuiTypography
                                            variant="h6"
                                        >
                                                Please Enter Driver new email id
                                        </SuiTypography>
                                        <SuiInput
                                            sx={{ mb: 2, position: 'relative', ml: 'auto', mr: 'auto' }}
                                            required
                                            value={phoneNumber}
                                            name="vSearch"
                                            placeholder="Phone Number"
                                            // onChange={(e) => setphoneNumber(e.target.value)}
                                            inputProps={{ maxLength: 14, minLength: 12 }}
                                            onChange={(e) => {
                                                // setphoneNumber(e.target.value)

                                                var re = /^(\+{91}|\+[0-9]+|[0-9]+|)$/;
                                                if (re.test(e.target.value)) {
                                                    setphoneNumber(e.target.value)
                                                }
                                                else {
                                                    setmatchs1('Special characters and Alphabets are not allowed')
                                                    setTimeout(() => {
                                                        setmatchs1(null)
                                                    }, 1600);
                                                }
                                            }
                                            }
                                        />
                                        <SuiButton
                                            type="submit"
                                            id="submits2"
                                            variant="gradient" color="info"  >
                                            Search SIM
                                        </SuiButton>
                                        <SuiTypography variant="h6" sx={{ color: 'red', fontSize: '1rem' }}>{matchs1}</SuiTypography>


                                    </Card>
                                </Card>
                            </Grid>
                        </Grid>
                    </Card>
                </form>

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default Alldrivers;
