import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import FormGroup from '@mui/material/FormGroup';
import { Box } from "@mui/system";
import LinearProgress from '@mui/material/LinearProgress';

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// Data
import CustomizedSnackbars from "../Alert/Alert";
import { useSelector, useD, ispatch } from "react-redux";
import { storage } from "../../CustomComponents/firebase";
import { useAuth } from "CustomComponents/Authcontext";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";


import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import {
  useHistory,
} from "react-router-dom";
function AddVehicle() {
  const { currentUser } = useAuth();
  const userinfo = useSelector((state) => state.Userinfo);
  const [sipnnershow, setspinnershow] = useState(false);
  const [selectCountry, setselectCountry] = useState(null);
  const [selectCity, setselectCity] = useState(null);
  let history = useHistory();
  const [message, setmessage] = useState("loading data....");
  const [VehicleRegistrationNumber, setVehicleRegistrationNumber] =
    useState("");
  const [Vehiclebrand, setVehiclebrand] = useState("");
  const [VehicleModel, setVehicleModel] = useState("");
  const [vehicleCategory, setvehicleCategory] = useState(null);
  const [VehicleJoindata, setVehicleJoindata] = useState("");
  const [vehicleregistrationcardimage, setvehicleregistrationcardimage] =
    useState("");
  const [vehicalVendor, setvehicalVendor] = useState({ value: '', label: '' });
  const [insuranceValidity, setinsuranceValidity] = useState("");
  const [VehicleInsurance, setVehicleInsurance] = useState("");
  const [VehicleFC, setVehicleFC] = useState("");
  const [vehiclefcValidDate, setvehiclefcValidDate] = useState("");
  const [proofofOwnershipDoc, setproofofOwnershipDoc] = useState("");
  const [autogenius_inspection_report, setautogenius_inspection_report] =
    useState("");
  const [VehicleColor, setVehicleColor] = useState(null);
  const [vehicleAGIRdate, setvehicleAGIRdate] = useState("");
  const [documentstatus, setdocumentstatus] = useState(null);
  const [uplode2, setuplode2] = useState({ label: "", value: 0 });
  const [uplode3, setuplode3] = useState({ label: "", value: 0 });
  const [uplode1, setuplode1] = useState({ label: "", value: 0 });
  const [uplode4, setuplode4] = useState({ label: "", value: 0 });
  const [uplode5, setuplode5] = useState({ label: "", value: 0 });
  const [vendorshow, setvendorshow] = useState(false);
  const [dropvalue, setdropvalue] = useState(null);

  //snack bar
  const [snakopen, setsnakopen] = useState(false)
  const [snackmessage, setsnackmessage] = useState(null)
  const [snackcolor, setsnackcolor] = useState('error');
  const handleSnackbar = (messages) => {
    setsnakopen(true)
    setsnackmessage(messages)
  };
  //
  const datesecond = (e) => {
    return new Date(e).getTime() / 1000;
  };
  async function formclean() {
    setselectCountry(null)
    setselectCity(null)
    setVehicleRegistrationNumber("")
    setVehiclebrand("")
    setVehicleModel("")
    setvehicleCategory("")
    setVehicleJoindata("")
    setvehicleregistrationcardimage('')
    setvehicalVendor({ value: '', label: '' })
    setinsuranceValidity("")
    setVehicleInsurance("")
    setVehicleFC("")
    setvehiclefcValidDate("")
    setproofofOwnershipDoc("")
    setautogenius_inspection_report("")
    setVehicleColor(null)
    setvehicleAGIRdate("")
    setdocumentstatus(null)
    setuplode2({ label: "", value: 0 })
    setuplode3({ label: "", value: 0 })
    setuplode1({ label: "", value: 0 })
    setuplode4({ label: "", value: 0 })
    setuplode5({ label: "", value: 0 })
    setvendorshow(false)
    setdropvalue(null)
    setsnakopen(false)
    setsnackmessage(null)
    setsnackcolor('error')
  }

  async function fileuplode(e, setdata, id, upoldevalue) {
    e.preventDefault();
    if (e.target.files[0] !== undefined) {
      const file = e.target.files[0];
      console.log("file", file);
      const metadata = {
        contentType: file.type,
      };
      var fileurl = URL.createObjectURL(file)
      var timese = Math.floor(new Date().getTime() / 1000);
      const mountainsRef = ref(storage, "vehicle_info/" + timese + id + file.name);
      const uploadTask = uploadBytesResumable(mountainsRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          var prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          upoldevalue({ label: fileurl, value: prog });
        },
        (error) => console.log(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setdata(downloadURL)
          });
        }
      );
    }
  }
  async function handleSubmit(event) {
    event.preventDefault();
    document.getElementById("submitVehicle").setAttribute("disabled", "disabled");
    setspinnershow(true)
    var check = true;
    setsnackcolor('error')

    if (vehicleCategory === null) {
      check = false;
      handleSnackbar('Please Select  Vehicle Category')
    }
    if (userinfo.isadmin && vehicalVendor.value === '') {
      check = false;
      handleSnackbar('Please Select Vehicle Vendor  ')
    }
    if (VehicleColor === null) {
      check = false;
      handleSnackbar('Please Select  Vehicle Color')
    }
    if (selectCity === null) {
      check = false;
      handleSnackbar('Please Select  Vehicle City')
    }
    if (documentstatus === null) {
      check = false;
      handleSnackbar('Please Select  Vehicle Document Status')
      setspinnershow(false)
    }

    if (check && currentUser.accessToken && currentUser.accessToken !== undefined) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + currentUser.accessToken
      );
      myHeaders.append("Content-Type", "application/json");
      var dataset = {
        registration_number: VehicleRegistrationNumber,
        brand: Vehiclebrand,
        model: VehicleModel,
        Category: vehicleCategory,
        joindate: datesecond(VehicleJoindata),
        city: selectCity,
        registration_card_image: vehicleregistrationcardimage,
        insurance: VehicleInsurance,
        insurance_validity: insuranceValidity,
        fc: VehicleFC,
        fc_validity: datesecond(vehiclefcValidDate),
        autogenius_inspection_report: autogenius_inspection_report,
        autogenius_inspection_report_validity: datesecond(vehicleAGIRdate),
        proof_of_ownership: proofofOwnershipDoc,
        document_status: documentstatus,
        vehicle_color: VehicleColor,
        vendor_id: undefined,
      }
      if (userinfo.isadmin) {
        dataset.vendor_id = vehicalVendor.value;
      }
      var raw = JSON.stringify(dataset);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(
        process.env.REACT_APP_BASE_URL + "/add_vehicles",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.status === true) {
            setsnackcolor('success')
            setspinnershow(false)
            handleSnackbar("Vehicle Added Successfully");
            formclean()
            document.getElementById("submitVehicle").removeAttribute("disabled");
          } else {
            setspinnershow(false)
            alert("Error :" + result.message);
          }
        })
        .catch((error) => {
          setspinnershow(false)
          // formclean()
          document.getElementById("submitVehicle").removeAttribute("disabled");
          console.log("error", error);
        });
    }
  }

  useEffect(() => {
    if (dropvalue === null && userinfo.isadmin) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + currentUser.accessToken
      );
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_BASE_URL + "/add_vehicles",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === false) {
            if (result.message !== undefined) {
              alert(result.message);
              setmessage(result.message);
              setvendorshow(false);
            } else {
              alert("Vendor data not found");
              setmessage("No data to load");
              setdropvalue([]);
              setvendorshow(false);
            }
          } else {
            setdropvalue(result.vendors);
            setvendorshow(true);
          }
        })
        .catch((error) => {
          setvendorshow(false);
          console.log("error", error);
        });
    } else {
      setmessage('')
    }
  }, [0]);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={2} pb={3}>
        <Card >
          <form onSubmit={handleSubmit} >
            <Grid container spacing={2} sx={{ p: 4 }}>
              <Grid item md={6} sm={12} xs={12} xl={6} lg={6} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Registration Number</SuiTypography >
                <SuiInput
                  sx={{ width: '100%' }}
                  required
                  placeholder="Enter Vehicle Registration Number"
                  type="text"
                  value={VehicleRegistrationNumber}
                  inputProps={{ maxLength: 20, }}
                  onChange={(e) => {
                    var re = /^([a-zA-Z0-9 ]+|[\b]|null|)$/;
                    if (re.test(e.target.value)) {
                      setVehicleRegistrationNumber(e.target.value)
                    }
                    else {
                      handleSnackbar("Special characters are not allowed");
                    }
                  }
                  }

                ></SuiInput>

              </Grid >
              <Grid item md={6} sm={12} xs={12} xl={6} lg={6} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Brand</SuiTypography >

                <SuiInput
                  required
                  value={Vehiclebrand}
                  onChange={(e) => {
                    var re = /^([a-zA-Z0-9 ]+|[\b]|null|)$/;
                    if (re.test(e.target.value)) {
                      setVehiclebrand(e.target.value)
                    }
                    else {
                      handleSnackbar("Special characters are not allowed");
                    }
                  }
                  }
                  inputProps={{ maxLength: 20, }}

                  placeholder="Enter Vehicle Brand"
                  type="text"
                />
              </Grid >
              <Grid item md={6} sm={12} xs={12} xl={6} lg={6} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Model</SuiTypography >
                <SuiInput
                  required
                  value={VehicleModel}
                  onChange={(e) => {
                    var re = /^([a-zA-Z0-9 ]+|[\b]|null|)$/;
                    if (re.test(e.target.value)) {
                      setVehicleModel(e.target.value)
                    }
                    else {
                      handleSnackbar("Special characters are not allowed");
                    }
                  }
                  }
                  inputProps={{ maxLength: 20, }}

                  placeholder="Enter Vehicle Model"
                  type="text"
                />
              </Grid >

              <Grid item md={6} sm={12} xs={12} xl={6} lg={6} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Category</SuiTypography >
                <SuiSelect
                  required
                  sx={{ width: '100%' }}
                  value={{ value: vehicleCategory, label: vehicleCategory }}
                  options={[" ", "single",
                    "share"].map((data2, i) => ({ value: data2, label: data2 }))}
                  onChange={(e) => {
                    setvehicleCategory(e.value);
                  }}
                />

              </Grid >

              <Grid item md={6} sm={12} xs={12} xl={6} lg={6} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Join Date</SuiTypography >
                <SuiInput
                  required
                  inputProps={{ min: "1960-01-01", max: "2045-05-04" }}

                  value={VehicleJoindata}

                  onChange={(e) => {
                    setVehicleJoindata(e.target.value);
                  }}
                  type="date"
                />
              </Grid >
              <Grid item md={6} sm={12} xs={12} xl={6} lg={6} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select Country</SuiTypography>

                {userinfo.countries.length !== 0 ? (
                  <SuiSelect
                    required
                    placeholder={'Query by country'}
                    value={selectCountry !== null ? { value: selectCountry, label: selectCountry } : null}
                    options={userinfo.countries.map((data2) => ({ value: data2, label: data2 }))}
                    onChange={(e) => {
                      setselectCountry(e.value);
                      setselectCity(null)
                    }}
                  />
                ) : null}
              </Grid>

              {selectCountry !== null ? userinfo.isadmin ? (
                <Grid item md={6} sm={12} xs={12} xl={6} lg={6} >
                  <FormGroup>
                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select City</SuiTypography>
                    <SuiSelect
                      required
                      placeholder={'Query by city'}
                      value={selectCity !== null ? { value: selectCity, label: selectCity } : null}
                      options={userinfo.allcities[selectCountry].map((data2, i) => ({ value: data2, label: data2 }))}
                      onChange={(e) => {
                        setselectCity(e.value);

                      }}
                    />
                  </FormGroup>
                </Grid>
              ) : (
                <Grid item md={6} sm={12} xs={12} xl={6} lg={6} ><FormGroup>

                  <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select City</SuiTypography>
                  <SuiSelect
                    required
                    placeholder={'Query by city'}
                    value={selectCity !== null ? { value: selectCity, label: selectCity } : null}
                    options={userinfo.allcities.map((data2, i) => ({ value: data2, label: data2 }))}
                    onChange={(e) => {
                      setselectCity(e.value);

                    }}
                  />
                </FormGroup>
                </Grid>
              ) : null}


              {vendorshow ? (
                dropvalue.length !== 0 ? (
                  <Grid item md={6} sm={12} xs={12} xl={6} lg={6} >
                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vendor Name</SuiTypography >
                    <SuiSelect
                      required
                      value={vehicalVendor}
                      options={dropvalue.map((data2) => ({ value: data2.id, label: data2.name }))}
                      onChange={(e) => {
                        setvehicalVendor(e);
                      }}
                    />

                  </Grid >
                ) : null
              ) : <Grid item md={6} sm={12} xs={12} xl={6} lg={6} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >{message}</SuiTypography >

              </Grid>}
              <Grid item md={6} sm={12} xs={12} xl={6} lg={6} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Color</SuiTypography >
                <SuiSelect
                  required
                  value={{ value: VehicleColor, label: VehicleColor }}
                  options={[" ", "Green",
                    "Blue",
                    "Red",
                    "Black",
                    "White",
                    "Yellow"].map((data2, i) => ({ value: data2, label: data2 }))}
                  onChange={(e) => {
                    setVehicleColor(e.value);
                  }}
                />

              </Grid >
              <Grid item md={12} xl={12} lg={12}>
                <Grid container>
                  <Grid item md={6} xl={6} lg={6} xs={{ mt: 'auto', mb: 'auto' }}>
                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Registration Card Image</SuiTypography >

                    <SuiInput
                      required
                      onChange={(e) => {
                        fileuplode(
                          e,
                          setvehicleregistrationcardimage,
                          "Rcard_image",
                          setuplode1
                        );
                      }}
                      placeholder="chose file"
                      type="file"
                    />
                  </Grid>

                  {uplode1.value !== 0 ? <Grid item md={6} sm={12} xs={12} xl={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                    {uplode1.value === 100?<img style={{ height: '10rem', width: '100%' }} src={uplode1.label} alt="preview image" />:<>'loading...'</>}
                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode1.value}%</SuiTypography >
                  </Grid> : null}

                </Grid>


              </Grid >
              <Grid item md={12} sm={12} xs={12} xl={12} lg={12}>
                <Grid container>
                  <Grid item md={6} xl={6} lg={6} sx={{ mt: 'auto', mb: 'auto' }}>
                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Insurance</SuiTypography >

                    <SuiInput
                      required
                      onChange={(e) => {
                        fileuplode(e, setVehicleInsurance, "insurance", setuplode5);
                      }}
                      type="file"
                    />
                  </Grid>

                  {uplode5.value !== 0 ? <Grid item md={6} sm={12} xs={12} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                    <img style={{ height: '10rem', width: '100%' }} src={uplode5.label} alt="preview image" />
                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode5.value}%</SuiTypography >
                  </Grid> : null}
                </Grid>

              </Grid>
              <Grid item md={6} sm={12} xs={12} xl={6} lg={6} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Insurance Validity</SuiTypography >
                <SuiInput
                  required
                  value={insuranceValidity}
                  inputProps={{ min: "1960-01-01", max: "2045-05-04" }}

                  onChange={(e) => {
                    setinsuranceValidity(e.target.value);
                  }}
                  type="date"
                />
              </Grid >
              <Grid item md={12} sm={12} xs={12} xl={12} lg={12} >
                <Grid container>
                  <Grid item md={6} sm={12} xs={12} xl={6} lg={6} sx={{ mt: 'auto', mb: 'auto' }}>
                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle Fc Document</SuiTypography >
                    <SuiInput
                      required
                      onChange={(e) => {
                        fileuplode(e, setVehicleFC, "fc", setuplode2);
                      }}
                      type="file"
                    />
                  </Grid>

                  {uplode2.value !== 0 ? <Grid item md={6} sm={12} xs={12} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                    <img style={{ height: '10rem', width: '100%' }} src={uplode2.label} alt="preview image" />
                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode2.value}%</SuiTypography >
                  </Grid> : null}

                </Grid>
              </Grid >
              <Grid item md={6} sm={6} xs={6} xl={6} lg={6} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Vehicle FC Valid Till</SuiTypography >
                <SuiInput
                  required
                  value={vehiclefcValidDate}
                  inputProps={{ min: "1960-01-01", max: "2045-05-04" }}

                  onChange={(e) => {
                    setvehiclefcValidDate(e.target.value);
                  }}
                  type="date"
                />
              </Grid >
              <Grid item md={6} sm={12} xs={12} xl={12} lg={6} />

              <Grid item md={6} sm={12} xs={12} xl={12} lg={6}  >
                <Grid container>
                  <Grid item md={6} sm={12} xs={12} xl={6} lg={6} sx={{ mt: 'auto', mb: 'auto' }}>
                    <SuiTypography sx={{ fontSize: '0.9rem', }}  > Vehicle AutoGenius Inspection Report</SuiTypography >
                    <SuiInput
                      required
                      onChange={(e) => {
                        fileuplode(
                          e,
                          setautogenius_inspection_report,
                          "agir",
                          setuplode3
                        );
                      }}
                      type="file"
                    />
                  </Grid>

                  {uplode3.value !== 0 ? <Grid item md={6} sm={12} xs={12} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                    <img style={{ height: '10rem', width: '100%' }} src={uplode3.label} alt="preview image" />
                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode3.value}%</SuiTypography >
                  </Grid> : null}
                </Grid>
              </Grid >
              <Grid item md={6} sm={12} xs={12} xl={6} lg={6} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >
                  Vehicle AutoGenius Inspection Report Validity
                </SuiTypography >
                <SuiInput
                  required
                  value={vehicleAGIRdate}
                  inputProps={{ min: "1960-01-01", max: "2045-05-04" }}

                  onChange={(e) => {
                    setvehicleAGIRdate(e.target.value);
                  }}
                  type="date"
                />
              </Grid >
              <Grid item md={6} sm={12} xs={12} xl={12} lg={6} />
              <Grid item md={6} sm={12} xs={12} xl={6} lg={6} >
                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Document Status</SuiTypography >
                <SuiSelect
                  required
                  value={{ value: documentstatus, label: documentstatus }}
                  options={[" ", "Pending",
                    "Approved",
                    "Rejected"].map((data2, i) => ({ value: data2, label: data2 }))}
                  onChange={(e) => {
                    setdocumentstatus(e.value);
                  }}
                />
                {" "}
              </Grid >
              <Grid item md={12} xl={12} lg={12} />
              <Grid item md={12} sm={12} xs={12} xl={12} lg={12} >
                <Grid container>
                  <Grid item md={6} sm={12} xs={12} xl={6} lg={6} sx={{ mt: 'auto', mb: 'auto' }}>
                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >Proof Of Ownership</SuiTypography >
                    <SuiInput
                      required
                      onChange={(e) => {
                        fileuplode(e, setproofofOwnershipDoc, "POfO", setuplode4);
                      }}
                      type="file"
                    />
                  </Grid>
                  {uplode4.value !== 0 ? <Grid item md={6} sm={12} xs={12} xl={6} lg={6} sx={{ p: 2, alignContent: 'center', textAlign: 'center' }}>
                    <img style={{ height: '10rem', width: '100%' }} src={uplode4.label} alt="preview image" />
                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >File Uploaded {uplode4.value}%</SuiTypography >
                  </Grid> : null}
                </Grid>
              </Grid >
              <Grid item md={6} sm={12} xs={12} xl={6} lg={6}>
                <SuiButton sx={{ ml: 'auto', mr: 'auto', display: 'flex' }}
                  color={'info'} id="submitVehicle" type="submit">
                  Submit
                </SuiButton>
              </Grid >
              <Grid item md={6} xl={6} lg={6}>
                <SuiButton
                  sx={{ ml: 'auto', mr: 'auto', display: 'flex' }}
                  color={'info'}
                  className="cancelButton"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  Go Back
                </SuiButton>
              </Grid >

            </Grid>
          </form>
          <Box sx={{ mt: 2 }}>
            {sipnnershow ? <LinearProgress color="success" /> : ''}
          </Box>
        </Card>
        <CustomizedSnackbars message={snackmessage} open={snakopen} color={snackcolor} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

      </SuiBox>
      <Footer />
    </DashboardLayout >
  );
}

export default AddVehicle;
{/*const initialState = { name: "Bob", occupation: "builder" };
const [state, updateState] = useReducer(
  (state, updates) => ({
    ...state,
    ...updates,
  }),
  initialState
);
And then you can update the state by only passing partial updates, like this:

updateState({ ocupation: "postman" })*/}