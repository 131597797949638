const initailState = []

const Permisioncopy=(state=initailState,action)=>{
    switch(action.type){
        case 'PermisionSet':
            return action.payload
        default:
            return state;
    }
}

export default Permisioncopy