import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Modal from '@mui/material/Modal';
import Grid from "@mui/material/Grid";
import CustomizedSnackbars from "../Alert/Alert";

import { addBlacklisteduser } from "../../CustomComponents/dataStore/actions";
import LinearProgress from '@mui/material/LinearProgress';
import defaultprofile from "../../assets/img/defaultprofile.png";
import { useAuth } from "../../CustomComponents/Authcontext";
function Riders() {
  const Permissions = useSelector((state) => state.Permisioncopy.riders);
  const dd = useSelector((state) => state.BlacklisteuUsersStore);
  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState(dd.nextKey || "");
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
  const [blacklistselectdata, setblacklistselectdata] = useState(null)
  const [checkedblacklist, setcheckedblacklist] = useState(false)
  const [reason, setreason] = useState(null)
  //model
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //snack bar
  const [snakopen, setsnakopen] = useState(false)
  const [snackmessage, setsnackmessage] = useState(null)
  const {currentUser}=useAuth();
const [loading, setloading] = useState(true)
  const handleSnackbar = (messages) => {
    setsnakopen(true)
    setsnackmessage(messages)
  };
  //
  useEffect(() => {
    setloading(true)

  // console.log(currentUser.accessToken)
    if (dd.arr.length === 0) {
      calldataall_riders("");
    }
    setloading(false)
  }, [0]);
  async function calldataall_ridersRepeat(e) {
    if (e >= 10) {
      var da = "";
      if (dd.nextKey !== "") {
          da = dd.nextKey;
      } else {
          da = nextkey;
      }
      if (da !== null && nexttoggle) {
          da = await calldataall_riders(da, e);
      }
  }

}
  async function calldataall_riders(nexttokenparam,limit=10) {
    setnexttoggle(false);
    setspinnershow(true);
    if (nexttokenparam === undefined) {
      nexttokenparam = dd.nextKey || nextkey;
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + currentUser.accessToken
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return await fetch(
      process.env.REACT_APP_BASE_URL+"/blacklist_riders?start=" +
      nexttokenparam+'&limit='+limit,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          setnextkey(result.start);
          //
          result.data.map((dat) => {
            dat.full_name = dat.first_name + " " + dat.last_name;
            dat.joined_on = new Date(dat.joined_on * 1000).toLocaleString("en-US", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });

              dat.action = (
                <div >
                  <SuiButton
                    onClick={() => {
                      handelblacklist(dat)
                    }}
                    color="primary"
                  >
                    White List User
                  </SuiButton>{" "}
  
                </div>
              );
           
          });
          dispatch(addBlacklisteduser(result.data, result.start));
          setnexttoggle(true);
          setspinnershow(false);
          return result.start;
        } else {
          alert(result.message)
          setnexttoggle(false);
          setmessagttouser("Unable to get data....ERROR : " + result.message);
          console.log("error", result.message);
          setspinnershow(false);

        }
      })
      .catch((error) => {
        setnexttoggle(false);
        setspinnershow(false);
        setmessagttouser("Unable to get data....ERROR");
        console.log("error", error);
      });

  }
  //blacklist functions
  function handelblacklist(params) {
    if (Permissions.includes('U')) {
      console.log(params);
       setblacklistselectdata(params)
      handelblacklistSubmit(params);

    } else {
      alert('Access Denied (User does not have permission)')
    }
  }
  function handelblacklistSubmit(params) {
      if (params === null) {
        handleSnackbar('Please Select blacklist User')
      } else if (currentUser.accessToken !== undefined) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Bearer " + currentUser.accessToken
        );
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
          "rider_id":params.id,
          "blacklisted": false, 
      });
        var requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        fetch(
          process.env.REACT_APP_BASE_URL+"/blacklist_riders",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === true) {
              handleSnackbar('User Whitelisted');
              setblacklistselectdata(null);
              handleClose();
            } else {
              handleSnackbar(result.message);
              document.getElementById("submit").removeAttribute("disabled");
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        alert("AccessToken error");
      }
    
  }
  if (dd.arr.length === 0&&loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox pt={2} pb={3}>
          <Card>
            <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
          </Card>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={3} pb={3}>
        <Card >
          {sipnnershow && (
            <SuiBox sx={{ width: '100%', m: 0, p: 0 }}>
              <LinearProgress color="success" sx={{ width: '100%' }} />
            </SuiBox>
          )}
          <DataTable table={dd.arr}
            hidecolumnsdata={['joined_on',]}
            functioncallcount={calldataall_ridersRepeat}
            datacolumns={[
              {
                Header: "Rider name",
                accessor: "full_name",
              },
              {
                Header: "gender",
                accessor: "gender",
              },
              {
                Header: "email Id",
                accessor: "email_id",
              },
              {
                Header: "phone number",
                accessor: "phone_number",
              },
              {
                Header: "joined on",
                accessor: "joined_on",
              },
              {
                Header: "DOB",
                accessor: "dob",
              },
              {
                Header: "Reason",
                accessor: "reason",
              },
              {
                Header: "action",
                accessor: "action",
              },

            ]}
            datalength={dd.TotalCount} canSearch />
        </Card>
        {/* <Modal
          aria-labelledby="transition-modal-title1"
          aria-describedby="transition-modal-description1"
          open={open}
          onClose={handleClose}
          closeAfterTransition
id={'blacklisted'}
        >
          <SuiBox sx={
            {
              border: 0,
              borderRadius: '10px',
              boxShadow: '0 0 15px rgb(0 0 0 / 15%), 0 0 1px 1px rgb(0 0 0 / 10%)',
              position: 'absolute',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              flexDirection: 'column',
              width: '50vw',
              pointerEvents: 'auto',
              backgroundColor: '#fff',
              backgroundClip: 'padding-box',
              outline: 0,
              padding: '20px 50px'
            }
          } >
            <SuiTypography variant='h4' sx={{ textAlign: 'center', mb: 1, p: 1 }}>
              User Details
            </SuiTypography>
            <SuiBox sx={{ alignContent: 'center', textAlign: 'center' }}>
              <img style={{ height: '9rem', borderRadius: '50%' }} src={blacklistselectdata && (blacklistselectdata.profile_pic !== 'null'&& blacklistselectdata.profile_pic !== undefined && blacklistselectdata.profile_pic !== ''&& blacklistselectdata.profile_pic !== null) ? blacklistselectdata.profile_pic : defaultprofile} alt="profile image" />
            </SuiBox>
            <SuiTypography sx={{ mb: 2, textAlign: 'center' }}>
              Name  :  {blacklistselectdata && blacklistselectdata.full_name}
            </SuiTypography>
            <SuiTypography sx={{ mb: 2, textAlign: 'center' }}>
              Email  :  {blacklistselectdata && blacklistselectdata.email_id}
            </SuiTypography>
            <SuiTypography sx={{ mb: 2, textAlign: 'center' }}>
              DOB  :  {blacklistselectdata && blacklistselectdata.dob}
            </SuiTypography>
            <SuiTypography sx={{ fontSize: '0.9rem', color: 'red', textAlign: 'center' }}>
              * Note:if the user is suspended the user will no longer can make a booking.
            </SuiTypography>
            <SuiBox sx={{ alignContent: 'center', textAlign: 'center', width: '70%', mx: 'auto' }}>
              <SuiTypography sx={{ fontSize: '0.9rem', }}  >Reason For Blacklisting the user</SuiTypography >
              <SuiInput required
                value={reason}
                placeholder="Enter the reason"
                multiline
                rows={2}
                onChange={(e) => {
                  setreason(e.target.value);
                }}
                type="textarea"
              />
            </SuiBox>
            <Grid container spacing={3} sx={{ alignContent: 'center', textAlign: 'center', mt: 1 }} >
              <Grid item md={6}>
                <SuiTypography sx={{ fontSize: '0.9rem', }}  > </SuiTypography >
                <SuiButton color="info" id="submit" type="submit"
                  onClick={() => handelblacklistSubmit()}
                >
                  Submit
                </SuiButton >
              </Grid >
              <Grid item md={6}>
                <SuiTypography sx={{ fontSize: '0.9rem', }}  > </SuiTypography >
                <SuiButton color="info"
                  className="cancelButton"
                  onClick={() => handleClose()}
                >
                  Close

                </SuiButton >

              </Grid >
            </Grid>
          </SuiBox>
        </Modal> */}
                        <CustomizedSnackbars color={'error'} message={snackmessage} open={snakopen} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Riders;
