import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";



function BookingAcceptedtracking() {
    const [message, setmessage] = useState('Loading data...')
    const dd = useSelector(state => state.Ongoingridesdata)
    const [isloading, setisloading] = useState(true)
    const [ongoingridesdata, setongoingridesdata] = useState([])
    useEffect(() => {
        var temp = [];
        if (dd.length === 0) {
            setTimeout(() => {
                setmessage('No Data To Display')
            }, 4000);
        } else {

            dd.map((data) => {
                if (data.accepted_on) {
                    const timeTemp = data.accepted_on;
                    data.accepted_on = new Date(data.accepted_on).toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        second: 'numeric',
                        hour12: true,
                    });
                    
                    const d = new Date();
                    var min15mili = 900000;
                    var min16mili = 960000;
                    let ms1 = d.getTime();
                    let ms2 = new Date(timeTemp).getTime();

                    //This checks if the difference is less than 15 minutes
                    if ((ms1 - ms2) / 1000 / 60 < 15) {
                        data.statuscolor = <Button sx={{ backgroundColor: 'green', borderRadius: '100px', minWidth: '10px', height: '20px' }}>

                        </Button>
                    };

                    //This checks if the difference is less than 30 minutes
                    if ((ms1 - ms2) / 1000 / 60 < 30 && (ms1 - ms2) / 1000 / 60 > 15) {
                        data.statuscolor = <Button sx={{ backgroundColor: 'orange', borderRadius: '100px', minWidth: '10px', height: '20px' }}></Button>

                    };
                    if ((ms1 - ms2) / 1000 / 60 > 30) {
                        data.statuscolor = <Button sx={{ backgroundColor: 'red', borderRadius: '100px', minWidth: '10px', height: '20px' }}></Button>

                    };
                    temp.push(data)
                }
            })
            setongoingridesdata(temp)
            setisloading(false)
        }
    }, [0])


    if (dd.length === 0 && isloading) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{message}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <Card >
                    <SuiTypography sx={{p:2}}> Less than 15min  <Button sx={{ backgroundColor: 'green', borderRadius: '100px', minWidth: '10px', height: '20px' }}>

                    </Button>, Less than 30min <Button sx={{ backgroundColor: 'orange', borderRadius: '100px', minWidth: '10px', height: '20px' }}></Button>,
                         Greater than 30min <Button sx={{ backgroundColor: 'red', borderRadius: '100px', minWidth: '10px', height: '20px' }}></Button>
                    </SuiTypography>
                    <DataTable table={ongoingridesdata}

                        hidecolumnsdata={[

                        ]}
                        functioncallcount={() => { }}
                        LoadData={false}

                        datacolumns={[

                            {
                                Header: "Driver name",
                                accessor: "driver_name",
                            },
                            {
                                Header: "Driver contact number",
                                accessor: "driver_contactno",
                            },
                            {
                                Header: "registration number",
                                accessor: "registration_number",
                            },
                            {
                                Header: "Booking Confirmation Time",
                                accessor: "accepted_on",
                            },
                            {
                                Header: "Status",
                                accessor: "statuscolor",
                            },

                        ]}
                        canSearch />
                </Card>
            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default BookingAcceptedtracking;
