import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import SuiSelect from "components/SuiSelect";
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { useAuth } from "CustomComponents/Authcontext";

import {
    addassetunlink,
    cleanAssetunlink
} from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';

function MobileVehicleLink() {
    const dd = useSelector((state) => state.Unlinkasseddata);
    const { currentUser } = useAuth();

    const [sipnnershow, setsipnnershow] = useState(false);
    //Unlinked
    const [dataselected, setdataselected] = useState([]);
    //selected id
    const [selectedVehical, setselectedVehical] = useState(null);
    const [selectedMobile, setselectedMobile] = useState(null);
    // 
    // console.log(currentUser.accessToken);
    const [AssetSearch, setAssetSearch] = useState(null);
    //next
    const [nextkey, setnextkey] = useState(dd.nextKey || "");
    const [messagttouser, setmessagttouser] = useState("Loading Data...");
    const [nexttoggle, setnexttoggle] = useState(true);
    const dispatch = useDispatch();
    //data colum header 
    const [Columdata, setColumdata] = useState([]);
    useEffect(() => {
        switch (AssetSearch) {
            case 'vehicle_driver':
                setColumdata([
                    {
                        Header: "Select ",
                        accessor: "action",
                    },
                    {
                        Header: "Driver Name ",
                        accessor: "driver_name",
                    },
                    {
                        Header: "Driver emailid ",
                        accessor: "emailid",
                    },
                    {
                        Header: "Driver contact no ",
                        accessor: "contactno",
                    },
                    {
                        Header: "Registration number ",
                        accessor: "registration_number",
                    },
                    {
                        Header: "vehicle color ",
                        accessor: "color",
                    },
                    {
                        Header: "vehicle brand ",
                        accessor: "brand",
                    },
                ])
                break;
            case 'vehicle_mobile':
                setColumdata([
                    {
                        Header: "Select",
                        accessor: "action",
                    },
                    {
                        Header: "registration number ",
                        accessor: "registration_number",
                    },
                    {
                        Header: "model ",
                        accessor: "model",
                    },
                    {
                        Header: "brand ",
                        accessor: "brand",
                    },
                    {
                        Header: "brand name ",
                        accessor: "brand_name",
                    },
                    {
                        Header: "IMEI ",
                        accessor: "IMEI",
                    },
                    {
                        Header: "build number ",
                        accessor: "build_number",
                    },
                ])
                break;
            case 'mobile_sim':
                setColumdata([
                    {
                        Header: "Select ",
                        accessor: "action",
                    },
                    {
                        Header: "Mobile brand name ",
                        accessor: "brand_name",
                    },
                    {
                        Header: "build_number ",
                        accessor: "build_number",
                    },

                    {
                        Header: "Mobile IMEI ",
                        accessor: "IMEI",
                    },
                    {
                        Header: "SIM Company ",
                        accessor: "company",
                    },
                    {
                        Header: "SIM number ",
                        accessor: "number",
                    },
                ])
                break;
            default:
                break;
        }

    }, [AssetSearch]);


    async function calldatasetApiRepeat(e) {
        if (e >= 10) {
            var da = "";
            if (dd.nextKey !== "") {
                da = dd.nextKey;
            } else {
                da = nextkey;
            }
            if (da !== null && nexttoggle) {
                da = await calldatasetApi(da, e);
            }
        }

    }
    const handleSearchAssets = (e) => {
        e.preventDefault()
        calldatasetApi('', 100)
    }

    function handleSubmit(event) {
        event.preventDefault();
        setsipnnershow(true);
        if (dataselected.length !== 0) {
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "type": AssetSearch,
                "unlink": dataselected
            });
            console.log(raw);
            console.log(dataselected);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(process.env.REACT_APP_BASE_URL + "/unlink", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === false) {
                        setmessagttouser(result.message);
                        setsipnnershow(false);
                        setTimeout(() => {
                            setmessagttouser('Loading Data...');
                        }, 4000);
                    } else {
                        setsipnnershow(false);
                        alert("Asset Unlinked Successfully");
                        setdataselected([])
                    }
                })
                .catch((error) => {
                    console.log("error", error); setsipnnershow(false);
                });
        } else {
            setsipnnershow(false);

            alert("Please select the Options to Unlink.");
        }
    }

    const checkdata = (e, data) => {
        switch (AssetSearch) {
            case 'vehicle_driver':

                if (e) {
                    if (!dataselected.filter(function (e) { return e.vehicle_id === data.id; }).length > 0) {
                        /* vendors contains the element we're looking for */
                        dataselected.push({
                            "vehicle_id": data.id,
                            "driver_id": data.driverUID
                        })
                        setdataselected(dataselected)
                    }


                } else {
                    for (var i = dataselected.length - 1; i >= 0; --i) {
                        if (dataselected[i].vehicle_id === data.id) {
                            dataselected.splice(i, 1);
                            setdataselected(dataselected)
                        }


                    }

                }


                break;
            case 'vehicle_mobile':
                if (e) {
                    if (!dataselected.filter(function (e) { return e.vehicle_id === data.id; }).length > 0) {
                        /* vendors contains the element we're looking for */
                        dataselected.push({
                            "vehicle_id": data.id,
                            "mobile_id": data.mobile_id
                        })
                        setdataselected(dataselected)
                    }
                } else {
                    for (var i = dataselected.length - 1; i >= 0; --i) {
                        if (dataselected[i].vehicle_id === data.id) {
                            dataselected.splice(i, 1);
                            setdataselected(dataselected)
                        }
                    }
                }
                break;
            case 'mobile_sim':
                if (e) {
                    if (!dataselected.filter(function (e) { return e.vehicle_id === data.id; }).length > 0) {
                        /* vendors contains the element we're looking for */
                        dataselected.push({
                            "sim_id": data.sim_id,
                            "mobile_id": data.id
                        })
                        setdataselected(dataselected)
                    }
                } else {
                    for (var i = dataselected.length - 1; i >= 0; --i) {
                        if (dataselected[i].mobile_id === data.id) {
                            dataselected.splice(i, 1);
                            setdataselected(dataselected)
                        }
                    }
                }
                break;
            default:
                break;
        }
    }
    async function calldatasetApi(nextaccessTokenparam, limit = 100) {
        setsipnnershow(true);
        if (nextaccessTokenparam === '') {
            dispatch(cleanAssetunlink());
        }
        var searckkeyUrl = ''
        switch (AssetSearch) {
            case 'vehicle_driver':
                searckkeyUrl = process.env.REACT_APP_BASE_URL + "/all_vehicles?limit=" + limit;
                break;
            case 'vehicle_mobile':
                searckkeyUrl = process.env.REACT_APP_BASE_URL + "/all_vehicles?type=" + 'linked_mobile&limit=' + limit
                break;
            case 'mobile_sim':
                searckkeyUrl = process.env.REACT_APP_BASE_URL + "/asset?type=mobile&query=linked_to_sim&limit=" + limit
                break;
            default:
                break;
        }
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        await fetch(
            searckkeyUrl + "&start=" +
            nextaccessTokenparam,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === true) {
                    var arrayvalue = []
                    result.data.map((data) => {
                        data.action = <div >
                            <Checkbox
                                color="primary"
                                onChange={(e) => checkdata(e.target.checked, data)}
                            />
                        </div>
                        if (AssetSearch === 'vehicle_driver' && data.linked_to_mobile !== false && data.linked_to_driver !== false) {
                            arrayvalue.push(data)
                        } else if (AssetSearch === 'vehicle_mobile' || AssetSearch === 'mobile_sim') {
                            arrayvalue.push(data)

                        }
                    });
                    dispatch(addassetunlink(arrayvalue, result.start));
                    setnextkey(result.start);
                    setnexttoggle(true);
                    setsipnnershow(false);

                    return result.start;
                } else {
                    alert(result.message);
                    setnexttoggle(false);
                    setmessagttouser("Unable to get data....ERROR : " + result.message);
                    console.log("error", result.message);
                    setsipnnershow(false);
                    setTimeout(() => {
                        setmessagttouser('Loading Data...')
                    }, 4000);
                    return 0;
                }
            })
            .catch((error) => {
                setnexttoggle(false);
                setmessagttouser("Unable to get data....ERROR" + error);
                setsipnnershow(false);

                setTimeout(() => {
                    setmessagttouser('Loading Data...')
                }, 4000);
                console.log("error", error);
            });
    }
    useEffect(() => {
        console.log(dataselected);
    }, [dataselected])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>

                <Card sx={{ p: 4, pt: 0 }}>
                    {sipnnershow && (
                        <Box sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{ width: '100%' }} />
                        </Box>

                    )}
                    <h5 style={{ display: "inline-block", marginTop: '10px' }}> Search Assets</h5>
                    <form onSubmit={handleSearchAssets} >
                        <SuiTypography
                            variant="h6"
                        >
                            Select Asset Type to be unlink
                        </SuiTypography>

                        <div style={{ width: '50%' }}>
                            <Select
                                value={AssetSearch}
                                required
                                sx={{ mb: 2, position: 'relative', ml: 'auto', mr: 'auto' }}
                                onChange={(e) => {
                                    console.log(e.target.value);
                                    setAssetSearch(e.target.value);
                                    setdataselected([])
                                }}
                                input={<SuiInput />} >
                                <MenuItem value="...">...</MenuItem>
                                {['vehicle_driver', 'vehicle_mobile', 'mobile_sim'].map((data2, i) => (
                                    <MenuItem key={i} value={data2}>{data2.replace("_", " ")}</MenuItem>))
                                }
                            </Select>
                        </div>
                        <SuiButton
                            type="submit"
                            disabled={AssetSearch === null}
                            id="submits2"
                            variant="gradient" color="info"  >
                            Search Assets
                        </SuiButton>
                        {messagttouser !== 'Loading Data...' ? <Typography sx={{ color: 'red', fontSize: '1rem' }}>{messagttouser}</Typography> : null}

                    </form>

                </Card>
                {/*dumy unlink api test*/}

                {dd.arr.length !== 0 ? <Card sx={{ m: 1, p: 3 }}>
                    <SuiTypography variant="h6">Search Results</SuiTypography>
                    <form onSubmit={handleSubmit} >
                        <Grid container spacing={2} >
                            <Grid item md={12} sm={12} xl={12} lg={12}>
                                <DataTable table={dd.arr}
                                    hidecolumnsdata={[]}
                                    functioncallcount={calldatasetApiRepeat}
                                    datacolumns={Columdata}
                                    datalength={dd.arr.length} canSearch />
                            </Grid>
                            <Grid item md={12}>
                                <SuiButton
                                    disabled={sipnnershow}
                                    sx={{ px: 5 }} id="submit" type="submit" color={'info'}>
                                    Submit
                                </SuiButton>
                            </Grid>{" "}
                        </Grid>
                    </form>

                </Card> : null}

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default MobileVehicleLink;
