const initailState = {
    arr:[],
    nextKey:''
}
const HierarchyDetails=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_HIREARCHY_DETAILS':
            return{ 
                arr: state.arr.concat(action.payload),
                nextKey:action.keynext,
                TotalCount:action.counts

            }
            case 'ADD_HIREARCHY_DETAILS_Clean':
        return initailState;
        default:
            return state;
    }
}

export default HierarchyDetails