import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { ref as sRef, child, set, get, onValue, update } from "firebase/database";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import { useHistory } from "react-router-dom";
import { useAuth } from "CustomComponents/Authcontext";

import { adduserpermition } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import { database } from "../../CustomComponents/firebase";
import CustomizedSnackbars from "../Alert/Alert";

function AddCityRateCard(props) {
    let history = useHistory();
    const [messagttouser, setmessagttouser] = useState("Loding Data...");
    //single
    const [baseFare, setbaseFare] = useState(0);
    const [convenience_multiplier, setconvenience_multiplier] = useState(0);
    const [lasg_fee, setlasg_fee] = useState();
    const [min_fare, setmin_fare] = useState(0);
    const [peak_multiplier, setpeak_multiplier] = useState(0);
    const [taxes, settaxes] = useState();
    const [time_multiplier, settime_multiplier] = useState();
    const [toll_charges, settoll_charges] = useState(0);
    const { currentUser } = useAuth();

    //share
    const [share_baseFare, setshare_baseFare] = useState(0);
    const [share_convenience_multiplier, setshare_convenience_multiplier] =
        useState(0);
    const [share_lasg_fee, setshare_lasg_fee] = useState();
    const [share_min_fare, setshare_min_fare] = useState(0);
    const [share_peak_multiplier, setshare_peak_multiplier] = useState(0);
    const [share_taxes, setshare_taxes] = useState();
    const [share_time_multiplier, setshare_time_multiplier] = useState();
    const [share_toll_charges, setshare_toll_charges] = useState(0);
    const [share_percentage_2, setshare_percentage_2] = useState();
    const [sipnnershow, setsipnnershow] = useState(false);
    const [network_charges, setnetwork_charges] = useState(0)
    //single distance peak_multiplier time_grid
    const [Value0_4, setValue0_4] = useState();
    const [Value4_8, setValue4_8] = useState();
    const [Value8_12, setValue8_12] = useState();
    const [Value12_16, setValue12_16] = useState();
    const [Value16, setValue16] = useState();
    //single timegrid
    const [singletg1, setsingletg1] = useState(0);
    const [singletg2, setsingletg2] = useState(0);
    const [singletg3, setsingletg3] = useState(0);

    //share distance peak_multiplier
    const [share_network_charges, setshare_network_charges] = useState(0)
    const [shareValue0_4, setshareValue0_4] = useState();
    const [shareValue4_8, setshareValue4_8] = useState();
    const [shareValue8_12, setshareValue8_12] = useState();
    const [shareValue12_16, setshareValue12_16] = useState();
    const [shareValue16, setshareValue16] = useState();
    //share timegrid
    const [sharetg1, setsharetg1] = useState(0);
    const [sharetg2, setsharetg2] = useState(0);
    const [sharetg3, setsharetg3] = useState(0);
    const [country, setcountry] = useState([]);
    const [countryname, setcountryname] = useState('');
    const [cityname, setcityname] = useState([])
    const [selectedcity, setselectedcity] = useState()
    const userinfo = useSelector((state) => state.Userinfo);
    //snack bar
    const [snakopen, setsnakopen] = useState(false)
    const [snackmessage, setsnackmessage] = useState(null)
    const [colorcode, setcolorcode] = useState('error')
    const handleSnackbar = (messages) => {
        setsnakopen(true)
        setsnackmessage(messages)
    };
    useEffect(() => {
        setcountry(userinfo.countries);

    }, []);
    const handleselect = (e) => {
        if (e !== '') {
            setcountryname(e)
            const dbRef = sRef(database);
            get(child(dbRef, "localization/" + e)).then((snapshot) => {
                const datacity = snapshot.val();
                if (datacity === null) {
                    alert('no cities avalable under this country. Please add city to this country')
                } else {
                    setcityname(datacity.cities);
                }
            })
        } else {
            setcountryname('')
            setcityname([])
        }
    }
    //   form submit
    const handleSubmit1 = (e) => {
        var status = true;
        if (min_fare === 0) {
            status = false
            handleSnackbar('Please fill min fare')
        }
        if (share_min_fare === 0) {
            status = false
            handleSnackbar('Please fill min fare')
        }
        // if (singletg1 === 0) {
        //     status = false
        //     handleSnackbar('Time  Multiplier Less than 20 minutes cannot be 0')
        // }
        // if (singletg2 === 0) {
        //     status = false
        //     handleSnackbar('Time  Multiplier Greater than 20 minutes,Less than 40 minutes cannot be 0')
        // }
        // if (singletg3 === 0) {
        //     status = false
        //     handleSnackbar('Time  Multiplier Greater than 40 minutes cannot be 0')
        // }
        // if (Value0_4 === 0 && Value0_4 === undefined) {
        //     status = false
        //     handleSnackbar('Please fill Distance Multiplier 0 - 4 kms')
        // }
        // if (Value4_8 === 0 && Value4_8 === undefined) {
        //     status = false
        //     handleSnackbar('Please fill Distance Multiplier 4 - 8 kms / cannot be 0')
        // }
        // if (Value8_12 === 0 && Value8_12 === undefined) {
        //     status = false
        //     handleSnackbar('Please fill Distance Multiplier 8 - 12 kms / cannot be 0')
        // }
        // if (Value12_16 === 0) {
        //     status = false
        //     handleSnackbar('Distance Multiplier 12 - 16 kms cannot be 0')
        // }

        if (countryname !== '' && selectedcity !== undefined && status) {
            setsipnnershow(true)
            var cname = selectedcity.toLowerCase();
            var data = {
                country: countryname,
                share: {
                    base_fare: Number(share_baseFare),
                    convenience_multiplier: Number(share_convenience_multiplier),
                    distance_multiplier: {
                        "0-4": Number(shareValue0_4),
                        "12-16": Number(shareValue12_16),
                        "16>": Number(shareValue16),
                        "4-8": Number(shareValue4_8),
                        "8-12": Number(shareValue8_12),
                    },
                    time_grid: {
                        tg1: Number(sharetg1),
                        tg2: Number(sharetg2),
                        tg3: Number(sharetg3),
                    },
                    lasg_fee: Number(share_lasg_fee),
                    min_fare: Number(share_min_fare),
                    peak_multiplier: Number(share_peak_multiplier),
                    share_percentage_2: Number(share_percentage_2),
                    taxes: share_taxes,
                    time_multiplier: Number(share_time_multiplier),
                    toll_charges: Number(share_toll_charges),
                    network_charges: network_charges
                },
                single: {
                    base_fare: Number(baseFare),
                    convenience_multiplier: Number(convenience_multiplier),
                    distance_multiplier: {
                        "0-4": Number(Value0_4),
                        "12-16": Number(Value12_16),
                        "16>": Number(Value16),
                        "4-8": Number(Value4_8),
                        "8-12": Number(Value8_12),
                    },
                    time_grid: {
                        tg1: Number(singletg1),
                        tg2: Number(singletg2),
                        tg3: Number(singletg3),
                    },

                    lasg_fee: Number(lasg_fee),
                    min_fare: Number(min_fare),
                    peak_multiplier: Number(peak_multiplier),
                    taxes: Number(taxes),
                    time_multiplier: Number(time_multiplier),
                    toll_charges: Number(toll_charges),
                    network_charges: share_network_charges,

                },

            };
            update(sRef(database, 'price_calculation/' + cname), data).then(() => {
                alert("successfully"); setsipnnershow(false)
            })
                .catch((e) => {
                    alert(e); setsipnnershow(false)
                });
        } else {
            alert('Please Fill The Form')
        }

    };
    if (country === null) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card sx={{ textAlign: 'center' }}>
                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Add City Rate Card</SuiTypography>
                        {country === null ? <SuiTypography sx={{ fontSize: '0.9rem', }}  >No country Data found</SuiTypography> : <SuiTypography></SuiTypography>}
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={1} pb={3}>
                <Card sx={{ m: 2, p: 2, pt: 0 }}>
                    {sipnnershow && (
                        <Box sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{ width: '100%' }} />
                        </Box>

                    )}
                    <Grid container spacing={3} sx={{ pt: 2 }}>
                        <Grid item md={4} >
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Country Name</SuiTypography>
                                <SuiSelect
                                    //required
                                    autoComplete="none"
                                    placeholder="select category"
                                    id="countryrtc"
                                    value={{ value: countryname, label: countryname }}
                                    options={userinfo.countries.map((entry) => ({ value: entry, label: entry }))}
                                    onChange={(e) => {
                                        handleselect(e.value)
                                    }}
                                />
                            </FormGroup>{" "}
                        </Grid>
                        {cityname.length !== 0 ? <><Grid item md={4} >
                            <SuiTypography sx={{ fontSize: '0.9rem', }}  >City Name</SuiTypography>

                            <SuiSelect
                                //required
                                autoComplete="none"
                                value={{ value: selectedcity, label: selectedcity }}
                                options={cityname.map((entry) => ({ value: entry, label: entry }))}
                                onChange={(e) => {
                                    setselectedcity(e.value)
                                }}
                            />


                        </Grid></> : null


                        }

                        <Grid item sm={12}>
                            <Divider variant="inset" />
                            <h4 className="m-2">Single Ride</h4>
                            <Divider variant="inset" />
                        </Grid>
                        <Grid item md={4} >
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Base Fare</SuiTypography>
                                <SuiInput
                                    //required
                                    placeholder="Enter Base Fare"
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                    value={baseFare}
                                    onChange={(e) => {
                                        setbaseFare(e.target.value);
                                    }}
                                />
                            </FormGroup>{" "}
                        </Grid>
                        <Grid item md={4} >
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Convenience Multiplier</SuiTypography>

                                <SuiInput
                                    //required
                                    placeholder="Enter convenience multiplier"
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                    value={convenience_multiplier}
                                    onChange={(e) => {
                                        setconvenience_multiplier(e.target.value);
                                    }}
                                />
                            </FormGroup>{" "}
                        </Grid>
                        <Grid item md={4} >
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Network Charges</SuiTypography>
                                <SuiInput
                                    //required
                                    placeholder="Enter Base Fare"
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                    value={network_charges}
                                    onChange={(e) => {
                                        setnetwork_charges(e.target.value);
                                    }}
                                />
                            </FormGroup>{" "}
                        </Grid>
                        <Grid item md={12} className="mt-2 px-5">
                            <Divider variant="inset" />
                            <SuiTypography sx={{ fontSize: '0.9rem', }}  >Distance Multiplier</SuiTypography >
                            <Divider variant="inset" />
                            <Grid container spacing={3}>
                                <Grid item md={4} >
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >0 - 4 kms</SuiTypography>

                                        <SuiInput
                                            //required
                                            value={Value0_4}
                                            onChange={(e) => {
                                                setValue0_4(e.target.value);
                                            }}
                                            placeholder="Enter number"
                                            type="number" inputProps={{ min: 0, max: 100 }}

                                        />
                                    </FormGroup>{" "}
                                </Grid>
                                <Grid item md={4} >
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >4 - 8 kms</SuiTypography>
                                        <SuiInput
                                            //required
                                            value={Value4_8}
                                            onChange={(e) => {
                                                setValue4_8(e.target.value);
                                            }}
                                            placeholder="Enter number"
                                            type="number" inputProps={{ min: 0, max: 100 }}

                                        />
                                    </FormGroup>{" "}
                                </Grid>
                                <Grid item md={4} >
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >8 - 12 kms</SuiTypography>

                                        <SuiInput
                                            //required
                                            value={Value8_12}
                                            onChange={(e) => {
                                                setValue8_12(e.target.value);
                                            }}
                                            placeholder="Enter  number"
                                            type="number" inputProps={{ min: 0, max: 100 }}

                                        />
                                    </FormGroup>{" "}
                                </Grid>
                                <Grid item md={4} >
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >12 - 16 kms</SuiTypography>

                                        <SuiInput
                                            //required
                                            value={Value12_16}
                                            onChange={(e) => {
                                                setValue12_16(e.target.value);
                                            }}
                                            placeholder="Enter number"
                                            type="number" inputProps={{ min: 0, max: 100 }}

                                        />
                                    </FormGroup>{" "}
                                </Grid>
                                <Grid item md={4} >
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  > 16kms +</SuiTypography>

                                        <SuiInput
                                            //required
                                            value={Value16}
                                            onChange={(e) => {
                                                setValue16(e.target.value);
                                            }}
                                            placeholder="Enter  number"
                                            type="number"
                                            inputProps={{ min: 0, max: 100 }}

                                        />
                                    </FormGroup>{" "}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12} className="mt-2 px-5">
                            <Divider variant="inset" />
                            <SuiTypography sx={{ fontSize: '0.9rem', }}  >Time  Multiplier</SuiTypography >
                            <Divider variant="inset" />
                            <Grid container spacing={3}>
                                <Grid item md={4} className="mt-4">
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Less than 20 minutes</SuiTypography>

                                        <SuiInput
                                            //required
                                            value={singletg1}
                                            onChange={(e) => {
                                                setsingletg1(e.target.value);
                                            }}
                                            placeholder="Enter number"
                                            type="number" inputProps={{ min: 0, max: 100 }}

                                        />
                                    </FormGroup>{" "}
                                </Grid>
                                <Grid item md={4} className="mt-1">
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >
                                            Greater than 20 minutes,Less than 40 minutes
                                        </SuiTypography>

                                        <SuiInput
                                            //required
                                            value={singletg2}
                                            onChange={(e) => {
                                                setsingletg2(e.target.value);
                                            }}
                                            placeholder="Enter number"
                                            type="number" inputProps={{ min: 0, max: 100 }}

                                        />
                                    </FormGroup>{" "}
                                </Grid>
                                <Grid item md={4} className="mt-4">
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Greater than 40 minutes</SuiTypography>

                                        <SuiInput
                                            //required
                                            value={singletg3}
                                            onChange={(e) => {
                                                setsingletg3(e.target.value);
                                            }}
                                            placeholder="Enter  number"
                                            type="number" inputProps={{ min: 0, max: 100 }}

                                        />
                                    </FormGroup>{" "}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={4} >
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >GOVT. Fee</SuiTypography>

                                <SuiInput
                                    //required
                                    value={lasg_fee}
                                    onChange={(e) => {
                                        setlasg_fee(e.target.value);
                                    }}
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                />
                            </FormGroup>{" "}
                        </Grid>
                        <Grid item md={4} >
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Min Fare</SuiTypography>

                                <SuiInput
                                    //required
                                    value={min_fare}
                                    onChange={(e) => {
                                        setmin_fare(e.target.value);
                                    }}
                                    placeholder="Enter  number"
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                />
                            </FormGroup>{" "}
                        </Grid>
                        <Grid item sm={4}>
                            {" "}
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Peak Multiplier</SuiTypography>

                                <SuiInput
                                    //required
                                    value={peak_multiplier}
                                    onChange={(e) => {
                                        setpeak_multiplier(e.target.value);
                                    }}
                                    placeholder="Enter Peak Multiplier"
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                />
                            </FormGroup>{" "}
                        </Grid>
                        <Grid item sm={4}>
                            {" "}
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Taxes</SuiTypography>

                                <SuiInput
                                    //required
                                    value={taxes}
                                    onChange={(e) => {
                                        settaxes(e.target.value);
                                    }}
                                    placeholder="Enter taxes"
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                />
                            </FormGroup>{" "}
                        </Grid>{" "}
                        <Grid item sm={4}>
                            {" "}
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Time Multiplier</SuiTypography>

                                <SuiInput
                                    //required
                                    value={time_multiplier}
                                    onChange={(e) => {
                                        settime_multiplier(e.target.value);
                                    }}
                                    placeholder=""
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                />
                            </FormGroup>{" "}
                        </Grid>{" "}
                        <Grid item sm={4}>
                            {" "}
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Toll Charges</SuiTypography>

                                <SuiInput
                                    //required
                                    value={toll_charges}
                                    onChange={(e) => {
                                        settoll_charges(e.target.value);
                                    }}
                                    placeholder=""
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                />
                            </FormGroup>{" "}
                        </Grid>
                        <Grid item sm={12}>
                            <Divider variant="inset" />
                            <h4 className="m-2">Share Ride </h4>
                            <hr className="mb-3" />
                        </Grid>
                        <Grid item md={4} >
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Base Fare</SuiTypography>
                                <SuiInput
                                    //required
                                    placeholder="Enter Base Fare"
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                    value={share_baseFare}
                                    onChange={(e) => {
                                        setshare_baseFare(e.target.value);
                                    }}
                                />
                            </FormGroup>{" "}
                        </Grid>
                        <Grid item md={4} >
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >convenience multiplier</SuiTypography>

                                <SuiInput
                                    //required
                                    placeholder="Enter convenience multiplier"
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                    value={share_convenience_multiplier}
                                    onChange={(e) => {
                                        setshare_convenience_multiplier(e.target.value);
                                    }}
                                />
                            </FormGroup>{" "}
                        </Grid>
                        <Grid item md={4} >
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Network Charges</SuiTypography>
                                <SuiInput
                                    //required
                                    placeholder="Enter Base Fare"
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                    value={share_network_charges}
                                    onChange={(e) => {
                                        setshare_network_charges(e.target.value);
                                    }}
                                />
                            </FormGroup>{" "}
                        </Grid>
                        <Grid item md={12} className=" px-5">
                            <Divider variant="inset" />
                            <SuiTypography sx={{ fontSize: '0.9rem', }}  >Distance Multiplier</SuiTypography >
                            <Divider variant="inset" />
                            <Grid container spacing={3}>
                                <Grid item md={4} >
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >0 - 4 kms</SuiTypography>

                                        <SuiInput
                                            //required
                                            value={shareValue0_4}
                                            onChange={(e) => {
                                                setshareValue0_4(e.target.value);
                                            }}
                                            placeholder="Enter number"
                                            type="number" inputProps={{ min: 0, max: 100 }}

                                        />
                                    </FormGroup>{" "}
                                </Grid>
                                <Grid item md={4} >
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >4 - 8 kms</SuiTypography>

                                        <SuiInput
                                            //required
                                            value={shareValue4_8}
                                            onChange={(e) => {
                                                setshareValue4_8(e.target.value);
                                            }}
                                            placeholder="Enter number"
                                            type="number" inputProps={{ min: 0, max: 100 }}

                                        />
                                    </FormGroup>{" "}
                                </Grid>
                                <Grid item md={4} >
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >8 - 12 kms</SuiTypography>

                                        <SuiInput
                                            //required
                                            value={shareValue8_12}
                                            onChange={(e) => {
                                                setshareValue8_12(e.target.value);
                                            }}
                                            placeholder="Enter  number"
                                            type="number" inputProps={{ min: 0, max: 100 }}

                                        />
                                    </FormGroup>{" "}
                                </Grid>
                                <Grid item md={4} >
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >12 - 16 kms</SuiTypography>

                                        <SuiInput
                                            //required
                                            value={shareValue12_16}
                                            onChange={(e) => {
                                                setshareValue12_16(e.target.value);
                                            }}
                                            placeholder="Enter number"
                                            type="number" inputProps={{ min: 0, max: 100 }}

                                        />
                                    </FormGroup>{" "}
                                </Grid>
                                <Grid item md={4} >
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  > 16kms +</SuiTypography>

                                        <SuiInput
                                            //required
                                            value={shareValue16}
                                            onChange={(e) => {
                                                setshareValue16(e.target.value);
                                            }}
                                            placeholder="Enter  number"
                                            type="number" inputProps={{ min: 0, max: 100 }}

                                        />
                                    </FormGroup>{" "}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12} className="mt-2 px-5">
                            <Divider variant="inset" />
                            <SuiTypography sx={{ fontSize: '0.9rem', }}  >Time  Multiplier</SuiTypography >
                            <Divider variant="inset" />
                            <Grid container spacing={3}>
                                <Grid item md={4} className="mt-4">
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Less than 20 minutes</SuiTypography>

                                        <SuiInput
                                            //required
                                            value={sharetg1}
                                            onChange={(e) => {
                                                setsharetg1(e.target.value);
                                            }}
                                            placeholder="Enter number"
                                            type="number" inputProps={{ min: 0, max: 100 }}

                                        />
                                    </FormGroup>{" "}
                                </Grid>
                                <Grid item md={4} className="mt-1">
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >
                                            Greater than 20 minutes,Less than 40 minutes
                                        </SuiTypography>

                                        <SuiInput
                                            //required
                                            value={sharetg2}
                                            onChange={(e) => {
                                                setsharetg2(e.target.value);
                                            }}
                                            placeholder="Enter number"
                                            type="number" inputProps={{ min: 0, max: 100 }}

                                        />
                                    </FormGroup>{" "}
                                </Grid>
                                <Grid item md={4} className="mt-4">
                                    <FormGroup>
                                        <SuiTypography sx={{ fontSize: '0.9rem', }}  >Greater than 40 minutes</SuiTypography>

                                        <SuiInput
                                            //required
                                            value={sharetg3}
                                            onChange={(e) => {
                                                setsharetg3(e.target.value);
                                            }}
                                            placeholder="Enter  number"
                                            type="number" inputProps={{ min: 0, max: 100 }}

                                        />
                                    </FormGroup>{" "}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={4} >
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >GOVT. Fee</SuiTypography>

                                <SuiInput
                                    //required
                                    value={share_lasg_fee}
                                    onChange={(e) => {
                                        setshare_lasg_fee(e.target.value);
                                    }}
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                />
                            </FormGroup>{" "}
                        </Grid>
                        <Grid item md={4} >
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Min Fare</SuiTypography>

                                <SuiInput
                                    //required
                                    value={share_min_fare}
                                    onChange={(e) => {
                                        setshare_min_fare(e.target.value);
                                    }}
                                    placeholder="Enter  number"
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                />
                            </FormGroup>{" "}
                        </Grid>
                        <Grid item sm={4}>
                            {" "}
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Peak Multiplier</SuiTypography>

                                <SuiInput
                                    //required
                                    value={share_peak_multiplier}
                                    onChange={(e) => {
                                        setshare_peak_multiplier(e.target.value);
                                    }}
                                    placeholder="Enter Peak Multiplier"
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                />
                            </FormGroup>{" "}
                        </Grid>
                        <Grid item sm={4}>
                            {" "}
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Taxes</SuiTypography>

                                <SuiInput
                                    //required
                                    value={share_taxes}
                                    onChange={(e) => {
                                        setshare_taxes(e.target.value);
                                    }}
                                    placeholder="Enter taxes"
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                />
                            </FormGroup>{" "}
                        </Grid>
                        <Grid item sm={4}>
                            {" "}
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >
                                    Percentage Increase for 2 Seats %
                                </SuiTypography>

                                <SuiInput
                                    //required
                                    value={share_percentage_2}
                                    onChange={(e) => {
                                        setshare_percentage_2(e.target.value);
                                    }}
                                    placeholder="Enter Percentage Increase "
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                />
                            </FormGroup>{" "}
                        </Grid>
                        <Grid item sm={4}>
                            {" "}
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Time Multiplier</SuiTypography>

                                <SuiInput
                                    //required
                                    value={share_time_multiplier}
                                    onChange={(e) => {
                                        setshare_time_multiplier(e.target.value);
                                    }}
                                    placeholder=""
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                />
                            </FormGroup>{" "}
                        </Grid>{" "}
                        <Grid item sm={4}>
                            {" "}
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >toll charges</SuiTypography>

                                <SuiInput
                                    //required
                                    value={share_toll_charges}
                                    onChange={(e) => {
                                        setshare_toll_charges(e.target.value);
                                    }}
                                    type="number" inputProps={{ min: 0, max: 100 }}

                                />
                            </FormGroup>{" "}
                        </Grid>{" "}
                        <Grid item md={12} />
                        <Grid item md={6}>
                            {" "}
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  > </SuiTypography>
                                <SuiButton id="submit" type="submit"
                                    onClick={() => { handleSubmit1() }}
                                >
                                    Submit
                                </SuiButton>
                            </FormGroup>{" "}
                        </Grid>
                        <Grid item md={6}>
                            {" "}
                            <FormGroup>
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  > </SuiTypography>
                                <SuiButton
                                    onClick={() => history.goBack()}
                                >
                                    Cancel
                                </SuiButton>
                            </FormGroup>{" "}
                        </Grid>
                    </Grid>
                </Card>
                <CustomizedSnackbars message={snackmessage} open={snakopen} color={'error'} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default AddCityRateCard;
