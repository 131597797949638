import React, { useEffect, useState } from "react";


// Soft UI Dashboard PRO React example components

import TimelineItem from "../Components/Timeline/TimelineItem"
import TimelineList from "../Components/Timeline/TimelineList"
import Grid from "@mui/material/Grid";
import { getDatabase, ref, onValue } from "firebase/database";
import CustomizedSnackbars from "../Alert/Alert";
import { useAuth } from "CustomComponents/Authcontext";
// Data
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
import { addLogsData, clearLogsData, addtempLogsData,clearTempLogsData } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
// Data
import { useSelector, useDispatch } from "react-redux";
import LinearProgress from '@mui/material/LinearProgress';

import { database } from "../../CustomComponents/firebase";
import { Box } from "@mui/material";

function Timeline() {
    const dd = useSelector((state) => state.LogesStore);
    const dispatch = useDispatch();
    const {currentUser}=useAuth();

    const [messagttouser, setmessagttouser] = useState("Loading Data...");
    const [sipnnershow, setspinnershow] = useState(false);
    const [selectCity, setselectCity] = useState(null);
    const [searchparam, setsearchparam] = useState(null);
    const [nexttoggle, setnexttoggle] = useState(true);
    //firestore options
    const [dropdowndata, setdropdowndata] = useState(null);
    //snack bar
    const [snakopen, setsnakopen] = useState(false)
    const [snackmessage, setsnackmessage] = useState(null)
    const [tempstate, settempstate] = useState(false)
    const [isend, setisend] = useState(false)
    const handleSnackbar = (messages) => {
        setsnakopen(true)
        setsnackmessage(messages)
    };
    //
    const [actionoption, setactionoption] = useState(null)
    const [rolesselect, setrolesselect] = useState(null)
    //state change
    const [setstatechange, setsetstatechange] = useState(true)
    //disabled set
    const [searchby, setsearchby] = useState({ value: 'All', label: 'All logs' })
    const [dataToggle, setdataToggle] = useState(dd.arr)
    useEffect(() => {
        if (searchby.value==='All') {
            setdataToggle(dd.arr)
        } else {
            setdataToggle(dd.temp)
        }
    }, [searchby,dd])

    useEffect(() => {
        if (dd.arr.length === 0) {
            onValue(ref(database, '/app_settings/logs_info/filters'), (snapshot) => {
                if (snapshot.val()) {
                    var temp = snapshot.val()
                    temp.Actions = Object.entries(temp.Actions)
                    setdropdowndata(temp)
                }
            }, {
                onlyOnce: true
            });
            calldataall_riderssearch("");
            settempstate(true)
            setsearchby({ value: 'All', label: 'All logs' })
        }
    }, [0]);

    async function calldataall_riderssearch(nexttokenparam) {
        var url = ''
        if (searchby.value === 'All') {
            nexttokenparam = dd.nextKey}
            else{
                nexttokenparam = dd.tempnextKey
            }
        if (searchby.value === 'All') {
            nexttokenparam = dd.nextKey
            url = process.env.REACT_APP_BASE_URL+`/logs?start=${nexttokenparam}&limit=20`
        } else if (searchby.value === 'Email') {
            if (searchparam !== null&&searchparam !== '') { url = process.env.REACT_APP_BASE_URL+`/logs?start=${nexttokenparam}&email=${searchparam}&limit=50` }
            else handleSnackbar('Please enter email id');
        } else if (searchby.value === 'Actions') {
            if (actionoption !== null) { url = process.env.REACT_APP_BASE_URL+`/logs?start=${nexttokenparam}&actions=${actionoption.value}&limit=50` }
            else handleSnackbar('Please select action type')
        } else if (searchby.value === 'Roles') {
            if (rolesselect !== null) { url = process.env.REACT_APP_BASE_URL+`/logs?start=${nexttokenparam}&roles=${rolesselect.value}&limit=50` }
            else handleSnackbar('Please select roles')
        }

        if (url !== '') {
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );
            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            if (!isend) {
                await fetch(
                    url, requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.status === true) {
                            if (result.data.length !== 0) {
                                setsnackmessage(' data to load')

                            }else{
                                setsnackmessage('No more data to load')

                            }
                            if (result.data.length !== 0) {
                                var dataset = []
                                result.data.map((item,key) => {
                                    var colord={colorcode:'info',badge_s:'un asigned'}
                                   
                                    dataset.push({
                                        color: (item.message).includes('updated') ? 'warning' : (item.message).includes('added')?'success':(item.message).includes('unlinked')?'dark':(item.message).includes('linked')?'light':'info',
                                        icon: "person",
                                        title: item.name,
                                        description: item.message,
                                        dateTime: item.logged_on,
                                        dateTimeq: new Date(item.logged_on * 1000).toLocaleString("en-US", {
                                            day: "numeric",
                                            month: "numeric",
                                            year: "numeric",
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                        }),
                                        lastItem:((result.data.length - 1)===key),
                                        badges: [(item.message).includes('updated') ? 'Updated' : (item.message).includes('added')||(item.message).includes('created')?'Created':(item.message).includes('unlinked')?'Unlinked':(item.message).includes('linked')?'Linked':'action error'],
                                    })
                                });
                                var lastkey = result.data[result.data.length - 1];
                                setnexttoggle(true);
                                if (setstatechange) {
                                    dispatch(addLogsData(dataset, lastkey.key));
                                } else {
                                    dispatch(addtempLogsData(dataset, lastkey.key));
                                }
                            } else {
                                setsnakopen(true)
                                setsnackmessage('No more data to load');
                                setisend(false)
                            }

                        } else {
                            alert(result.message);
                        }
                       
                    })
                    .catch((error) => {
                        setmessagttouser("Unable to get data....ERROR");
                        console.log("error", error);
                    });
            } else {
                setsnackmessage('No more data to load');
            }
        }



    }

    function handeloptionchange(params) {
        setsetstatechange(false)
        setisend(false);
        setsearchby(params);
    }

    const renderTimelineItems = dataToggle.map(
        ({ color, icon, title, dateTimeq, description, badges, lastItem },key) => (
            <TimelineItem
                key={title + color + dateTimeq+key}
                color={color}
                icon={icon}
                title={title}
                dateTime={dateTimeq}
                description={description}
                badges={badges}
                lastItem={lastItem}
            />
        )
    );
    if (dd.arr.length === 0) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        {sipnnershow && (
                            <Box sx={{ width: '100%', m: 0, p: 0 }}>
                                <LinearProgress color="success" sx={{ width: '100%' }} />
                            </Box>

                        )}
                        Loading..
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={1} pb={3}>
                <Card sx={{py:3}} >
                    {sipnnershow && (
                        <SuiBox sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{ width: '100%' }} />
                        </SuiBox>
                    )}
                    <SuiBox>

                        <Grid container spacing={2} sx={{ ml: 3, p: 2 }}>
                            <Grid item xs={12} sm={12} md={12} xl={12}>
                                <SuiTypography sx={{ ml: 1 }}  >Please select sort option</SuiTypography >
                            </Grid>

                            <Grid item xs={12} sm={3} md={3} xl={3}>
                                <SuiSelect
                                    required
                                    placeholder={'SearchBy'}
                                    value={searchby}
                                    options={['All', 'Email', 'Actions', 'Roles'].map((data2) => ({ value: data2, label: data2 }))}
                                    onChange={(e) => {
                                        handeloptionchange(e)
                                    }}
                                />

                            </Grid>
                            {searchby.value === 'Email' ? <Grid item xs={12} sm={4} md={4} xl={4}>
                                <SuiBox sx={{ display: 'flex', gap: 2 }}>
                                    <SuiInput
                                        value={searchparam}
                                        onChange={(e) => {
                                            setsearchparam(e.target.value);
                                        }}
                                        inputProps={{ type: "email" }}
                                        placeholder='Search by email id' />

                                </SuiBox>
                            </Grid> : null}
                            {searchby.value === 'Actions' ? <Grid item xs={12} sm={4} md={4} xl={4}>
                                {dropdowndata !== null ? (
                                    <SuiSelect
                                        required
                                        placeholder={'select actions'}
                                        value={actionoption}
                                        options={dropdowndata.Actions.map((data2) => ({ value: data2[1], label: data2[0] }))}
                                        onChange={(e) => {
                                            setactionoption(e);
                                            setsetstatechange(false)
                                        }}
                                    />
                                ) : null}

                            </Grid> : null}
                            {searchby.value === 'Roles' ? <Grid item xs={12} sm={4} md={4} xl={4}>
                                {dropdowndata !== null ? (
                                    <SuiSelect
                                        required
                                        placeholder={'select roles'}
                                        value={rolesselect}
                                        options={dropdowndata.Roles.map((data2) => ({ value: data2, label: data2 }))}
                                        onChange={(e) => {
                                            setrolesselect(e);
                                            setsetstatechange(false)
                                        }}
                                    />
                                ) : null}

                            </Grid> : null}
                           {dd.tempnextKey===''? <Grid item xs={12} sm={2} md={2} xl={2} sx={{ float: 'right', }}>
                               {searchby.value!==0} <SuiButton
                                    disabled={searchby.value === 'All'}
                                    variant="gradient" onClick={() => {
                                        calldataall_riderssearch()
                                    }} color="info"  >
                                    Search
                                </SuiButton>
                            </Grid>:null}
                            {dd.tempnextKey!==''?  <Grid item xs={12} sm={2} md={2} xl={2} sx={{ float: 'right', }}>
                                <SuiButton
                                    disabled={!searchby.value === 'All'}
                                    variant="gradient" onClick={() => {
                                        setisend(false)
                                        dispatch(clearTempLogsData());                                        
                                    }} color="info"  >
                                    Clear
                                </SuiButton>
                            </Grid>:null}
                        </Grid>
                    </SuiBox>
                    <TimelineList title="Activity logs">{renderTimelineItems}</TimelineList>
                    <SuiButton
                        disabled={isend}
                        onClick={() => {
                            calldataall_riderssearch('')
                        }} >show more</SuiButton>
                    <CustomizedSnackbars color={'error'} message={snackmessage} open={snakopen} handleClose={() => setsnakopen(false)} timeout={3000} />

                </Card>

            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Timeline;

