
// =========================================================

import { useState, useEffect, useMemo } from "react";
// import { Store } from './redux/Store'
import { Provider } from 'react-redux'
import store from './CustomComponents/dataStore/store';
import { useSelector, useDispatch } from "react-redux";
import Error404 from "./CustomViews/coverlogin/error/404";

import { Switch, Route, Redirect, useRoutes, useLocation, useRouteMatch } from 'react-router-dom'
// @mui material components
// import { ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";
import { AuthProvider } from "CustomComponents/Authcontext";
// Soft UI Dashboard PRO React components
import Cover from "./CustomViews/coverlogin";
// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
// import Configurator from "examples/Configurator";

// Soft UI Dashboard PRO React themes
// import theme from "assets/theme";
// import themeRTL from "assets/theme/theme-rtl";
import logo from "./assets/img/spinerlogo.png";
import logomini from "./assets/img/africarlogomini.png";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import "./loder.css";
// Soft UI Dashboard PRO React routes

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";
// Images
const Apps = () => {
  const Loading = useSelector((state) => state.Isload);
  const Permissions = useSelector((state) => state.Permissions);

  const dispatchstore = useDispatch();

  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);
  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  let { path, url } = useRouteMatch();

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={`${path}${route.route}`} group={route.group} component={route.component} key={route.key} />;
      }

      return null;
    });
  if (Loading) {
    return (<>
      <div className="wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="300" height="300">
          <circle
            className="circle"
            cx="150"
            cy="150"
            r="140"
            fill="none"
            stroke="#632b26"
            strokeWidth="5"
            strokeLinecap="square"
            strokeOpacity=".99213"
            paintOrder="fill markers stroke"
          />

          <circle
            id="arc4"
            className="circle"
            cx="150"
            cy="150"
            r="120"
            fill="none"
            stroke="#632b26"
            strokeWidth="30"
            strokeLinecap="square"
            strokeOpacity=".99213"
            paintOrder="fill markers stroke"
          />
          <image
            className="circle"
            xlinkHref={logo}
            x="25%"
            y="20%"
            height="200"
            width="150"
          />
          <circle
            id="arc6"
            className="circle"
            cx="150"
            cy="150"
            r="90"
            fill="none"
            stroke="#632b26"
            strokeWidth="16"
            strokeLinecap="square"
            strokeOpacity=".99213"
            paintOrder="fill markers stroke"
          />
          <circle
            id="arc5"
            className="circle"
            cx="150"
            cy="150"
            r="90"
            opacity=".49"
            fill="none"
            stroke="#632b26"
            strokeWidth="16"
            strokeLinecap="square"
            strokeOpacity=".99213"
            paintOrder="fill markers stroke"
          />
        </svg>{" "}
      </div>
    </>)
  }
  return (<>
    {layout === "dashboard" && (
      <>
        <Sidenav
          color={'success'}
          brand={logo}
          brandmini={logomini}
          brandName="Africar"
          routes={Permissions}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      </>
    )}

    <Switch>
      <Route exact path="/africar">
        <Redirect to="/africar/WarRoom" />
      </Route>
      {getRoutes(Permissions)}
      <Route component={Error404} />

    </Switch>

  </>
  )
}
function App() {
  store.subscribe(() => console.log)

  return (
    <Provider store={store}>
      <AuthProvider>
        <Switch>
          <Route exact path="/">
            <Redirect to="/africar/WarRoom" />
          </Route>
          <Route path='/africar' component={Apps} />
          <Route exact path='/login' component={Cover} />
          <Route component={Error404} />

        </Switch>
      </AuthProvider>
    </Provider>
  )
}

export default App


