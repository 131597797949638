
const initailState = {
    arr: [],
  arrsearch: [],
  nextKey: "",
}
const AccountsRevenuDriverdetails=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_REVENUE_DRIVER_DETAIL_DATA':
            return{ 
                arr: state.arr.concat(action.payload),
                nextKey:action.nextKey
            }
        
            case 'ADD_HIREARCHY_DETAILS_Clean':
                return initailState;
        default:
            return state;
    }
}

export default AccountsRevenuDriverdetails