import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useAuth } from "CustomComponents/Authcontext";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import CustomizedSnackbars from "../../Alert/Alert";

import { addRevenueRides, addRevenueRidesSearch } from "CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import LinearProgress from '@mui/material/LinearProgress';
import Rides from "./Rides.json";
import Divider from "@mui/material/Divider";

function RevenueRides() {
    const dd = useSelector((state) => state.AccountsRevenueRides);
    const { currentUser } = useAuth();

    const dispatch = useDispatch();
    const [nextkey, setnextkey] = useState(dd.nextKey || 0);
    const [messagttouser, setmessagttouser] = useState("Loading Data...");
    const [nexttoggle, setnexttoggle] = useState(true);
    const [sipnnershow, setspinnershow] = useState(false);
    //data date
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);
    const [isDisabled, setisDisabled] = useState(false);
    const [setclear, setsetclear] = useState(true);
    //dataexchange
    const [arrayData, setarrayData] = useState(dd.arr);
    const [minvalue, setminvalue] = useState()
    //snack bar
    const [snakopen, setsnakopen] = useState(false)
    const [snackmessage, setsnackmessage] = useState(null)
    const [snackcolor, setsnackcolor] = useState('error');

    const handleSnackbar = (messages) => {
        setsnakopen(true)
        setsnackmessage(messages)
    };
    //
    //min date
    var dmin = (dateselected) => {
        var temp = dateselected ? new Date(dateselected) : new Date()
        var d = temp.getDate();
        var m = temp.getMonth() + 1; //Month from 0 to 11
        var y = temp.getFullYear();
        return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }
    const handleSubmitSearch = async (event) => {
        event.preventDefault();
        setspinnershow(true);

        setisDisabled(true);
        var todateseconds = new Date(todate).getTime() / 1000;
        var fromdateseconds = new Date(fromdate).getTime() / 1000;
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        await fetch(
            process.env.REACT_APP_BASE_URL + `/accounts?type=revenue&sub_type=rides&from=${fromdateseconds}&to=${todateseconds}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === true) {
                    setisDisabled(false);
                    result.data.map((pro) => {
                        pro.netfare = pro.ride_cost - pro.rate_card.tax;
                        if (pro.booked_on) {
                            pro.booked_on = new Date(pro.booked_on).toLocaleString("en-US", {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                            });
                        }
                    });

                    setsetclear(false);

                    dispatch(
                        addRevenueRidesSearch(result.data, result.start, result.count)
                    );
                } else {
                    alert(result.message);
                }
                setspinnershow(false);
            })
            .catch((error) => {
                setspinnershow(false);

                setmessagttouser("Unable to get data....ERROR");
                console.log("error", error);
            });
    };
    const DownloadCSV = async () => {
        setspinnershow(true);
        setisDisabled(true);
        console.log(todate, fromdate);
        if (todate !== null && fromdate !== null && todate !== '' && fromdate !== '') {
            var todateseconds = new Date(todate).getTime() / 1000;
            var fromdateseconds = new Date(fromdate).getTime() / 1000;
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + currentUser.accessToken
            );

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            await fetch(
                process.env.REACT_APP_BASE_URL + `/accounts?type=revenue&sub_type=rides&from=${fromdateseconds}&to=${todateseconds}`, requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === true) {
                        setisDisabled(false);
                        result.data.map((pro) => {
                            pro.netfare = pro.ride_cost - pro.rate_card.tax;
                            if (pro.booked_on) {
                                pro.booked_on = new Date(pro.booked_on).toLocaleString("en-US", {
                                    day: "numeric",
                                    month: "numeric",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                });
                            }
                        });
                        setsetclear(false);
                        fileJson(result.data, 'Revenue Rides')
                        setspinnershow(false);

                    } else {
                        alert(result.message);
                        setspinnershow(false);
                        dispatch(addRevenueRidesSearch([]));
                        setsetclear(true);
                        setfromdate('')
                        settodate('')
                    }
                })
                .catch((error) => {
                    setspinnershow(false);
                    setmessagttouser("Unable to get data....ERROR");
                    console.log("error", error);
                });
        } else {

            alert('Please select the date')
            setspinnershow(false);
            dispatch(addRevenueRidesSearch([]));
            setsetclear(true);
            setfromdate('')
            settodate('')
        }
    };
    const fileJson = async (JSONData, ReportTitle, ShowLabel) => {
        var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

        var CSV = "";
        var datasest = [];
        datasest= arrData.map((data) => {
            data.rider_name = data.rider_lname + " " + data.rider_fname;
            const header = Object.keys(data);
            var rt = data.rate_card;
            var coupon = data.coupon_used;
            var cstate = data.coupon;
            delete data.rate_card;
            var dd = data.type;
            data.ride_type = dd;
            var con,
                aon,
                bon,
                von = "";
            if (data.completed_on) {
                con = new Date(data.completed_on * 1000).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (data.accepted_on) {
                aon = new Date(data.accepted_on).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (data.booked_on) {
                bon = new Date(data.booked_on).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (data.verified_on) {
                von = new Date(data.verified_on * 1000).toLocaleString("en-US", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
            }
            if (cstate) {
                data.coupon_name = coupon.id;
                data.coupon_discount = coupon.discount;
            } else {
                data.coupon_name = "";
                data.coupon_discount = "";
            }
            // if (bon[0] === '6' || bon[0] === 6) {
            //     datasest.push(
                   return {
                    ARN: data.ARN,
                    "Booking Time": bon,
                    "Accepted Time": aon,
                    "Rider name": data.rider_name,
                    "Driver name": data.driver_name,
                    "Registration number": data.registration_number,
                    "Ride Type": data.type,
                    "Required seats": data.required_seats,
                    "Distance Travelled": Number(data.distance).toFixed(2),
                    "Base Fare": rt.base_fare,
                    "Distance Fare": rt.distance_fare,
                    "Time Fare": rt.time_fare,
                    "Minimum fare": rt.min_fare_flag ? rt.min_fare : 0,
                    VAT: rt.tax,
                    "Network charges": rt.network_charges,
                    Rounded: rt.rounded,
                    "Ride Cost": data.ride_cost,
                    Coupon: data.coupon,
                    "Coupon Name": data.coupon_name,
                    "Coupon Discount": rt.discount,
                    "Rider Ratings": data.ratings,
                    "payment method": data.payment_method,
                    "Invoice ID   ": data.invoice,
                    "Paystack ID   ": data.pystk_id,
                }
                // );
            // }
        }
        );

        const items = datasest;
        const replacer = (key, value) => (value === null ? " " : value); // specify how you want to handle null values here
        const header = Object.keys(items[0]);
        CSV = [
            header.join(","), // header row first
            ...items.map((row) =>
                header
                    .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                    .join(",")
            ),
        ].join("\r\n");
        if (CSV == "") {
            alert("Invalid data");
            return;
        }
        //Generate a file name
        var fileName = "Report_";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += ReportTitle.replace(/ /g, "_");

        //Initialize file format you want csv or xls
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setisDisabled(false);
        setfromdate()
        settodate()
        dispatch(addRevenueRidesSearch([]));
        setsetclear(true);
    };
    //runes after opens page
    useEffect(() => {
        if (dd.arr.length === 0) {
            calldataall_riders(0);
        } else {
            setarrayData(dd.arr);
        }
    }, [dd.arr]);
    async function calldataall_ridersRepeat(e) {
        if (e >= 10) {
            var da = "";
            if (dd.nextKey !== "") {
                da = dd.nextKey;
            } else {
                da = nextkey;
            }
            if (da !== null && nexttoggle) {
                da = await calldataall_riders(da, e);
            }
        }

    }
    useEffect(() => {
        if (setclear) {
            setarrayData(dd.arr);
        } else {
            setarrayData(dd.arrsearch);
        }
    }, [dd.arrsearch]);
    //function call api
    async function calldataall_riders(nexttokenparam, limit = 10) {
        setspinnershow(true);
        setsetclear(true);
        if (nexttokenparam === undefined) {
            nexttokenparam = dd.nextKey || nextkey;
        }
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + currentUser.accessToken
        );

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        return await fetch(
            process.env.REACT_APP_BASE_URL + "/accounts?type=revenue&sub_type=rides&start=" +
            nexttokenparam + '&limit=' + limit,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === true) {
                    setnextkey(result.start);

                    result.data.map((pro) => {
                        if (pro.original_cost) {
                            pro.netfare = pro.original_cost - (pro.rate_card.tax + pro.rate_card.lasg_fee)
                        } else {

                        } pro.netfare = pro.ride_cost - (pro.rate_card.tax + pro.rate_card.lasg_fee);

                        if (pro.coupon) {
                            pro.discount = pro.coupon_used.discount
                            pro.netfare = pro.coupon_used.discount
                        } else {
                            pro.discount = 0

                        }
                        pro.rate_card.tax = pro.rate_card.tax + pro.rate_card.lasg_fee;

                        if (pro.booked_on) {
                            pro.booked_on = new Date(pro.booked_on).toLocaleString("en-US", {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                            });
                        }
                    });
                    dispatch(addRevenueRides(result.data, result.start, result.count));
                    setnexttoggle(true);
                    setspinnershow(false);
                    return result.start;
                } else {
                    setspinnershow(false);
                    setnexttoggle(false);
                    setmessagttouser("Unable to get data....ERROR : " + result.message);
                    console.log("error", result.message);
                    alert("No Data To Load");
                    return null;
                }
            })
            .catch((error) => {
                setnexttoggle(false);
                setmessagttouser("Unable to get data....ERROR");
                console.log("error", error);
            });
        //
    }
    if (dd.arr.length === 0) {

        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={2} pb={3}>
                <Card sx={{ m: 1, p: 2, pt: 0 }}>
                    {sipnnershow && (
                        <Box sx={{ width: '100%', m: 0, p: 0 }}>
                            <LinearProgress color="success" sx={{ width: '100%' }} />
                        </Box>

                    )}
                    <Divider />

                    <div>
                        <form onSubmit={handleSubmitSearch} >
                            <Grid container spacing={3}>
                                <Grid item md={3} >

                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >
                                        From Date{" "}
                                    </SuiTypography   >
                                    <SuiInput
                                        required
                                        inputProps={{ max: dmin(), min: "2010-05-04" }}
                                        value={fromdate}
                                        onChange={(e) => {
                                            setfromdate(e.target.value);
                                        }}
                                        type="date"
                                    />{" "}

                                </Grid>
                                <Grid item md={3} >

                                    <SuiTypography sx={{ fontSize: '0.9rem', }}  >
                                        To Date{" "}
                                    </SuiTypography   >
                                    <SuiInput
                                        required
                                        value={todate}
                                        inputProps={{ max: dmin(), min: dmin(fromdate) }}
                                        onChange={(e) => {
                                            settodate(e.target.value);
                                        }}
                                        type="date"
                                    />{" "}

                                </Grid>
                                <Grid item md={2} sx={{ mt: 'auto' }}>
                                    {setclear ? (
                                        <SuiButton id="search" type="submit">
                                            Submit
                                        </SuiButton>
                                    ) : null}

                                    {!setclear ? (
                                        <SuiButton
                                            type="button"
                                            onClick={() => {
                                                dispatch(addRevenueRidesSearch([]));
                                                setsetclear(true);
                                                setfromdate('')
                                                settodate('')
                                            }}
                                        >
                                            clear
                                        </SuiButton>
                                    ) : null}
                                </Grid>
                                <Grid item md={3} sx={{ mt: 'auto' }}>
                                    {setclear ? (
                                        <SuiButton onClick={() => {
                                            fileJson(Rides, 'Revenue Rides')
                                        }}>
                                            Download csv file
                                        </SuiButton>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <Divider />

                    <DataTable table={arrayData}
                        hidecolumnsdata={["coupon", "payment_id", "verified_on"]}
                        functioncallcount={calldataall_ridersRepeat}

                        datacolumns={[
                            {
                                Header: "Ride Number   ",
                                accessor: "ARN",
                            },
                            {
                                Header: "vehicle number",
                                accessor: "registration_number",
                            },
                            {
                                Header: "driver name",
                                accessor: "driver_name",
                            },
                            {
                                Header: "date",
                                accessor: "booked_on",
                            },
                            {
                                Header: "payment method",
                                accessor: "payment_method",
                            },
                            {
                                Header: "Paystack   ID   ",
                                accessor: "pystk_id",
                                tooltip: true
                            },
                            {
                                Header: "time of travel",
                                accessor: "duration_str",
                            },
                            {
                                Header: "distance ",
                                accessor: "distance_str",
                            },
                            {
                                Header: "coupon",
                                accessor: "coupon",
                            },
                            {
                                Header: "GROSS FARE ",
                                accessor: "netfare",
                            },

                            {
                                Header: "DISCOUNTS ",
                                accessor: "discount",
                            },
                            {
                                Header: "VAT",
                                accessor: "rate_card.tax",
                            },
                            {
                                Header: "final amount",
                                accessor: "ride_cost",
                            },
                        ]}

                        canSearch />
                </Card>
                <CustomizedSnackbars message={snackmessage} open={snakopen} color={snackcolor} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default RevenueRides;
