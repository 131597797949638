import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';

import { addvehicalsData } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete from "react-google-autocomplete";
import { useHistory } from "react-router-dom";
import { database } from "../../CustomComponents/firebase";
import { getDatabase, ref as sRef, push, child, set, get } from "firebase/database";
import { SignalCellularNullTwoTone } from "@mui/icons-material";
import { async } from "@firebase/util";

function AddCountry() {
    let history = useHistory();
    const [messagttouser, setmessagttouser] = useState("Loding Data...");
    const [countryname, setcountryname] = useState(null);
    const [playstore, setplaystore] = useState(null);
    const [appstore, setappstore] = useState(null);
    const [country, setcountry] = useState([]);
    useEffect(() => {

        const dbRef = sRef(database);
        get(child(dbRef, "app_settings/country_names")).then((snapshot) => {
            if (snapshot.exists()) {
                const datarate = snapshot.val();
                setcountry(datarate);
            } else {
                setmessagttouser('Data fetch error')
                console.log("No data available");
            }
        })
    }, []);
    const handleSubmit1 = async (e) => {
        e.preventDefault();

        if (countryname !== null && appstore !== null && playstore !== null&&appstore !== '' && playstore !== '') {
            var datass = JSON.parse(countryname);
            var data = {
                appstore_link: appstore,
                playstore_link: playstore,
                ...datass
            };
            var status = true;
            const war_roomdbRef = sRef(database);
            await get(child(war_roomdbRef, `localization/countries`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const datawar = snapshot.val();
                    console.log(datawar, datass.name);
                    datawar.map((d) => {
                        if (d.name === datass.name) {
                            status = false
                            alert('This Country Already Exists')
                        }
                    })
                    console.log(status);

                    if (status) {
                        datawar.push({ name: datass.name, code: datass.code })
                        set(sRef(database, 'localization/countries/'), datawar);
                    }
                }
            }).catch((error) => {
                console.error(error);
            });
            if (status) {
                set(sRef(database, 'localization/' + datass.name), data)
                    .then(() => {
                        // Data saved successfully!
                        alert('country added successfully');
                        setcountryname(null);
                        setplaystore('');
                        setappstore('');
                    })
                    .catch((e) => alert(e));
            } else {
                setplaystore('');
                setappstore('');
                setcountryname(null);

            }
        } else {
            alert('please fill the form')
        }
    }

    if (country.length === 0) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SuiBox pt={2} pb={3}>
                    <Card>
                        <SuiTypography variant="h6" sx={{ textAlign: 'center' }}>{messagttouser}</SuiTypography>
                    </Card>
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={1} pb={3}>
                <Card sx={{ m: 2, py: 5,pl:5 }}>
                <SuiTypography variant="h3" sx={{ textAlign: 'center' }}>Add Country</SuiTypography>

                    <form onSubmit={handleSubmit1} >
                        <Grid container spacing={2} sx={{ pl: 3 }}>
                            <Grid item xs={6} sm={5} md={6} xl={6}
                            >
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Select Country</SuiTypography>
                                <SuiSelect

                                    placeholder='select country'
                                    defaultValue={{ value: null, label: null }}
                                    value={countryname !== null ? { value: JSON.parse(countryname).name, label: JSON.parse(countryname).name } : null}
                                    options={country.map((entry) => ({ value: JSON.stringify(entry), label: entry.name }))}
                                    onChange={(e) => {
                                        setplaystore('');
                                        setappstore('');
                                        setcountryname(e.value);
                                    }}
                                />

                            </Grid >
                            <Grid item xs={12} sm={12} xl={12} md={12} />
                            <Grid item xs={6} sm={6} xl={6} md={6} >
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Enter Playstore App Link</SuiTypography>

                                <SuiInput
                                    required
                                    disabled={countryname === null}
                                    value={playstore}
                                    onChange={(e) => {
                                        setplaystore(e.target.value);
                                    }}
                                    inputProps={{ type: "text" }} placeholder='Playstore Link' />

                            </Grid >
                            <Grid item xs={12} sm={12} xl={12} md={12} />

                            <Grid item xs={6} sm={6} xl={6} md={6} >
                                <SuiTypography sx={{ fontSize: '0.9rem', }}  >Enter App Store Link</SuiTypography>

                                <SuiInput
                                    required
                                    disabled={countryname === null}

                                    value={appstore}
                                    onChange={(e) => {
                                        setappstore(e.target.value);
                                    }}
                                    inputProps={{ type: "text" }} placeholder='AppStore Link' />

                            </Grid >
                            <Grid item xs={12} sm={12} xl={12} md={12} sx={{ mb: 5, pb:1 }} />
                            <Grid item xs={6} sm={5} xl={3} md={3} sx={{ textAlign: 'center' }}
                            >
                                {" "}
                                <SuiButton
                                    disabled={countryname === null}

                                    sx={{ width: '100%' }}
                                    id="submit" type="submit" >
                                    Save
                                </SuiButton>

                            </Grid >

                            <Grid item xs={6} sm={5} xl={3} md={3} sx={{ textAlign: 'center' }}
                            >
                                {" "}
                                <SuiButton
                                    sx={{ width: '100%' }}

                                    className="cancelButton"
                                    onClick={() => history.goBack()}
                                >
                                    Back
                                </SuiButton>

                            </Grid >
                        </Grid>
                    </form>
                </Card>
            </SuiBox>
            <Footer />
        </DashboardLayout >
    );
}

export default AddCountry;
