
const initailState = {
    arr:[],
    nextKey:''
}
const BlacklisteuUsersStore=(state=initailState,action)=>{
    switch(action.type){
        case 'ADD_BLACKLISTED_USERS':
            return{ 
                arr: state.arr.concat(action.payload),
                nextKey:action.keynext,

            }
        default:
            return state;
    }
}

export default BlacklisteuUsersStore