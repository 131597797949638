import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "CustomViews/Components/Tables/DataTable";
// Data
import DownloadIcon from '@mui/icons-material/Download';
import Papa from "papaparse";
import Fade from '@mui/material/Fade';
import CustomizedSnackbars from "../Alert/Alert";

import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';

import { AddMobileddata } from "../../CustomComponents/dataStore/actions";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from "CustomComponents/Authcontext";

function Mobile() {
  const dd = useSelector((state) => state.MobileData);
  const cities = []
  // useSelector((state) => state.Ratecarddata.asasa||[]);
  const dispatch = useDispatch();
  const [nextkey, setnextkey] = useState(dd.nextKey || "");
  const [messagttouser, setmessagttouser] = useState("Loading Data...");
  const [nexttoggle, setnexttoggle] = useState(true);
  const [sipnnershow, setspinnershow] = useState(false);
  const { currentUser } = useAuth();

  const Userinfo = useSelector((state) => state.Userinfo);

  //data date
  const [isDisabled, setisDisabled] = useState(false);
  const [searchparam, setsearchparam] = useState("");
  //dataexchange
  const [arrayData, setarrayData] = useState(dd.arr);
  const [isDisabledsearch, setisDisabledsearch] = useState(false);
  //model handlers
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //snack bar
  const [snakopen, setsnakopen] = useState(false)
  const [snackmessage, setsnackmessage] = useState(null)

  const handleSnackbar = (messages) => {
    setsnakopen(true)
    setsnackmessage(messages)
  };
  //
  //modal form
  const [Brandname, setBrandname] = useState("");
  const [IMEInumber, setIMEInumber] = useState("");
  const [buildNumber, setbuildNumber] = useState("");
  const [showMultiple, setshowMultiple] = useState(true);
  const [fileselectedjson, setfileselectedjson] = useState({ data: [] });
  const CSVToJsonData = (e) => {
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        header: true,
        complete: function (results) {
          setfileselectedjson({ data: results.data });
        },
      });
    }
  };
  function CallMobileData(nexttokenparam, limit = 100) {
    setspinnershow(true)
    if (nexttokenparam === undefined) {
      nexttokenparam = dd.nextKey || nextkey;
    }

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + currentUser.accessToken
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_BASE_URL + "/asset?start=" +
      nexttokenparam +
      "&type=mobile&limit=" + limit,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true && result.data.length !== 0) {

          result.data.map((doc) => {
            doc.linked_to_vehicle = doc.linked_to_vehicle ? "Yes" : "No";
            doc.linked_to_sim = doc.linked_to_sim ? "Yes" : "No";
            doc.added_on = new Date(doc.added_on * 1000).toLocaleString(
              "en-US",
              {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }
            );
            if (doc.linked_on) {
              if (doc.linked_on.length > 1) {
                doc.linked_on = new Date(doc.linked_on[doc.linked_on.length - 1] * 1000).toLocaleString(
                  "en-US",
                  {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }
                );
              } else {
                doc.linked_on = new Date(doc.linked_on * 1000).toLocaleString(
                  "en-US",
                  {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }
                );
              }
            } else {
              doc.linked_on = "--";
            }
            console.log(doc.linked_to_sim);
            if (Userinfo.isadmin&&doc.linked_to_sim === 'Yes') {
              doc.unlinkaction = (
                <SuiButton variant="gradient"
                  color="info" sx={{ width: '100%' }} onClick={() => {
                    if (doc.linked_to_sim === 'Yes') {
                      var myHeaders = new Headers();
                      myHeaders.append("Authorization", "Bearer " + currentUser.accessToken);
                      myHeaders.append("Content-Type", "application/json");
                      var raw = JSON.stringify({
                        "type": "mobile_sim",
                        "unlink": [
                          {
                            "sim_id": doc.sim_id,
                            "mobile_id": doc.id
                          }
                        ],
                        "reason": "Admin unlinked the assets"
                      });
                      console.log(raw);
                      var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                      };

                      fetch(process.env.REACT_APP_BASE_URL + "/unlink", requestOptions)
                        .then(response => response.text())
                        .then(result => { alert(result) })
                        .catch(error => console.log('error', error));

                    }

                  }}>{'Unlink'}</SuiButton>);
            } else {
              doc.unlinkaction = (
                <div >
                  {/* use this button to add a edit kind of action */}
                  <Typography variant="h6">-</Typography>

                </div>
              );
            }
          });
          if (result.start === null) {
            // alert("No More Data To Load.");
            setmessagttouser("No Data To Load");
          } else {
            setnextkey(result.start);
            setmessagttouser("");
            setspinnershow(false)
            dispatch(AddMobileddata(result.data, result.start));
          }
          return result.start;

        } else {
          setnexttoggle(false)
          alert('No Data To Load');
          setspinnershow(false)

          return null;

        }
      })
      .catch((error) => {
        console.log("error", error); setspinnershow(false)
      });
  }
  function DownloadSampleCSV() {
    const header = ["brand_name", "build_number", "IMEI"];
    var CSV = [header.join(",")].join("\r\n");
    //Generate a file name
    var fileName = "Sample";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += "MobileUpload".replace(/ /g, "_");
    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    var link = document.createElement("a");
    link.href = uri;
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function handleSubmitMultiple(e) {
    e.preventDefault();
    setspinnershow(true);

    document.getElementById("multiple").setAttribute("disabled", "disabled");
    if (fileselectedjson.data.length !== 0) {
      var heads = Object.keys(fileselectedjson.data[0]);
      if (
        heads.includes("IMEI") &&
        heads.includes("build_number") &&
        heads.includes("IMEI") &&
        heads.length === 3
      ) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Bearer " + currentUser.accessToken
        );
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(fileselectedjson);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          process.env.REACT_APP_BASE_URL + "/asset?type=mobile",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === true) {
              setspinnershow(false);

              alert("Mobiles added Successfully");
              document.getElementById("multiple").removeAttribute("disabled");
              setfileselectedjson({ data: [] });
            } else {
              alert("Error", result.message);
              document.getElementById("multiple").removeAttribute("disabled");
              setspinnershow(false);
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        document.getElementById("multiple").removeAttribute("disabled");
        setspinnershow(false);
        alert(
          "The Uploaded File Contains foreign fields.Please Check the file format"
        );
      }
    } else {
      document.getElementById("multiple").removeAttribute("disabled");
      setspinnershow(false);
      alert("The uploaded file contain Zero Fields");
    }
  }
  function handleSubmit1(e) {
    e.preventDefault();
    if (Brandname !== null && buildNumber !== null && IMEInumber !== null) {

      setspinnershow(true);

      setisDisabled(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + currentUser.accessToken
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        type: "mobile",
        brand_name: Brandname,
        build_number: buildNumber,
        IMEI: IMEInumber,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(
        process.env.REACT_APP_BASE_URL + "/asset",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            alert("Mobile added Successfully");
            document.getElementById("submit").removeAttribute("disabled");
            setIMEInumber(null);
            setbuildNumber(null);
            setBrandname(null);
            setisDisabled(false);
            setspinnershow(false);
            handleClose()
          } else {
            setIMEInumber(null);
            setbuildNumber(null);
            setBrandname(null);
            setmessagttouser(result.message);
            alert("Error", result.message);
            document.getElementById("submit").removeAttribute("disabled");
            setisDisabled(false);
            setspinnershow(false);
          }
        })
        .catch((error) => {
          console.log("error", error);
          setspinnershow(false);

        });
    } else {
      alert('Please provide valid data.')
    }
  }

  useEffect(() => {
    if (dd.arr.length === 0) {
      CallMobileData('', 200);
    }
  }, []);
  async function callsimRepeat(e) {
    if (e >= 10) {
      var da = "";
      if (dd.nextKey !== "") {
        da = dd.nextKey;
      } else {
        da = nextkey;
      }
      if (da !== null && nexttoggle) {
        da = await CallMobileData(da, e);
      }
    }

  }
  // if (dd.arr.length === 0) {
  //   return (
  //     <DashboardLayout>
  //       <DashboardNavbar />
  //       <SuiBox pt={2} pb={3}>
  //         <Card>
  //           {sipnnershow && (
  //             <Box sx={{ width: '100%', m: 0, p: 0 }}>
  //               <LinearProgress color="success" sx={{ width: '100%' }} />
  //             </Box>

  //           )}
  //           <Grid container spacing={3} >

  //             <Grid item xs={12} sm={4} md={4} xl={4}>
  //               <p style={{ marginLeft: '10px' }}>{messagttouser}</p>

  //             </Grid>
  //             <Grid item xs={12} sm={4} md={4} xl={4}>

  //             </Grid>
  //             <Grid item xs={12} sm={4} md={4} xl={4}>
  //               <SuiBox>
  //                 <SuiButton sx={{ float: 'right' }} variant="gradient" onClick={() => {
  //                   handleOpen()
  //                 }} color="info"  >
  //                   Add Mobiles <span style={{ marginLeft: '5px' }}> <FontAwesomeIcon sx={{ ml: 2 }} icon={faMobileAlt} /></span>
  //                 </SuiButton>
  //               </SuiBox>
  //             </Grid>

  //           </Grid>
  //         </Card>
  //       </SuiBox>
  //       <Footer />
  //     </DashboardLayout>
  //   );
  // }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={2} pb={3}>
        <Card >
          {sipnnershow && (
            <Box sx={{ width: '100%', m: 0, p: 0 }}>
              <LinearProgress color="success" sx={{ width: '100%' }} />
            </Box>
          )}
          <Grid container spacing={1} sx={{ p: 2 }}>

            <Grid item xs={12} sm={4} md={4} xl={4}>

            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={4}>

            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={4}>
              <SuiBox>
                <SuiButton sx={{ float: 'right' }} variant="gradient" onClick={() => {
                  handleOpen()
                }} color="info"  >
                  Add Mobiles<span style={{ marginLeft: '5px' }}> <FontAwesomeIcon sx={{ ml: 2 }} icon={faMobileAlt} /></span>
                </SuiButton>
              </SuiBox>
            </Grid>

          </Grid>
          {dd.arr.length !== 0 ? (

            <DataTable table={dd.arr}

              functioncallcount={callsimRepeat}

              hidecolumnsdata={["linked_on", ]}
              datacolumns={[
                // {
                //   Header: "Mobile Brand",
                //   accessor: "brand_name",
                // },
                {
                  Header: "IMEI Number",
                  accessor: "IMEI",
                },
                {
                  Header: "Phone number",
                  accessor: "number",
                },
                
                {
                  Header: "unlinkaction",
                  accessor: "unlinkaction",
                },

                // {
                //   Header: "Build Number",
                //   accessor: "build_number",
                // },
                // {
                //   Header: "added on",
                //   accessor: "added_on",
                // },
                {
                  Header: "linked to vehicle",
                  accessor: "linked_to_vehicle",
                },
                {
                  Header: "linked to SIM",
                  accessor: "linked_to_sim",
                },
               
               
              
              ]}
              canSearch />) : null}

        </Card>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box sx={
            {
              border: 0,
              borderRadius: '10px',
              boxShadow: '0 0 15px rgb(0 0 0 / 15%), 0 0 1px 1px rgb(0 0 0 / 10%)',
              position: 'absolute',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              flexDirection: 'column',
              width: showMultiple ? '50vw' : '60vw',
              pointerEvents: 'auto',
              backgroundColor: '#fff',
              backgroundClip: 'padding-box',
              outline: 0,
              padding: '20px 50px'
            }
          } >
            <Typography sx={{ textAlign: 'center' }}>
              Add Mobile
            </Typography>
            <hr />
            <Typography sx={{ fontSize: '0.9rem', m: 2 }}>
              Upload Multiple
              <Switch
                onChange={() => {
                  setshowMultiple(!showMultiple);
                }
                }
              />
            </Typography>
            {showMultiple === true ? (
              <div>

                <form onSubmit={handleSubmit1} >
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} xl={6} >
                      <formGroup>
                        <p className="Labelstyle">Brand Name </p>
                        <SuiInput
                          required
                          placeholder="Enter Brand Name "
                          type="text"
                          value={Brandname}
                          // onChange={(e) => {
                          //   setBrandname(e.target.value);
                          // }}
                          inputProps={{ maxLength: 20 }}

                          onChange={(e) => {
                            var re = /^([a-zA-z0-9 ]+|)$/;
                            if (re.test(e.target.value)) {
                              setBrandname((e.target.value))
                            }
                            else {
                              handleSnackbar('Special characters are not allowed')

                            }
                          }
                          }
                        />
                      </formGroup>{" "}
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} xl={6}>
                      <formGroup>
                        <p className="Labelstyle">
                          IMEI Number for SIM one
                        </p>
                        <SuiInput
                          required
                          placeholder="Enter IMEI Number"
                          type="text"
                          value={IMEInumber}
                          inputProps={{ maxLength: 20 }}

                          onChange={(e) => {
                            var re = /^([0-9]+|)$/;
                            if (re.test(e.target.value)) {
                              setIMEInumber((e.target.value))
                            }
                            else {
                              handleSnackbar('Special characters and alphabet are not allowed')

                            }
                          }
                          }

                        />
                      </formGroup>{" "}
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} xl={6}>
                      <formGroup>
                        <p className="Labelstyle">Build Number</p>
                        <SuiInput
                          required
                          placeholder="Enter Build Number"
                          type="text"
                          value={buildNumber}

                          inputProps={{ maxLength: 20 }}

                          onChange={(e) => {
                            var re = /^([A-Za-z0-9_]+|)$/;
                            if (re.test(e.target.value)) {
                              setbuildNumber((e.target.value))
                            }
                            else {
                              handleSnackbar('Special characters are not allowed')
                            }
                          }
                          }
                        />
                      </formGroup>{" "}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12} />

                    <Grid item xs={6} sm={6} md={6} xl={6}>
                      <Button
                        disabled={isDisabled}
                        id="submit"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} xl={6}>
                      <Button
                        onClick={() => {
                          setOpen(false)
                        }}
                      >
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            ) : (
              <div>
                {sipnnershow ? (
                  <div style={{ display: "inline-block" }}>
                    Processing data... <CircularProgress size={20} color="primary" />
                  </div>
                ) : null}
                <form onSubmit={handleSubmitMultiple} className="w-100">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                      <p>
                        Please follow the format given while uploading the
                        .csv
                      </p>
                      <p>
                        Download sample CSV
                        <Button
                          style={{ width: "100px" }}
                          onClick={() => {
                            DownloadSampleCSV();
                          }}
                        >
                          <DownloadIcon sx={{ width: '2rem', height: '2rem', color: 'green' }} />
                        </Button>
                      </p>

                      <table style={{ backgroundColor: 'black', color: 'white', width: "75%", textAlign: 'center' }}>
                        <thead style={{ padding: '.1rem' }}>
                          <tr>
                            <th style={{ textTransform: "lowercase" }}>
                              brand_name
                            </th>
                            <th style={{ textTransform: "lowercase", borderLeft: '2px solid white' }}>
                              build_number
                            </th>
                            <th style={{ textTransform: "lowercase", borderLeft: '2px solid white' }}>
                              IMEI
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Samsung</td>
                            <td style={{ borderLeft: '2px solid white' }}>S2043xx</td>
                            <td style={{ borderLeft: '2px solid white' }}>14212XXXXXXX</td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} xl={6}>
                      <hr />

                      <SuiInput
                        style={{ borderLeft: "6px solid red" }}
                        required
                        type="file"
                        accept=".csv"
                        onChange={(e) => {
                          CSVToJsonData(e);
                        }}
                      />
                      <hr />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12} />

                    <Grid item xs={6} sm={6} md={6} xl={6}>
                      <Button
                        disabled={isDisabled}
                        id="multiple"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} xl={6}>
                      <Button
                        onClick={() => {
                          setOpen(false)
                        }}
                      >
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            )}
          </Box>
        </Modal>
        <CustomizedSnackbars color={'error'} message={snackmessage} open={snakopen} handleClose={() => setsnakopen(!snakopen)} timeout={3000} />

      </SuiBox>
      <Footer />
    </DashboardLayout >
  );
}

export default Mobile;
