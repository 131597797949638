
const initailState = {
  arr:[],
  nextKey:0,
  secarr:[],
  secnextKey:0,

}
const AccountsCollectionRides=(state=initailState,action)=>{
  switch(action.type){
      case 'ADD_COLLECTION_RIDES_DATA':
          return{ 
              ...state,
              arr: state.arr.concat(action.payload),
              nextKey:action.nextKey,

          }
          case 'ADD_SECOND_COLLECTION_RIDES_DATA':
              return{ 
                  ...state,
                  secarr: state.secarr.concat(action.payload),
                  secnextKey:action.nextKey,
  
              }
              case 'CLEAN_SECOND_COLLECTION_RIDES_DATA':
              return{ 
                  ...state,
                  secarr: [],
                  secnextKey:'',
  
              }
      default:
          return state;
  }
}

export default AccountsCollectionRides